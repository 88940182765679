import React from 'react';
import { UiView } from '@dex/bubl-ui';
import styles from "./Line.module.scss";

const Line: React.FC<LineProps> = (props) => {

    return (

        <UiView className={styles.line}>

        </UiView>

    )

}

interface LineProps {
    [key: string]: any,
}

export default Line;