import React from 'react'

function QuickLinksSubscribeIcon(props: any) {
    return (
        <svg
            width="43"
            height="40"
            viewBox="0 0 43 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >

            <g id="subscriptions_24px">

                <path
                    id="icon/av/subscriptions_24px"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.29159 0H23.7083C24.5562 0 25.2499 0.69375 25.2499 1.54167C25.2499 2.38958 24.5562 3.08333 23.7083 3.08333H8.29159C7.44367 3.08333 6.74992 2.38958 6.74992 1.54167C6.74992 0.69375 7.44367 0 8.29159 0ZM5.20825 9.25H26.7916C27.6395 9.25 28.3333 8.55625 28.3333 7.70833C28.3333 6.86042 27.6395 6.16667 26.7916 6.16667H5.20825C4.36034 6.16667 3.66659 6.86042 3.66659 7.70833C3.66659 8.55625 4.36034 9.25 5.20825 9.25ZM31.4166 15.4167V27.75C31.4166 29.4458 30.0291 30.8333 28.3333 30.8333H3.66659C1.97075 30.8333 0.583252 29.4458 0.583252 27.75V15.4167C0.583252 13.7208 1.97075 12.3333 3.66659 12.3333H28.3333C30.0291 12.3333 31.4166 13.7208 31.4166 15.4167ZM12.9166 16.5421L20.9178 20.905C21.4574 21.1979 21.4574 21.9687 20.9178 22.2617L12.9166 26.6092V16.5421Z"
                    fill="#DFE4EA" />
            </g>

        </svg>
    )
}

export default QuickLinksSubscribeIcon
