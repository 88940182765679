import React, { useState } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./LeagueLeaderPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import Grid from "../../elements/Grid/Grid";
import MemberCard from "../../elements/MemberCard/MemberCard";
import DropSelect from "../../elements/DropSelect/DropSelect";

const LeagueLeaderPanel: React.FC<LeagueLeaderPanelProps> = (props) => {

    let { heading, leaderboards, seasonSlug, seasonId } = props;

    const selectOptions: { label: any; value: any; }[] = [];

    if (leaderboards) {
        // eslint-disable-next-line array-callback-return
        leaderboards.map((item: any, key: any) => {
            selectOptions.push({ label: item.name, value: key });
        })
    }

    const [current, setCurrent] = useState<any>(leaderboards[0]);

    const onHandleLabelChange = (item: any) => {

        setCurrent(leaderboards[item.value]);

    }

    return (

        <Panel
            heading={heading}
            edge={"sides"}
            topLeft={
                <>
                    {selectOptions &&
                        <DropSelect
                            items={selectOptions}
                            defaultValue={selectOptions[0]}
                            onChange={onHandleLabelChange}
                            styleDropDown={styles.select}
                        />
                    }
                </>
            }
            id={props.id}
        >

            {leaderboards &&
                <Grid
                    columns={6}
                    gap={4}
                >

                    {current.players.map((player: any, index: number) => (

                        <UiView className={index ? styles.item : styles.itemLeader} key={index}>

                            <MemberCard
                                name={player.name}
                                position={player.position}
                                teamName={player.teamName}
                                number={player.jerseyNumber}
                                stat={player.stat}
                                statLabel={current.statSuffix}
                                image={{
                                    type: "thumb",
                                    src: player.photo || { fileId: "" },
                                }}
                                path={`/leagues/season/${seasonId}/${seasonSlug}/player/${player.id}/${player.slug}`}
                            />

                        </UiView>

                    ))}

                </Grid>

            }

        </Panel>

    );
};

interface LeagueLeaderPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default LeagueLeaderPanel;
