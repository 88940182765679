import React from 'react';
import { UiView, UiText } from '@dex/bubl-ui';
import styles from "./DotIndicator.module.scss";

const DotIndicator: React.FC<DotIndicatorProps> = (props) => {

    const { count, index } = props;

    const repeatIndicator = new Array(count).fill(0);

    return (

        <UiView className={styles.container}>

            {repeatIndicator.map((value: any, key: any) => <UiView className={[styles.dot, index === key ? styles.active : '']} key={key}></UiView>)}

        </UiView>

    )

}

interface DotIndicatorProps {
    [key: string]: any,
}

export default DotIndicator;