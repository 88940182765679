import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./CollectionBlock.module.scss";

import Grid from "../Grid/Grid";
import StandardCard from "../StandardCard/StandardCard";
import Panel from "../Panel/Panel";
import CommunityCard from "../CommunityCard/CommunityCard";
import NotFound from "../NotFound/NotFound";

const CollectionBlock: React.FC<CollectionBlockProps> = (props) => {

    let prefix = "";
    let columns = props.columns || 5;
    let gap = props.gap || 4;
    let Element = props.element || StandardCard;

    if (props.modelName === "League") {

        prefix = "/leagues/operator";

    } else if (props.modelName === "CampSessionGroup") {

        prefix = "/camps/group";

    } else if (props.modelName === "PickupSessionGroup") {

        prefix = "/pickups/group";

    } else if (props.modelName === "VenueCourt") {

        prefix = "/venues/court";

    } else if (props.modelName === "Hosts") {

        prefix = "/community";
        columns = 7;
        Element = CommunityCard;

    }

    return (

        <Panel
            id={props.id}
            heading={props.heading}
            edge={"sides"}
        >

            {props.cards &&
                <Grid
                    columns={columns}
                    gap={gap}
                >

                    {props.cards.map((card: any, key: number) => {

                        card.prefix = prefix;

                        if (card.modelName === "League") {

                            card.prefix = "/leagues/operator";

                        } else if (card.modelName === "CampSessionGroup") {

                            card.prefix = "/camps/group";

                        } else if (card.modelName === "PickupSessionGroup") {

                            card.prefix = "/pickups/group";

                        } else if (card.modelName === "VenueCourt") {

                            card.prefix = "/venues/court";

                        } else if (card.modelName === "Hosts") {

                            card.prefix = "/community";

                        }

                        return (

                            <UiView className="item" key={key}>

                                <Element
                                    path={`${card.prefix}/${card.id}/${card.slug}`}
                                    title={card.title}
                                    image={card.thumbnail || card.logo || card.cover}
                                />

                            </UiView>

                        )

                    })}

                </Grid>
            }

            <NotFound
                data={props.cards}
                message={props.empty}
            />

        </Panel>

    );
};

interface CollectionBlockProps {
    modelName: string,
    heading: string,
    cards?: any,
    [key: string]: any;
}

export default CollectionBlock;
