import React, { useCallback } from 'react';
import { UiView, UiText } from '@dex/bubl-ui';
import { IoMdVideocam } from 'react-icons/io';
import { MdAddShoppingCart } from "react-icons/md";
import moment from 'moment';

import Space from "../../elements/Space/Space";
import Button from "../../elements/Button/Button";
import VideoBlock from "../../elements/VideoBlock/VideoBlock";
import OrderApi from '../../apis/OrderApi';
import PassesPopupBuy from "../PassesPopupBuy/PassesPopupBuy";

import styles from './VenueBookingVideo.module.scss'
import GoogleAd from '../../elements/GoogleAd/GoogleAd';

const VenueBookingVideo: React.FC<VenueBookingVideoProps> = (props) => {

    const { data } = props;

    return (
        <>

            {data.videoStatus === "available" && data.sessionGroup.hasVideo && data.sessionGroup.videoProductId &&

                <VenueBookingVideoAvailable
                    bookingId={data.id}
                    productId={data.sessionGroup.videoProductId}
                    price={data.videoPrice}
                />

            }

            {(data.videoStatus === "purchased" || (data.videoStatus === "ready" && (!data.video || (data.video && !data.video.id)))) &&

                <VenueBookingVideoPurchased date={data.dateTime} />

            }

            {data.videoStatus === "ready" && data.video && data.video.id && data.video.source &&

                <VenueBookingVideoReady video={data.video} />

            }

            <Space />
        </>
    )
}


const VenueBookingVideoAvailable: React.FC<VenueBookingVideoAvailableProps> = (props) => {

    const { bookingId, productId, price } = props

    const label = `Unlock game footage for everyone in this session for ${price && ': RM ' + price}`

    const order = OrderApi.generate();

    const handleClick = useCallback(() => {

        if (order.loading) return;

        order.run({
            data: {
                bookingId,
                productId,
            }
        })

    }, [])

    const handleRefresh = useCallback(() => {

        order.reset();

    }, [order])

    return (

        <>


            <UiView className={styles.buttonContainer}>

                <Button
                    label={label}
                    theme={"secondary"}
                    onClick={handleClick}
                    iconAfter
                    icon={<MdAddShoppingCart size={18} className={styles.cartIcon} />}
                />

                {order.complete && order.data &&
                    <PassesPopupBuy
                        order={order.data}
                        handleRefresh={handleRefresh}
                    />
                }

            </UiView>

        </>

    )

}

const VenueBookingVideoPurchased: React.FC<VenueBookingVideoPurchasedProps> = (props) => {

    const { date } = props;

    const when = moment(date);

    return (
        <UiView className={styles.buttonContainer}>

            <IoMdVideocam className={styles.videoIcon} />

            <UiText className={styles.streamTitle}>
                LIVE STREAM WILL START @ {when.format('hh:mm A')}
                {!when.isSame(new Date(), 'day') &&
                    <><br />{when.format("D MMMM YYYY")}</>
                }
            </UiText>

        </UiView>
    )
}

const VenueBookingVideoReady: React.FC<VenueBookingVideoReadyProps> = (props) => {

    const { video } = props;

    return (
        <>

            <VideoBlock
                source={video.source}
                id={video.id}
            />

            <Space />

            <GoogleAd
                slot="leaderboard"
            />

        </>
    )

}

interface VenueBookingVideoReadyProps {
    [key: string]: any;
}

interface VenueBookingVideoPurchasedProps {
    [key: string]: any;
}

interface VenueBookingVideoAvailableProps {
    [key: string]: any;
}

interface VenueBookingVideoProps {
    [key: string]: any;
}

export default VenueBookingVideo;