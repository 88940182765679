import React from "react";
import { UiFieldDate } from "@dex/bubl-ui";
import { MdDateRange } from 'react-icons/md';

import styles from "../Fields.module.scss";

const FieldDate: React.FC<FieldDateProps> = (props) => {

    return (

        <UiFieldDate
            {...props}
            indicator={<MdDateRange className={styles.dateIndicator} />}
            classes={{
                wrap: [styles.wrap],
                label: [styles.label],
                input: [styles.input],
                error: [styles.error],
            }}
        />

    );
};

interface FieldDateProps {
    [key: string]: any;
}

export default FieldDate;
