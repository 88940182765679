import React from "react";

import Space from "../Space/Space";
import ContentBlocks from "../ContentBlocks/ContentBlocks";
import NewsArticleHeader from "./NewsArticleHeader";
import NewsArticleBanner from "./NewsArticleBanner";
import NewsArticlePhotos from "./NewsArticlePhotos";
import NewsArticleBoxScore from "./NewsArticleBoxScore";

const NewsArticle: React.FC<NewsArticleProps> = (props) => {

    const { article } = props;

    return (

        <>

            <Space height={3} />

            <NewsArticleHeader
                article={article}
            />

            <Space height={2} />

            <NewsArticleBanner
                article={article}
            />

            {article.contentBlocks &&
                <>

                    <Space height={2} />

                    <ContentBlocks
                        blocks={article.contentBlocks}
                    />

                </>
            }

            {(article.type === "recap" || article.type === "photos") && article.galleryIds && article.galleryIds.length > 0 &&

                <>

                    <Space height={2} />

                    <NewsArticlePhotos
                        article={article}
                    />

                </>

            }

            {article.type === "recap" && article.gameLog &&

                <>

                    <Space height={2} />

                    <NewsArticleBoxScore
                        article={article}
                    />

                </>

            }

            <Space height={3} />

        </>

    );
};

interface NewsArticleProps {
    article?: any,
    [key: string]: any;
}

export default NewsArticle;
