import React, { useCallback, useState } from "react";

import styles from "./BracketPanel.module.scss";
import Panel from "../../elements/Panel/Panel";
import Bracket from "../../elements/Bracket/Bracket";
import DropSelect from "../../elements/DropSelect/DropSelect";

const BracketPanel: React.FC<BracketPanelProps> = (props) => {

    let { season, heading, divisions } = props;

    const options = divisions.map((division: any) => {
        return { value: division.id, label: division.name }
    });

    const [division, setBracket] = useState(divisions[0]);

    const handleChangeBracket = useCallback((event) => {

        let selected = divisions[0];

        divisions.map((division: any) => {

            if (division.id === event.value) selected = division;

            return division;

        })

        setBracket(selected);

    }, []);


    return (

        <Panel
            heading={heading || "Bracket"}
            topLeft={
                <>
                    <DropSelect
                        items={options}
                        defaultValue={division && division.id}
                        onChange={handleChangeBracket}
                    />
                </>
            }
        >

            {division &&
                <Bracket
                    division={division}
                    bracket={division.bracket}
                    season={season}
                />
            }

        </Panel>

    );
};

interface BracketPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default BracketPanel;
