import React from "react";
import { UiFieldNumber } from "@dex/bubl-ui";

import styles from "../Fields.module.scss";

const FieldNumber: React.FC<FieldNumberProps> = (props) => {

    return (

        <UiFieldNumber
            {...props}
            classes={{
                wrap: [styles.wrap],
                label: [styles.label],
                input: [styles.input, props.className],
                error: [styles.error],
            }}
        />


    );
};

interface FieldNumberProps {
    [key: string]: any;
}

export default FieldNumber;
