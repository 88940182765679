import React, { useMemo, useCallback, useState } from "react";
import { UiCol, UiLink, UiRow, UiText, useAsyncEffect, useUser } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";
import { IoMdArrowBack } from "react-icons/io";
import { config } from "@dex/bubl-web";
import moment from "moment";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import Panel from "../../elements/Panel/Panel";
import InlineLogin from "../../groups/InlineLogin/InlineLogin";
import VenueBookingJoin from "../../groups/VenueBookingJoin/VenueBookingJoin";
import SessionInfo from "../../elements/SessionInfo/SessionInfo";
import EventCard from "../../elements/EventCard/EventCard";
import VenueBookingApi from "../../apis/VenueBookingApi";
import OrderDetailsPopup from "../../groups/OrderDetailsPopup/OrderDetailsPopup";
import ChatThread from "../../groups/ChatThread/ChatThread";
import VenueBookingVideo from "../../groups/VenueBookingVideo/VenueBookingVideo";

import styles from "./VenueBookingScreen.module.scss";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";

const VenueBookingScreen: React.FC<VenueBookingScreenProps> = (props) => {

    const user = useUser("appUser");

    const bookingId = get(props, 'screenProps.routeParams.bookingId');

    const orderId = get(props, 'screenProps.routeParams.orderId');

    const booking = VenueBookingApi.getOne(bookingId);

    const [key, setKey] = useState(0);

    useAsyncEffect((isMounted) => {

        if (booking.data) booking.reset();

        booking.run({
            cache: false,
            onComplete: (response: any) => {

                if (!isMounted()) return;

                config.setMeta({
                    title: response.sessionGroup.title,
                    desc: moment(response.dateTime).format('ddd, DD MMMM hh:mm a') + ' - ' + moment(response.endDateTime).format('hh:mm a') + ' at ' + response.sessionGroup.location,
                    image: response.sessionGroup.cover || response.sessionGroup.thumbnail
                });

            }
        });

    }, [key, bookingId]);

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    return useMemo(() => (

        <>

            {orderId &&
                <OrderDetailsPopup orderId={orderId} />
            }

            <Header />

            {booking.data &&

                <Container>

                    <Space height={3} />

                    <UiLink
                        path={`/venues/court/${booking.data.sessionGroup.id}/${booking.data.sessionGroup.slug}`}
                        className={styles.back}
                    >

                        <IoMdArrowBack className={styles.backText} />

                        <UiText className={styles.backText}>Back</UiText>

                    </UiLink>

                    <Space height={3} />

                    <UiRow
                        className={styles.box}
                        edge={true}
                        gutter={2}
                    >

                        <UiCol
                            col={{ xs: 24, lg: 14 }}
                            className={styles.cover}
                        >

                            {user.loggedIn && booking.data.isAttending &&
                                <VenueBookingVideo data={booking.data} />
                            }

                            <Panel
                                heading="Your Selected Booking"
                            >

                                <EventCard
                                    session={booking.data}
                                    hideImage={true}
                                    hideStatus={true}
                                />

                            </Panel>

                            <Space />

                            {!user.loggedIn &&
                                <>
                                    <InlineLogin />
                                </>
                            }

                            {user.loggedIn &&
                                <>

                                    <VenueBookingJoin
                                        key={key}
                                        session={booking.data}
                                        handleRefresh={upKey}
                                    />

                                </>
                            }


                        </UiCol>

                        <UiCol
                            col={{ xs: 24, lg: 10 }}
                            className={styles.cover}
                        >

                            {booking.data.threadId &&
                                <>

                                    <ChatThread
                                        threadId={booking.data.threadId}
                                    />

                                    <Space />

                                </>
                            }

                            {(booking.data.sessionGroup.address || booking.data.sessionGroup.email || booking.data.sessionGroup.contact) &&
                                <>
                                    <SessionInfo
                                        session={booking.data}
                                    />
                                </>
                            }

                            <Space />

                            <GoogleAd
                                slot="sidebar"
                            />

                        </UiCol>

                    </UiRow>

                </Container>

            }

            <Footer />

        </>

    ), [booking, key]);
};

interface VenueBookingScreenProps {
    [key: string]: any;
}

export default VenueBookingScreen;
