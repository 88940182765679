import { Events, UiForm, UiText, UiView, useForm } from '@dex/bubl-ui';
import React, { useCallback } from 'react'
import LeagueSeasonTeamApi from '../../apis/LeagueSeasonTeamApi';
import Button from '../../elements/Button/Button';
import Divider from '../../elements/Divider/Divider';
import FieldText from '../../elements/Fields/FieldText/FieldText';
import FieldUpload from '../../elements/Fields/FieldUpload/FieldUpload';
import Space from '../../elements/Space/Space';

import styles from './LeagueTeamRegisterForm.module.scss'

const LeagueTeamRegisterForm: React.FC<LeagueTeamRegisterFormProps> = (props) => {

    const { team, handleRefresh } = props;

    const form = useForm(team);

    const updateTeamInfo = LeagueSeasonTeamApi.updateTeamInfo(team.id);

    const handleSubmit = useCallback((values) => {

        updateTeamInfo.run({
            data: values,
            onComplete: (res) => {

                Events.emit("ui", {
                    action: "success",
                    timeout: 3000,
                    message: "Changes Saved"
                });

                handleRefresh();

            }
        });

    }, [form.values]);

    const disabled = team.season.lockRosters || !team.isManager;

    return (
        <>
            <Divider
                title={"Team Information"}
                dark={true}
            />

            <UiView className={styles.body}>

                <UiText className={styles.text}>Kindly fill in additional details about your team.</UiText>

                <Space />

                <UiForm
                    form={form}
                    onSubmit={handleSubmit}
                    loading={updateTeamInfo.loading}
                >

                    <UiView className={styles.image}>
                        <FieldUpload
                            form={form}
                            name={"logo"}
                            accept={"image/*"}
                            fieldType="profilePhoto"
                            defaultValue={form.defaults.logo}
                            disabled={disabled}
                        />
                    </UiView>

                    <FieldText
                        form={form}
                        name={"name"}
                        label={"Team Name"}
                        placeholder={"Enter your team name"}
                        defaultValue={form.defaults.name}
                        required={true}
                        disabled={disabled}
                    />

                    <Space />

                    <FieldText
                        form={form}
                        name={"shortName"}
                        label={"Short Name"}
                        placeholder={"Enter your team short name"}
                        defaultValue={form.defaults.shortName}
                        required={true}
                        disabled={disabled}
                    />

                    <Space />

                    <FieldText
                        form={form}
                        name={"color"}
                        label={"Color"}
                        placeholder={"Enter your team color"}
                        defaultValue={form.defaults.color}
                        required={true}
                        disabled={disabled}
                    />

                    <Space />

                    <FieldText
                        form={form}
                        name={"city"}
                        label={"City"}
                        placeholder={"Enter your team city"}
                        defaultValue={form.defaults.city}
                        required={true}
                        disabled={disabled}
                    />

                    <Space />

                    <FieldText
                        form={form}
                        name={"coachName"}
                        label={"Coach's Name"}
                        placeholder={"Enter your team coach's name"}
                        defaultValue={form.defaults.coachName}
                        disabled={disabled}
                    />

                    <Space height={2} />

                    {!disabled &&
                        <Button
                            label={"Save Team Information"}
                            block={true}
                            onClick={form.triggerSubmit}
                            loading={updateTeamInfo.loading}
                        />
                    }

                    {disabled &&
                        <UiText className={styles.text}>Roster and team changes are locked. Please contact the league admin to request any changes.</UiText>
                    }

                </UiForm>

            </UiView>

        </>
    )
}

interface LeagueTeamRegisterFormProps {
    [key: string]: any;
}

export default LeagueTeamRegisterForm