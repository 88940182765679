import React from "react";
import { UiLink, UiView } from "@dex/bubl-ui";
// @ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table';
import moment from 'moment';

import styles from "./StatsTable.module.scss";
import { slug } from "@dex/bubl-helpers";

const StatsTable: React.FC<StatsTableProps> = (props) => {

    const { stats, columns, seasonSlug, seasonId } = props;

    if (!stats) return <></>;

    const header: any = [];

    header.push({
        key: 'date',
        label: 'DATE',
        type: 'text',
    });

    header.push({
        key: 'team',
        label: 'VS. TEAM',
        type: 'text',
    });

    header.push({
        key: 'outcome',
        label: 'RES',
        type: 'text',
    });

    columns.map((column: any) => {

        if (column.stat) header.push(column);

        return column;

    })

    return (

        <UiView>

            <StickyTable
                className={styles.table}
                stickyHeaderCount={1}
                leftStickyColumnCount={2}
                borderWidth={0}>

                <Row className="head">

                    {header.map((item: any, index: number) => (

                        <Cell key={index} className={item.key}>{item.label}</Cell>

                    ))}

                </Row>

                {stats &&
                    stats.map((item: any, index: any) => (

                        <Row key={index}>

                            {header.map((stat: any, cellIndex: any) => (

                                <Cell key={cellIndex} className={stat.key}>

                                    <>

                                        {stat.key === 'date' && item[stat.key] && moment(item[stat.key].str).format("DD-MMM-YYYY").toUpperCase()}

                                        {stat.key === 'team' && <UiLink path={`/leagues/season/${seasonId}/${seasonSlug}/team/${item.vsTeam.id}/${slug(item.vsTeam.name)}`}>{item.vsTeam.name}</UiLink>}

                                        {stat.key === 'outcome' && item.outcome.toUpperCase()}

                                        {stat.key !== 'team' && stat.key !== 'date' && item.stats[stat.key]}

                                        {/* {stat.key === 'fgm,fga' && item.stats.fgm + " - " + item.stats.fga}

                                        {stat.key === 'threepm,threepa' && item.stats.threepm + " - " + item.stats.threepa}

                                        {stat.key === 'ftm,fta' && item.stats.ftm + " - " + item.stats.fta} */}

                                    </>

                                </Cell>

                            ))}

                        </Row>

                    ))
                }


            </StickyTable>

        </UiView >

    );
};

interface StatsTableProps {
    [key: string]: any;
}

export default StatsTable;
