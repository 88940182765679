import React from "react";
import { UiImage, UiText, UiLink } from "@dex/bubl-ui";

import styles from "./StandardCard.module.scss";

const StandardCard: React.FC<StandardCardProps> = (props) => {

    return (

        <UiLink path={props.path} className={styles.card}>

            <UiImage
                className={styles.image}
                src={props.image || {}}
                filters={{ width: 300, height: 300 }}
                type={'thumb'}
            />

            <UiText className={styles.title}>
                {props.title}
            </UiText>

        </UiLink>

    );
};

interface StandardCardProps {
    [key: string]: any;
}

export default StandardCard;
