import React from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./Divider.module.scss";

const Divider: React.FC<DividerProps> = (props) => {

    return (

        <UiView className={[props.dark ? styles.containerDark : styles.container, props.className]}>

            <UiView className={styles.headingContainer}>

                <UiText className={[props.dark ? styles.headerTextDark : styles.headerText, props.TextClassName]}>
                    {props.title}
                </UiText>

            </UiView>

        </UiView>

    );
};

interface DividerProps {
    title?: string;
    [key: string]: any;
}

export default Divider;
