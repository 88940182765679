import React from "react";
import { UiFieldText } from "@dex/bubl-ui";

import styles from "../Fields.module.scss";

const FieldText: React.FC<FieldTextProps> = (props) => {

    return (

        <UiFieldText
            {...props}
            classes={{
                wrap: [styles.wrap],
                label: [styles.label],
                input: [styles.input, props.className],
                error: [styles.error],
            }}
        />


    );
};

interface FieldTextProps {
    [key: string]: any;
}

export default FieldText;
