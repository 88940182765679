import { UiLink, UiText, UiView, useUser } from '@dex/bubl-ui';
import React, { useCallback, useState } from 'react'
import VenueBookingJoinInvite from '../../groups/VenueBookingJoin/VenueBookingJoinInvite';
import Divider from '../Divider/Divider';
import Grid from '../Grid/Grid';
import LeagueTeamRegisterPlayerLeave from '../../groups/LeagueTeamRegisterPlayerLeave/LeagueTeamRegisterPlayerLeave';
import LeagueTeamRegisterPlayerJoin from '../../groups/LeagueTeamRegisterPlayerJoin/LeagueTeamRegisterPlayerJoin';
import MemberCard from '../MemberCard/MemberCard';
import Space from '../Space/Space';

import styles from './LeagueTeamRegisterRoster.module.scss'
import LeagueTeamRegisterPlayerEdit from '../../groups/LeagueTeamRegisterPlayerEdit/LeagueTeamRegisterPlayerEdit';
import LeagueTeamRegisterPlayerRemove from '../../groups/LeagueTeamRegisterPlayerRemove/LeagueTeamRegisterPlayerRemove';

const LeagueTeamRegisterRoster: React.FC<LeagueTeamRegisterRosterProps> = (props) => {

    const { team } = props;

    const user = useUser('appUser');

    const [editPlayer, setEditPlayer] = useState<any>(null);

    const [removePlayer, setRemovePlayer] = useState<any>(null);

    return (

        <>

            <Divider
                title={props.title}
                dark={true}
            />

            <UiView UiView className={styles.container}>

                {(!team.season.lockRosters && team.isPlayer) &&
                    <LeagueTeamRegisterPlayerLeave
                        {...props}
                    />
                }

                {(!team.season.lockRosters && !team.isPlayer) &&
                    <LeagueTeamRegisterPlayerJoin
                        {...props}
                    />
                }

                {editPlayer &&
                    <LeagueTeamRegisterPlayerEdit
                        {...props}
                        player={editPlayer}
                        handleClose={setEditPlayer.bind(null, null)}
                    />
                }

                {removePlayer &&
                    <LeagueTeamRegisterPlayerRemove
                        {...props}
                        player={removePlayer}
                        handleClose={setEditPlayer.bind(null, null)}
                    />
                }

                <Grid
                    fixed={true}
                    columns={4}
                    gap={10}
                >

                    {team.roster.map((player: any, index: any) => (

                        <UiView key={index} className={'item'}>

                            <MemberCard
                                size={"mini"}
                                onPress={(team.isManager || team.player?.id === player.id) ? setEditPlayer.bind(null, player) : null}
                                name={`#${player.jerseyNumber} ${player.name}`}
                                image={{
                                    type: "thumb",
                                    src: player.photo || { fileId: null }
                                }}
                                overlay={(team.isManager) ? () => (

                                    <UiLink
                                        onPress={setRemovePlayer.bind(null, player)}
                                        className={styles.remove}
                                    >
                                        <UiText>Remove</UiText>
                                    </UiLink>

                                ) : null}
                            />

                        </UiView>

                    ))}

                </Grid>

                {(!team.season.lockRosters && (team.isManager || team.isPlayer)) &&
                    <VenueBookingJoinInvite
                        session={{
                            ...team,
                            subject: `${team.name} - ${team.season.title}`,
                        }}
                    />
                }

            </UiView>

        </>

    )

}

interface LeagueTeamRegisterRosterProps {
    [key: string]: any;
}

export default LeagueTeamRegisterRoster