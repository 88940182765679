import React, { useCallback, useEffect, useState } from 'react';
import { UiLink, UiSpace, UiText, UiView } from '@dex/bubl-ui';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';

import Modal from '../../elements/Modal/Modal';
import Divider from '../../elements/Divider/Divider';
import TimeSlots from '../../elements/TimeSlots/TimeSlots';
import Button from '../../elements/Button/Button';
import FieldDate from '../../elements/Fields/FieldDate/FieldDate';

import styles from './BlockBookingModel.module.scss';
import VenueBlockSessionCard from '../../elements/VenueBlockSessionCard/VenueBlockSessionCard';
import CartSelected from '../../elements/CartSelected/CartSelected';
import VenueCourtApi from '../../apis/VenueCourtApi';
import Space from '../../elements/Space/Space';
import OrderApi from '../../apis/OrderApi';
import InlineMessage from '../../elements/InlineMessage/InlineMessage';

const BlockBookingModelCheck: React.FC<BlockBookingModelCheckProps> = (props) => {

    const { date, info, courtId, slot, number, setCheck, setOrder } = props;

    const check = VenueCourtApi.checkBlockAvailability(courtId);

    const order = OrderApi.generate();

    useEffect(() => {

        check.run({
            params: {
                date: date.startOf("day").format("YYYY-MM-DD"),
                number: number,
                slot: slot.split(".")[1]
            }
        });

    }, [date]);

    const handleCheckout = useCallback((cart) => {

        if (order.loading) return;

        const data: any = { productId: cart.productId, slot: cart };

        order.run({
            data: data,
            onComplete: (response) => {

                setOrder(response);

            }
        });

    }, [order]);

    return (
        <>
            <UiView className={styles.body}>

                {check.loading &&
                    <>
                        <BeatLoader size={12} />
                    </>
                }

                {check.data &&
                    <>

                        <UiLink
                            onPress={setCheck.bind(null, false)}
                            className={styles.backLink}
                        >
                            <HiOutlineArrowNarrowLeft className={styles.backIcon} /> Change Selection
                        </UiLink>

                        <Space height={2} />

                        {check.data.message && check.data.message.text && check.data.message.type &&
                            <>
                                <Space height={1} />

                                <InlineMessage
                                    message={check.data.message.text}
                                    type={check.data.message.type}
                                />
                            </>
                        }

                        <UiView className={styles.sessions}>

                            {check.data.sessions && check.data.sessions.length > 0 && check.data.sessions.map((item: any, i: number) => (
                                <VenueBlockSessionCard
                                    key={i}
                                    session={item}
                                />
                            ))}

                        </UiView>

                    </>
                }

            </UiView>

            {check.data && check.data.cart &&
                <>

                    <CartSelected
                        className={styles.cart}
                        cart={check.data.cart}
                        loading={order.loading}
                        buttonLabel={"BOOK NOW"}
                        handleCheckout={handleCheckout}
                    />

                </>
            }

        </>

    )

}

interface BlockBookingModelCheckProps {
    [key: string]: any
}

export default BlockBookingModelCheck;