import { options, response, apiPost } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Payment';

const PaymentApi = {

    pay: (options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/pay",
            ...options
        });

    }

}

export default PaymentApi;