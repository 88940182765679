import React, { useEffect, useCallback, useMemo, useState } from "react";
import { UiView, UiRow, UiCol, useLink } from "@dex/bubl-ui";

import styles from "./PassesPanel.module.scss";
import Panel from "../../elements/Panel/Panel";
import PassCard from "../../elements/PassCard/PassCard";
import PassesApi from "../../apis/PassesApi";
import Divider from "../../elements/Divider/Divider";
import Button from "../../elements/Button/Button";
import OrderApi from "../../apis/OrderApi";
import PassesPopupBuy from "../PassesPopupBuy/PassesPopupBuy";

const PassesPanel: React.FC<PassesPanelProps> = (props) => {

    let { heading, id } = props;

    return (

        <Panel
            id={id}
            heading={heading}
            edge="all"
        >

            <UiView className={styles.container}>

                <PassesPanelContent {...props} />

            </UiView>

        </Panel>

    )

}


const PassesPanelContent: React.FC<any> = (props) => {

    let { parentType, parentId, sessionGroupId, expiryDate, fullWidth, preventBuy } = props;

    const [showPopup, setShowPopup] = useState(false);
    const [key, setKey] = useState(0);

    const params = { sessionGroupId: sessionGroupId, expiryDate: expiryDate };

    const passes = PassesApi.passes(parentId, parentType, params, { timeout: 10000 });

    const newOrder = OrderApi.generate();

    const linkTo = useLink();

    useEffect(() => {

        if (parentId) passes.run();

    }, [key]);

    const handleBuy = useCallback((pass: any) => {

        if (preventBuy) return;

        if (newOrder.loading) return;

        const data: any = { productId: pass.id };

        if (parentType === "PickupOperator" && sessionGroupId) data.pickupSessionId = sessionGroupId;

        newOrder.run({
            data: data,
            onComplete: (response: any) => {
                setShowPopup(true);
            }
        });

    }, [parentId]);

    const handleRefresh = useCallback(() => {

        setKey(key + 1);

        setShowPopup(false);

    }, [key])

    return useMemo(() => (

        <>

            {passes.data && passes.data.active && passes.data.active.length > 0 &&

                <>

                    <Divider
                        title={"My Active Passes"}
                    />

                    <UiRow
                        gutter={2}
                    >

                        {passes.data.active.map((data: any, key: any) => (

                            <UiCol
                                col={{ xs: 24, sm: fullWidth ? 24 : 12 }}
                                key={key}
                                className={['item', styles.itemContainer]}
                            >

                                <PassCard
                                    title={data.title}
                                    content={data.description}
                                    status={data.status}
                                    onClick={props.handleJoin ? props.handleJoin : null}
                                />

                            </UiCol>

                        ))}

                    </UiRow>

                    {props.handleJoin &&
                        <UiView className={"p-lr-3 p-b-2"}>
                            <Button
                                onClick={props.handleJoin}
                                label="JOIN"
                                block={true}
                            />
                        </UiView>
                    }

                </>

            }

            {passes.data && passes.data.available && (!props.hideAvailableIfHasActive || !passes.data.active) &&

                <>

                    <Divider
                        title={"Available Passes"}
                    />

                    <UiRow
                        gutter={3}
                    >

                        {passes.data.available.map((data: any, key: any) => (

                            <UiCol
                                col={{ xs: 24, sm: fullWidth ? 24 : 12 }}
                                key={key}
                                className={['item', styles.itemContainer]}
                            >

                                <PassCard
                                    title={data.title}
                                    content={data.description}
                                    image={require('../../assets/images/pass-card.png')}
                                    price={data.price}
                                    fullWidth={fullWidth ? fullWidth : false}
                                    onClick={handleBuy.bind(null, data)}
                                />

                            </UiCol>

                        ))}

                    </UiRow>

                </>

            }

            {showPopup && newOrder.complete && <PassesPopupBuy order={newOrder.data} handleRefresh={handleRefresh} />}

        </>

    ), [passes]);

};

interface PassesPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}


export default PassesPanel;

export { PassesPanelContent };