import React from 'react';
import { useStore } from '@dex/bubl-ui';
import LoginScreen from '../screens/LoginScreen/LoginScreen';

const ProtectedHandler: React.FC<any> = (props) => {

    const [user] = useStore("appUser");

    if (user.loggedIn) {

        return <props.screenProps.routeParams.component {...props} />

    } else {

        return <LoginScreen {...props} />

    }

};

export default ProtectedHandler;