import React from 'react';
import { UiView, UiText, UiLink, UiImage } from '@dex/bubl-ui';
import moment from 'moment';

import Panel from '../../elements/Panel/Panel';
import Line from '../../elements/Line/Line'
import TabBubble from '../../elements/TagBubble/TagBubble';

import styles from "./VideoInfoPanel.module.scss";

const VideoInfoPanel: React.FC<VideoInfoPanelProps> = (props) => {

    const { video } = props;

    return (

        <Panel
            hideTop={true}
        >

            <UiView className={styles.header}>

                <UiView className={styles.details}>

                    <UiLink path={`/community/${video.host.id}/${video.host.slug}`}>

                        <UiImage
                            src={video.host.logo}
                            className={styles.avatar}
                            filters={{ width: 100, height: 100 }}
                            type={'thumb'}
                        />

                    </UiLink>

                    <UiView>

                        <UiText className={styles.title}>{video.title}</UiText>

                        <UiLink path={`/watch/host/${video.host.id}/${video.host.slug}`}>

                            <UiText className={styles.hostName}>
                                {video.host.title}
                            </UiText>

                        </UiLink>

                    </UiView>

                </UiView>

                <UiText className={styles.date}>
                    {moment(video.date).fromNow()}
                </UiText>

            </UiView>

            <UiView className={styles.lineWrapper}>

                <Line />

            </UiView>

            <UiText className={styles.content}>

                {video.description}

            </UiText>

            <UiView className={styles.tabBubbleWrap}>

                {video.categories && video.categories.length > 0 &&
                    video.categories.map((category: any, index: any) => (

                        <TabBubble
                            key={index}
                            tag={category}
                            type={"category"}
                        />

                    ))
                }

                {video.tags && video.tags.length > 0 &&
                    video.tags.map((tag: any, index: any) => (

                        <TabBubble
                            key={index}
                            tag={tag}
                            type={"tag"}
                        />

                    ))
                }

            </UiView>

        </Panel>

    )

}

interface VideoInfoPanelProps {
    [key: string]: any,
}

export default VideoInfoPanel;