import React from "react";
import { UiText, UiView, UiRow, UiCol, UiLink } from "@dex/bubl-ui";

import styles from "./Panel.module.scss";

const Panel: React.FC<PanelProps> = (props) => {

    return (

        <UiView
            id={props.id}
            className={[styles.wrap, props.transparent ? styles.transparent : '', props.className]}
        >

            {!props.hideTop &&

                <UiView
                    className={styles.top}
                >

                    <UiRow
                        gutter={2}
                        edge={true}
                    >

                        <UiCol
                            grow={1}
                            className={styles.top}
                        >

                            {props.heading &&
                                <UiText tag="h3">{props.heading}</UiText>
                            }

                            {props.topLeft &&
                                <UiView className={styles.left}>
                                    {props.topLeft}
                                </UiView>
                            }

                        </UiCol>

                        <UiCol className={styles.right}>

                            {props.topRight &&
                                <UiView>
                                    <UiView className={styles.rightText}>{props.topRight}</UiView>
                                </UiView>
                            }

                            {props.moreText && props.moreLink &&
                                <UiLink className={styles.rightTextLink} path={props.moreLink}>{props.moreText}</UiLink>
                            }

                        </UiCol>

                    </UiRow>

                </UiView>

            }

            <UiView className={[styles.body, props.edge === "all" ? styles.edge : '', props.edge === "sides" ? styles.sides : '']}>

                {props.children}

            </UiView>

        </UiView>

    );
};

interface PanelProps {
    heading?: string;
    button?: string;
    edge?: "all" | "sides",

    [key: string]: any;
}

export default Panel;
