import React, { createRef, useLayoutEffect, useCallback, useState, useMemo } from "react";
import { UiView, UiLink } from "@dex/bubl-ui";
import { parseNum } from "@dex/bubl-helpers";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import styles from "./Grid.module.scss";

const Grid: React.FC<GridProps> = (props) => {

    let { columns, fixed, gap } = props;

    const [hasToLeft, setHasToLeft] = useState(false);

    const [hasToRight, setHasToRight] = useState(false);

    const wrapRef = createRef();
    const scrollRef = createRef();
    const gridRef = createRef();

    const classes: any = [styles.grid];

    if (typeof gap === undefined) gap = 2;

    if (!columns) columns = 5;

    if (columns) classes.push(styles['columns-' + columns]);

    if (gap) classes.push(styles['gap-' + gap]);

    if (props.className) classes.push(props.className);

    useLayoutEffect(() => {

        if (fixed) return;

        const scroll: any = scrollRef.current;
        const grid: any = gridRef.current;

        setHasToLeft(scroll.scrollLeft > 0);

        if (grid.clientWidth > (scroll.clientWidth + scroll.scrollLeft)) {

            setHasToRight(true);

        } else {

            setHasToRight(false);

        }

    });

    const onScroll = useCallback(() => {

        if (fixed) return;

        const scroll: any = scrollRef.current;
        const grid: any = gridRef.current;

        setHasToLeft(scroll.scrollLeft > 0);

        if (grid.clientWidth > (scroll.clientWidth + scroll.scrollLeft)) {

            setHasToRight(true);

        } else {

            setHasToRight(false);

        }

    }, [scrollRef, gridRef]);

    const move = useCallback((direction) => {

        if (fixed) return;

        const scroll: any = scrollRef.current;
        const grid: any = gridRef.current;

        const children: any = grid.children;
        const child: any = children[0];

        if (!child) return;

        let width = child.clientWidth;
        let left = scroll.scrollLeft;

        if (direction === "left") {

            const x = Math.round(parseNum(left / width)) * width;

            left = x - width;

        } else {

            const x = Math.round(parseNum(left / width)) * width;

            left = x + width;

        }

        scroll.scrollLeft = left;

    }, [scrollRef, gridRef]);

    const moveLeft = useCallback(() => {

        move('left');

    }, [scrollRef, gridRef]);

    const moveRight = useCallback(() => {

        move('right');

    }, [scrollRef, gridRef]);

    return useMemo(() => (

        <UiView className={styles.relative}>

            <UiView
                className={[styles.wrap, hasToLeft ? "has-left" : "", hasToRight ? "has-right" : ""].filter(Boolean)}
                forwardedRef={wrapRef}
            >

                {fixed &&

                    <UiView
                        className={[...classes, styles.fixed]}
                        forwardedRef={gridRef}
                    >

                        {props.children}

                    </UiView>

                }

                {!fixed &&

                    <>

                        <UiView
                            className={styles.scroll}
                            forwardedRef={scrollRef}
                            onScroll={onScroll}
                        >

                            <UiView
                                className={[...classes, styles.rotate]}
                                forwardedRef={gridRef}
                            >

                                {props.children}

                            </UiView>

                        </UiView>

                    </>

                }

            </UiView>

            {!fixed && hasToLeft &&
                <UiLink
                    onClick={moveLeft}
                    className={styles.left}
                >
                    <IoIosArrowBack className={styles.iconLeft} />
                </UiLink>
            }

            {!fixed && hasToRight &&
                <UiLink
                    onClick={moveRight}
                    className={styles.right}
                >
                    <IoIosArrowForward className={styles.iconRight} />
                </UiLink>
            }

        </UiView>

    ), [hasToLeft, hasToRight, moveLeft, moveRight, props]);
};

interface GridProps {
    columns?: number,
    gap?: number,
    [key: string]: any
}

export default Grid;
