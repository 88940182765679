import React, { useEffect, useCallback, useState, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import FeaturedNewsBanner from '../../elements/FeaturedNewsBanner/FeaturedNewsBanner';
import NewsArchive from "../../groups/NewsArchive/NewsArchive";
import Button from "../../elements/Button/Button";

import NewsApi from "../../apis/NewsApi";

const NewsScreen: React.FC<NewsScreenProps> = (props) => {

    const key = get(props, 'screenProps.routeKey');

    const categoryId = get(props, 'screenProps.routeParams.categoryId');
    const type = get(props, 'screenProps.routeParams.type');
    const tagId = get(props, 'screenProps.routeParams.tagId');
    const hostId = get(props, 'screenProps.routeParams.hostId');

    const [page, setPage] = useState(0);

    const [filter, setFilter] = useState<any>(null);

    const [featured, setFeatured] = useState<any>();

    const [articles, setArticles] = useState<any>([]);

    const list = NewsApi.list();

    const defaults = {
        include: [
            {
                relation: "category",
                scope: {
                    fields: ["id", "slug", "title", "thumbnail"]
                }
            }
        ],
        limit: 12,
        skip: 0,
        where: {},
        order: ['date Desc'],
    };

    useEffect(() => {

        if (articles && articles.length > 0) {
            setArticles([]);
            setFeatured(null);
            setPage(0);
        }

    }, [key]);

    useEffect(() => {

        const query: any = { ...defaults };

        query.skip = page * defaults.limit;

        if (categoryId) {
            query.where.categoryId = categoryId;
        }

        if (tagId) {
            query.where.tagId = tagId;
        }

        if (hostId) {
            query.where.hostId = hostId;
        }

        if (type) {
            query.where.type = type;
        }

        setFilter(query);

    }, [key, page]);

    useEffect(() => {

        if (!filter) return;

        list.run({
            params: { filter },
            onComplete: (response: Array<any>) => {

                if (!page) setFeatured(response[0]);

                setArticles([...articles, ...response]);

            }
        });

    }, [filter]);

    const loadMore = useCallback(() => {

        setPage(page + 1);

    }, [page]);

    return useMemo(() => (

        <>

            <Header />

            <SubMenu
                menu={[
                    { label: "All", path: '/news' },
                    { label: "News", path: '/news/type/news' },
                    { label: "Recaps", path: '/news/type/recap' },
                    { label: "Watch", path: '/news/type/watch' },
                    { label: "Photos", path: '/news/type/photos' },
                ]}
            />

            {articles &&

                <Container>

                    {featured &&
                        <FeaturedNewsBanner
                            title={featured.title}
                            description={featured.description}
                            path={`/news/article/${featured.id}/${featured.slug}`}
                            image={featured.thumbnail || featured.gallery[0]}
                        />
                    }

                    <Space />

                    <NewsArchive
                        list={articles}
                    />

                    <Space height={3} />

                    <UiView style={{ textAlign: "center" }}>

                        <Button
                            loading={list.loading}
                            onClick={loadMore}
                            label="LOAD MORE"
                            size={"medium"}
                        />

                    </UiView>

                    <Space height={3} />

                </Container>

            }

            <Footer />

        </>

    ), [list.loading, loadMore, articles]);
};

interface NewsScreenProps {
    [key: string]: any;
}

export default NewsScreen;
