import React, { useEffect, useMemo } from "react";
import { config } from "@dex/bubl-web";
import { get } from "@dex/bubl-helpers";

import styles from "./PageScreen.module.scss";

import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import PageNotFoundScreen from "../PageNotFoundScreen/PageNotFoundScreen";
import Banner from "../../elements/Banner/Banner";
import ContentBlocks from "../../elements/ContentBlocks/ContentBlocks";
import Space from "../../elements/Space/Space";

import PageApi from "../../apis/PageApi";

const PageScreen: React.FC<PageScreenProps> = (props) => {

    const key = get(props, 'screenProps.routeKey');

    const slug = get(props, 'screenProps.routeParams.slug');

    const page = PageApi.bySlug();

    useEffect(() => {

        page.run({
            params: { slug: slug },
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.title,
                    desc: response.description,
                    image: response.cover || response.thumbnail
                });

            }
        });

    }, [slug, key]);

    return useMemo(() => (

        <>

            {(page.loading || (!page.loading && page.data)) &&
                <>

                    <Header />

                    {page.data &&

                        <>

                            <Banner
                                heading={page.data.title}
                                backgroundImage={page.data.cover}
                            />

                            <Space />

                            <ContentBlocks
                                blocks={page.data.contentBlocks}
                            />

                        </>
                    }
                    <Footer />

                </>
            }

            {!page.loading && !page.data &&
                <PageNotFoundScreen />
            }

        </>

    ), [page.data, page.loading, key]);
};

interface PageScreenProps {
    [key: string]: any;
}

export default PageScreen;
