import React, { useEffect } from 'react';
import { UiLink, UiView } from '@dex/bubl-ui';

import styles from "./Bracket.module.scss";
import ResultCard from '../ResultCard/ResultCard';
import Modal from '../Modal/Modal';
import { IoMdClose } from 'react-icons/io';
import Divider from '../Divider/Divider';
import LeagueSeasonApi from '../../apis/LeagueSeasonApi';

const BracketMatchup: React.FC<BracketMatchupProps> = (props) => {

    const { matchup, onClose, season, division } = props;

    const games = LeagueSeasonApi.getMatchup(division.id, matchup.key);

    useEffect(() => {

        games.run();

    }, [])

    return (

        <Modal
            show={true}
            onClose={onClose}
        >

            <UiView className={styles.popup}>

                <UiLink
                    onPress={onClose}
                    className={styles.close}
                >

                    <IoMdClose />

                </UiLink>

                <Divider
                    title={`${matchup.teams[0].name} vs ${matchup.teams[1].name}`}
                />

                {games.data && games.data &&

                    <UiView className={styles.games}>

                        {(games.data || []).map((result: any, key: number) => (

                            <ResultCard
                                alt={key % 2 === 0}
                                key={key}
                                results={result}
                                hasGameScores={result.hasGameScores}
                                date={result.date}
                                time={result.time}
                                seasonId={season.id}
                                slug={season.slug}
                                id={result.id}
                                path="game"
                            />

                        ))}

                    </UiView>

                }

            </UiView>

        </Modal>

    )
}

interface BracketMatchupProps {
    [key: string]: any
}

export default BracketMatchup;