import React from "react";
import { UiLink, UiText, UiView } from "@dex/bubl-ui";
import { slug } from "@dex/bubl-helpers";


import styles from "./LeaguesSeasonCard.module.scss";

const LeaguesSeasonCard: React.FC<LeagueSessionCardProps> = (props) => {

    const { season, title, image } = props;

    return (

        <UiLink
            className={styles.wrapper}
            path={`/leagues/season/${season.id}/${slug(season.title)}`}
        >

            <UiView
                className={styles.container}
                background={{
                    src: image,
                    type: 'thumb',
                    resizeMode: 'cover',
                    filters: { width: 440, height: 275 }
                }}
            >

                <UiText className={styles.title}>{title}</UiText>

            </UiView>

        </UiLink>

    );
};

interface LeagueSessionCardProps {
    [key: string]: any;
}

export default LeaguesSeasonCard;
