import React, { useCallback } from "react";
import { UiText, UiView, UiForm, useForm, UiRow, UiCol, useStore } from "@dex/bubl-ui";
import { IoIosSearch } from "react-icons/io";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import Button from "../../elements/Button/Button";

//@ts-ignore
import { StackActions } from '@react-navigation/core';

import styles from "./BigSearch.module.scss";

import BigSearchImage from '../../assets/images/bigsearch.png';

const BigSearch: React.FC<BigSearchProps> = (props) => {

    const [nav] = useStore("nav");

    const form = useForm({});

    const onSearch = useCallback(() => {

        const route = nav.router.getActionForPathAndParams('discover');

        let ref = nav.currentRoute.path.split("/");

        if (ref[0] && !["news", "leagues", "camps", "pickups"].includes(ref[0])) ref[0] = "all";

        route.params.keyword = form.values.search;

        if (ref[0]) route.params.ref = ref[0];

        if (route && route.routeName) {

            let params = StackActions.push(route);

            if (!params.key) params.key = Date.now();

            nav.dispatch(params);

        }

    }, [form.values]);


    return (

        <UiView
            className={styles.wrap}
            background={{ src: BigSearchImage, type: 'local', resizeMode: 'contain' }}
        >

            <UiForm
                form={form}
                className={styles.contentContainer}
                onSubmit={onSearch}
            >

                <UiRow
                    gutter={2}
                    edge={true}
                >

                    <UiCol col={{ xs: 24 }}>

                        <UiView className={styles.headingContainer}>

                            <UiView className={styles.headingTextContainer}>

                                <UiText className={styles.headingText}>Bringing Everyday Athletes Together</UiText>

                            </UiView>


                        </UiView>

                        <UiView className={styles.searchContainer}>

                            <UiView className={styles.searchBoxContainer}>

                                <FieldText
                                    form={form}
                                    name={"search"}
                                    required={false}
                                    defaultValue={form.defaults.search}
                                    placeholder={"DISCOVER YOUR SPORT"}
                                    className={styles.inputBigSearch}
                                />

                                <IoIosSearch className={styles.searchIcon} />

                            </UiView>

                            <Button
                                label={"SEARCH"}
                                type="submit"
                                block={false}
                                className={styles.buttonContainer}
                            />

                        </UiView>

                    </UiCol>

                </UiRow>

            </UiForm>

        </UiView>

    );
};

interface BigSearchProps {
    [key: string]: any;
}

export default BigSearch;
