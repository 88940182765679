import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./TextBlock.module.scss";

const TextBlock: React.FC<TextBlockProps> = (props) => {

    const { content } = props

    return (

        <UiView className={styles.container}>

            <div className={styles.text} dangerouslySetInnerHTML={{
                __html: content
            }}></div>

        </UiView>

    );
};

interface TextBlockProps {
    content: any;
    [key: string]: any;
}

export default TextBlock;
