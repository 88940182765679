import React from "react";

import styles from "./PageNotFoundScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";

const PageNotFoundScreen: React.FC<PageNotFoundScreenProps> = (props) => {

    return (

        <>

            <Header />

            <Container>

                Page Not Found

            </Container>


            <Footer />

        </>

    );
};

interface PageNotFoundScreenProps {
    [key: string]: any;
}

export default PageNotFoundScreen;
