import React, { useMemo, useState } from "react";

import styles from "./Results.module.scss";

import ResultCard from "../../elements/ResultCard/ResultCard";
import Panel from "../../elements/Panel/Panel";

// @ts-ignore
import { Scrollbars } from 'react-custom-scrollbars';
import DropSelect from "../../elements/DropSelect/DropSelect";

const Results: React.FC<ResultsProps> = (props) => {

    let { set, heading, divisions, title, results, seasonId, slug } = props;

    const options: { label: any; value: any; }[] = [{ label: "All Divisions", value: null }];

    if (divisions) {
        // eslint-disable-next-line array-callback-return
        divisions.map((item: any, key: any) => {
            options.push({ label: item.name, value: item.id });
        })
    }

    const [current, setCurrent] = useState<any>(null);

    const onHandleChange = (item: any) => {

        setCurrent(item.value);

    }

    const games = useMemo(() => {

        return results.map((result: any, key: number) => {

            if (current && result.divisionId !== current) return null;

            return result;

        }).filter(Boolean);

    }, [current])

    return (

        <Panel
            heading={heading}
            id={props.id}
            edge="all"
            topLeft={
                <>
                    {options &&
                        <DropSelect
                            items={options}
                            defaultValue={options[0]}
                            onChange={onHandleChange}
                            styleDropDown={styles.select}
                        />
                    }
                </>
            }
        >

            <Scrollbars
                autoHide
                style={{ height: "400px" }}
            >

                {games.map((result: any, key: number) => (

                    <ResultCard
                        alt={key % 2 === 0}
                        key={key}
                        results={result}
                        hasGameScores={result.hasGameScores}
                        date={result.date}
                        time={result.time}
                        seasonId={seasonId}
                        slug={slug}
                        id={result.id}
                        path="game"
                    />

                ))}

            </Scrollbars>

        </Panel>

    );
};

interface ResultsProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default Results;
