import React from "react";
import { UiLink, UiText, UiView, useLink, useUser } from "@dex/bubl-ui";
import moment from "moment";

import styles from "./EventCard.module.scss";

import { titleCase } from "@dex/bubl-helpers";
import Button from "../Button/Button";
import EventCardBadge from "../EventCardBadge/EventCardBadge";

const EventCard: React.FC<EventCardProps> = (props) => {

    const { session, button } = props;

    let { path, badge } = props;

    if (path === "auto" && session.modelName) {
        if (session.modelName === "PickupSession") path = `/pickups/session/${session.id}`;
        if (session.modelName === "CampSession") path = `/camps/session/${session.id}`;
        if (session.modelName === "VenueBooking") path = `/venues/booking/${session.id}`;
    }

    const info = [titleCase(session.sessionGroup.court), session.sessionGroup.location].filter(Boolean);

    return (

        <UiLink
            path={(!button && path) ? path : undefined}
            className={styles.container}
        >

            <UiView className={styles.date}>

                <UiText className={[styles.dateDay, props.className]}>
                    {moment(session.dateTime).format('ddd')}
                </UiText>

                <UiText className={styles.dateDay}>
                    {moment(session.dateTime).format('DD/MM')}
                </UiText>

            </UiView>

            <UiView className={styles.wrapper}>

                {session.title &&
                    <UiText className={styles.titleText}>{session.title}</UiText>
                }

                <UiText className={styles.groupTitleText}>{session.sessionGroup.title}</UiText>

                {info &&
                    <UiText className={styles.locationText} numberOfLines={1}>{info.join(' . ')}</UiText>
                }

                <UiView className={styles.duration}>

                    <UiText className={styles.start}>Starts {moment(session.dateTime).format('hh:mma')}</UiText>

                    <UiText className={styles.end}>Ends {moment(session.dateTime).add(session.duration, "hours").format('hh:mma')}</UiText>

                </UiView>

            </UiView>

            {button &&
                <UiView style={styles.buttonWrap}>

                    {session.isAttending ? (
                        <Button
                            label="Attending"
                            type="PassPrimary"
                            size="small"
                            path={path}
                        />
                    ) : session.isFull ? (
                        <Button
                            label="Full"
                            type="PassFull"
                            size="small"
                            path={path}
                        />
                    ) : session.canJoin && (
                        <Button
                            label="Join"
                            type="PassJoin"
                            size="small"
                            path={path}
                        />
                    )}

                </UiView>
            }

            {badge &&
                <UiView style={styles.badgeWrap}>

                    <EventCardBadge
                        modelName={session.modelName}
                        isOwner={session.isOwner}
                        videoStatus={session.videoStatus}
                    />

                </UiView>
            }

        </UiLink>

    )

};

interface EventCardProps {
    [key: string]: any;
}

export default EventCard;
