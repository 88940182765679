import React, { useMemo, useCallback, useEffect } from 'react';
import { useStore } from '@dex/bubl-ui';
import { config, AppWrapper, AppErrorWrapper } from '@dex/bubl-web';

// @ts-ignore
import SimpleReactLightbox from "simple-react-lightbox"

import './styles/Web.scss';
import Routes from './routes/Routes';
import SplashLogo from './elements/SplashLogo/SplashLogo';

export default function App() {

    config.init({
        siteName: "Huddle",
        analytics: {
            ga: {
                id: process.env.REACT_APP_GA,
                params: {
                    map: {
                        "host": 1,
                        "sport": 2,
                        "category": 3,
                        "operator": 4,
                        "tag": 5,
                        "league": 6,
                        "season": 7,
                        "group": 8,
                    }
                }
            },
            fp: {
                id: process.env.REACT_APP_FP
            }
        }
    });

    const [key] = useStore("appKey", 1);

    useStore('preferredSportId', '');

    const routeHash = Routes();

    return useMemo(() => {

        return (

            <>

                <AppErrorWrapper>

                    <SimpleReactLightbox>

                        <AppWrapper
                            key={key + routeHash}
                            hasScreenInfo={true}
                            loadingScreen={() => {
                                return (
                                    <SplashLogo />
                                )
                            }}
                        />

                    </SimpleReactLightbox>

                </AppErrorWrapper>

            </>

        )

    }, [key, routeHash]);

}
