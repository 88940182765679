import React, { useState } from "react";
import { UiCol, UiRow } from "@dex/bubl-ui";

import Panel from "../../elements/Panel/Panel";
import DropSelect from "../../elements/DropSelect/DropSelect";
import AssesmentCard from "../../elements/AssesmentCard/AssesmentCard";

import styles from "./AthleteAssessmentsPanel.module.scss";

const AthleteAssessmentsPanel: React.FC<AthleteAssessmentsPanelProps> = (props) => {

    let { heading, assessments, hosts } = props;

    const selectOptions = [{ label: 'All Assessment', value: null }];

    const [host, setHost] = useState<any>(null);

    if (hosts) {

        hosts.map((item: any) => {

            selectOptions.push({ label: item.label, value: item.value });

            return item;

        })
    }


    const onHandleChangeHost = (item: any) => {

        setHost(item.value);

    }

    const selectField = (
        <>
            {hosts &&
                <DropSelect
                    items={selectOptions}
                    defaultValue={selectOptions[0]}
                    onChange={onHandleChangeHost}
                    styleDropDown={styles.dropDownSelect}
                />
            }
        </>
    );

    return (

        <Panel
            heading={heading}
            topLeft={selectField}
            id={props.id}
        >

            <UiRow edge={true} gutter={2}>

                {assessments.map((item: any, key: number) => (

                    <>
                        {(!host || item.hostId === host) &&
                            <UiCol col={{ xs: 24, sm: 12, md: 8 }} key={key}>

                                <AssesmentCard
                                    data={item}
                                />

                            </UiCol>
                        }
                    </>

                ))}

            </UiRow>

        </Panel>

    );

};

interface AthleteAssessmentsPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default AthleteAssessmentsPanel;
