import React from 'react'

function QuickLinksWatchIcon(props: any) {
    return (
        <svg width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >

            <g id="phonelink_24px">

                <path
                    id="icon/hardware/phonelink_24px"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.74967 11.2917C6.74967 10.4438 7.44342 9.75002 8.29134 9.75002H32.958C33.8059 9.75002 34.4997 9.05627 34.4997 8.20835C34.4997 7.36044 33.8059 6.66669 32.958 6.66669H6.74967C5.05384 6.66669 3.66634 8.05419 3.66634 9.75002V26.7084H2.89551C1.61592 26.7084 0.583008 27.7413 0.583008 29.0209C0.583008 30.3004 1.61592 31.3334 2.89551 31.3334H19.8538C21.1334 31.3334 22.1663 30.3004 22.1663 29.0209C22.1663 27.7413 21.1334 26.7084 19.8538 26.7084H6.74967V11.2917ZM36.0413 12.8334H26.7913C25.9434 12.8334 25.2497 13.5271 25.2497 14.375V29.7917C25.2497 30.6396 25.9434 31.3334 26.7913 31.3334H36.0413C36.8893 31.3334 37.583 30.6396 37.583 29.7917V14.375C37.583 13.5271 36.8893 12.8334 36.0413 12.8334ZM28.333 26.7084H34.4997V15.9167H28.333V26.7084Z"
                    fill="#DFE4EA"
                />

            </g>

        </svg>
    )
}

export default QuickLinksWatchIcon