import React, { useMemo, useCallback, useState } from "react";
import { UiCol, UiLink, UiRow, UiText, useAsyncEffect, useUser } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";
import { IoMdArrowBack } from "react-icons/io";
import { config } from "@dex/bubl-web";
import moment from "moment";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import SubMenu from "../../elements/SubMenu/SubMenu"
import Space from "../../elements/Space/Space";
import PickupSessionApi from "../../apis/PickupSessionApi";
import Panel from "../../elements/Panel/Panel";
import InlineLogin from "../../groups/InlineLogin/InlineLogin";
import PickupSessionJoin from "../../groups/PickupSessionJoin/PickupSessionJoin";

import styles from "./PickupSessionScreen.module.scss";
import EventCard from "../../elements/EventCard/EventCard";
import VideoBlock from "../../elements/VideoBlock/VideoBlock";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";

const PickupSessionScreen: React.FC<PickupSessionScreenProps> = (props) => {

    const user = useUser("appUser");

    const [key, setKey] = useState(0);

    const sessionId = get(props, 'screenProps.routeParams.sessionId');

    const orderId = get(props, 'screenProps.routeParams.orderId');

    const session = PickupSessionApi.getOne(sessionId);

    useAsyncEffect((isMounted) => {

        if (session.data) session.reset();

        session.run({
            cache: false,
            onComplete: (response: any) => {

                if (!isMounted()) return;

                config.setMeta({
                    title: response.sessionGroup.title,
                    desc: moment(response.dateTime).format('ddd, DD MMMM hh:mm a') + ' - ' + moment(response.endDateTime).format('hh:mm a') + ' at ' + response.sessionGroup.location,
                    image: response.sessionGroup.cover || response.sessionGroup.thumbnail
                });

            }
        });

    }, [key]);

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    return useMemo(() => (

        <>

            <Header />

            <SubMenu />

            {session.data &&

                <Container>

                    <Space height={3} />

                    <UiLink
                        path={`/pickups/group/${session.data.sessionGroup.id}/${session.data.sessionGroup.slug}`}
                        className={styles.back}
                    >

                        <IoMdArrowBack className={styles.backText} />

                        <UiText className={styles.backText}>Back</UiText>

                    </UiLink>

                    <Space height={3} />

                    <UiRow
                        className={styles.box}
                        edge={true}
                        gutter={2}
                    >

                        <UiCol
                            col={{ xs: 24, lg: 14 }}
                            className={styles.cover}
                        >

                            {session.data.isAttending && session.data.video && session.data.video.id && session.data.source &&
                                <>
                                    <VideoBlock
                                        id={session.data.video.id}
                                        source={session.data.video.source}
                                    />

                                    <Space height={1} />

                                    <GoogleAd
                                        slot="leaderboard"
                                    />

                                    <Space />

                                </>
                            }

                            <Panel
                                heading="Your Selected Session"
                                topRight={
                                    <UiLink path={`/pickups/group/${session.data.sessionGroup.id}/${session.data.sessionGroup.slug}`}>

                                        <UiText className={styles.selectText}>Select another session</UiText>

                                    </UiLink>
                                }
                            >

                                <EventCard
                                    session={session.data}
                                    hideStatus={true}
                                />

                            </Panel>

                            <Space />

                            {!user.loggedIn &&
                                <>
                                    <InlineLogin />
                                </>
                            }

                            {user.loggedIn &&
                                <>

                                    <PickupSessionJoin
                                        key={key}
                                        session={session.data}
                                        handleRefresh={upKey}
                                    />

                                </>
                            }

                            <Space />

                        </UiCol>

                        <UiCol
                            col={{ xs: 24, lg: 10 }}
                            className={styles.cover}
                        >

                            <GoogleAd
                                slot="sidebar"
                            />

                        </UiCol>

                    </UiRow>

                </Container>

            }

            <Footer />

        </>

    ), [session, key]);
};

interface PickupSessionScreenProps {
    [key: string]: any;
}

export default PickupSessionScreen;
