import React, { useEffect } from "react";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import SignInCta from "../../elements/SignInCta/SignInCta";
import Space from "../../elements/Space/Space";
import BigSearch from "../../elements/BigSearch/BigSearch";
import Footer from "../../elements/Footer/Footer";
import CommunitiesPanel from "../../groups/CommunitiesPanel/CommunitiesPanel";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import SportsPanel from "../../groups/SportsPanel/SportsPanel";
import AdBanner from "../../elements/AdBanner/AdBanner";
import WatchPanel from "../../groups/WatchPanel/WatchPanel";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";
import QuickLinksPanel from "../../elements/QuickLinksPanel/QuickLinksPanel";
import { useStore } from "@dex/bubl-ui";

const HomeScreen: React.FC<HomeScreenProps> = (props) => {

    const [user] = useStore("appUser");

    useEffect(() => {

        if (window && window.history) window.history.replaceState({}, '', '/');

    }, []);

    return (

        <>

            <Header />

            <SubMenu
                menu={[
                    { label: "Communities", scrollTo: "communities" },
                    { label: "Huddle+", scrollTo: "huddle" },
                    { label: "News", scrollTo: "news" },
                    { label: "Sports", scrollTo: "sports" }
                ]}
            />

            {!user.loggedIn &&
                <SignInCta
                    link="/login"
                    text=" SIGN IN TO CUSTOMIZE YOUR HUDDLE EXPERIENCE, TODAY →"
                />

            }


            <QuickLinksPanel
                links={[
                    {
                        icon: 'watch',
                        title: 'Watch games on Huddle+',
                        description: 'Tune in to available livestreams or find your game to watch on Huddle+',
                        path: '/watch',
                        mobileText: 'Watch games',

                    },
                    {
                        icon: 'event',
                        title: 'Book a venue',
                        description: 'Find your sports venue today and make a booking.Get your friends to join the session.',
                        path: '/venues'
                    },
                    {
                        icon: 'subscribe',
                        title: 'Buy game footage and livestream',
                        description: 'Miss the action? Game footage is available for purchase. Stream your event on the Huddle app and web.',
                        path: '/venues',
                        mobileText: 'Get livestream',
                        mobileDescription: 'Game footage is available for purchase. Stream your event on the Huddle app & web.'
                    },
                    {
                        icon: 'statics',
                        title: 'Check results and statistics',
                        description: 'Get access to game results together with team and player statistics.',
                        path: '/leagues',
                        mobileText: 'Results & stats'
                    }
                ]}
            />

            <Space />

            <Container>

                <GoogleAd
                    source="ad-butler"
                    slot="front-top"
                />

                <WatchPanel
                    id={"huddle"}
                    filters={{
                        globalFeed: true
                    }}
                    more
                    path={"/watch"}
                />

                <Space />

                <CommunitiesPanel
                    id={"communities"}
                    set='top'
                />

                {/* <Space />

                <AdBanner /> */}

                <Space />

                <GoogleAd
                    source="ad-butler"
                    slot="front-mid"
                />

                <Space />

                <NewsPanel
                    set='latest'
                />

                <Space />

                <SportsPanel
                    id={"sports"}
                    set='all'
                />

                <Space />

                <GoogleAd
                    source="ad-butler"
                    slot="front-bottom"
                />

                <Space />

            </Container>

            <Footer />

        </>

    );
};

interface HomeScreenProps {
    [key: string]: any;
}

export default HomeScreen;