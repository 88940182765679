import React from "react";
import { UiView } from "@dex/bubl-ui";

import Divider from "../Divider/Divider";
import StatsCard from "../StatsCard/StatsCard";

import styles from "./UserStatsCard.module.scss";

const UserStatsCard: React.FC<UserStatsCardProps> = (props) => {

    let { stat } = props;

    return (

        <UiView className={styles.card}>

            <Divider title={stat.title}/>

            <StatsCard stat={stat} />

        </UiView>

    );
};

interface UserStatsCardProps {
    [key: string]: any;
}

export default UserStatsCard;
