import React from "react";
import { UiFieldUpload, UiImage, UiLink, UiView } from "@dex/bubl-ui";

import styles from "./FieldUpload.module.scss";
import { BeatLoader } from "react-spinners";

const FieldUpload: React.FC<FieldUploadProps> = (props) => {

    return (

        <UiFieldUpload
            {...props}
            classes={{ wrap: [styles.wrap] }}
            renderInput={(args: any) => {

                return (

                    <>

                        {!args.file &&

                            <UiLink
                                onPress={args.handleFocus}
                            >
                                <UiView className={styles.placeholder} />
                            </UiLink>

                        }

                        {args.file &&
                            <UiImage
                                type="thumb"
                                src={args.file}
                                className={[styles.image, "image"]}
                                filters={{ width: 180, height: 180 }}
                            />
                        }

                        {args.loading &&
                            <UiView className={styles.loading} tag="span"><BeatLoader size={10} /></UiView>
                        }

                        <UiLink
                            onPress={args.handleFocus}
                            className={styles.icon}
                        >

                            <UiImage
                                type="local"
                                src={require('../../../assets/images/add-image.png')}
                                className={styles.iconImage}
                            />

                        </UiLink>

                    </>

                )

            }}
        />

    );
};

interface FieldUploadProps {
    [key: string]: any;
}

export default FieldUpload;
