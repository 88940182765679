import React, { useCallback, useMemo } from 'react';

import DropDate from '../../elements/DropDate/DropDate';
import DropSelect from '../../elements/DropSelect/DropSelect';

const WatchArchiveFilters: React.FC<WatchArchiveFiltersProps> = (props) => {

    const { handleFilterChange, filters, values } = props;

    const handleDateChange = useCallback((value) => {

        handleFilterChange("date", value);

    }, [handleFilterChange]);

    const handleSelectChange = useCallback((key, { value }) => {

        handleFilterChange(key, value);

    }, [handleFilterChange]);

    return useMemo(() => (

        <>

            <DropDate
                defaultValue={null}
                onChange={handleDateChange}
                placeholder="Select Date"
                showMonthYearPicker={false}
                dateFormat={"d MMMM yyyy"}
            />

            {filters && filters.map((filter: any, index: number) => (

                <DropSelect
                    key={index}
                    defaultValue={values[filter.key] || ''}
                    items={filter.options}
                    onChange={(value: any) => handleSelectChange(filter.key, value)}
                />

            ))}

        </>

    ), [filters, values, handleDateChange])

}

interface WatchArchiveFiltersProps {
    [key: string]: any
}

export default WatchArchiveFilters;