import React, { useState } from 'react';
import { UiLink, UiView } from '@dex/bubl-ui';
import { IoMdClose } from "react-icons/io";

import styles from './PassesPopupBuy.module.scss';

import Modal from '../../elements/Modal/Modal';
import OrderCheckout from '../OrderCheckout/OrderCheckout';

const PassesPopupBuy: React.FC<PassesPopupBuyProps> = (props) => {

    const { order, handleRefresh } = props;

    return (

        <Modal
            show={true}
            onClose={handleRefresh}
        >

            <UiView className={styles.popup}>

                <UiLink
                    onPress={handleRefresh}
                    className={styles.close}
                >

                    <IoMdClose />

                </UiLink>

                <OrderCheckout
                    order={order}
                    handleRefresh={handleRefresh}
                />

            </UiView>

        </Modal>
    )
}

interface PassesPopupBuyProps {
    [key: string]: any;
}

export default PassesPopupBuy;