import React from "react";
import { UiImage, UiLink, UiText, UiView } from "@dex/bubl-ui";

import styles from "./HeaderButton.module.scss";

const HeaderButton: React.FC<HeaderButtonProps> = (props) => {

    const manyLinks = [{ 'id': '1', 'pathLink': '/my-profile/my-sessions', 'label': 'My Sessions' },
    { 'id': '2', 'pathLink': '/my-profile/my-active-passes', 'label': 'My Active Passes' },
    { 'id': '3', 'pathLink': '/my-profile/athlete-profiles', 'label': 'Athlete Profiles' },
    { 'id': '4', 'pathLink': '/my-profile/my-orders', 'label': 'My Orders' },
    { 'id': '5', 'pathLink': '/my-profile', 'label': 'Announcements' },
    { 'id': '6', 'pathLink': '/my-profile/edit-account', 'label': 'Edit Account' },
    { 'id': '7', 'pathLink': '/my-profile/change-password', 'label': 'Change Password' },
    { 'id': '8', 'pathLink': '/my-profile/invite', 'label': 'Invite' }];

    const { user, onSignout } = props;

    return (

        <UiView className={styles.container}>

            <UiView className={styles.contentContainer}>
                {manyLinks.map((link) =>
                    <UiView key={link.id}>
                        <UiLink
                            path={link.pathLink}
                            className={styles.listContainer}
                        >
                            <UiText className={styles.link}>{link.label}</UiText>
                        </UiLink>
                    </UiView>
                )}

                <UiLink
                    className={styles.listContainer}
                    onPress={onSignout}
                >

                    <UiText className={styles.link}>Log Out</UiText>

                </UiLink>


            </UiView>

        </UiView>

    );
};

interface HeaderButtonProps {
    [key: string]: any;
}

export default HeaderButton;
