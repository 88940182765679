import React, { useEffect, useMemo, useState } from "react";
import { UiView, useUser } from "@dex/bubl-ui";
import { config } from "@dex/bubl-web";
import { get } from "@dex/bubl-helpers";

import styles from "./AssessmentsScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import SubMenu from "../../elements/SubMenu/SubMenu";
import AthleteAssessmentsPanel from "../../groups/AthleteAssessmentsPanel/AthleteAssessmentsPanel";
import Banner from "../../elements/Banner/Banner";

import AssessmentsApi from "../../apis/AssessmentsApi";

const AssessmentsScreen: React.FC<AssessmentsScreenProps> = (props) => {

    const athleteId = get(props, "screenProps.routeParams.athleteId");

    const user = useUser("appUser");

    const [athlete, setAthlete] = useState<any>(null);

    const assessments = AssessmentsApi.list(athleteId);

    useEffect(() => {

        if (assessments.data) assessments.reset();

        assessments.run({
            url: assessments.options.url,
            onComplete: (response: any) => {

                if (response) {

                    config.setMeta({
                        title: response.list.name || "Assessments"
                    });

                    setAthlete(response.athletes.find((item: any) => (item.id === athleteId)));

                }

            }
        });

    }, [athleteId]);

    return useMemo(() => (

        <>

            <Header />

            {assessments.data &&

                <>

                    {assessments.data.athletes &&

                        <SubMenu
                            menu={assessments.data.athletes.map((item: any) => ({ label: item.name, path: '/assessments/' + item.id }))}
                        />

                    }

                    {athlete &&

                        <Banner
                            heading={athlete.name}
                            avatar={athlete.image ?
                                {
                                    src: athlete.image,
                                    type: "thumb",
                                } :
                                {
                                    src: { id: user.id },
                                    type: "profile",
                                }}
                            backgroundImage={user.data.profileCover}
                        />

                    }

                    <Container>

                        <UiView className={styles.container}>

                            {assessments.data.list &&
                                assessments.data.hosts &&

                                <AthleteAssessmentsPanel
                                    heading={"Athlete Assessments"}
                                    assessments={assessments.data.list}
                                    hosts={assessments.data.hosts}
                                />

                            }

                        </UiView>

                    </Container>

                </>

            }

            <Footer />

        </>

    ), [assessments.loading, assessments.data, athlete, athleteId]);
};

interface AssessmentsScreenProps {
    [key: string]: any;
}

export default AssessmentsScreen;
