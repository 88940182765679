import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'News';

const NewsApi = {

    list: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/list",
            params: {},
            cache: true,
            ...options
        });

    },

}

export default NewsApi;