import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'CampOperator';

const CampOperatorApi = {

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            params: {},
            cache: true,
            ...options
        });

    },

    sessionList: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + '/sessionList',
            params: {},
            ...options
        });

    },

}

export default CampOperatorApi;