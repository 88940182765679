import { apiGet, options, response, apiPost } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'ChatThread';

const ChatApi = {

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            params: {},
            ...options
        });

    },

    getMessages: (threadId: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + '/public/' + threadId + '/getMessages',
            params: {},
            ...options,
        })

    },

    addMessage: (threadId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + '/public/' + threadId + '/addMessage',
            params: {
                id: threadId
            },
            ...options
        })

    }

}

export default ChatApi;