import { UiImage, UiLink, UiText, UiView, useUser } from '@dex/bubl-ui';
import moment from 'moment';
import React from 'react';
import nl2br from 'react-nl2br';
import Linkify from 'react-linkify';

import styles from './ChatRoomMessages.module.scss'

const ChatRoomMessages: React.FC<ChatRoomMessagesProps> = (props) => {

    const { messages } = props;

    return (
        <UiView className={styles.messageContainer}>

            {messages && messages.map((message: any, index: number) => (

                <ChatBubble
                    key={index}
                    message={message}
                />

            ))}

        </UiView>
    )
}


const ChatBubble: React.FC<ChatBubbleProps> = (props) => {

    const { message } = props;

    const user = useUser("appUser");

    const isSender = (id: any) => {
        return id === user.data.id
    }

    const linkComponent = (href: string, text: string) => {

    }

    return (
        <UiView
            className={[
                styles.chatBubble,
                isSender(message.senderId) ? styles.chatBubbleSender : ''
            ]}
        >

            <UiView
                className={[
                    styles.inner,
                    isSender(message.senderId) ? styles.innerSender : ''
                ]}
            >


                <UiImage
                    type="profile"
                    src={{
                        fileId: message.senderId,
                    }}
                    filters={{ width: 60, height: 60 }}
                    className={styles.profileImage}
                />



                <UiText
                    className={[
                        styles.chatBubbleText,
                        isSender(message.senderId) ? styles.chatBubbleTextSender : ''
                    ]}
                >

                    <Linkify
                        componentDecorator={(href: string, text: string) => (
                            <Link
                                key={href}
                                href={href}
                                text={text}
                                isSender={isSender(message.senderId)}
                            />
                        )}
                    >
                        <UiText className={styles.profileName}>{message.created?.fullName || "Name"}</UiText>
                        {nl2br(message.content)}

                    </Linkify>

                </UiText>



            </UiView>


            {/* <UiText className={styles.time}>

                {moment(message.date).format('h:mm')}

            </UiText> */}

        </UiView>
    )

}

const Link: React.FC<LinkProps> = (props) => {

    const { href, text, isSender } = props;

    return (
        <UiLink
            href={href}
            target="_blank"
            rel="noreferrer"
            className={isSender ? styles.linkSender : styles.link}
        >
            {text}

        </UiLink>
    )
}

interface LinkProps {
    [key: string]: any;
}

interface ChatBubbleProps {
    [key: string]: any
}

interface ChatRoomMessagesProps {
    [key: string]: any
}

export default ChatRoomMessages;