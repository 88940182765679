import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./LeagueSeasonsPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import Grid from "../../elements/Grid/Grid";
import LeaguesSeasonCard from "../../elements/LeaguesSeasonCard/LeaguesSeasonCard";

const LeagueSeasonsPanel: React.FC<LeagueSeasonsPanelProps> = (props) => {

    let { heading, seasons, operator } = props;

    return (

        <Panel
            id={props.id}
            heading={heading}
            edge={"sides"}
        >

            <Grid
                columns={3}
                gap={2}
            >

                {seasons.map((item: any, index: number) => (

                    <UiView
                        className={'item'}
                        key={index}
                    >

                        <LeaguesSeasonCard
                            title={item.title}
                            image={item.thumbnail || operator.thumbnail || operator.cover}
                            season={item}
                            operator={operator}
                        />

                    </UiView>

                ))}

            </Grid>

        </Panel>

    );
};

interface LeagueSeasonsPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default LeagueSeasonsPanel;
