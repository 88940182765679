import React, { useMemo } from "react";
import { UiView, useForm, UiRow, UiCol, UiText, UiForm } from "@dex/bubl-ui";

import styles from "./CampAthleteForm.module.scss";

import FieldText from "../../elements/Fields/FieldText/FieldText";
import FieldSelect from "../../elements/Fields/FieldSelect/FieldSelect";
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";
import FieldUpload from "../../elements/Fields/FieldUpload/FieldUpload";
import FieldDate from "../../elements/Fields/FieldDate/FieldDate";

const CampAthleteForm: React.FC<CampAthleteFormProps> = (props) => {

    let { onSubmit, athlete, loading } = props;

    const form = useForm(athlete || {});

    return useMemo(() => (

        <>

            <UiForm
                form={form}
                loading={loading}
                onSubmit={onSubmit}
            >

                <UiView className={['p-b-1 p-t-2 p-l-3 p-r-3']}>

                    <UiView className={styles.headingContainer}>

                        <UiText className={styles.headingText}>Please fill in the following information. The coach will verify this information on your first visit.</UiText>

                    </UiView>

                    <UiView className={styles.colContainer}>

                        <UiRow
                            className={styles.box}
                            edge={true}
                            gutter={2}
                        >

                            <UiCol col={{ xs: 24, lg: 14 }}>

                                <FieldText
                                    form={form}
                                    name={"name"}
                                    required={true}
                                    label={"Name"}
                                    defaultValue={form.defaults.name}
                                />

                                <Space />

                                <FieldSelect
                                    form={form}
                                    name={"gender"}
                                    required={true}
                                    label={"Gender"}
                                    defaultValue={form.defaults.gender}
                                    options={[
                                        { label: 'Male', value: 'male' },
                                        { label: 'Female', value: 'female' },
                                    ]}
                                />

                                <Space />

                                <FieldDate
                                    form={form}
                                    name={"dob"}
                                    required={true}
                                    label={"Date of Birth"}
                                    defaultValue={form.defaults.dob}
                                />

                                <Space />

                                <FieldText
                                    form={form}
                                    name={"email"}
                                    required={false}
                                    label={"Email"}
                                    defaultValue={form.defaults.email}
                                />

                                <Space />

                                <FieldText
                                    form={form}
                                    name={"phone"}
                                    required={false}
                                    label={"Contact Number"}
                                    defaultValue={form.defaults.phone}
                                />

                                <Space />

                                <FieldText
                                    form={form}
                                    name={"idNumber"}
                                    required={false}
                                    label={"ID Number"}
                                    defaultValue={form.defaults.idNumber}
                                />

                            </UiCol>

                            <UiCol
                                col={{ xs: 24, lg: 10 }}
                                className={styles.imageCol}
                            >

                                <UiView className={styles.photoHeading}>

                                    <UiText className={styles.profileText}>Photo</UiText>

                                </UiView>

                                <FieldUpload
                                    form={form}
                                    name={"image"}
                                    accept={"image/*"}
                                    defaultValue={form.defaults.image}
                                />

                            </UiCol>

                            <UiCol col={{ xs: 24, lg: 24 }}>

                                <Space />

                                <Button
                                    label={athlete ? "Save Changes" : "Register Athlete"}
                                    block={true}
                                    type={"submit"}
                                    loading={loading}
                                />

                            </UiCol>

                        </UiRow>

                    </UiView>

                </UiView>

            </UiForm>

        </>

    ), [form.values, form.errors, loading]);

};

interface CampAthleteFormProps {
    [key: string]: any;
}

export default CampAthleteForm;
