import React, { useMemo, useCallback } from "react";
import { LoginApi } from "@dex/bubl-web";
import { UiRow, UiCol, useStore, UiForm, useForm, UiText, UiLink, useLink } from "@dex/bubl-ui";

import styles from "./LoginForm.module.scss";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";

const LoginForm: React.FC<LoginFormProps> = (props) => {

    const [user] = useStore("appUser");

    const [key, setKey] = useStore("appKey");

    const [loaded, setLoaded] = useStore("appLoaded");

    const login = LoginApi.login({});

    const linkto = useLink();

    const onSubmit = useCallback((values) => {

        login.run({
            data: values,
            onComplete: (response: any) => {

                if (response) {

                    if (props.redirectTo) linkto(props.redirectTo, "replace");

                    setLoaded(false);

                    setKey(key + 1);

                }

            }
        });

    }, [login.loading]);

    const form = useForm({});

    return useMemo(() => (

        <UiForm
            form={form}
            loading={login.loading}
            onSubmit={onSubmit}
        >

            {!props.inline &&
                <>
                    <UiText className={styles.heading}>Log in to your Huddle account</UiText>
                    <UiText className={styles.subHeading}>New Here? <UiLink path="/register">Create an account →</UiLink></UiText>

                    <Space height={2} />
                </>
            }

            <UiRow
                gutter={2}
                edge={true}
            >

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"email"}
                        required={true}
                        label={"Email address"}
                        defaultValue={form.defaults.email}
                    />

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"password"}
                        required={true}
                        type={"password"}
                        label={"Password"}
                        defaultValue={form.defaults.password}
                    />

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <UiText className={styles.forgotPassword}><UiLink path="/forgot-password">Forgot Your Password?</UiLink></UiText>

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <Button
                        label={"SUBMIT"}
                        block={true}
                        loading={login.loading}
                        type={"submit"}
                    />

                </UiCol>

            </UiRow>

        </UiForm>

    ), [form, login.loading]);
};

interface LoginFormProps {
    [key: string]: any;
}

export default LoginForm;
