import React from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./NotFound.module.scss";

const NotFound: React.FC<NotFoundProps> = (props) => {

    if (props.loading) return <></>;

    if (Array.isArray(props.data) && props.data.length > 0) return <></>;
    else if (!Array.isArray(props.data) && props.data) return <></>;

    return (

        <UiView className={styles.box}>

            <UiText className={styles.message}>{props.message || "No Content Found"}</UiText>

        </UiView>

    );
};

interface NotFoundProps {
    [key: string]: any;
}

export default NotFound;
