import React, { useEffect, useMemo } from "react";
import { UiView, UiRow, UiCol, useStore, useLink, UiText } from "@dex/bubl-ui";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import LoginForm from "../../groups/LoginForm/LoginForm";

import styles from "./LoginScreen.module.scss";
import { get } from "@dex/bubl-helpers";

const LoginScreen: React.FC<LoginScreenProps> = (props) => {

    const [user] = useStore("appUser");

    const linkto = useLink();

    const returnTo = get(props, 'screenProps.routeParams.returnTo') || "/my-profile";

    useEffect(() => {

        if (user.loggedIn) {

            linkto(returnTo, "replace");

        }

    }, [user]);

    return useMemo(() => (

        <>

            <Header />

            <Container>

                <Space height={8} />

                <UiRow className={styles.box}>

                    <UiCol
                        col={{ xs: 24, lg: 12 }}
                        className={styles.cover}
                        background={{
                            src: require('../../assets/images/register-bg.jpg'),
                            resizeMode: "cover",
                            type: 'local',
                        }}
                    >

                        <UiText className={styles.text}>
                            YOU ARE JUST ONE<br /> STEP AWAY FROM<br /> AWESOMENESS
                        </UiText>

                    </UiCol>

                    <UiCol
                        col={{ xs: 24, lg: 12 }}
                        className={styles.form}
                    >

                        <UiView
                            className={['p-4']}
                        >

                            {!user.loggedIn &&
                                <LoginForm
                                    redirectTo={returnTo}
                                />
                            }

                        </UiView>

                    </UiCol>

                </UiRow>

                <Space height={8} />

            </Container>

            <Footer />

        </>

    ), [user]);
};

interface LoginScreenProps {
    [key: string]: any;
}

export default LoginScreen;
