import React, { useCallback, useEffect } from 'react';
import { get } from '@dex/bubl-helpers';
import { UiCol, UiRow, UiView, useLink, useUser } from '@dex/bubl-ui';
import { config } from '@dex/bubl-web';

import Header from "../../elements/Header/Header";
import VideoBlock from '../../elements/VideoBlock/VideoBlock';
import Container from '../../elements/Container/Container';
import VideoInfoPanel from '../../groups/VideoInfoPanel/VideoInfoPanel';
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import GameLogsPlayers from '../../groups/GameLogsPlayers/GameLogsPlayers';
import ResultCard from '../../elements/ResultCard/ResultCard';

import WatchApi from '../../apis/WatchApi';

import styles from "./WatchVideoScreen.module.scss";
import GoogleAd from '../../elements/GoogleAd/GoogleAd';
import OrderApi from '../../apis/OrderApi';
import Button from '../../elements/Button/Button';
import PassesPopupBuy from '../../groups/PassesPopupBuy/PassesPopupBuy';
import { MdAddShoppingCart } from 'react-icons/md';
import OrderDetailsPopup from '../../groups/OrderDetailsPopup/OrderDetailsPopup';
import ChatThread from '../../groups/ChatThread/ChatThread';
import ResultDisclaimer from '../../elements/ResultDisclaimer/ResultDisclaimer';

const WatchVideoScreen: React.FC<WatchVideoScreenProps> = (props) => {

    const videoId = get(props, 'screenProps.routeParams.videoId');

    const video = WatchApi.getOne(videoId);

    const orderId = get(props, 'screenProps.routeParams.orderId');

    useEffect(() => {

        video.run({
            onComplete: (response) => {

                config.setMeta({
                    title: response.title,
                    desc: response.description,
                    image: response.thumbnail || response.host.cover || response.host.logo
                });

            }
        });

    }, []);

    return (

        <>

            {orderId &&
                <OrderDetailsPopup orderId={orderId} />
            }

            <Header />

            {video.data &&
                <>

                    <Space height={5} />

                    <Container>

                        <GoogleAd
                            size="leaderboard"
                            slot="video"
                        />

                        <Space />

                        <UiRow
                            className={styles.box}
                            edge={true}
                            gutter={2}
                        >

                            <UiCol
                                col={{ xs: 24, lg: 17 }}
                                className={styles.cover}
                            >

                                <UiView className={styles.playerWrap}>

                                    {(!video.data.enablePPV || (video.data.enablePPV && video.data.ppvCanWatch)) &&
                                        <VideoBlock
                                            id={video.data.id}
                                            source={"watch"}
                                        />
                                    }

                                    {(video.data.enablePPV && !video.data.ppvCanWatch) &&
                                        <WatchVideoPurchasePpv
                                            videoId={video.data.id}
                                            productId={video.data.ppvProductId}
                                            price={video.data.ppvPrice}
                                        />
                                    }

                                </UiView>

                                <Space />

                                <GoogleAd
                                    slot="video"
                                    size="leaderboard"
                                />

                                <VideoInfoPanel
                                    video={video.data}
                                />

                                <Space />

                                {video.data.game && video.data.game.hasGameScores &&
                                    <>
                                        <ResultCard
                                            results={video.data.game}
                                            path={"team"}
                                            seasonId={video.data.game.season.id}
                                            slug={video.data.game.season.slug}
                                        />

                                        <ResultDisclaimer />
                                    </>
                                }

                                <Space />

                                {video.data.game && video.data.game.hasGameData &&
                                    <GameLogsPlayers
                                        id="gameLogs"
                                        heading="Box Scores"
                                        columns={video.data.game.boxScoreColumns}
                                        teams={video.data.game.teams}
                                    />
                                }

                            </UiCol>

                            <UiCol
                                col={{ xs: 24, lg: 7 }}
                                className={styles.cover}
                            >

                                {/* <ChatThread
                                    threadId={"kb7q5Qe1K5SCkzXVSEomrh"}
                                />

                                <Space /> */}

                                <GoogleAd
                                    size="rect"
                                    slot="video"
                                />

                            </UiCol>

                        </UiRow>

                    </Container>

                </>
            }

            <Footer />

        </>

    )

}

const WatchVideoPurchasePpv: React.FC<any> = (props) => {

    const { videoId, productId, price } = props

    const label = `PURCHASE TO WATCH ${price && ': RM ' + price}`

    const order = OrderApi.generate();

    const user = useUser("appUser");

    const linkTo = useLink();

    const handleClick = useCallback(() => {

        if (order.loading) return;

        if (user.loggedIn) {

            order.run({
                data: {
                    videoId,
                    productId,
                }
            })

        } else {

            linkTo("/login", "replace", { returnTo: window.location.pathname });

        }

    }, [])

    const handleRefresh = useCallback(() => {

        order.reset();

    }, [order])

    return (

        <>

            <UiView className={styles.buttonContainer}>

                <Button
                    label={label}
                    theme={"secondary"}
                    onClick={handleClick}
                    iconAfter
                    icon={<MdAddShoppingCart size={18} className={styles.cartIcon} />}
                />

                {order.complete && order.data &&
                    <PassesPopupBuy
                        order={order.data}
                        handleRefresh={handleRefresh}
                    />
                }

            </UiView>

        </>

    )

}

interface WatchVideoScreenProps {
    [key: string]: any,
}

export default WatchVideoScreen;