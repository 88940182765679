import React from "react";

import styles from "./GameLogs.module.scss";

import StatsTable from "../../elements/StatsTable/StatsTable";
import Panel from "../../elements/Panel/Panel";

const GameLogs: React.FC<GameLogsProps> = (props) => {

    let { set, heading, title, logs, columns, seasonId, seasonSlug } = props;

    return (

        <Panel
            heading={heading}
            id={props.id}
            edge="all"
        >

            <StatsTable
                stats={logs}
                columns={columns}
                seasonId={seasonId}
                seasonSlug={seasonSlug}
            />

        </Panel>


    );
};

interface GameLogsProps {
    set?: string;
    heading?: string;
    logs?: any;
    [key: string]: any;
}

export default GameLogs;
