
import React, { useRef, useState } from 'react';
import { UiLink, UiText, UiView } from '@dex/bubl-ui';
import Slider from 'react-slick';
import DotIndicator from '../DotIndicator/DotIndicator';
import Button from "../Button/Button";
import ProgressBar from '../ProgressBar/ProgressBar';
import styles from './Wizard.module.scss';

const Wizard: React.FC<WizardProps> = (props) => {

    const { slides, onClose } = props;

    const count = slides.length ? slides.length : 0;

    const [active, setActive] = useState(0);

    const [progressValue, setProgressValue] = useState((1 / count) * 100)

    const sliderRef: any = useRef(null);

    const settings: any = {
        infinite: false,
        arrows: false,
        beforeChange: (current: any, index: any) => index && setActive(index),
        afterChange: (current: any, index: any) => {

            setActive(current);

            const progress = ((current + 1) / count) * 100;

            setProgressValue(progress);
        }
    }

    const handleNext = (index: any) => {

        const nextStep = index + 1;

        if (count === nextStep) {

            onClose();

            setTimeout(() => {

                setActive(0);

                sliderRef.current.slickGoTo(0);

            }, 500)

        }

        sliderRef.current.slickGoTo(nextStep);

    }

    return (
        <UiView className={styles.slider}>

            <WizardHeader
                title={`${active + 1}/${count} : ${slides[active].title}`}
                progress={progressValue}
                onClose={onClose}
            />

            <Slider
                ref={sliderRef}
                {...settings}
            >

                {slides && slides.length > 0 && slides.map((slide: any, index: number) => (

                    <WizardSlide key={index} align={slide.align} >

                        {slide.render()}

                    </WizardSlide>

                ))}

            </Slider>

            <WizardFooter
                index={active}
                count={count}
                next={handleNext.bind(null, active)}
                buttonLabel={slides[active].buttonLabel || "NEXT"}
            />

        </UiView>
    )
}

const WizardSlide: React.FC<WizardProps> = (props) => {

    const { children, align } = props;

    return (
        <UiView className={[
            styles.slide,
            align === "top" ? styles.slideAlignTop : '',
            align === "center" ? styles.slideAlignCenter : '',
            align === "bottom" ? styles.slideAlignBottom : ''
        ]}>

            {children}

        </UiView>
    )

}

const WizardFooter: React.FC<WizardProps> = (props) => {

    const { buttonLabel, next, index, count } = props;

    return (

        <UiView className={styles.footer}>

            <UiView>

                <DotIndicator count={count} index={index} />

            </UiView>

            <Button
                size={"medium"}
                label={buttonLabel || "Next"}
                onClick={next}
            />

        </UiView>

    )

}

const WizardHeader: React.FC<WizardProps> = (props) => {

    const { title, progress, onClose } = props;

    return (
        <UiView className={styles.topBar}>

            <UiLink
                className={styles.closeLink}
                onClick={onClose}
            >
                <UiText className={styles.closeText}>Close</UiText>

            </UiLink>

            <UiView className={styles.titleBar}>

                <UiText className={styles.titleText}>{title}</UiText>

            </UiView>

            <ProgressBar percentage={progress} />

        </UiView>
    )
}

const WizardHeading: React.FC<WizardProps> = (props) => {

    return (

        <UiText className={styles.heading}>

            {props.children}

        </UiText>
    )

}

const WizardText: React.FC<WizardProps> = (props) => {

    const { bold, children } = props;

    return (

        <UiText className={[bold ? styles.textBold : styles.text]}>

            {children}

        </UiText>

    )

}

export { WizardHeading, WizardText }


interface WizardProps {
    [key: string]: any
}

export default Wizard;