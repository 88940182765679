import React, { useMemo, useCallback } from "react";
import { UiView, UiRow, UiCol, UiForm, useForm, Events } from "@dex/bubl-ui";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import Button from "../../elements/Button/Button";

import styles from "./ChangePassword.module.scss";
import { LoginApi } from "@dex/bubl-web";

const ChangePassword: React.FC<ChangePasswordProps> = (props) => {

    const form = useForm({ props });

    const update = LoginApi.changePassword();

    const onSubmit = useCallback((values) => {

        update.run({
            data: values,
            onComplete: (response: any) => {

                Events.emit("ui", {
                    action: "success",
                    message: "Password updated."
                });

            }
        });

    }, [update.run]);

    return useMemo(() => (

        <UiView className={styles.container}>

            <UiForm
                form={form}
                loading={update.loading}
                className={styles.contentContainer}
                onSubmit={onSubmit}
            >

                <UiRow
                    gutter={2}
                    edge={true}
                >

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"currentPassword"}
                            required={true}
                            type={"password"}
                            label={"Current Password"}
                            defaultValue={form.defaults.currentPassword}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"newPassword"}
                            required={true}
                            type={"password"}
                            label={"New Password"}
                            defaultValue={form.defaults.newPassword}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"confirmPassword"}
                            required={true}
                            type={"password"}
                            label={"Confirm Password"}
                            defaultValue={form.defaults.confirmPassword}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <Button
                            loading={update.loading}
                            label={"CHANGE PASSWORD"}
                            block={true}
                            type="submit"
                        />

                    </UiCol>

                </UiRow>

            </UiForm>
        </UiView>


    ), [form, update.run]);
};

interface ChangePasswordProps {
    [key: string]: any;
}

export default ChangePassword;
