import React, { useMemo, useState } from "react";
import { UiLink, UiText } from "@dex/bubl-ui";
import * as _ from "lodash";
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
// @ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table';

import styles from "./StatsPlayerTable.module.scss";
import { slug } from "@dex/bubl-helpers";

const StatsPlayerTable: React.FC<StatsPlayerTableProps> = (props) => {

    const { stats, columns, season } = props;

    const [sortBy, setSortBy] = useState("default");

    const header = [...columns];

    header.unshift({
        key: 'name',
        label: 'Player',
        type: 'text',
    });

    header.unshift({
        key: 'number',
        label: '#',
        type: 'text',
    });

    const players = useMemo(() => {

        if (!stats) return [];

        let players = [...stats];

        let total = players.pop();

        if (sortBy === "default") {
            players = _.orderBy(players, ['stats.dnp', 'stats.started', 'stats.pts', 'jerseyNumber'], ['desc', 'desc', 'desc', 'asc']);
        } else {
            players = _.orderBy(players, ['stats.dnp', 'stats.' + sortBy, 'jerseyNumber'], ['desc', 'desc', 'asc']);
        }

        players.push(total);

        return players;

    }, [stats, sortBy]);

    return (

        <>
            {players &&
                <StickyTable
                    className={styles.table}
                    stickyHeaderCount={1}
                    leftStickyColumnCount={2}
                    borderWidth={0}
                >

                    <Row className="head">

                        {header.map((column: any, index: number) => (

                            <Cell key={index} className={column.key}>

                                {!column.stat && column.label}

                                {column.stat &&
                                    <UiLink
                                        onClick={setSortBy.bind(null, column.key)}
                                        className={(sortBy === column.key || (sortBy === "default" && column.key === "pts")) ? [styles.sortBy, styles.sortByActive] : [styles.sortBy]}
                                    >
                                        {column.label}
                                    </UiLink>
                                }
                            </Cell>

                        ))}

                    </Row>

                    {players.map((player: any, index: any) => (

                        <Row key={index}>

                            {header.map((column: any, cellIndex: any) => (

                                <Cell key={cellIndex} className={column.key}>

                                    <UiText tag={"span"}>

                                        {column.stat &&
                                            player.stats && player.stats[column.key]
                                        }

                                        {column.key === "name" && season &&
                                            <UiLink path={`/leagues/season/${season.id}/${season.slug}/player/${player.id}/${slug(player.name)}`} className={styles.player}>
                                                {player.name}
                                            </UiLink>
                                        }
                                        {column.key === "name" && !season &&
                                            player.name
                                        }

                                        {column.key === "number" &&
                                            <>{player.jerseyNumber + ""}</>
                                        }

                                        {column.key === "dnp" &&
                                            player.stats && player.stats[column.key] && "DNP"
                                        }

                                        {column.key === "started" &&
                                            player.stats && player.stats[column.key] && <IoIosCheckmarkCircleOutline />
                                        }

                                    </UiText>

                                </Cell>

                            ))}

                        </Row>

                    ))}

                </StickyTable>
            }

        </>

    );
};

interface StatsPlayerTableProps {
    [key: string]: any;
}

export default StatsPlayerTable;
