import React, { createRef } from "react";
import { Events, UiText, UiView, useUser } from "@dex/bubl-ui";

import styles from "./InviteFriends.module.scss";
import Button from "../../elements/Button/Button";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import Space from "../../elements/Space/Space";

const InviteFriends: React.FC<InviteFriendsProps> = (props) => {

    const user = useUser("appUser");

    const referralCodeRef: any = createRef();

    const copyRefCode = (e: any) => {

        referralCodeRef.current.select();
        document.execCommand('copy');

        Events.emit("ui", {
            action: "success",
            message: "Your referral code has been copied successfully."
        });

    };


    return (
        <UiView className={styles.container}>

            <UiView className={styles.countContainer}>
                <UiText className={styles.count}>
                    {user.data.refferedCount || '0'}
                </UiText>
            </UiView>

            <UiView className={styles.refCodeContainer}>

                <FieldText
                    forwardedRef={referralCodeRef}
                    defaultValue={user.data.myReferralCode || "-"}
                    className={styles.refCode}
                    readOnly={true}
                />

                <Space height={1} />

                <Button
                    label={"COPY MY REFERRAL CODE"}
                    theme={'primary'}
                    block={true}
                    onClick={copyRefCode}
                />

            </UiView>

        </UiView>
    );
};

interface InviteFriendsProps {
    [key: string]: any;
}

export default InviteFriends;