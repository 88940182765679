import React, { useMemo, useCallback, useState } from "react";
import { LoginApi } from "@dex/bubl-web";
import { UiRow, UiCol, useStore, UiForm, useForm, UiText, Events, UiView, UiLink } from "@dex/bubl-ui";

import styles from "./ResetPasswordForm.module.scss";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {

    const resetPassword = LoginApi.resetPassword({});

    const [reset, setReset] = useState(false);

    const { resetKey } = props;

    const onSubmit = useCallback((values) => {

        values.resetKey = resetKey;

        if (resetPassword.loading) return;

        resetPassword.run({
            data: values,
            onComplete: (response: any) => {

                Events.emit("ui", {
                    action: "success",
                    message: response.message
                });

                form.resetFields();

                setReset(true);

            }
        });

    }, [resetPassword.loading]);

    const form = useForm({});

    return useMemo(() => (

        <>

            {reset &&

                <>
                    <UiText className={styles.heading}>Reset Your Password</UiText>
                    <UiText className={styles.subHeading}>Your password has been reset. <UiLink path="/login">Proceed to log in →</UiLink></UiText>
                </>

            }

            {!reset &&

                <UiForm
                    form={form}
                    loading={resetPassword.loading}
                    onSubmit={onSubmit}
                >

                    <UiText className={styles.heading}>Reset Your Password</UiText>

                    <Space height={2} />

                    <UiView className={'hidden'}>

                        {/* To prevent auto fill */}
                        <FieldText
                            form={form}
                            type="password"
                            name={"password"}
                            label={"Password"}
                            defaultValue={form.defaults.password}
                        />

                    </UiView>


                    <UiRow
                        gutter={2}
                        edge={true}
                    >

                        <UiCol col={{ xs: 24 }}>

                            <FieldText
                                form={form}
                                type="password"
                                name={"newPassword"}
                                required={true}
                                label={"Your New Password"}
                                autocomplete="off"
                                defaultValue={form.defaults.newPassword}
                            />

                        </UiCol>

                        <UiCol col={{ xs: 24 }}>

                            <FieldText
                                form={form}
                                type="password"
                                name={"repeatPassword"}
                                required={true}
                                label={"Repeat Your New Password"}
                                defaultValue={form.defaults.repeatPassword}
                            />

                        </UiCol>

                        <Space height={2} />

                        <UiCol col={{ xs: 24 }}>

                            <Button
                                label={"RESET MY PASSWORD"}
                                block={true}
                                type={"submit"}
                                loading={resetPassword.loading}
                            />

                        </UiCol>

                    </UiRow>

                </UiForm>

            }

        </>

    ), [form, resetPassword.loading, reset]);
};

interface ResetPasswordFormProps {
    [key: string]: any;
}

export default ResetPasswordForm;
