import React from "react";

import styles from "./TeamRoster.module.scss";

import StatsTable from "../../elements/StatsTable/StatsTable";
import Panel from "../../elements/Panel/Panel";
import StatsRosterTable from "../../elements/StatsRosterTable/StatsRosterTable";

const TeamRoster: React.FC<TeamRosterProps> = (props) => {

    let { set, heading, title, roster, columns, seasonId, seasonSlug } = props;

    return (


        <Panel
            heading={heading}
            id={props.id}
            edge="all"
        >

            <StatsRosterTable
                stats={roster}
                columns={columns}
                seasonId={seasonId}
                seasonSlug={seasonSlug}
            />

        </Panel>


    );
};

interface TeamRosterProps {
    set?: string;
    heading?: string;
    logs?: any;
    [key: string]: any;
}

export default TeamRoster;
