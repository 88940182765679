import { UiLink, UiText, UiView } from '@dex/bubl-ui';
import React from 'react';

import styles from './WatchSportCard.module.scss'

const WatchSportCard: React.FC<WatchSportCardProps> = (props) => {

    const { sport } = props

    return (

        <UiLink path={`/watch/sport/${sport.id}/${sport.slug}`}>

            <UiView
                className={styles.container}
                background={{
                    src: sport.thumbnail,
                    resizeMode: "cover",
                    type: "thumb",
                    filters: { width: 550, height: 285 }
                }}
            >

                <UiText className={styles.title}>
                    {sport.title}
                </UiText>

                <UiText className={styles.count}>
                    {sport.count} videos
                </UiText>

            </UiView>

        </UiLink>

    )

}

interface WatchSportCardProps {
    [key: string]: any
}

export default WatchSportCard;