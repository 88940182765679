import React from 'react'

function QuickLinksStaticsIcons(props: any) {
    return (
        <svg
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}

        >

            <g id="timeline_24px">

                <path
                    id="ic_timeline_24px"
                    d="M35.4584 12.3333C35.4584 14.0292 34.0709 15.4167 32.375 15.4167C32.0975 15.4167 31.8354 15.3858 31.5888 15.3088L26.1004 20.7817C26.1775 21.0283 26.2084 21.3058 26.2084 21.5833C26.2084 23.2792 24.8209 24.6667 23.125 24.6667C21.4292 24.6667 20.0417 23.2792 20.0417 21.5833C20.0417 21.3058 20.0725 21.0283 20.1496 20.7817L16.2184 16.8504C15.9717 16.9275 15.6942 16.9583 15.4167 16.9583C15.1392 16.9583 14.8617 16.9275 14.615 16.8504L7.60044 23.8804C7.67752 24.1271 7.70835 24.3892 7.70835 24.6667C7.70835 26.3625 6.32085 27.75 4.62502 27.75C2.92919 27.75 1.54169 26.3625 1.54169 24.6667C1.54169 22.9708 2.92919 21.5833 4.62502 21.5833C4.90252 21.5833 5.1646 21.6142 5.41127 21.6912L12.4413 14.6767C12.3642 14.43 12.3334 14.1525 12.3334 13.875C12.3334 12.1792 13.7209 10.7917 15.4167 10.7917C17.1125 10.7917 18.5 12.1792 18.5 13.875C18.5 14.1525 18.4692 14.43 18.3921 14.6767L22.3234 18.6079C22.57 18.5308 22.8475 18.5 23.125 18.5C23.4025 18.5 23.68 18.5308 23.9267 18.6079L29.3996 13.1196C29.3225 12.8729 29.2917 12.6108 29.2917 12.3333C29.2917 10.6375 30.6792 9.25 32.375 9.25C34.0709 9.25 35.4584 10.6375 35.4584 12.3333Z"
                    fill="#DFE4EA"
                />

            </g>

        </svg>

    )
}

export default QuickLinksStaticsIcons
