import React, { useState, useCallback, useEffect } from "react";
import { UiView, useDataStore, useAsyncEffect } from "@dex/bubl-ui";

import styles from "./DevElementsScreen.module.scss";
import SampleElement from "../../elements/SampleElement/SampleElement";
import SignInElement from "../../elements/SignInCta/SignInCta";
import HeaderElement from "../../elements/Header/Header";
import PanelElement from "../../elements/Panel/Panel";
import BigSearchElement from "../../elements/BigSearch/BigSearch";
import ButtonElement from "../../elements/Button/Button";
import ShareButtonElement from '../../elements/ShareButton/ShareButton';
import Banner from "../../elements/Banner/Banner";
import Footer from "../../elements/Footer/Footer";
import NewsCard from "../../elements/NewsCard/NewsCard";
import StandardCard from "../../elements/StandardCard/StandardCard";
import CommunityCard from "../../elements/CommunityCard/CommunityCard";
import EventCard from "../../elements/EventCard/EventCard";
import GroupBannerElement from '../../elements/GroupBanner/GroupBanner';
import FeaturedNewsBannerElement from '../../elements/FeaturedNewsBanner/FeaturedNewsBanner';
import DividerElement from '../../elements/Divider/Divider';
import SubMenu from '../../elements/SubMenu/SubMenu';
import FeaturedGrouCardElement from '../../elements/FeaturedGroupCard/FeaturedGroupCard';
import ProfileIntroCardElement from '../../elements/ProfileIntroCard/ProfileIntroCard';
import UserActivityCard from "../../elements/UserActivityCard/UserActivityCard";
import StatsCard from "../../elements/StatsCard/StatsCard";
import LeaguesSeasonCard from "../../elements/LeaguesSeasonCard/LeaguesSeasonCard";
import ResultCardElement from '../../elements/ResultCard/ResultCard';
import PhotoGrid from '../../elements/PhotoGrid/PhotoGrid';

// Images
import CoverBanner from "../../elements/CoverBanner/CoverBanner";
import PageBanner from "../../elements/PageBanner/PageBanner";
import OrgIntro from "../../elements/OrgIntro/OrgIntro";
import MenuItems from "../../elements/MenuItems/MenuItems";
import GroupCard from "../../elements/GroupCard/GroupCard";
import MemberCard from "../../elements/MemberCard/MemberCard";
import PassCard from "../../elements/PassCard/PassCard";
import FeaturedStats from "../../elements/FeaturedStats/FeaturedStats";
import StatsTable from "../../elements/StatsTable/StatsTable";
import Container from "../../elements/Container/Container";
import ProfileOption from "../../elements/ProfileOption/ProfileOption";

// Icon
import { FaUserAlt } from 'react-icons/fa';

const DevElementsScreen: React.FC<DevElementsScreenProps> = (props) => {

    const [selectedElement, setSelectedElement] = useState(0);

    useAsyncEffect(async () => {

        const selected = await useDataStore.get('devSelectedElement');

        setSelectedElement(selected || selectedElement);

    }, []);

    const elements = [
        {
            label: "Sample Element",
            render: () => (
                <SampleElement
                    heading={"Hello World"}
                />
            )
        },
        {
            label: "Header Element",
            render: () => (
                <HeaderElement />
            )
        },
        {
            label: "Sign In Element",
            render: () => (
                <SignInElement
                    headingText={"SIGN IN TO CUSTOMIZE YOUR HUDDLE EXPERIENCE, TODAY"}
                />
            )
        },
        {
            label: "Panel Element",
            render: () => (
                <Container>

                    <PanelElement
                        heading={"Panel Heading"}
                        button={"View All"}
                    >

                        Panel Body

                    </PanelElement>

                </Container>
            )
        },
        {
            label: "Big Search Element",
            render: () => (
                <BigSearchElement
                    heading={"Bringing Everyday Athletes Together"}
                    button={"SEARCH"}
                />
            )
        },
        {
            label: "Button Element",
            render: () => (
                <ButtonElement
                    label={"Label"}
                />
            )
        },
        {
            label: "Share Button Element",
            render: () => (
                <ShareButtonElement
                    text={"Share This"}
                />
            )
        },
        {
            label: "Banner Element",
            render: () => (
                <Banner
                    subHeading={'Sub Heading Text'}
                    heading={'Sample Banner'}
                    avatar={{
                        src: require('../../assets/images/avatar.png'),
                        type: "local",
                    }}
                    backgroundImage={require("../../assets/images/banner-bg.png")}
                />
            )
        },
        {
            label: "Footer Element",
            render: () => (
                <Footer />
            )
        },
        {
            label: "NewsCard Element",
            render: () => (
                <Container
                    width={"full"}
                    className={["w-300"]}
                >
                    <NewsCard
                        title={"News Card Title"}
                        content={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus commodi cum, ducimus eaque eos ex excepturi facilis fugit necessitatibus numquam obcaecati odio officia perspiciatis quaerat quidem reiciendis sequi sint sit."}
                        logo={require('../../assets/images/news-card-logo.png')}
                        image={require('../../assets/images/news-card.png')}
                    />
                </Container>
            )
        },
        {
            label: "StandardCard Element",
            render: () => (
                <Container
                    width={"full"}
                    className={["w-300"]}
                >
                    <StandardCard
                        title={"Title"}
                        image={require('../../assets/images/standard-card.png')}
                    />
                </Container>
            )
        },
        {
            label: "CommunityCard Element",
            render: () => (
                <CommunityCard
                    title={"Statsports League"}
                    image={require('../../assets/images/community-card.png')}
                />
            )
        },
        {
            label: "EventCard Element",
            render: () => (
                <EventCard
                    title={"Title"}
                    image={require('../../assets/images/event-card.png')}
                    subTitle={"Sub Title"}
                    dates={{ date1: "Thu 30/05", date2: "Sat 01/06" }}
                />
            )
        },
        {
            label: "CoverBanner Element",
            render: () => (
                <CoverBanner
                    background={require("../../assets/images/cover-banner.png")}
                />
            )
        },
        {
            label: "PageBanner Element",
            render: () => (
                <PageBanner
                    background={require("../../assets/images/page-banner.png")}
                    heading={'Heading'}
                />
            )
        },
        {
            label: "OrgIntro Element",
            render: () => (
                <Container
                    width={"thin"}
                    padding={2}
                >

                    <OrgIntro
                        image={require("../../assets/images/community-card.png")}
                        title={'Title'}
                        content={'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores eligendi fuga molestias possimus quidem ullam voluptas? Accusantium harum quo quos tenetur ullam!...'}
                    />

                </Container>
            )
        },
        {
            label: "Group Banner Element",
            render: () => (
                <GroupBannerElement
                    title={"STATSPORTS LEAGUE"}
                    description={"The Pro Experience for the average Joe"}
                    rating={"5.0"}
                    reviews={"2 Reviews"}
                />
            )
        },
        {
            label: "Menu Items Element",
            render: () => (
                <MenuItems menu={[{ path: "#foo", label: "Bar" }]} />
            )
        },
        {
            label: "Featured News Banner Element",
            render: () => (
                <Container>

                    <FeaturedNewsBannerElement
                        title={"Clippers owner completes $400 million deal for The Forum"}
                        description={"The Nuggets, lest you have forgotten, were one of the NBA's hottest... one of the toughest stretch run schedules in the NBA, something that starts..."}
                    />

                </Container>
            )
        },
        {
            label: "Divider",
            render: () => (
                <DividerElement
                    title={"Title"}
                />
            )
        },
        {
            label: "Group Card Element",
            render: () => (
                <GroupCard
                    title={"Title"}
                    description={"The Pro Experience for the average Joe"}
                    image={require('../../assets/images/group-card.png')}
                />
            )
        },
        {
            label: "Featured Group Card Element",
            render: () => (
                <Container width="full" className={"w-680"}>

                    <FeaturedGrouCardElement
                        title={"Title"}
                        buttonText={"Men's Open League"}
                        image={require('../../assets/images/featurednews.png')}
                    />

                </Container>
            )
        },
        {
            label: "Member Card Element",
            render: () => (
                <MemberCard
                    name={"Name"}
                    description={"Description"}
                    image={require('../../assets/images/member-card.png')}
                    stat={"Stat"}
                />
            )
        },
        {
            label: "Pass Card Element",
            render: () => (
                <PassCard
                    title={"Title"}
                    content={"Content"}
                    image={require('../../assets/images/pass-card.png')}
                    price={'RM2,100.00'}
                />
            )
        },
        {
            label: "User Activity Card Element",
            render: () => (
                <UserActivityCard
                    title={"Title"}
                    image={require('../../assets/images/thumbs-up.png')}
                    time={'1 day ago'}
                />
            )
        },
        {
            label: "Profile Intro Card Element",
            render: () => (
                <ProfileIntroCardElement
                    bioContent={`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. \n Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum`}
                    location={"Kuala Lumpur, Malaysia"}
                />
            )
        },
        {
            label: "Sub Menu Element",
            render: () => (
                <SubMenu />
            )
        },
        {
            label: "Stats Card Element",
            render: () => (
                <StatsCard
                    title="Mercennaries"
                    id="14"
                    name="Sample"
                    image={require('../../assets/images/avatar2.png')} />
            )
        },
        {
            label: "LeaguesSeasonCard Element",
            render: () => (
                <LeaguesSeasonCard
                    title={'Title'}
                    image={require('../../assets/images/league-session.png')}
                />
            )
        },
        {
            label: "Result Card Element",
            render: () => (
                <ResultCardElement
                    team1={"MasterCard"}
                    team1Score={"69"}
                    team2={"General Electric"}
                    team2Score={"80"}
                    date={"Thu, 27 Feb 2020"}
                    time={"5:00 PM"}
                />
            )
        },
        {
            label: "Featured Stats Element",
            render: () => (
                <FeaturedStats title="Title" />
            )
        },
        {
            label: "Stats Table Element",
            render: () => (
                <StatsTable stats={[
                    { date: '03-APR-2020', team: 'Young Thunders', res: 'W', pts: 27, reb: 49, ast: 95, stl: 91, blk: 3, fgm: 95, fga: 60, fgPer: 70, threePm: 88, threePa: 3, threePPer: 23, ftm: 23, fta: 54, ftPer: 49, off: 6, def: 70, to: 2, pf: 4 },
                    { date: '03-APR-2020', team: 'Young Thunders', res: 'W', pts: 27, reb: 49, ast: 95, stl: 91, blk: 3, fgm: 95, fga: 60, fgPer: 70, threePm: 88, threePa: 3, threePPer: 23, ftm: 23, fta: 54, ftPer: 49, off: 6, def: 70, to: 2, pf: 4 },
                    { date: '03-APR-2020', team: 'Young Thunders', res: 'W', pts: 27, reb: 49, ast: 95, stl: 91, blk: 3, fgm: 95, fga: 60, fgPer: 70, threePm: 88, threePa: 3, threePPer: 23, ftm: 23, fta: 54, ftPer: 49, off: 6, def: 70, to: 2, pf: 4 },
                ]} />
            )
        },
        {
            label: "Profile Options",
            render: () => (
                <ProfileOption
                    heading="View my social profile"
                    desc="As seen by others"
                    active
                >
                    <FaUserAlt />

                </ProfileOption>
            )
        },
    ];

    const onChange = useCallback((event) => {

        setSelectedElement(event.target.value);

        useDataStore.set("devSelectedElement", event.target.value);

    }, [setSelectedElement]);

    return (

        <UiView className={styles.container} key={selectedElement}>

            <>
                <UiView className={styles.head}>

                    <select
                        className={styles.select}
                        onChange={onChange}
                        defaultValue={selectedElement}
                    >
                        {elements.map((el, index) => (
                            <option key={index} value={index}>{el.label}</option>
                        ))}
                    </select>

                </UiView>

                <UiView className={styles.body}>

                    {elements[selectedElement] &&
                        <>
                            {elements[selectedElement].render()}
                        </>
                    }

                </UiView>
            </>

        </UiView>

    );
};

interface DevElementsScreenProps {
    [key: string]: any;
}

export default DevElementsScreen;
