import React from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./GroupCard.module.scss";

const GroupCard: React.FC<GroupCardProps> = (props) => {

    return (

        <UiView className={styles.container}
            background={{
                src: props.image,
                type: 'local',
                resizeMode: 'contain'
            }}
        >

            <UiText className={styles.title}>
                {props.title}
            </UiText>

            <UiText className={styles.description}>
                {props.description}
            </UiText>

        </UiView>

    );
};

interface GroupCardProps {
    [key: string]: any;
}

export default GroupCard;
