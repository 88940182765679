import React from 'react';
import { UiView, UiText, UiLink } from '@dex/bubl-ui';
import styles from "./RatingsAndReviews.module.scss";
import { AiOutlineGlobal } from "react-icons/ai";

const RatingsAndReviews: React.FC<RatingsAndReviewsProps> = (props) => {

    const { rating, review, websiteLink } = props;

    return (

        <UiView className={styles.textContainer}>

            {rating && (

                <UiView className={styles.textRow}>

                    <UiText className={styles.strongText}>{rating} </UiText>

                    <UiText className={styles.text}> rating</UiText>

                </UiView>
            )}


            {review && (

                <UiView className={styles.textRow}>

                    <UiText className={styles.strongText}>{review} </UiText>

                    <UiText className={styles.text}>reviews</UiText>

                </UiView>

            )}

            {websiteLink &&
                <UiLink
                    href={websiteLink}
                    target="_blank"
                    className={styles.textRow}
                >

                    <UiText className={styles.strongText}> <AiOutlineGlobal size={24} /> </UiText>

                    <UiText className={styles.textWeb}>visit website</UiText>

                </UiLink>
            }

        </UiView>

    )

}

interface RatingsAndReviewsProps {
    [key: string]: any,
}

export default RatingsAndReviews;