import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Announcements';

const AnnouncementsApi = {

    list: (hostId: string | null = null, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/list",
            params: { hostId: hostId },
            ...options
        });

    },

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            params: {},
            ...options
        });

    }

}

export default AnnouncementsApi;