import { apiGet, options, response, apiPost } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'PickupSession';

const PickupSessionApi = {

    getAttendees: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + `/public/${id}/getPeople`,
            params: {},
            ...options
        })

    },

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + `/public/${id}`,
            params: {},
            cache: true,
            ...options
        })

    },

    join: (sessionId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/${sessionId}/join`,
            params: {},
            ...options
        })

    },

    cancel: (sessionId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/${sessionId}/cancel`,
            params: {},
            ...options
        })

    },

}

export default PickupSessionApi;