import React, { useEffect, useMemo } from 'react';
import { get } from '@dex/bubl-helpers'
import { useStore } from '@dex/bubl-ui';

import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Container from '../../elements/Container/Container';
import Footer from "../../elements/Footer/Footer";
import WatchArchive from '../../groups/WatchArchive/WatchArchive';

const WatchSportScreen: React.FC<WatchSportScreenProps> = (props) => {

    const sportId = get(props, 'screenProps.routeParams.sportId');
    const key = get(props, 'screenProps.routeKey');

    const [user] = useStore('appUser');

    const menu = [
        { label: "All Videos", path: "/watch" },
        { label: "Live Streams", path: "/watch/live" }
    ]

    if (user.loggedIn) menu.push({ label: "My Videos", path: "/watch/my-videos" })

    return (

        <>
            <Header />

            <SubMenu
                menu={menu}
            />

            <Container>

                <WatchArchive
                    key={key}
                    sportId={sportId}
                />

            </Container>

            <Footer />

        </>
    )
}

interface WatchSportScreenProps {
    [key: string]: any
}

export default WatchSportScreen;