import React, { useMemo } from "react";
import { UiView, useAsyncEffect } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";

import styles from "./CommunityMemberScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Banner from "../../elements/Banner/Banner";
import StatsPanel from "../../groups/StatsPanel/StatsPanel";
import ActivitiesPanel from "../../groups/ActivitiesPanel/ActivitiesPanel";
import ProfileInfoCard from "../../elements/ProfileIntroCard/ProfileIntroCard";

import ProfileApi from "../../apis/ProfileApi";

const CommunityMemberScreen: React.FC<CommunityMemberScreenProps> = (props) => {

    const profileId = get(props, 'screenProps.routeParams.id');

    const profile = ProfileApi.communityProfile(profileId);
    const stats = ProfileApi.myStats(profileId);

    let params = {
        limit: 80,
        page: 1
    };

    useAsyncEffect(async (isMounted) => {

        await profile.runAsync();

        if (!isMounted()) return;

        await stats.runAsync({ params: params });

    }, [profileId]);

    return useMemo(() => (

        <>

            <Header />

            <SubMenu
                menu={[
                    { label: "Profile", scrollTo: "profile" },
                    { label: "Stats", scrollTo: "stats" },
                    { label: "Activities", scrollTo: "activities" }
                ]}
            />

            {profile &&
                profile.data &&

                <Banner
                    heading={profile.data.fullName}
                    avatar={{
                        src: profile.data.profilePhoto,
                        type: "thumb",
                    }}
                    backgroundImage={profile.data.profileCover}
                />

            }

            <Container>

                {profile &&
                    profile.data &&

                    <ProfileInfoCard
                        id="profile"
                        bioContent={profile.data.aboutMe}
                        location={profile.data.location}
                        communityProfile={true}
                        sportsPlayed={profile.data.sportsPlayedNames}
                    />

                }

                <Space height={3} />

                {stats &&
                    stats.data &&
                    <StatsPanel
                        id="stats"
                        heading={"My Stats"}
                        stats={stats.data.list}
                    />
                }

            </Container>

            <Space height={10} />

            <Footer />

        </>

    ), [profile.data, stats.data]);

};

interface CommunityMemberScreenProps {
    [key: string]: any;
}

export default CommunityMemberScreen;
