import React, { useEffect, useMemo } from "react";
import { UiView, useStore } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import FeaturedSlider from "../../groups/FeaturedSlider/FeaturedSlider";
import StandardCard from "../../elements/StandardCard/StandardCard";
import Panel from "../../elements/Panel/Panel";
import Grid from "../../elements/Grid/Grid";
import SportsTabs from "../../groups/SportsTabs/SportsTabs";

import CampSessionGroupApi from "../../apis/CampSessionGroupApi";

const CampsScreen: React.FC<CampsScreenProps> = (props) => {

    const [preferredSportId] = useStore("preferredSportId");

    const sportId = get(props, "screenProps.routeParams.sportId"); //preferredSportId

    const cards = CampSessionGroupApi.cards();

    let params = {
        sportId: sportId,
        limit: sportId ? 20 : 10,
    };

    useEffect(() => {

        cards.run({ params: params });

    }, [sportId]);

    let featured: any = [];

    if (cards.data && cards.data.featured) {

        featured = cards.data.featured.map((item: any, index: any) => {
            return {
                id: item.id,
                slug: item.slug,
                path: `/camps/group/${item.id}/${item.slug}`,
                title: item.title,
                category: item.category,
                image: item.thumbnail || item.cover,
            };

        });

    }

    return useMemo(() => (

        <>
            <Header />

            {/* <SubMenu menu={[]}/> */}


            <SportsTabs
                type="Camp"
                prefix="/camps/sport"
                selected={sportId}
            />

            <Space />

            {cards.data &&

                <>
                    {featured && featured.length > 0 &&

                        <>

                            <FeaturedSlider itemsData={featured} />

                            <Space height={4} />

                        </>
                    }

                    <Container>

                        {cards.data.categories.map((item: any, index: number) => (

                            <React.Fragment key={index}>

                                <Panel
                                    heading={item.category.title}
                                    edge={"sides"}
                                    moreText={"View More"}
                                    moreLink={sportId ? `/camps/sport/${item.category.sport.id}/category/${item.category.id}` : `/camps/sport/${item.category.id}/${item.category.slug}`}
                                >

                                    <Grid
                                        columns={5}
                                        gap={4}>

                                        {item.cards.map((card: any, key: number) => (

                                            <UiView
                                                className="item"
                                                key={key} >

                                                <StandardCard
                                                    path={`/camps/group/${card.id}/${card.slug}`}
                                                    title={card.title}
                                                    image={card.thumbnail || card.cover} />

                                            </UiView>

                                        ))}

                                    </Grid>

                                </Panel>

                                <Space />

                            </React.Fragment>
                        ))}

                    </Container>
                </>
            }

            <Footer />
        </>
    ),
        [cards.loading, cards.data]
    );
};

interface CampsScreenProps {
    [key: string]: any;
}

export default CampsScreen;
