import React, { useMemo } from "react";
import Grid from "../../elements/Grid/Grid";
import MemberCard from "../../elements/MemberCard/MemberCard";

import styles from "./PickupSessionJoin.module.scss";

const PickupSessionJoinPopupAttendees: React.FC<PickupSessionJoinPopupAttendeesProps> = (props) => {

    let { session } = props;

    return useMemo(() => (

        <>

            <Grid
                columns={4}
                gap={0}
                fixed={true}
            >

                {session.attendees.map((attendee: any, key: any) => (

                    <MemberCard
                        key={key}
                        size={"mini"}
                        name={attendee.fullName}
                        image={{
                            type: "profile",
                            src: { fileId: attendee.id, fileName: attendee.fullName },
                        }}
                        path={`/community/member/${attendee.id}/${attendee.slug}`}
                    />

                ))}

            </Grid>

        </>

    ), [session]);

};

interface PickupSessionJoinPopupAttendeesProps {
    [key: string]: any;
}

export default PickupSessionJoinPopupAttendees;
