import React from "react";
import { UiImage } from "@dex/bubl-ui";

import styles from "./ContentBlocks.module.scss";
import Container from "../Container/Container";
import Space from "../Space/Space";
import VideoBlock from "../VideoBlock/VideoBlock";
import TextBlock from "../TextBlock/TextBlock";
import CollectionBlock from "../CollectionBlock/CollectionBlock";

const ContentBlocks: React.FC<ContentBlocksProps> = (props) => {

    let { blocks, about } = props;

    return (

        <>

            {(blocks || []).map((block: any, index: number) => {

                if (block.type === "video") {

                    return (

                        <Container
                            key={index}
                            width="thin"
                        >

                            <VideoBlock
                                id={block.video.id}
                                source={block.video.source}
                                autoplay={false}
                            />

                            <Space />

                        </Container>

                    )

                } else if (block.type === "image") {

                    return (

                        <Container
                            key={index}
                            width="thin"
                        >

                            <UiImage
                                className={[styles.image]}
                                src={block.image}
                                filters={{ width: 960, resizeMode: "center" }}
                                type={'thumb'}
                            />

                            <Space />

                        </Container>

                    )

                } else if (block.type === "text") {

                    return (

                        <Container
                            key={index}
                            width={"thin"}
                            padding={4}
                        >

                            <TextBlock
                                content={block.content}
                            />

                            <Space />

                        </Container>

                    )

                } else if (block.type === "collection") {

                    let ids = [];
                    const type = block.collectionType;

                    if (type === "camp") ids = block.campIds || [];
                    if (type === "pickup") ids = block.pickupIds || [];
                    if (type === "league") ids = block.leagueIds || [];

                    return (

                        <Container
                            key={index}
                        >

                            <CollectionBlock
                                heading={block.heading}
                                modelName={block.modelName}
                                cards={block.cards}
                            />

                            <Space />

                        </Container>

                    )

                } else {

                    return (

                        <Container
                            key={index}
                        >

                            {JSON.stringify(block)}

                        </Container>

                    )

                }


            })}

        </>

    );
};

interface ContentBlocksProps {
    [key: string]: any;
}

export default ContentBlocks;
