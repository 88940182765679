import React from "react";

import styles from "./NewsArticle.module.scss";

import Container from "../Container/Container";
import Space from "../Space/Space";
import Panel from "../Panel/Panel";
import PhotoGrid from "../PhotoGrid/PhotoGrid";

const NewsArticlePhotos: React.FC<NewsArticlePhotosProps> = (props) => {

    const { article } = props;

    return (

        <Container
            width="full"
            className={styles.galleryWrap}
        >

            <Space height={2} />

            <Container>

                <Panel
                    heading={"Photo Gallery"}
                    edge={"all"}
                    topRight={article.gallery.length + ' photos available'}
                    hideTop={false}
                    transparent={true}
                >

                    <PhotoGrid
                        gallery={article.gallery}
                        columns={5}
                    />

                </Panel>

            </Container>

            <Space height={4} />

        </Container>

    );

};

interface NewsArticlePhotosProps {
    article?: any,
    [key: string]: any;
}

export default NewsArticlePhotos;
