import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./NewsArchive.module.scss";

import Panel from "../../elements/Panel/Panel";
import Grid from "../../elements/Grid/Grid";
import NewsCard from "../../elements/NewsCard/NewsCard";

const NewsArchive: React.FC<NewsArchiveProps> = (props) => {

    let { heading, list } = props;

    return (

        <Panel
            heading={heading}
            hideTop={!heading}
        >

            <Grid
                columns={4}
                gap={2}
                fixed={true}
            >

                {(list || []).map((item: any, index: number) => (

                    <UiView
                        className="item"
                        key={index}
                    >

                        <NewsCard
                            id={item.id}
                            slug={item.slug}
                            title={item.title}
                            content={item.description}
                            category={item.category}
                            time={item.date}
                            image={item.thumbnail || item.gallery[0]}
                            sport={item.sport}
                        />

                    </UiView>

                ))}

            </Grid>

        </Panel>

    );
};

interface NewsArchiveProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default NewsArchive;
