import React from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./ResultDisclaimer.module.scss";

const ResultDisclaimer: React.FC<ResultDisclaimerProps> = (props) => {

    return (

        <UiView className={styles.wrap}>
            <UiText className={styles.text}>* Results and statistics provided by Huddle are interpreted by Huddle AI and data teams. They should not impact the official competition results or organizer decisions. Rosters and player info are provided by the organizer and are accurate thereto.</UiText>
        </UiView>

    );
};

interface ResultDisclaimerProps {
    [key: string]: any;
}

export default ResultDisclaimer;
