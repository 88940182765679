import React from 'react';
import { get } from '@dex/bubl-helpers'
import { useStore } from '@dex/bubl-ui';

import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Container from '../../elements/Container/Container';
import Footer from "../../elements/Footer/Footer";
import WatchArchive from '../../groups/WatchArchive/WatchArchive';
import GoogleAd from '../../elements/GoogleAd/GoogleAd';
import Space from '../../elements/Space/Space';

const WatchCategoryScreen: React.FC<WatchCategoryScreenProps> = (props) => {

    const categoryId = get(props, 'screenProps.routeParams.categoryId');
    const key = get(props, 'screenProps.routeKey');

    const [user] = useStore('appUser');

    const menu = [
        { label: "All Videos", path: "/watch" },
        { label: "Live Streams", path: "/watch/live" }
    ]

    if (user.loggedIn) menu.push({ label: "My Videos", path: "/watch/my-videos" })

    return (

        <>
            <Header />

            <SubMenu
                menu={menu}
            />

            <Container>

                <Space />

                <GoogleAd
                    source="ad-butler"
                    slot={"watch-category"}
                />

                <Space />

                <WatchArchive
                    key={key}
                    categoryId={categoryId}
                />

                <Space />

                <GoogleAd
                    source="ad-butler"
                    slot={"watch-category"}
                />

                <Space />

            </Container>

            <Footer />

        </>
    )
}

interface WatchCategoryScreenProps {
    [key: string]: any
}

export default WatchCategoryScreen;