import React from "react";
import { UiFieldSelect } from "@dex/bubl-ui";

import styles from "./FieldSelect.module.scss";

const FieldSelect: React.FC<FieldSelectProps> = (props) => {

    return (

        <UiFieldSelect
            classes={{
                wrap: [styles.wrap],
                label: [styles.label],
                input: [!!props.className ? [styles.input, props.className].join(' ') : styles.input],
                error: [styles.error],
            }}
            options={props.options}
            {...props}
        />


    );
};

interface FieldSelectProps {
    [key: string]: any;
}

export default FieldSelect;
