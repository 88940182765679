import React from "react";
import { UiText, UiView, UiImage, UiLink } from "@dex/bubl-ui";
import { slug } from "@dex/bubl-helpers";

// @ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table';

import styles from "./GroupTable.module.scss";

const GroupTable: React.FC<GroupTableProps> = (props) => {

    const { stats } = props;

    if (!stats) return <></>;

    // let rows = [...stats];

    // const keys = Object.keys(rows[0]);
    // const head = Object.values(rows[0]);

    // rows.shift();

    const columns = [
        {
            key: "pos",
            label: "Pos",
        },
        {
            label: "team",
            key: "teamName",
            className: styles.teamName,
            render: (data: any) => (
                <UiLink
                    path={`/leagues/season/${props.seasonId}/${props.slug}/team/${data.teamId}/${slug(data.teamName)}`}
                    className={styles.team}
                >

                    <UiImage
                        className={styles.avatar}
                        src={data.teamLogo || { fileId: "#" }}
                        type={"thumb"}
                        resizeMode={"contain"}
                        filters={{ width: 100, height: 100, fallback: { text: data.teamName[0] } }}
                    />

                    <UiText>{data.teamName}</UiText>
                </UiLink>
            )
        },
        {
            label: "GP",
            key: "gp"
        },
        {
            label: "W",
            key: "w"
        },
        {
            label: "L",
            key: "l"
        },
        {
            label: "D",
            key: "d",
            align: "center"
        },
        {
            label: "Points",
            key: "points"
        },
        {
            label: "PF",
            key: "pf",
            align: "center"
        },
        {
            label: "PA",
            key: "pa",
            align: "center"
        },
        {
            label: "Diff",
            key: "pd",
            align: "center"
        },
    ]

    return (

        <UiView className={styles.wrap} id={"group"}>

            {stats &&

                <StickyTable
                    className={styles.table}
                    stickyHeaderCount={1}
                    leftStickyColumnCount={2}
                    borderWidth={0}
                >

                    <Row className={"head"}>

                        {columns.map((column: any, key: any) => (
                            <Cell key={key}>
                                {column.label}
                            </Cell>
                        ))}

                    </Row>

                    {stats.map((data: any, key: any) => (

                        <Row key={key} className={'body'}>

                            {columns.map((column: any, key: any) => (

                                <Cell key={key} className={column.className || ''}>

                                    {column.render ? column.render(data) : data[column.key]}

                                </Cell>

                            ))}

                        </Row>

                    ))}

                </StickyTable>
            }

        </UiView >

    );
};

interface GroupTableProps {
    [key: string]: any;
}

export default GroupTable;
