import React, { useMemo, useState } from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./PickupSessionJoin.module.scss";

import Divider from "../../elements/Divider/Divider";
import Space from "../../elements/Space/Space";
import PickupSessionJoinPopupBuy from "./PickupSessionJoinPopupBuy";
import PickupSessionJoinPopupJoin from "./PickupSessionJoinPopupJoin";
import PickupSessionJoinPopupCancel from "./PickupSessionJoinPopupCancel";
import PickupSessionJoinPopupAttendees from "./PickupSessionJoinPopupAttendees";

const PickupSessionJoin: React.FC<PickupSessionJoinProps> = (props) => {

    // Api to fetch list of athletes and valid passes for this session/user
    const { handleRefresh, session } = props;

    const [didAction, setDidAction] = useState<any>(false);

    return useMemo(() => (

        <UiView>

            {session.isAttending &&
                <>

                    <Divider
                        title={"Attendance"}
                        dark={true}
                    />

                    <UiView className={[styles.box, 'p-4']}>

                        <PickupSessionJoinPopupAttendees
                            handleRefresh={handleRefresh}
                            setDidAction={setDidAction}
                            session={session}
                        />

                        {session.canCancel &&
                            <>

                                <Space height={2} />

                                <PickupSessionJoinPopupCancel
                                    handleRefresh={handleRefresh}
                                    setDidAction={setDidAction}
                                    session={session}
                                />

                            </>

                        }

                    </UiView>
                </>
            }

            {!session.isAttending &&
                <>

                    <Divider
                        title={"Join this session"}
                        dark={true}
                    />

                    {session.canJoin &&

                        <UiView className={[styles.box, 'p-4']}>

                            {session.activePass &&

                                <PickupSessionJoinPopupJoin
                                    handleRefresh={handleRefresh}
                                    setDidAction={setDidAction}
                                    session={session}
                                />

                            }

                            {!session.activePass &&

                                <PickupSessionJoinPopupBuy
                                    handleRefresh={handleRefresh}
                                    setDidAction={setDidAction}
                                    session={session}
                                />

                            }

                        </UiView>

                    }

                    {!session.canJoin &&

                        <UiView className={[styles.box, 'p-4']}>

                            <UiText>This session is closed. Please select another session to join.</UiText>

                        </UiView>

                    }

                </>
            }

        </UiView>

    ), [session, didAction]);

};

interface PickupSessionJoinProps {
    [key: string]: any;
}

export default PickupSessionJoin;
