import React, { useEffect } from 'react';
import { get } from '@dex/bubl-helpers'
import { useLink, useStore } from '@dex/bubl-ui';

import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Container from '../../elements/Container/Container';
import Footer from "../../elements/Footer/Footer";
import WatchArchive from '../../groups/WatchArchive/WatchArchive';

const WatchMyVideosScreen: React.FC<WatchMyVideosScreenProps> = (props) => {

    const key = get(props, 'screenProps.routeKey');

    const [user] = useStore('appUser');
    const linkto = useLink();

    useEffect(() => {
        
        if(!user.loggedIn) linkto('/login',"replace");
        
    },[user])

    return (

        <>
            <Header />

            <SubMenu
                menu={[
                    { label: "All Videos", path: "/watch" },
                    { label: "Live Streams", path: "/watch/live" },
                    { label: "My Videos", path: "/watch/my-videos" },
                ]}
            />

            <Container>

                <WatchArchive
                    key={key}
                    myVideos
                />

            </Container>

            <Footer />

        </>

    )

}

interface WatchMyVideosScreenProps {
    [key: string]: any,
}

export default WatchMyVideosScreen;