import React, { useEffect, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";

import styles from "./AnnouncementScreen.module.scss";

import Container from "../../elements/Container/Container";
import Space from "../../elements/Space/Space";
import CoverBanner from "../../elements/CoverBanner/CoverBanner";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import AnnouncementContent from "../../elements/AnnouncementContent/AnnouncementContent";

import AnnouncementsApi from "../../apis/AnnouncementsApi";

const AnnouncementScreen: React.FC<AnnouncementScreenProps> = (props) => {

    const announcementId = get(props, 'screenProps.routeParams.announcementId');

    const announcement = AnnouncementsApi.getOne(announcementId);

    useEffect(() => {

        announcement.run();

    }, announcementId);

    return useMemo(() => (

        <>

            <Header />

            {announcement.data &&
                <>

                    <UiView className={styles.coverBanner}>

                        <CoverBanner
                            background={announcement.data.host.cover || {}}
                        />

                    </UiView>

                    <UiView className={styles.content}>

                        <Container
                            width={"thin"}
                            padding={2}
                        >

                            <AnnouncementContent
                                announcement={announcement.data}
                            />

                        </Container>

                    </UiView>

                </>
            }

            <Space />

            <Footer />

        </>

    ), [announcement.data]);

};

interface AnnouncementScreenProps {
    [key: string]: any;
}

export default AnnouncementScreen;
