import React, { useCallback, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";
//@ts-ignore
import Select from 'react-select';

import styles from "./DropSelect.module.scss";

const DropSelect: React.FC<DropSelectProps> = (props) => {

    let { id, items, defaultValue, keywordSearch, onChange, styleDropDown } = props;

    let searchFilters: any = [];

    if (!defaultValue && items && items[0]) {

        defaultValue = items[0];

    }

    const handleChange = useCallback((item) => {

        let values = { id: id, item: item.value }

        if (onChange) onChange(keywordSearch ? values : item);

    }, [onChange]);

    return useMemo(() => {

        if (typeof defaultValue === "string") {

            (items || []).map((item: any) => {

                if (defaultValue === item.value) defaultValue = item;

                return item;

            });

        }

        if (keywordSearch) {

            items.map((filters: any) => {
                if (typeof filters === "object") {
                    searchFilters.push(filters);
                }
                else {
                    searchFilters.push({ "label": filters, "value": filters });
                    defaultValue = { "label": items[0], "value": items[0] };
                }
                return [];
            })

        }

        return (

            <UiView className={[styles.dropmenu, styleDropDown, keywordSearch ? styles.searchDropmenu : '']}>

                <Select
                    isClearable={false}
                    isSearchable={false}
                    options={keywordSearch ? searchFilters : items}
                    defaultValue={defaultValue}
                    className={styles.select}
                    classNamePrefix={"drop"}
                    onChange={handleChange}
                />

            </UiView>

        );

    }, [items]);

};

interface DropSelectProps {
    [key: string]: any;
}

export default DropSelect;
