import React, { useMemo } from "react";
import { useAsyncEffect } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";
import { config } from "@dex/bubl-web";

import styles from "./LeagueTeamScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import LeagueLeaderPanel from "../../groups/LeagueLeaderPanel/LeagueLeaderPanel";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import Banner from "../../elements/Banner/Banner";
import SubMenu from "../../elements/SubMenu/SubMenu";
import FeaturedStats from "../../elements/FeaturedStats/FeaturedStats";
import GameLogs from "../../groups/GameLogs/GameLogs";

import LeagueSeasonApi from "../../apis/LeagueSeasonApi";
import TeamRoster from "../../groups/TeamRoster/TeamRoster";
import WatchPanel from "../../groups/WatchPanel/WatchPanel";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";
import LeagueSeasonTeamApi from "../../apis/LeagueSeasonTeamApi";
import ResultDisclaimer from "../../elements/ResultDisclaimer/ResultDisclaimer";

const LeagueTeamScreen: React.FC<LeagueTeamScreenProps> = (props) => {

    const seasonId = get(props, 'screenProps.routeParams.seasonId');
    const teamId = get(props, 'screenProps.routeParams.teamId');
    const slug = get(props, 'screenProps.routeParams.slug');

    const team = LeagueSeasonTeamApi.getTeam(teamId, ["season", "stats", "results", "leaderboard"]);

    useAsyncEffect((isMounted) => {


        team.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.name + ' - ' + response.season.title,
                    desc: response.season.description,
                    image: response.logo,
                    imageType: "thumb"
                });

            },
            onEnd: (response: any) => {

            }
        });

    }, []);

    return useMemo(() => (

        <>

            <Header />

            {(team.data && team.data.season) &&
                <SubMenu
                    menu={[
                        team.data.season ? { label: team.data.season.title, path: `/leagues/season/${team.data.season.id}/${team.data.season.slug}` } : undefined,
                        { label: "STATS", scrollTo: 'stats' },
                        { label: "LEADERS", scrollTo: 'leaders' },
                        { label: "GAME LOGS", scrollTo: 'gameLogs' },
                        { label: "ROSTER", scrollTo: 'roster' },
                        { label: "WATCH", scrollTo: 'watch' },
                        { label: "NEWS", scrollTo: 'news' },
                    ].filter(Boolean)}
                />
            }

            {(team.data && team.data.season) &&
                <Banner
                    heading={team.data.name}
                    avatar={{
                        src: team.data.logo,
                        type: "thumb",
                    }}
                    backgroundImage={team.data.season.thumbnail}
                />
            }

            <Container>

                <Space />

                {team.data &&
                    <>
                        <FeaturedStats
                            id="stats"
                            title="AVERAGES"
                            stats={team.data.stats.averages}
                        />
                        <Space />
                    </>
                }

                {team.data &&
                    <>
                        <FeaturedStats
                            title="TOTALS"
                            stats={team.data.stats.totals}
                        />
                        <Space />
                    </>
                }

                <GoogleAd
                    source="ad-butler"
                    slot="opp-top"
                />

                <Space />

                {team.data && team.data.leaderboards &&
                    <>
                        <LeagueLeaderPanel
                            id="leaders"
                            heading="Team Leaders"
                            leaderboard={team.data.leaderboards}
                            seasonId={team.data.seasonId}
                            seasonSlug={team.data.season.slug}
                        />
                        <Space />
                    </>
                }

                {team.data && team.data.results && team.data.season &&
                    <>
                        <GameLogs
                            id="gameLogs"
                            heading="Gamelogs"
                            logs={team.data.results}
                            columns={team.data.boxScoreColumns}
                            seasonId={team.data.seasonId}
                            seasonSlug={team.data.season.slug}
                        />

                        <ResultDisclaimer />

                        <Space />
                    </>
                }


                {team.data && team.data.roster &&
                    <>
                        <TeamRoster
                            id="roster"
                            heading="Roster"
                            roster={team.data.roster}
                            columns={team.data.boxScoreColumns}
                            seasonId={team.data.season.id}
                            seasonSlug={team.data.season.slug}
                        />
                        <Space />
                    </>
                }

                {team.data &&
                    <>
                        <WatchPanel
                            id={"watch"}
                            filter={{
                                teamId
                            }}
                        />

                        <Space />
                    </>
                }

                {team.data &&
                    <NewsPanel
                        set="LeagueTeam"
                        teamId={team.data.id}
                    />
                }

                <Space />

                <GoogleAd
                    source="ad-butler"
                    slot="opp-bottom"
                />

                <Space />

            </Container>

            <Footer />

        </>

    ), [team.loading, team.data]);

};

interface LeagueTeamScreenProps {
    [key: string]: any;
}

export default LeagueTeamScreen;
