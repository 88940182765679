import React from "react";
import { UiText, UiView, UiLink } from "@dex/bubl-ui";
import { FaTicketAlt } from "react-icons/fa";
// @ts-ignore
import { StickyTable, Row, Cell } from "react-sticky-table";

import styles from "./OrderTable.module.scss";

import moment from "moment";
import Space from "../Space/Space";

const OrderTable: React.FC<OrderTableProps> = (props) => {

    const { orders } = props;

    return (

        <UiView>

            <UiText className={styles.heading}>{props.heading}</UiText>

            <Space height={2} />

            <StickyTable
                className={styles.table}
                stickyHeaderCount={1}
                leftStickyColumnCount={0}
            >
                <Row className="head">

                    <Cell>
                        ITEM
                    </Cell>

                    <Cell>
                        ORDER #
                    </Cell>

                    <Cell>
                        DATE
                    </Cell>

                    <Cell>
                        TOTAL
                    </Cell>

                </Row>

                {orders.map((order: any, index: any) => (
                    <Row
                        key={index}
                    >

                        <Cell>

                            <UiLink path={`/order-details/${order.id}`} className={"item"}>

                                <UiView className={styles.icon}>
                                    <FaTicketAlt />
                                </UiView>

                                <UiText>{order.item.title}</UiText>

                            </UiLink>

                        </Cell>

                        <Cell>

                            <UiLink path={`/order-details/${order.id}`}>

                                {order.id}

                            </UiLink>

                        </Cell>

                        <Cell>

                            <UiLink path={`/order-details/${order.id}`}>

                                {moment(order.created.date).format("DD/MM/YYYY")}

                            </UiLink>

                        </Cell>

                        <Cell className="total">

                            <UiLink path={`/order-details/${order.id}`}>

                                {order.currency} {order.total}

                            </UiLink>

                        </Cell>

                    </Row>
                ))}

            </StickyTable>

        </UiView>


    );
};

interface OrderTableProps {
    [key: string]: any;
}

export default OrderTable;
