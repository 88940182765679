import React, { useCallback, useState } from "react";
import { UiImage, UiText, UiView, UiLink, useForm, UiForm, Events } from "@dex/bubl-ui";
import { LoginApi } from "@dex/bubl-web";

import styles from "./Banner.module.scss";
import Container from "../Container/Container";
import FieldUpload from "../Fields/FieldUpload/FieldUpload";

const BannerEdit: React.FC<BannerEditProps> = (props) => {

    const { user } = props;

    const form1 = useForm(user.data || {});
    const form2 = useForm(user.data || {});

    const updatePhoto = LoginApi.update('profilePhoto');

    const updateCover = LoginApi.update('profileCover');

    const handleProfile = useCallback((values) => {

        if (updatePhoto.loading) return;

        updatePhoto.run({
            data: {
                profilePhoto: values
            },
            onComplete: (response: any) => {

                Events.emit("ui", {
                    action: "success",
                    timeout: 3000,
                    message: "Changes Saved"
                });

                if (response) user.setState(response);

            }
        });

    }, [updatePhoto]);

    const handleCover = useCallback((values) => {

        if (updateCover.loading) return;

        updateCover.run({
            data: {
                profileCover: values
            },
            onComplete: (response: any) => {

                Events.emit("ui", {
                    action: "success",
                    timeout: 3000,
                    message: "Changes Saved"
                });

                if (response) user.setState(response);

            }
        });

    }, [updateCover]);

    return (

        <UiView className={styles.banner}>

            <Container>

                <UiView
                    className={styles.center}
                    background={{
                        src: form2.values.profileCover,
                        type: "thumb",
                        resizeMode: "cover",
                        filters: { width: Math.min(window.innerWidth, 1400), height: 300 }
                    }}
                >

                    <UiView className={styles.profileImage}>

                        <UiForm
                            form={form1}
                            onSubmit={handleProfile}
                            loading={updatePhoto.loading}
                            className={styles.profileImageForm}
                        >

                            <FieldUpload
                                form={form1}
                                name={'profilePhoto'}
                                accept={"image/*"}
                                fieldType="profilePhoto"
                                defaultValue={form1.defaults.profilePhoto}
                                onChange={handleProfile}
                            />

                        </UiForm>

                    </UiView>

                    <UiView className={styles.headings}>

                        <UiText className={styles.subHeading}>
                            {props.subHeading}
                        </UiText>

                        {props.heading &&

                            <UiText className={styles.heading}>
                                {props.heading}
                            </UiText>

                        }

                        {props.badgeText &&

                            <UiText className={styles.badge}>
                                {props.badgeText}
                            </UiText>

                        }

                    </UiView>

                    <UiForm
                        form={form2}
                        loading={updateCover.loading}
                        onSubmit={handleCover}
                        className={styles.formImageCover}
                    >

                        <FieldUpload
                            form={form2}
                            name={"profileCover"}
                            accept={"image/*"}
                            fieldType={"profileCover"}
                            defaultValue={form2.defaults.profileCover}
                            onChange={handleCover}
                            className={"file-upload-cover"}
                        />

                    </UiForm>

                </UiView>

            </Container>

        </UiView>

    );
};

interface BannerEditProps {
    heading?: string;
    subHeading?: string;
    [key: string]: any;
}

export default BannerEdit;
