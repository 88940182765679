import React from "react";
import { UiText, UiView, UiLink, UiImage } from "@dex/bubl-ui";
import Rating from "react-rating"
import { IoIosStar } from 'react-icons/io';

import styles from "./GroupBanner.module.scss";

import StarImage from "../../assets/images/star.png"
import Container from "../Container/Container";
import Space from "../Space/Space";

const GroupBanner: React.FC<GroupBannerProps> = (props) => {

    const { title, description, rating, reviews } = props

    return (

        <UiView
            className={styles.wrap}
            background={{
                src: props.image,
                type: 'thumb',
                resizeMode: 'cover',
                filters: { width: window.innerWidth, ratio: window.innerWidth > 1260 ? "16/7" : "3/4" }
            }}
        >

            <Container
                padding={4}
                className={styles.contentContainer}
            >

                <UiView className={styles.titleContainer}>

                    <UiText className={styles.titleText}>{title}</UiText>

                </UiView>

                <UiView className={styles.descriptionContainer}>

                    <UiText className={styles.descriptionText}>{description}</UiText>

                </UiView>

                {reviews &&
                    <UiView className={styles.ratingContainer}>

                        <UiText className={styles.descriptionText}>{rating || 0} / 5</UiText>

                        <UiView className={styles.starContainer}>

                            <Rating
                                initialRating={Number(rating)}
                                emptySymbol={<IoIosStar size="20" className={styles.startEmpty} />}
                                fullSymbol={<IoIosStar size="20" className={styles.StarFill} />}
                                readonly={true}
                            />

                        </UiView>

                    </UiView>
                }

                <UiLink className={styles.reviewContainer}>

                    <UiText className={styles.descriptionText}>{reviews || 0} Reviews</UiText>

                </UiLink>

                <Space height={15} />

            </Container>

        </UiView>

    );
};

interface GroupBannerProps {
    title: string;
    description: string;
    rating: number | string;
    reviews: number | string;
    [key: string]: any;
}

export default GroupBanner;
