import React, { useMemo } from "react";
import { useAsyncEffect } from "@dex/bubl-ui";
import { config } from "@dex/bubl-web";
import { get } from "@dex/bubl-helpers";

import styles from "./LeaguePlayerScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Banner from "../../elements/Banner/Banner";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Space from "../../elements/Space/Space";
import FeaturedStats from "../../elements/FeaturedStats/FeaturedStats";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import GameLogs from "../../groups/GameLogs/GameLogs";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";
import LeagueSeasonPlayerApi from "../../apis/LeagueSeasonPlayerApi";
import ResultDisclaimer from "../../elements/ResultDisclaimer/ResultDisclaimer";

const LeaguePlayerScreen: React.FC<LeaguePlayerScreenProps> = (props) => {

    const playerId = get(props, 'screenProps.routeParams.playerId');
    const seasonId = get(props, 'screenProps.routeParams.seasonId');

    const player = LeagueSeasonPlayerApi.getPlayer(playerId, ["season", "stats", "results", "team"]);

    useAsyncEffect((isMounted) => {

        player.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.name + ' - ' + response.season.title,
                    desc: response.season.description,
                    image: response.photo,
                    imageType: "thumb"
                });

            },

        });


    }, []);


    return useMemo(() => (

        <>

            {player.data &&

                <>

                    <Header />

                    <SubMenu
                        menu={[
                            player.data.season ? { label: player.data.season.title, path: `/leagues/season/${player.data.season.id}/${player.data.season.slug}` } : undefined,
                            { label: "STATS", scrollTo: 'stats' },
                            { label: "GAME LOGS", scrollTo: 'gameLogs' },
                            { label: "FEED", scrollTo: 'feed' },
                        ].filter(Boolean)}
                    />

                    {(player.data && player.data.season) &&
                        <Banner
                            subHeading={player.data.name}
                            badgeText={[player.data.position, player.data.team.name, "#" + player.data.jerseyNumber].filter(Boolean).join(" • ")}
                            avatar={{
                                src: `${player.data.photo}`,
                                type: "thumb",
                            }}
                            backgroundImage={player.data.season.thumbnail}
                        />
                    }

                    <Container>

                        <FeaturedStats
                            id="stats"
                            title="AVERAGES"
                            stats={player.data.stats.averages}
                        />

                        <Space />

                        <FeaturedStats
                            title="TOTALS"
                            stats={player.data.stats.totals}
                        />

                        <Space />

                        <GoogleAd
                            source="ad-butler"
                            slot="opp-top"
                        />

                        <Space />

                        {player.data.results &&
                            <>
                                <GameLogs
                                    id="gameLogs"
                                    heading="Gamelogs"
                                    logs={player.data.results}
                                    columns={player.data.boxScoreColumns}
                                    seasonId={player.data.seasonId}
                                    seasonSlug={player.data.season.slug}
                                />

                                <ResultDisclaimer />
                            </>
                        }

                        <Space height={2} />

                        <NewsPanel
                            id="feed"
                            set="LeaguePlayer"
                            playerId={playerId}
                        />

                        <Space />

                        <GoogleAd
                            source="ad-butler"
                            slot="opp-bottom"
                        />

                        <Space />

                    </Container>

                    <Footer />

                </>

            }

        </>

    ), [player.loading]);

};

interface LeaguePlayerScreenProps {
    [key: string]: any;
}

export default LeaguePlayerScreen;
