import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./GameLogsPlayers.module.scss";

import Panel from "../../elements/Panel/Panel";
import Divider from "../../elements/Divider/Divider";
import StatsPlayerTable from "../../elements/StatsPlayerTable/StatsPlayerTable";
import Space from "../../elements/Space/Space";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";

const GameLogsPlayers: React.FC<GameLogsPlayersProps> = (props) => {

    const { teams, columns, heading, season } = props;

    return (


        <Panel
            heading={heading}
            id={props.id}
            edge="all"
        >

            {teams.map((team: any, index: number) => {

                return (

                    <>

                        <UiView className={styles.tableContainer} key={index}>

                            <Divider title={team.name} dark={true} />

                            <StatsPlayerTable
                                stats={team.roster}
                                columns={columns}
                                season={season}
                            />

                        </UiView>

                        {!index &&
                            <>

                                <GoogleAd
                                    slot="leaderboard"
                                />

                                <Space />

                            </>
                        }

                    </>

                )

            })}

        </Panel>


    );
};

interface GameLogsPlayersProps {
    set?: string;
    heading?: string;
    logs?: any;
    [key: string]: any;
}

export default GameLogsPlayers;
