import React, { useMemo } from "react";
import { UiView, useAsyncEffect } from "@dex/bubl-ui";
import { config } from "@dex/bubl-web";
import { get } from "@dex/bubl-helpers";

import styles from "./LeagueSeasonScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Results from "../../groups/Results/Results";
import Space from "../../elements/Space/Space";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import LeagueLeaderPanel from "../../groups/LeagueLeaderPanel/LeagueLeaderPanel";
import Banner from "../../elements/Banner/Banner";
import SubMenu from "../../elements/SubMenu/SubMenu";

import LeagueSeasonApi from "../../apis/LeagueSeasonApi";
import WatchPanel from "../../groups/WatchPanel/WatchPanel";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";
import BracketPanel from "../../groups/BracketPanel/BracketPanel";
import LeagueStadings from "../../groups/LeagueStadings/LeagueStadings";
import SignInCta from "../../elements/SignInCta/SignInCta";

const LeagueSeasonScreen: React.FC<LeagueSeasonScreenProps> = (props) => {

    const seasonId = get(props, 'screenProps.routeParams.seasonId');
    const slug = get(props, 'screenProps.routeParams.slug');

    const season = LeagueSeasonApi.getSeason(seasonId);
    const brackets = LeagueSeasonApi.getBrackets(seasonId);
    const standings = LeagueSeasonApi.getStandings(seasonId);
    const results = LeagueSeasonApi.getResults(seasonId);
    const upcoming = LeagueSeasonApi.getUpcoming(seasonId);
    const leaderboards = LeagueSeasonApi.getLeaderboards(seasonId);

    useAsyncEffect(async (isMounted) => {

        const _season: any = await season.runAsync();

        if (!isMounted()) return;

        if (_season) {
            config.setMeta({
                title: _season.title,
                desc: _season.description,
                image: _season.thumbnail
            });
        }

        if (_season.hasBrackets) {
            await brackets.runAsync();

            if (!isMounted()) return;
        }

        if (_season.hasGameLogs) {
            await results.runAsync();

            if (!isMounted()) return;
        }

        if (_season.hasCalendar) {
            await upcoming.runAsync();

            if (!isMounted()) return;
        }

        if (_season.hasStandings) {
            await standings.runAsync();

            if (!isMounted()) return;
        }

        if (_season.hasLeaderboard) {
            await leaderboards.runAsync();
        }

    }, []);

    return useMemo(() => (

        <>

            <Header />

            {season.data &&
                <>

                    <SubMenu
                        menu={[
                            season.data.hasBrackets ? { label: "BRACKET", scrollTo: "bracket" } : null,
                            season.data.hasGameLogs ? { label: "RESULTS", scrollTo: "results" } : null,
                            season.data.hasCalendar ? { label: "UPCOMING", scrollTo: "upcoming" } : null,
                            season.data.hasStandings ? { label: "STANDINGS", scrollTo: "standings" } : null,
                            season.data.hasLeaderboard ? { label: "LEADERS", scrollTo: "leaders" } : null,
                            { label: "WATCH", scrollTo: "watch" },
                            { label: "NEWS", scrollTo: "news" },
                        ].filter(Boolean)}
                    />

                    {season.data.team &&
                        <SignInCta
                            text="COMPLETE YOUR TEAM ROSTER!"
                            link={`/leagues/register/${season.data.team.id}`}
                        />
                    }

                    <Banner
                        heading={season.data.title}
                        backgroundImage={season.data.thumbnail}
                    />

                    <Container>

                        <Space />

                        {brackets.data &&
                            <>
                                <BracketPanel
                                    season={season.data}
                                    divisions={brackets.data}
                                />

                                <Space />
                            </>
                        }

                        {(season.data.hasCalendar || season.data.hasGameLogs) &&
                            <>
                                <UiView className={styles.container}>

                                    <UiView className={styles.resultsContainer} id="results">

                                        {results &&
                                            results.data &&
                                            <Results
                                                heading="Results"
                                                results={results.data}
                                                divisions={season.data.divisions}
                                                seasonId={seasonId}
                                                slug={season.data ? season.data.slug : null}
                                            />
                                        }

                                    </UiView>

                                    <UiView className={styles.upcomingContainer} id="upcoming">

                                        {upcoming &&
                                            upcoming.data &&
                                            <Results
                                                heading="Upcoming"
                                                results={upcoming.data}
                                                divisions={season.data.divisions}
                                                seasonId={seasonId}
                                                slug={season.data ? season.data.slug : null}
                                            />
                                        }

                                    </UiView>

                                </UiView>

                                <Space />
                            </>
                        }

                        <GoogleAd
                            source="ad-butler"
                            slot="opp-top"
                        />

                        <Space />


                        {standings.data && standings.data.length > 0 &&
                            <>

                                <LeagueStadings
                                    id="standings"
                                    heading="Standings"
                                    standings={standings.data}
                                    seasonId={seasonId}
                                    slug={season.data ? season.data.slug : null}
                                />

                                <Space />

                            </>
                        }

                        {leaderboards.data && leaderboards.data.length > 0 &&
                            <>

                                <LeagueLeaderPanel
                                    id="leaders"
                                    heading="League Leaders"
                                    leaderboards={leaderboards.data}
                                    seasonId={seasonId}
                                    seasonSlug={season.data ? season.data.slug : null}
                                />

                                <Space />

                            </>
                        }

                        <WatchPanel
                            id="watch"
                            filter={{
                                seasonId
                            }}
                            more={true}
                            path={`/watch/season/${seasonId}/${slug}`}
                        />

                        <Space />

                        {season.data.hasNews &&
                            <>
                                <NewsPanel
                                    id="news"
                                    set="LeagueSeason"
                                    seasonId={seasonId}
                                />

                                <Space />
                            </>
                        }

                        <GoogleAd
                            source="ad-butler"
                            slot="opp-bottom"
                        />

                        <Space />

                    </Container>

                </>
            }

            <Footer />

        </>

    ), [season.loading, results.loading, upcoming.loading, standings.loading, leaderboards.loading, brackets.loading]);
};

interface LeagueSeasonScreenProps {
    [key: string]: any;
}

export default LeagueSeasonScreen;
