import React, { useCallback } from "react";
import { UiImage, UiText, UiView } from "@dex/bubl-ui";

import Button from "../../elements/Button/Button";
import styles from "./AthleteSelect.module.scss";

const AthleteSelect: React.FC<AthleteSelectProps> = (props) => {

    const { athlete, isFull, canJoin, canCancel, onSelect } = props;

    let buttonStyle: any = 'secondary';
    let buttonText: any = 'Join';

    if (athlete.isAttending) {

        buttonStyle = "primary";
        buttonText = "Attending";

    } else if (isFull) {

        buttonStyle = "faded";
        buttonText = "Full";

    } else if (!canJoin) {

        buttonStyle = "faded";
        buttonText = "Closed";

    } else if (!athlete.activePass) {

        buttonStyle = "alt";
        buttonText = "Buy A Pass";

    }

    const onPress = useCallback(() => {

        if (athlete.isAttending) {

            //cancel popup
            if (canCancel) onSelect('cancel', athlete);

        } else if (isFull) {

            //do nothing

        } else if (!canJoin) {

            //do nothing

        } else if (!athlete.activePass) {

            //buy popup
            onSelect('buy', athlete);

        } else {

            //join popup
            onSelect('join', athlete);

        }


    }, []);

    return (

        <UiView className={styles.container}>

            <UiView className={styles.imageContainer}>

                <UiImage
                    className={styles.image}
                    src={athlete.image || { fileId: "" }}
                    type="thumb"
                    filters={{ width: 60, height: 60 }}
                />

            </UiView>

            <UiView className={styles.nameContainer}>

                <UiText className={styles.headerText}>
                    {!athlete.activePass &&
                        <>No Pass</>
                    }
                    {athlete.activePass &&
                        <>{athlete.activePass.description}</>
                    }
                </UiText>

                <UiText className={styles.nameText}>{athlete.name}</UiText>

            </UiView>

            <UiView className={styles.buttonContainer}>

                <Button
                    theme={buttonStyle}
                    label={buttonText}
                    block={true}
                    onPress={onPress}
                />

                {(athlete.isAttending && canCancel) &&
                    <UiText className={styles.helpText}>click to cancel</UiText>
                }

            </UiView>

        </UiView>
    );
};

interface AthleteSelectProps {
    [key: string]: any;
}

export default AthleteSelect;
