import React, { useEffect, useMemo, useRef } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./GoogleAd.module.scss";

const GoogleAd: React.FC<GoogleAdProps> = (props) => {

    const { slot, size, source } = props;

    let isMobile = window.innerWidth < 1024;

    let src = source || 'innity';

    let height = 90;

    if (isMobile) {

        if (slot === "sidebar") {

            height = 250;

        } else {

            height = 50;

        }

    } else {

        if (slot === "sidebar") {

            height = 250;

        } else if (slot === "leaderboard") {

            height = 90;

        } else if (slot === "banner") {

            height = 90;

        } else {

            height = 90

        }

    }

    if (slot === "video") {

        src = "ad-video-" + size;

        if (size === "leaderboad") {
            height = 90;
        } else if (size === "rect") {
            height = 250;
        }

    }

    return useMemo(() => (

        <UiView
            className={styles.wrap}
        >

            <iframe
                title={"Huddle Ads"}
                src={`/${src}.html?slot=${slot}&isMobile=${isMobile ? 1 : 0}`}
                width={"100%"}
                height={height}
            />

        </UiView>

    ), [props]);

};

interface GoogleAdProps {
    [key: string]: any;
}

export default GoogleAd;