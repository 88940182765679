import React, { useEffect } from 'react';
import { UiLink, UiView, useStore } from '@dex/bubl-ui';
import Container from '../../elements/Container/Container';

import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import Panel from '../../elements/Panel/Panel';
import Grid from "../../elements/Grid/Grid";
import WatchSportCard from '../../elements/WatchSportCard/WatchSportCard';
import WatchCard from '../../elements/WatchCard/WatchCard';

import WatchApi from '../../apis/WatchApi';

import styles from './WatchScreen.module.scss'

const WatchScreen: React.FC<WatchScreenProps> = (props) => {

    const [user] = useStore('appUser');

    const menu = [
        { label: "All Videos", path: "/watch" },
        { label: "Live Streams", path: "/watch/live" }
    ]

    const watch = WatchApi.home();

    useEffect(() => {

        watch.run();

    }, []);

    if (user.loggedIn) menu.push({ label: "My Videos", path: "/watch/my-videos" })

    return (
        <>
            <Header />

            <SubMenu
                menu={menu}
            />

            <Container>

                {watch.data &&
                    watch.data.map((section: any, index: any) => (

                        <React.Fragment key={index}>

                            {index > 0 &&
                                <Space />
                            }

                            <Panel
                                heading={section.title}
                                edge={"sides"}
                                moreText={"View More"}
                                moreLink={section.more}
                            >

                                {section.type === "featured" &&
                                    <Grid
                                        columns={4}
                                        gap={3}
                                    >

                                        {section.list.map((item: any, i: any) => (

                                            <UiView
                                                key={i}
                                                className={"item"}
                                            >

                                                <WatchCard
                                                    video={item}
                                                />

                                            </UiView>

                                        ))}

                                    </Grid>
                                }

                                {section.type === "sports" &&
                                    <Grid
                                        columns={5}
                                        gap={3}
                                    >

                                        {section.list.map((item: any, i: any) => (

                                            <UiView
                                                key={i}
                                                className={"item"}
                                            >

                                                <WatchSportCard
                                                    sport={item}
                                                />

                                            </UiView>

                                        ))}

                                    </Grid>
                                }

                                {section.type === "videos" &&
                                    <Grid
                                        columns={5}
                                        gap={3}
                                    >

                                        {section.list.map((item: any, i: any) => (

                                            <UiView
                                                key={i}
                                                className={"item"}
                                            >

                                                <WatchCard
                                                    video={item}
                                                />

                                            </UiView>

                                        ))}

                                    </Grid>
                                }

                            </Panel>

                        </React.Fragment>
                    ))
                }

            </Container>

            <Footer />

        </>
    )
}

interface WatchScreenProps {
    [key: string]: any
}

export default WatchScreen;