import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = '/Pass/public/byOperator';
const endpointAlt = '/Product/public/byOperator';

const OperatorPassesApi = {

    passes: (parentId: string, parentType: string, params: any = {}, options: options = {}): response => {

        // sessionGroupId: session.sessionGroupId, expiryDate: session.endDateTime

        if (parentType === "PickupOperator") params.operatorId = parentId;
        if (parentType === "CampOperator") params.campId = parentId;
        if (parentType === "League") params.leagueId = parentId;

        return apiGet({
            url: parentType === "PickupOperator" ? endpoint : endpointAlt,
            params: params,
            ...options
        })

    },

}

export default OperatorPassesApi;