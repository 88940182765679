import React, { useEffect } from 'react'
import { UiLink, UiText, UiView } from '@dex/bubl-ui'

import VenueBookingApi from '../../apis/VenueBookingApi'
import Panel from '../../elements/Panel/Panel'
import VenueSessionCard from '../../elements/VenueSessionCard/VenueSessionCard'

import styles from './VenueBookingsPanel.module.scss'
import EventCard from '../../elements/EventCard/EventCard'


const VenueBookingsPanel: React.FC<VenueBookingsPanelProps> = (props) => {

    const { courtId, type, title, id } = props;

    const bookings = VenueBookingApi.myBookings(courtId);

    useEffect(() => {
        bookings.run({
            params: { type: type }
        });
    }, [])

    return (
        <Panel
            id={id}
            heading={title}
        >

            <UiView className={styles.bookings}>

                {bookings.data && bookings.data.map((booking: any, i: number) => (
                    <UiView
                        key={i}
                        className={styles.session}
                    >

                        <EventCard
                            path={`/venues/booking/${booking.id}`}
                            session={booking}
                            hideImage={true}
                            hideStatus={true}
                        />

                    </UiView>
                ))}

            </UiView>

        </Panel>
    )
}

interface VenueBookingsPanelProps {
    [key: string]: any;
}

export default VenueBookingsPanel