import React, { useMemo, useState } from "react";
import { UiLink, UiText, UiView, useUser } from "@dex/bubl-ui";
import { IoMdClose } from "react-icons/io";

import styles from "./PickupSessionJoin.module.scss";

import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";
import PickupSessionApi from "../../apis/PickupSessionApi";
import PassCard from "../../elements/PassCard/PassCard";
import Modal from "../../elements/Modal/Modal";
import Divider from "../../elements/Divider/Divider";

const PickupSessionJoinPopupJoin: React.FC<PickupSessionJoinPopupJoinProps> = (props) => {

    let { handleRefresh, setDidAction, session } = props;

    const [showConfirm, setShowConfirm] = useState(false);

    const user = useUser();

    const join = PickupSessionApi.join(session.id);

    const pass = session.activePass;

    const handleJoin = () => {

        join.run({
            onComplete: () => {

                setDidAction(true);

            }
        });

    }

    const handleClose = () => {

        setShowConfirm(false);
        handleRefresh();

    }

    return useMemo(() => (

        <>

            <UiText>You have a valid pass. Click join to confirm your attendance.</UiText>

            <Space height={2} />

            <PassCard
                title={pass.title}
                content={pass.description}
                image={require('../../assets/images/pass-card.png')}
                price={pass.price}
                fullWidth={true}
                onClick={setShowConfirm.bind(null, true)}
            />

            <Space height={2} />

            <Button
                label={"Join This Session"}
                block={true}
                theme={"secondary"}
                onClick={setShowConfirm.bind(null, true)}
            />

            <Modal
                show={showConfirm}
                onClose={handleClose}
            >

                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleClose}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider
                        title={`Confirm Attendance`}
                    />

                    <UiView className={[styles.box, 'p-4']}>

                        <UiText className={styles.headerText}>You are joining this session as:</UiText>

                        <UiText className={styles.nameText}>{user.get('fullName')}</UiText>

                        <Space height={4} />

                        {!join.complete &&
                            <Button
                                label={"Yes, Count Me In!"}
                                block={true}
                                theme={"secondary"}
                                onPress={handleJoin}
                                loading={join.loading}
                            />
                        }

                        {join.complete &&
                            <Button
                                label={"Confirmed"}
                                block={true}
                                theme={"success"}
                                onPress={() => { }}
                                loading={join.loading}
                            />
                        }

                    </UiView>

                </UiView>


            </Modal>

        </>

    ), [showConfirm, pass, join]);

};

interface PickupSessionJoinPopupJoinProps {
    [key: string]: any;
}

export default PickupSessionJoinPopupJoin;
