import React, { useCallback, useEffect, useState } from "react";
import { UiImage, UiText, UiView, useLink, useUser } from "@dex/bubl-ui";
import { BsFillHeartFill } from "react-icons/bs";
import styles from "./PageIntro.module.scss";
import Button from "../Button/Button";
import RatingsAndReviews from "../RatingsAndReviews/RatingsAndReviews";
import FollowApi from "../../apis/FollowApi";
import Panel from "../Panel/Panel";

const PageIntro: React.FC<PageIntroProps> = (props) => {

    const user = useUser("appUser");

    const linkTo = useLink();

    const { hostId, title, image, logo, content, sport, websiteLink } = props;

    const [isFollowed, setIsFollowed] = useState(false);

    const follow = FollowApi.follow();

    const followed = FollowApi.followed({
        params: {
            hostId: hostId,
        }
    });

    useEffect(() => {

        if (user.loggedIn && hostId) {

            followed.run({
                onComplete: (response: any) => {

                    setIsFollowed(response ? true : false);

                }
            });

        }

    }, [user.loggedIn]);

    const handleFollow = useCallback(() => {

        if (user.loggedIn) {

            follow.run({
                params: {
                    hostId: hostId,
                },
                onComplete: (response: any) => {

                    setIsFollowed(response ? true : false);
                    followed.clearCache();

                }
            });

        } else {

            linkTo("/login", "replace", { returnTo: window.location.pathname });

        }

    }, [user.loggedIn]);

    const buttonClasses = [styles.followButton];

    if (isFollowed) buttonClasses.push(styles.followed);

    return (

        <UiView className={styles.container}>

            <UiView className={styles.imageContainer}>

                <UiImage
                    className={styles.image}
                    type={'thumb'}
                    src={image}
                    resizeMode="cover"
                    filters={{ width: 1400, height: 360 }}
                />

            </UiView>

            <Panel
                hideTop={true}
            >

                <UiView className={styles.contentContainer}>

                    <UiView className={styles.logoContainer}>

                        {!sport &&
                            <UiImage
                                className={styles.logo}
                                type={'thumb'}
                                resizeMode={"contain"}
                                filters={{ width: 250, height: 250 }}
                                src={logo}
                            />
                        }

                        {sport &&
                            <UiImage
                                className={styles.logo}
                                type={'thumb'}
                                resizeMode={"contain"}
                                filters={{ width: 250, height: 250 }}
                                src={logo}
                            />
                        }

                    </UiView>

                    <UiView className={styles.textContainer}>

                        <UiView className={styles.textSection}>

                            <UiText className={styles.titleText}>{title}</UiText>

                            <UiText
                                className={styles.descriptionText}
                            >
                                {content}

                            </UiText>

                            {(props.reviewCount || props.reviewScore || props.websiteLink) &&
                                <RatingsAndReviews
                                    rating={props.reviewScore ? `${props.reviewScore}/5.00` : undefined}
                                    review={props.reviewCount}
                                    websiteLink={props.websiteLink}
                                />
                            }

                        </UiView>

                        {hostId &&
                            <UiView className={styles.buttonContainer}>

                                <Button
                                    label={isFollowed ? "FOLLOWING" : "FOLLOW"}
                                    className={styles.button}
                                    theme={isFollowed ? "faded" : "secondary"}
                                    onPress={handleFollow}
                                    icon={<BsFillHeartFill size={14} />}
                                    iconAfter
                                    loading={follow.loading || followed.loading}
                                    block={true}
                                />

                            </UiView>
                        }

                    </UiView>

                </UiView>

            </Panel>

        </UiView>

    );
};

interface PageIntroProps {
    [key: string]: any;
}

export default PageIntro;
