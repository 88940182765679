import React, { useCallback, useMemo, useState, useEffect } from "react";
import { UiLink, UiText, UiView } from "@dex/bubl-ui";
import { IoMdClose } from "react-icons/io";

import styles from "./CampSessionJoin.module.scss";

import CampSessionApi from "../../apis/CampSessionsApi";

import Divider from "../../elements/Divider/Divider";
import Space from "../../elements/Space/Space";
import Modal from "../../elements/Modal/Modal";
import CampSessionJoinPopupAthlete from "./CampSessionJoinPopupAthlete";
import AthleteSelect from "../../elements/AthleteSelect/AthleteSelect";
import CampSessionJoinPopupBuy from "./CampSessionJoinPopupBuy";
import CampSessionJoinPopupJoin from "./CampSessionJoinPopupJoin";
import CampSessionJoinPopupCancel from "./CampSessionJoinPopupCancel";

const CampSessionJoin: React.FC<CampSessionJoinProps> = (props) => {

    // Api to fetch list of athletes and valid passes for this session/user
    const { handleRefresh, session } = props;

    const [popup, setPopup] = useState<any>(null);

    const [didAction, setDidAction] = useState<any>(false);

    const [athlete, setAthlete] = useState<any>(null);

    const athletes = CampSessionApi.getAthletes(session.id);

    useEffect(() => {

        athletes.run();

    }, []);

    const handlePopup = useCallback((name, athlete) => {

        setPopup(name);
        setAthlete(athlete);

    }, []);

    const handleClosePopup = useCallback(() => {

        setPopup(null);

        if (didAction) {
            setDidAction(false);
            handleRefresh();
        }

    }, [didAction]);

    return useMemo(() => (

        <UiView>

            <Divider
                title={"Select athlete for this session"}
                dark={true}
            />

            <UiView className={[styles.box, 'p-4']}>

                <UiText className={styles.text}>Please select who will be joining this session.</UiText>

                <Space />

                {athletes.data &&
                    athletes.data.map((athlete: any, index: number) => (

                        <AthleteSelect
                            key={index}
                            athlete={athlete}
                            isFull={session.isFull}
                            canJoin={session.canJoin}
                            canCancel={session.canCancel}
                            onSelect={handlePopup}
                        />

                    ))
                }

                <Space />

                <UiLink className={styles.addAthleteLink} onPress={handlePopup.bind(null, 'athlete', null)}>
                    Add An Athlete →
                </UiLink>

            </UiView>

            <Modal
                show={popup}
                onClose={handleClosePopup}
            >
                {popup &&

                    <UiView className={styles.popup}>

                        <UiLink
                            onPress={handleClosePopup}
                            className={styles.close}
                        >

                            <IoMdClose />

                        </UiLink>

                        {popup === "athlete" &&

                            <CampSessionJoinPopupAthlete
                                handleRefresh={handleRefresh}
                            />
                        }

                        {popup === "buy" &&

                            <CampSessionJoinPopupBuy
                                handleRefresh={handleRefresh}
                                setDidAction={setDidAction}
                                athlete={athlete}
                                session={session}
                            />
                        }

                        {popup === "join" &&

                            <CampSessionJoinPopupJoin
                                handleRefresh={handleRefresh}
                                setDidAction={setDidAction}
                                athlete={athlete}
                                session={session}
                            />
                        }

                        {popup === "cancel" &&

                            <CampSessionJoinPopupCancel
                                handleRefresh={handleRefresh}
                                setDidAction={setDidAction}
                                athlete={athlete}
                                session={session}
                            />
                        }

                    </UiView>

                }

            </Modal>

        </UiView>

    ), [popup, didAction, athlete, athletes.loading, athletes.data]);

};

interface CampSessionJoinProps {
    [key: string]: any;
}

export default CampSessionJoin;
