import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./CoverBanner.module.scss";

const CoverBanner: React.FC<CoverBannerProps> = (props) => {

    return (

        <UiView className={styles.bannerWrapper}>

            <UiView className='container'>

                <UiView
                    className={styles.container}
                    background={{
                        src: props.background,
                        type: props.local ? "local" : "thumb",
                        resizeMode: "cover",
                        filters: { width: Math.min(window.innerWidth, 1400), height: 370 }
                    }}
                >
                    {props.children}
                </UiView>

            </UiView>

        </UiView>

    );
};

interface CoverBannerProps {
    [key: string]: any;
}

export default CoverBanner;
