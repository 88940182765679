import React, { useMemo } from "react";
import { UiImage, UiText, UiLink, UiView } from "@dex/bubl-ui";
import { GoPrimitiveDot } from "react-icons/go";

import styles from "./MemberCard.module.scss";

const MemberCard: React.FC<MemberCardProps> = (props) => {

    const clasess = [styles.card];

    const filters: any = props.size === "mini" ? { width: 100, height: 100, textSize: 64 } : { width: 200, height: 200, textSize: 96 };

    filters.fallback = { text: props.name[0] };

    if (props.size) clasess.push(styles[`card${props.size}`]);

    return useMemo(() => (

        <UiView className={clasess}>

            <UiLink path={props.path} onPress={props.onPress}>

                <UiImage
                    className={[styles.image, 'image']}
                    style={props.imageStyle}
                    resizeMode="cover"
                    filters={filters}
                    {...props.image}
                />

                <UiText className={[styles.name, 'name']}>{props.name}</UiText>

                {(props.position || props.teamName || props.number) &&
                    <UiText className={[styles.description, 'description']}>{props.position} <GoPrimitiveDot className={styles.dot} /> {props.teamName} <GoPrimitiveDot className={styles.dot} /> #{props.number}</UiText>
                }

                {props.stat &&
                    <UiText className={[styles.stat, 'stat']}>{props.stat} {props.statLabel}</UiText>
                }

            </UiLink>

            {props.overlay &&
                props.overlay()
            }

        </UiView>

    ), [props]);

};

interface MemberCardProps {
    [key: string]: any;
}

export default MemberCard;
