import { apiGet, options, response, apiPost } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Following';

const FollowApi = {

    followed: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/followed",
            cache: true,
            ...options
        });

    },

    follow: (options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/follow",
            ...options
        })

    },

}

export default FollowApi;