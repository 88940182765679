import React, { useState } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./LeagueStadings.module.scss";

import Divider from "../../elements/Divider/Divider";
import GroupTable from "../../elements/GroupTable/GroupTable";
import Panel from "../../elements/Panel/Panel";

// @ts-ignore
import { Scrollbars } from 'react-custom-scrollbars';
import DropSelect from "../../elements/DropSelect/DropSelect";

const LeagueStadings: React.FC<LeagueStadingsProps> = (props) => {

    let { set, heading, title, standings, slug, seasonId } = props;

    const options: { label: any; value: any; }[] = [];

    if (standings) {
        // eslint-disable-next-line array-callback-return
        standings.map((item: any, key: any) => {
            options.push({ label: item.name, value: key });
        })
    }

    const [current, setCurrent] = useState<any>(standings[0]);

    const onHandleChange = (item: any) => {

        setCurrent(standings[item.value]);

    }

    return (

        <Panel
            heading={heading}
            id={props.id}
            edge="all"
            topLeft={
                <>
                    {options &&
                        <DropSelect
                            items={options}
                            defaultValue={options[0]}
                            onChange={onHandleChange}
                            styleDropDown={styles.select}
                        />
                    }
                </>
            }
        >

            <UiView className={styles.container}>

                {current &&

                    <UiView>

                        <Divider
                            className={styles.dividerContainer}
                            TextClassName={styles.dividerText}
                            title={current.name}
                        />

                        <GroupTable
                            stats={current.table}
                            seasonId={seasonId}
                            slug={slug}
                        />

                    </UiView>

                }

            </UiView>

        </Panel>

    );
};

interface LeagueStadingsProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default LeagueStadings;
