import React, { useCallback, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./SubMenu.module.scss";

import MenuItems from "../MenuItems/MenuItems";
import Container from "../Container/Container";

const SubMenu: React.FC<SubMenuProps> = (props) => {

    return useMemo(() => (

        <UiView id={"submenu"}>

            <UiView className={[styles.spacer]} />

            <UiView className={styles.submenu}>

                <Container>

                    {props.menu && props.menu.length > 0 &&

                        <UiView className={styles.menu}>

                            <MenuItems menu={props.menu} />

                        </UiView>

                    }

                </Container>

            </UiView>

        </UiView>

    ), [props]);

};

interface SubMenuProps {
    [key: string]: any;
}

export default SubMenu;
