import React, { useMemo, useCallback, useEffect, useState } from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./CampSessionJoin.module.scss";

import Divider from "../../elements/Divider/Divider";
import OperatorPassesApi from "../../apis/PassesApi";
import Space from "../../elements/Space/Space";
import PassCard from "../../elements/PassCard/PassCard";
import OrderApi from "../../apis/OrderApi";
import CampSessionJoinPopupPayment from "./CampSessionJoinPopupPayment";

const CampSessionJoinPopupBuy: React.FC<CampSessionJoinPopupBuyProps> = (props) => {

    let { handleRefresh, setDidAction, session, athlete } = props;

    const params = { sessionGroupId: session.sessionGroupId, expiryDate: session.dateTime };

    const passes = OperatorPassesApi.passes(session.operatorId, "CampOperator", params);

    const order = OrderApi.generate();

    useEffect(() => {

        passes.run();

        return () => {

            passes.cancel();

        }

    }, []);

    const onSelectPass = useCallback((pass: any) => {

        if (order.loading) return;

        const data: any = { productId: pass.id };

        data.campSessionId = session.id;
        data.athleteId = athlete.id;

        setDidAction(true);

        order.run({
            data: data,
        });

    }, []);

    return useMemo(() => (

        <>

            {order.data &&
                <CampSessionJoinPopupPayment
                    handleRefresh={handleRefresh}
                    order={order.data}
                />
            }

            {!order.data &&
                <>

                    <Divider
                        title={`Select a pass for ${athlete.name}`}
                    />

                    <UiView className={['p-b-1 p-t-2 p-l-3 p-r-3']}>

                        <UiText>It looks like you do not have a valid pass. Please choose from the options below.</UiText>

                        <Space height={2} />

                        {!order.loading && passes.data && passes.data.available &&
                            <>

                                {passes.data.available.map((pass: any, key: any) => (

                                    <PassCard
                                        key={key}
                                        title={pass.title}
                                        content={pass.description}
                                        image={require('../../assets/images/pass-card.png')}
                                        price={pass.price}
                                        fullWidth={true}
                                        onClick={onSelectPass.bind(null, pass)}
                                    />

                                ))}

                            </>
                        }

                    </UiView>

                </>
            }

        </>

    ), [passes]);

};

interface CampSessionJoinPopupBuyProps {
    [key: string]: any;
}

export default CampSessionJoinPopupBuy;
