import React from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./PageBanner.module.scss";

const PageBanner: React.FC<PageBannerProps> = (props) => {

    return (

        <UiView className={styles.bannerWrapper}>

            <UiView className='container'>

                <UiView
                    className={styles.container}
                    background={{
                        src: props.background,
                        type: "local",
                        resizeMode: "cover"
                    }}
                >

                    <UiText className={styles.heading}>
                        {props.heading}
                    </UiText>

                </UiView>

            </UiView>

        </UiView>

    );
};

interface PageBannerProps {
    [key: string]: any;
}

export default PageBanner;
