import React, { useCallback, useState } from 'react';
import { UiForm, UiText, UiView, useForm } from '@dex/bubl-ui';
import Rating from 'react-rating';
import { IoIosStar } from 'react-icons/io';


import styles from "./ReviewForm.module.scss";
import FieldTextArea from '../../elements/Fields/FieldText/FieldTextArea';
import Button from '../../elements/Button/Button';
import CommentsApi from '../../apis/CommentsApi';

const ReviewForm: React.FC<ReviewFormProps> = (props) => {
    const [review, setReview] = useState(null);
    const { parent, handleRefresh, handlePopupClose } = props;

    const form = useForm({});

    const addReview: any = CommentsApi.addNew(parent.parentId, parent.parentType, "review");

    const handleReviewChange = (value: any) => {
        setReview(value);
    }

    const onSubmit = useCallback((value: any) => {

        const val = {
            ...value,
            rating: review
        }

        addReview.run({
            data: val,
            params: {
                parentId: parent.parentId,
                parentType: parent.parentType,
                type: 'review'
            },
            onComplete: (response: any) => {
                if (response) {
                    handleRefresh();
                    handlePopupClose();
                }
            }
        })

    }, [addReview.run, review]);

    return (

        <UiForm
            form={form}
            onSubmit={onSubmit}
            className={styles.form}
        >
            <UiText className={styles.title}>
                Rate Your Experience
            </UiText>

            <UiText className={styles.quation}>
                How did you like your session at {parent.parentTitle}?
            </UiText>

            <Rating
                initialRating={review || 0}
                emptySymbol={<IoIosStar size="50" className={styles.empty} />}
                fullSymbol={<IoIosStar size="50" className={styles.full} />}
                onChange={handleReviewChange}
            />

            <FieldTextArea
                form={form}
                name={"message"}
                placeholder="Tell us more (Optional)"
                defaultValue={form.defaults.message || ''}
                style={{
                    height: 130,
                    resize: "vertical",
                    marginTop: 38,
                }}
            />

            <Button
                label={"SUBMIT YOUR REVIEW"}
                block={true}
                className={styles.buttonSubmit}
                onClick={form.triggerSubmit}
            />
        </UiForm>

    )
}

interface ReviewFormProps {
    [key: string]: any;
}

export default ReviewForm;