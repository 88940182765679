import React, { useState, useEffect, useCallback } from "react";
import { UiView, UiText, UiLink } from "@dex/bubl-ui";
import { IoMdClose } from "react-icons/io";

import Athletes from '../../elements/Athletes/Athletes';
import Button from "../../elements/Button/Button";
import Modal from "../../elements/Modal/Modal";
import CampAthletesApi from "../../apis/CampAthletesApi";
import CampSessionJoinPopupAthlete from "../CampSessionJoin/CampSessionJoinPopupAthlete";

import styles from "./AthleteProfiles.module.scss";
import Space from "../../elements/Space/Space";

const AthleteProfiles: React.FC<AthleteProfilesProps> = (props) => {

    const { profile } = props;

    const [key, setKey] = useState(0);

    const [popup, setPopup] = useState<any>(null);

    const athletes = CampAthletesApi.list();

    useEffect(() => {

        athletes.run();

    }, [key]);

    const handlePopup = useCallback((name, athlete) => {

        setPopup(name);

    }, [key]);

    const handleClosePopup = useCallback(() => {

        setPopup(null);
        handleRefresh();

    }, [key]);

    const handleRefresh = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    return (

        <UiView className={styles.container}>

            <UiView className={styles.athletesContainer}>

                <UiText className={styles.heading}>My Athlete Profiles</UiText>

                <Space height={2} />

                {athletes.data &&
                    athletes.data.map((data: any, key: any) => (
                        <Athletes
                            key={key}
                            athlete={data}
                            handleRefresh={handleRefresh}
                        />
                    ))

                }

            </UiView>

            <UiView className={styles.buttonContainer}>

                <Button
                    label={"Add An Athlete"}
                    block={true}
                    type={"submit"}
                    onPress={handlePopup.bind(null, 'addAthlete', null)}
                    theme={"secondary"}
                />

            </UiView>

            <Modal
                show={popup}
                onClose={handleClosePopup}
            >
                {popup &&

                    <UiView className={styles.popup}>

                        <UiLink
                            onPress={handleClosePopup}
                            className={styles.close}
                        >

                            <IoMdClose />

                        </UiLink>

                        {popup === "addAthlete" &&

                            <CampSessionJoinPopupAthlete
                                handleRefresh={handleClosePopup}
                            />
                        }

                    </UiView>

                }

            </Modal>


        </UiView>


    )

};

interface AthleteProfilesProps {
    [key: string]: any;
}

export default AthleteProfiles;
