import { UiLink, UiSpace, UiText, UiView } from "@dex/bubl-ui";
import React, { useMemo } from "react";
import Button from "../../elements/Button/Button";
import { IoLogoWhatsapp } from 'react-icons/io'
import { HiOutlineMail } from 'react-icons/hi'

import styles from "./VenueBookingJoin.module.scss";

const VenueBookingJoinInvite: React.FC<VenueBookingJoinInviteProps> = (props) => {

    let { session } = props;

    const subject = `Join Me @ ${session.subject}.`;
    const message = `Click this link to register & join.`;
    const link = session.shareLink;

    return useMemo(() => (

        <>

            <hr />

            <UiText className={styles.headerText}>Get Your Teammates To Join You</UiText>

            <UiSpace />

            <UiText className={styles.stepHeading}>Step 1</UiText>
            <UiText className={styles.stepText}>Share this URL with joining code {session.shareCode}</UiText>
            <UiText className={styles.stepText}><UiLink className={styles.stepLink} href={session.shareLink}>{session.shareLink}</UiLink></UiText>

            <UiSpace height={16} />

            <UiText className={styles.stepHeading}>Step 2</UiText>
            <UiText className={styles.stepText}>Fill in your details and click Register to join</UiText>

            <UiSpace height={16} />

            <UiText className={styles.stepHeading}>Share</UiText>

            <UiSpace height={8} />

            <UiView className={styles.buttons}>

                <Button
                    label={"Whatsapp"}
                    theme={'secondary'}
                    size={"medium"}
                    icon={<IoLogoWhatsapp />}
                    href={`https://wa.me/?text=${encodeURIComponent(subject) + ' ' + encodeURIComponent(message)}%0a${encodeURIComponent(link)}`}
                    target={'_blank'}
                />

                <Button
                    label={"Email"}
                    theme={'secondary'}
                    size={"medium"}
                    icon={<HiOutlineMail />}
                    href={`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message) + '%0a' + encodeURIComponent(link)}`}
                    target={'_blank'}
                />

            </UiView>

        </>

    ), [session]);

};

interface VenueBookingJoinInviteProps {
    [key: string]: any;
}

export default VenueBookingJoinInvite;
