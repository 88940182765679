import { options, response, apiPost } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const SearchFiltersApi = {

    search: (endpoint: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/searchFilters",
            cache: true,
            cacheTTL: 120000,
            ...options
        })

    },

}

export default SearchFiltersApi;