import React from "react";

import Container from "../Container/Container";
import GameLogsPlayers from "../../groups/GameLogsPlayers/GameLogsPlayers";

const NewsArticleBoxScore: React.FC<NewsArticleBoxScoreProps> = (props) => {

    const { article } = props;

    return (

        <Container>

            {article.game && article.game.hasGameData &&
                <GameLogsPlayers
                    id="gameLogs"
                    heading="Box Scores"
                    season={article.data.game.season}
                    columns={article.data.game.boxScoreColumns}
                    teams={article.data.game.teams}
                />
            }

        </Container>

    );

};

interface NewsArticleBoxScoreProps {
    article?: any,
    [key: string]: any;
}

export default NewsArticleBoxScore;
