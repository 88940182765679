import { apiGet, apiPost, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars
import UsersApi from "./UsersApi";

const endpoint = 'VenueLiveStreams';

const VenueLiveStreamApi = {

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            params: {},
            ...options
        });

    },

    join: (sessionId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/${sessionId}/join`,
            params: {},
            ...options
        })

    },

    cancel: (sessionId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/${sessionId}/cancel`,
            params: {},
            ...options
        })

    },

    removeAttendee: (sessionId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/${sessionId}/removeAttendee`,
            params: {},
            ...options
        })

    },

    manageBooking: (sessionId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/${sessionId}/manageBooking`,
            params: {},
            ...options
        })

    },

    myBookings: UsersApi.myBookings

}

export default VenueLiveStreamApi;