import { apiGet, apiPost, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'LeagueSeasonTeam';

const LeagueSeasonTeamApi = {

    getTeam: (teamId: string, include?: Array<string>, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + teamId,
            params: { include: include },
            cache: true,
            ...options
        });

    },

    getRoster: (teamId: string, seasonId: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + teamId + "/roster",
            cache: true,
            ...options
        });

    },

    updateTeamInfo: (teamId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/" + teamId + "/updateTeamInfo",
            params: {},
            ...options
        })

    },

    joinTeam: (teamId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/" + teamId + "/joinTeam",
            params: {},
            ...options
        })

    },

    leaveTeam: (teamId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/" + teamId + "/leaveTeam",
            params: {},
            ...options
        })

    },

    removePlayer: (teamId: string, playerId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/" + teamId + "/removePlayer",
            params: { playerId: playerId },
            ...options
        })

    }

}

export default LeagueSeasonTeamApi;