import React from "react";
import { UiImage } from "@dex/bubl-ui";

import styles from "./NewsArticle.module.scss";

import Container from "../Container/Container";
import ResultCard from "../ResultCard/ResultCard";
import VideoBlock from "../VideoBlock/VideoBlock";
import Space from "../Space/Space";

const NewsArticleBanner: React.FC<NewsArticleBannerProps> = (props) => {

    const { article } = props;

    return (

        <Container
            width={"thin"}
            className={styles.banner}
        >

            {article.gameLog && article.type === "recap" &&

                <>

                    {article.gameLog.hasGameScores &&
                        <ResultCard
                            results={article.gameLog.data}
                            alt={true}
                            noPath={true}
                        />
                    }

                    {article.gameLog.hasGameScores && article.video &&

                        <Space height={2} />

                    }

                    {article.video &&
                        <VideoBlock
                            id={article.video.id}
                            source={article.video.source}
                        />
                    }

                </>

            }

            {article.type === "watch" && article.video &&

                <VideoBlock
                    id={article.video.id}
                    source={article.video.source}
                />

            }

            {article.type === "news" &&

                <UiImage
                    className={[styles.image]}
                    src={article.thumbnail}
                    filters={{ width: 960 }}
                    type={'thumb'}
                />

            }

        </Container>

    );
};

interface NewsArticleBannerProps {
    article?: any,
    [key: string]: any;
}

export default NewsArticleBanner;
