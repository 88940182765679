import { UiLink, UiText, UiView } from "@dex/bubl-ui";
import React, { useCallback, useMemo } from "react";
import VenueBookingApi from "../../apis/VenueBookingApi";
import Divider from "../../elements/Divider/Divider";
import Modal from "../../elements/Modal/Modal";
import { IoMdClose } from 'react-icons/io';

import styles from "./VenueBookingJoin.module.scss";
import Button from "../../elements/Button/Button";
import MemberCard from "../../elements/MemberCard/MemberCard";

const VenueBookingRemoveAttendee: React.FC<VenueBookingRemoveAttendeeProps> = (props) => {

    let { session, attendee, handleRefresh, setDidAction, setToRemove } = props;

    const remove = VenueBookingApi.removeAttendee(session.id);

    const handleRemoveAttendee = useCallback(() => {

        remove.run({
            params: { attendeeId: attendee.id },
            onComplete: () => {

                setDidAction(true);
                handleRefresh();


            }
        });

    }, []);

    const handleClosePopup = useCallback(() => {

        setToRemove(null);

    }, []);

    return useMemo(() => (

        <>

            <Modal
                show={true}
                onClose={handleClosePopup}
            >

                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleRefresh}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider
                        title={`Remove Attendee`}
                    />

                    <UiView className={['p-3']}>

                        <UiView className={styles.popupContent}>

                            <UiText className={styles.confirmText}>You are about to remove:</UiText>

                            <MemberCard
                                size={"mini"}
                                name={attendee.fullName}
                                image={{
                                    type: "profile",
                                    src: { fileId: attendee.id, fileName: attendee.fullName },
                                }}
                                path={`/community/member/${attendee.id}/${attendee.slug}`}
                            />

                        </UiView>

                        <Button
                            onPress={handleRemoveAttendee}
                            label={"Remove Attendee"}
                            block={true}
                            loading={remove.loading}
                        />

                    </UiView>

                </UiView>

            </Modal>

        </>

    ), [remove.loading, attendee]);

};

interface VenueBookingRemoveAttendeeProps {
    [key: string]: any;
}

export default VenueBookingRemoveAttendee;
