import React, { useMemo, useState } from "react";
import { UiLink, UiView } from "@dex/bubl-ui";
import { IoMdClose } from "react-icons/io";

import styles from "./PickupSessionJoin.module.scss";

import Modal from "../../elements/Modal/Modal";
import OrderCheckout from "../OrderCheckout/OrderCheckout";

const PickupSessionJoinPopupPayment: React.FC<PickupSessionJoinPopupPaymentProps> = (props) => {

    let { handleRefresh, order } = props;

    return useMemo(() => (

        <Modal
            show={true}
            onClose={handleRefresh}
        >

            <UiView className={styles.popup}>

                <UiLink
                    onPress={handleRefresh}
                    className={styles.close}
                >

                    <IoMdClose />

                </UiLink>

                <OrderCheckout
                    order={order}
                    handleRefresh={handleRefresh}
                />

            </UiView>

        </Modal>

    ), [props]);

};

interface PickupSessionJoinPopupPaymentProps {
    [key: string]: any;
}

export default PickupSessionJoinPopupPayment;
