import React from "react";
import { UiCol, UiRow, UiText, UiView } from "@dex/bubl-ui";
import { IoMdCompass } from 'react-icons/io'
import { MdLocationOn, MdLocalPhone } from 'react-icons/md'
import { HiOutlineMail } from 'react-icons/hi'

import styles from "./SessionInfo.module.scss";
import Panel from "../Panel/Panel";
import Button from "../Button/Button";

const SessionInfo: React.FC<SessionInfoProps> = (props) => {

    const { session } = props;

    const address = session.sessionGroup.address;
    const contact = session.sessionGroup.contact;
    const email = session.sessionGroup.email;

    return (

        <Panel
            heading={"How to get there"}
        >

            {address &&

                <>

                    <UiText className={styles.subHeading}>Directions</UiText>

                    <UiView className={styles.buttons}>

                        <Button
                            label={"Google Maps"}
                            theme={'secondary'}
                            size={"medium"}
                            icon={<IoMdCompass />}
                            href={'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address)}
                            target={"_blank"}
                        />
                    </UiView>

                </>

            }

            <UiText className={styles.subHeading}>Contact Details</UiText>

            <UiRow gutter={2} edge={true}>

                {address &&
                    <UiCol col={{ xs: 24, md: 12 }}>

                        <UiView className={styles.flex}>

                            <MdLocationOn size={26} className={styles.icon} />

                            <UiText>{address}</UiText>

                        </UiView>

                    </UiCol>
                }

                <UiCol col={{ xs: 24, md: 12 }}>

                    {email &&
                        <UiView className={styles.flex}>

                            <HiOutlineMail size={26} className={styles.icon} />

                            <UiText>{email}</UiText>

                        </UiView>
                    }

                    {contact &&

                        <UiView className={styles.flex}>

                            <MdLocalPhone size={26} className={styles.icon} />

                            <UiText>{contact}</UiText>

                        </UiView>

                    }

                </UiCol>

            </UiRow>

        </Panel>

    );
};

interface SessionInfoProps {
    [key: string]: any;
}

export default SessionInfo;
