import React, { useMemo } from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./CampSessionJoin.module.scss";

import Divider from "../../elements/Divider/Divider";
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";
import CampSessionApi from "../../apis/CampSessionsApi";
import EventCard from "../../elements/EventCard/EventCard";

const CampSessionJoinPopupJoin: React.FC<CampSessionJoinPopupJoinProps> = (props) => {

    let { handleRefresh, setDidAction, session, athlete } = props;

    const join = CampSessionApi.join(session.id, athlete.id);

    const handleJoin = () => {

        join.run({
            onComplete: () => {

                setDidAction(true);

            }
        });

    }

    return useMemo(() => (

        <>

            <Divider
                title={"Join this session"}
            />

            <UiView className={['p-b-3 p-t-2 p-l-3 p-r-3']}>

                <UiView className={styles.headerAlt}>

                    <UiText className={styles.headerText}>You are joining this session as:</UiText>

                    <UiText className={styles.nameText}>{athlete.name}</UiText>

                </UiView>

                <EventCard
                    session={session}
                    hideStatus={true}
                />

                <Space height={3} />

                {!join.complete &&
                    <Button
                        label={"Join This Session"}
                        block={true}
                        theme={"secondary"}
                        onPress={handleJoin}
                        loading={join.loading}
                    />
                }

                {join.complete &&
                    <Button
                        label={"Confirmed"}
                        block={true}
                        theme={"success"}
                        onPress={() => { }}
                        loading={join.loading}
                    />
                }

            </UiView>

        </>

    ), [join.complete, join.loading]);

};

interface CampSessionJoinPopupJoinProps {
    [key: string]: any;
}

export default CampSessionJoinPopupJoin;
