import React, { useEffect, useMemo } from "react";
import { get } from "@dex/bubl-helpers";

import styles from "./MyOrders.module.scss";

import OrderTable from "../../elements/OrderTable/OrderTable";
import OrderApi from "../../apis/OrderApi";

const MyOrders: React.FC<MyOrdersProps> = (props) => {

    const page = get(props, 'screenProps.routeParams.page');

    const orders = OrderApi.orders();

    useEffect(() => {

        orders.run();

    }, []);

    return useMemo(() => (

        <>

            {orders &&
                orders.data &&

                <>
                    <OrderTable orders={orders.data} heading="Order History" />
                </>

            }


        </>

    ), [orders.data, orders.loading]);
};

interface MyOrdersProps {
    [key: string]: any;
}

export default MyOrders;
