import { Events, UiForm, UiLink, UiText, UiView } from '@dex/bubl-ui';
import React, { useCallback, useState } from 'react'
import { IoMdClose } from 'react-icons/io';
import LeagueSeasonTeamApi from '../../apis/LeagueSeasonTeamApi';
import Button from '../../elements/Button/Button';
import Divider from '../../elements/Divider/Divider';
import Modal from '../../elements/Modal/Modal';
import Space from '../../elements/Space/Space';

import styles from './LeagueTeamRegisterPlayerRemove.module.scss'

const LeagueTeamRegisterPlayerRemove: React.FC<LeagueTeamRegisterPlayerRemoveProps> = (props) => {

    const { team, player, handleRefresh, handleClose } = props;

    const remove = LeagueSeasonTeamApi.removePlayer(team.id, player.id);

    const handleCancel = useCallback(() => {

        if (handleClose) handleClose();
        if (handleRefresh) handleRefresh();

    }, []);

    const handleSubmit = () => {

        remove.run({
            onComplete: () => {

                Events.emit("ui", {
                    action: "success",
                    timeout: 3000,
                    message: "Successfully Removed Player"
                });

                handleCancel();

            }
        })

    }

    return (
        <>

            <Modal
                show={true}
                onClose={handleCancel}
            >

                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleCancel}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider
                        title={"Remove " + player.name}
                    />


                    <UiView className={styles.content}>

                        <UiText className={styles.text}>Are you sure you want to remove {player.name}</UiText>

                        <Space height={4} />

                        <Button
                            label={"Confirm & Remove Player"}
                            size={"large"}
                            block={true}
                            type={"primary"}
                            onClick={handleSubmit}
                        />

                    </UiView>

                </UiView>

            </Modal>

        </>
    )
}

interface LeagueTeamRegisterPlayerRemoveProps {
    [key: string]: any;
}

export default LeagueTeamRegisterPlayerRemove