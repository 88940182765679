import { apiGet, options, response, apiPost, apiDelete } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'CampAthletes';

const CampAthletesApi = {

    list: (options: options = {}): response => {

        return apiGet({
            url: endpoint + `/public/list`,
            params: {},
            ...options
        })

    },

    addAthlete: (options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/addAthlete`,
            params: {},
            ...options
        })

    },

    updateAthlete: (id: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + `/public/${id}/updateAthlete`,
            params: {},
            ...options
        })

    },

    removeAthlete: (id: string, options: options = {}): response => {

        return apiDelete({
            url: endpoint + `/public/${id}/removeAthlete`,
            params: {},
            ...options
        })

    },

}

export default CampAthletesApi;