import React from "react";
import { UiText, UiView, UiLink } from "@dex/bubl-ui";

import styles from "./FeaturedGroupCard.module.scss";

const FeaturedGroupCard: React.FC<FeaturedGroupCardProps> = (props) => {

    return (

        <UiLink
            path={props.path}
            className={styles.item}
        >

            <UiView
                background={{ src: props.image || {}, type: 'thumb', resizeMode: 'cover', filters: { width: 700, height: 360 } }}
                className={styles.wrap}
            >

                <UiView className={styles.contentContainer}>

                    {props.category &&
                        <UiView className={styles.buttonContainer}>

                            <UiLink className={styles.button}>

                                <UiText className={styles.buttonText}>{props.category.title}</UiText>

                            </UiLink>

                        </UiView>
                    }

                    <UiView className={styles.titleContainer}>

                        <UiText className={styles.text}>{props.title}</UiText>

                    </UiView>

                </UiView>

            </UiView>

        </UiLink>

    );
};

interface FeaturedGroupCardProps {
    title: string;
    buttonText: string;
    [key: string]: any;
}

export default FeaturedGroupCard;
