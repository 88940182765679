import React, { useState, useMemo, useCallback } from "react";
import { UiView, UiLink, UiImage, useUser, useStore, useDataStore, UiForm, useForm, UiText, UiButton } from "@dex/bubl-ui";
import { IoIosClose, IoIosPower, IoMdMenu } from "react-icons/io";

//@ts-ignore
import { StackActions } from '@react-navigation/core';

import { config, LoginApi } from "@dex/bubl-web";
import UserImage from "../../assets/images/user.png"
import HeaderButton from "../../elements/HeaderButton/HeaderButton";

import styles from "./Header.module.scss";
import FieldText from "../Fields/FieldText/FieldText";

const Header: React.FC<HeaderProps> = (props) => {

    const user = useUser("appUser");

    const form = useForm({});

    const [nav] = useStore("nav");

    const [showMenu, setShowMenu] = useState(false);

    const [showAccount, setShowAccount] = useState(false);

    const [activeLink, setActiveLink]: any = useState(null);

    const [key, setKey] = useStore("appKey");

    const [loaded, setLoaded] = useStore("appLoaded");

    const signout = LoginApi.logout();

    const links = [
        {
            path: '/watch',
            label: 'Huddle+'
        },
        {
            path: '/leagues',
            label: 'Leagues'
        },
        {
            path: '/camps',
            label: 'Camps'
        },
        {
            path: '/pickups',
            label: 'Pickups'
        },
        {
            path: '/venues',
            label: 'Venues'
        },
        {
            path: '/news',
            label: 'News'
        }
    ]

    const onSignout = useCallback(() => {

        signout.run({
            onEnd: async (error: any, response: any) => {


                setLoaded(false);

                setKey(key + 1);

            }
        });

    }, [key, loaded]);

    const onSearch = useCallback((values) => {

        const route = nav.router.getActionForPathAndParams('discover');

        let ref = nav.currentRoute?.path?.split("/");

        if (ref[0] && !["news", "leagues", "camps", "pickups", "venues", "watch"].includes(ref[0])) ref[0] = "all";

        route.params.keyword = values.search;

        if (ref[0]) route.params.ref = ref[0];

        if (route && route.routeName) {

            let params = StackActions.push(route);

            if (!params.key) params.key = Date.now();

            nav.dispatch(params);

        }

    }, [nav.currentRoute]);

    const isActive = (path: string, index: number) => {

        let routePath = '/' + nav.currentRoute.path;

        if (routePath.includes(path)) setActiveLink(index);

        return routePath.includes(path);
    }

    return useMemo(() => (

        <UiView id={"header"}>

            <UiView className={[styles.spacer]} />

            <UiView className={[styles.header, showMenu ? styles.open : '']}>

                <UiLink path="/dashboard" className={styles.logo}>

                    <UiImage
                        resizeMode={"contain"}
                        src={require('../../assets/images/huddle.png')}
                        type={'local'}
                        className={styles.logoLarge}
                    />

                    <UiImage
                        resizeMode={"contain"}
                        src={require('../../assets/images/huddle-sm.png')}
                        type={'local'}
                        className={styles.logoSmall}
                    />

                </UiLink>

                {activeLink !== null &&
                    <UiLink
                        path={links[activeLink].path}
                        className={[styles.activeLink]}
                    >
                        {links[activeLink].label}
                    </UiLink>
                }

                <UiView className={styles.links}>

                    <UiView className={[styles.link, styles.logoLink]}>

                        <UiLink path="/dashboard">
                            <UiImage
                                resizeMode={"contain"}
                                src={require('../../assets/images/huddle.png')}
                                type={'local'}
                            />
                        </UiLink>

                        <UiLink
                            onClick={setShowMenu.bind(null, !showMenu)}
                        >
                            <IoIosClose className={styles.closeIcon} />
                        </UiLink>

                    </UiView>

                    <UiView className={styles.linksWrap}>

                        {links.map((link, index) => (
                            <UiLink
                                key={index}
                                path={link.path}
                                className={[styles.link, isActive(link.path, index) ? styles.active : '']}
                            >
                                {link.label}
                            </UiLink>
                        ))}

                    </UiView>

                    <UiView className={styles.sidebarUser}>

                        {user.loggedIn &&
                            <>

                                <UiLink
                                    className={[styles.sidebarProfile]}
                                    path="/my-profile"
                                >

                                    <UiImage
                                        className={styles.userImage}
                                        resizeMode={"cover"}
                                        src={{ fileId: user.get('id') }}
                                        type={"profile"}
                                        fallback={UserImage}
                                        filters={{ width: 60, height: 60, textSize: 96 }}
                                    />

                                    <UiView tag='span'>{user.get('firstName')}</UiView>

                                </UiLink>

                                <UiLink
                                    className={[styles.sidebarProfile]}
                                    onClick={onSignout}
                                >
                                    <IoIosPower className={styles.userImage} />

                                    <UiView tag='span'>Logout</UiView>

                                </UiLink>

                            </>
                        }

                        {!user.loggedIn &&

                            <UiLink path="/login" className={[styles.sidebarProfile]}>

                                <UiImage
                                    className={styles.userImage}
                                    resizeMode={"contain"}
                                    src={UserImage}
                                    type="local"
                                />

                                <UiView tag='span'>Login / Register</UiView>

                            </UiLink>

                        }

                    </UiView>

                    <UiView className={styles.sidebarSpacer} />

                </UiView>

                <UiView className={styles.search}>

                    <UiForm
                        form={form}
                        className={styles.searchForm}
                        onSubmit={onSearch}
                    >

                        <FieldText
                            form={form}
                            name={"search"}
                            required={false}
                            defaultValue={form.defaults.search}
                            placeholder={"SEARCH"}
                            className={styles.searchInput}
                        />

                        <UiLink
                            onClick={form.triggerSubmit}
                            type="submit"
                            className={styles.searchIcon}
                        >
                            <UiImage
                                src={require('../../assets/images/search.png')}
                                type={'local'}
                            />
                        </UiLink>

                    </UiForm>

                    {user.loggedIn &&

                        <UiLink
                            className={[styles.profile]}
                            onClick={() => {
                                setShowAccount(prev => !prev);
                            }}
                        >

                            <UiImage
                                className={styles.userImage}
                                resizeMode={"cover"}
                                src={{ fileId: user.get('id') }}
                                type={"profile"}
                                filters={{ width: 60, height: 60, textSize: 64 }}
                            />

                            <UiView tag='span' className={styles.username}>{user.get('firstName')}</UiView>

                        </UiLink>
                    }

                    {user.loggedIn &&
                        showAccount &&
                        <HeaderButton
                            user={user}
                            onSignout={onSignout}
                        />

                    }

                    {!user.loggedIn &&
                        <UiLink path="/login" className={[styles.profile]}>

                            <UiImage
                                className={styles.userImage}
                                resizeMode={"contain"}
                                src={UserImage}
                                type="local"
                            />

                        </UiLink>
                    }

                </UiView>

                <UiView className={styles.icons}>

                    <UiLink
                        className={styles.overlay}
                        onClick={() => {
                            setShowMenu(!showMenu);
                        }}
                    >

                    </UiLink>

                    <UiLink
                        onClick={() => {
                            setShowMenu(!showMenu);
                        }}
                        className={styles.toggle}
                    >

                        <IoMdMenu className={styles.icon} />

                    </UiLink>

                </UiView>

            </UiView>

        </UiView>

    ), [props, user, showMenu, showAccount, signout.loading, activeLink]);
};

interface HeaderProps {
    [key: string]: any;
}

export default Header;
