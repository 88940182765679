import React, { useMemo, useCallback, useEffect } from "react";
import { UiText } from "@dex/bubl-ui";

import styles from "./PickupSessionJoin.module.scss";

import Space from "../../elements/Space/Space";
import PassCard from "../../elements/PassCard/PassCard";
import PickupSessionJoinPopupPayment from "./PickupSessionJoinPopupPayment";

import OperatorPassesApi from "../../apis/PassesApi";
import OrderApi from "../../apis/OrderApi";

const PickupSessionJoinPopupBuy: React.FC<PickupSessionJoinPopupBuyProps> = (props) => {

    let { handleRefresh, setDidAction, session, athlete } = props;

    const params = { sessionGroupId: session.sessionGroupId, expiryDate: session.dateTime };

    const passes = OperatorPassesApi.passes(session.operatorId, "PickupOperator", params);

    const order = OrderApi.generate();

    useEffect(() => {

        passes.run();

        return () => {

            passes.cancel();

        }

    }, []);

    const onSelectPass = useCallback((pass: any) => {

        if (order.loading) return;

        const data: any = { productId: pass.id };

        data.pickupSessionId = session.id;

        setDidAction(true);

        order.run({
            data: data,
        });

    }, []);

    const handleClose = useCallback((pass: any) => {

        order.reset();
        handleRefresh();

    }, [order]);

    return useMemo(() => (

        <>

            {order.data &&
                <PickupSessionJoinPopupPayment
                    handleRefresh={handleClose}
                    order={order.data}
                />
            }

            <UiText>It looks like you do not have a valid pass. Please choose from the options below.</UiText>

            <Space height={2} />

            {!order.loading && passes.data && passes.data.available &&
                <>

                    {passes.data.available.map((pass: any, key: any) => (

                        <PassCard
                            key={key}
                            title={pass.title}
                            content={pass.description}
                            image={require('../../assets/images/pass-card.png')}
                            price={pass.price}
                            fullWidth={true}
                            onClick={onSelectPass.bind(null, pass)}
                        />

                    ))}

                </>
            }

        </>

    ), [passes]);

};

interface PickupSessionJoinPopupBuyProps {
    [key: string]: any;
}

export default PickupSessionJoinPopupBuy;
