import React from "react";
import { UiText, UiView, UiImage, UiRow, UiCol } from "@dex/bubl-ui";

import styles from "./ProfileIntroCard.module.scss";

import GreyImage from '../../assets/images/greyImage.png';
import Space from "../Space/Space";

const ProfileIntroCard: React.FC<ProfileIntroCardProps> = (props) => {

    return (

        <UiView className={"container"} id={props.id}>

            <Space height={1} />

            <UiRow gutter={0} edge={true}>

                <UiCol
                    col={{ xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }}
                    className={styles.col}
                    box={{ className: styles.colInner }}
                >
                    <UiView className={styles.contentContainer}>

                        <UiView className={styles.headingContainer}>

                            <UiText className={styles.headingText}>Bio</UiText>

                        </UiView>

                        <UiView className={styles.descriptionContainer}>

                            <UiText className={styles.contentText}>{props.bioContent}</UiText>

                        </UiView>

                    </UiView>

                </UiCol>

                <UiCol
                    col={{ xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }}
                    className={styles.col}
                    box={{ className: styles.colInner }}
                >

                    <UiView className={styles.contentContainer}>

                        <UiView className={styles.headingContainer}>

                            <UiText className={styles.headingText}>Location</UiText>

                        </UiView>

                        <UiView className={styles.descriptionContainer}>

                            <UiText className={styles.contentText}>{props.location}</UiText>

                        </UiView>

                        {props.communityProfile &&
                            <UiView className={styles.headingContainerAlt}>

                                <UiText className={styles.headingText}>Sports Played</UiText>

                            </UiView>

                        }

                        {props.communityProfile &&
                            props.sportsPlayed &&

                            <UiView className={styles.descriptionContainer}>

                                {props.sportsPlayed.map((data: any, index: any, array: any) => {

                                    if (array.length - 1 === index) {

                                        return <UiText className={styles.contentText}>{data}</UiText>

                                    }
                                    else {

                                        return <UiText className={styles.contentText}>{data} ,</UiText>

                                    }

                                })}

                            </UiView>
                        }

                    </UiView>

                </UiCol>

                <UiCol
                    col={{ xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }}
                    className={styles.col}
                    box={{ className: styles.colInner }}
                >

                    {!props.communityProfile &&
                        <UiView className={styles.contentContainer}>

                            <UiImage
                                className={styles.image}
                                resizeMode={'contain'}
                                src={GreyImage}
                                type={'local'}
                            />

                        </UiView>
                    }

                </UiCol>


            </UiRow>

        </UiView >

    );
};

interface ProfileIntroCardProps {
    [key: string]: any;
}

export default ProfileIntroCard;
