import React, { useEffect, useMemo } from "react";
import { UiView, useStore, useUser } from "@dex/bubl-ui";
import { get, hash } from "@dex/bubl-helpers";
import { LoginApi } from "@dex/bubl-web";

import styles from "./MyProfileScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Banner from "../../elements/Banner/Banner";
import MyProfileSidebar from "../../groups/MyProfileSidebar/MyProfileSidebar";
import AthleteProfiles from "../../groups/AthleteProfiles/AthleteProfiles";
import Announcements from "../../groups/Announcements/Announcements";
import EditProfile from "../../groups/EditProfile/EditProfile";
import ChangePassword from "../../groups/ChangePassword/ChangePassword";
import MyOrders from "../../groups/MyOrders/MyOrders";
import InviteFriends from "../../groups/InviteFriends/InviteFriends";
import MySessions from "../../groups/MySessions/MySessions";
import ActivePasses from "../../groups/ActivePasses/ActivePasses";
import BannerEdit from "../../elements/Banner/BannerEdit";


const MyProfileScreen: React.FC<MyProfileScreenProps> = (props) => {

    const key = get(props, 'screenProps.routeKey');

    const page = get(props, 'screenProps.routeParams.page') || "my-sessions";

    const profile = useUser("appUser");

    const user = LoginApi.me();

    useEffect(() => {

        user.reset();

        user.run({
            params: {
                platform: null
            },
            onComplete: (response: any) => {

                profile.setState(response);

            }
        });

    }, []);

    return useMemo(() => (

        <>

            <Header />

            <SubMenu
                menu={[
                    { label: "My Sessions", path: '/my-profile/my-sessions' },
                    { label: "My Active Passes", path: '/my-profile/my-active-passes' },
                    { label: "Athlete Profiles", path: '/my-profile/athlete-profiles' },
                    { label: "My Orders", path: '/my-profile/my-orders' },
                    { label: "Announcements", path: '/my-profile' },
                    { label: "Edit Account", path: '/my-profile/edit-account' },
                    { label: "Change Password", path: '/my-profile/change-password' },
                    { label: "Invite", path: '/my-profile/invite' },
                ]}
            />

            {profile.data &&
                <>

                    {page === "edit-account" ? (

                        <BannerEdit
                            heading={profile.data.fullName}
                            user={profile}
                        />

                    ) : (

                        <Banner
                            heading={profile.data.fullName}
                            avatar={{
                                src: { ...profile.data.profilePhoto, fileId: profile.data.id },
                                type: "profile",
                            }}
                            backgroundImage={profile.data.profileCover}
                        />

                    )}

                    <Container>

                        <UiView className={styles.profileContainer}>

                            <UiView className={styles.leftContainer}>

                                <MyProfileSidebar
                                    active={page}
                                />

                            </UiView>

                            {profile.data &&

                                <UiView className={styles.rightContainer}>

                                    {page === "announcements" &&
                                        <UiView
                                            className={styles.edge}
                                        >
                                            <Announcements
                                                profile={profile.data}
                                            />
                                        </UiView>
                                    }

                                    {page === "athlete-profiles" &&
                                        <AthleteProfiles
                                            profile={profile.data}
                                        />
                                    }

                                    {page === "invite" &&
                                        <InviteFriends
                                            profile={profile.data}
                                        />
                                    }

                                    {page === "edit-account" &&
                                        <EditProfile
                                            profile={profile.data}
                                        />
                                    }

                                    {page === "change-password" &&
                                        <ChangePassword
                                            profile={profile.data}
                                        />
                                    }

                                    {page === "my-orders" &&
                                        <MyOrders
                                            profile={profile.data}
                                        />
                                    }

                                    {page === "my-active-passes" &&
                                        <ActivePasses
                                            profile={profile.data}
                                        />
                                    }

                                    {page === "my-sessions" &&
                                        <MySessions
                                            profile={profile.data}
                                        />
                                    }

                                </UiView>

                            }

                        </UiView>

                    </Container>

                </>
            }

            <Footer />

        </>

    ), [key, page, profile]);

};

interface MyProfileScreenProps {
    [key: string]: any;
}

export default MyProfileScreen;
