import React, { useState, useCallback } from "react";
import { UiImage, UiText, UiView, UiLink } from "@dex/bubl-ui";
import { IoMdClose } from "react-icons/io";

import moment from 'moment';

import Modal from "../../elements/Modal/Modal";
import AssesmentDetails from "../AssesmentDetails/AssesmentDetails";
import AssesmentScoreSlider from "../AssesmentScoreSlider/AssesmentScoreSlider";

import styles from "./AssesmentCard.module.scss";

const AssesmentCard: React.FC<AssesmentCardProps> = (props) => {

    const { data } = props;

    const [popup, setPopup] = useState<any>(null);

    const handlePopup = useCallback((name, athlete) => {

        setPopup(name);

    }, []);

    const handleClosePopup = useCallback(() => {

        setPopup(null);

    }, []);

    return (

        <UiView className={styles.container}>

            <UiView className={styles.titleContainer}>

                <UiView className={styles.hostContainer}>

                    <UiText className={styles.hostName}>{data.host.orgName}</UiText>

                    <UiText className={styles.title}>{data.title}</UiText>

                </UiView>

                <UiText className={styles.date}>{moment(data.date).format('DD-MMM-YY').toUpperCase()}</UiText>

            </UiView>

            {data.coach &&
                <UiView className={styles.coachContainer}>

                    <UiText className={styles.placeholder}>COACH</UiText>

                    <UiText className={styles.coach}>{data.coach.name}</UiText>

                </UiView>
            }

            <AssesmentScoreSlider score={data.combinedScore} />

            <UiLink className={styles.link} onPress={handlePopup.bind(null, 'view')}>

                <UiImage
                    className={styles.icon}
                    src={require('../../assets/images/insert-chart.png')}
                    type="local"
                />

                <UiText tag="span">VIEW ASSESSMENT</UiText>

            </UiLink>

            <Modal
                show={popup}
                onClose={handleClosePopup}
            >
                {popup === 'view' &&

                    <UiView className={styles.popup}>

                        <UiLink
                            onPress={handleClosePopup}
                            className={styles.close}
                        >

                            <IoMdClose />

                        </UiLink>

                        <AssesmentDetails data={data} />

                    </UiView>

                }

            </Modal>

        </UiView>

    );
};

interface AssesmentCardProps {
    [key: string]: any;
}

export default AssesmentCard;
