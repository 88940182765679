import React, { useEffect, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./NewsArticleScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import SubMenu from "../../elements/SubMenu/SubMenu";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import NewsArticle from "../../elements/NewsArticle/NewsArticle";

import { get } from "@dex/bubl-helpers";
import NewsApi from "../../apis/NewsApi";
import { config } from "@dex/bubl-web";

const NewsArticleScreen: React.FC<NewsArticleScreenProps> = (props) => {

    const articleId = get(props, 'screenProps.routeParams.id');
    const articleSlug = get(props, 'screenProps.routeParams.slug');

    const article = NewsApi.list();

    let params = {
        filter: {
            where: { id: articleId },
            include: [{
                relation: "category",
                scope: {
                    fields: ["id", "slug", "title", "thumbnail"]
                }
            }]
        }
    };

    useEffect(() => {

        if (articleId) article.run({
            params: params,
            onComplete: (response: any) => {

                if (response && response[0]) {

                    config.setMeta({
                        title: response[0].title,
                        desc: response[0].description,
                        image: response[0].thumbnail || response[0].gallery[0]
                    });

                }

            }
        });

    }, [articleId, articleSlug]);

    return useMemo(() => (

        <UiView>

            <Header />

            {/* <SubMenu
                menu={[]}
            /> */}

            <UiView className={styles.wrap}>

                {article.data &&

                    <>

                        <NewsArticle
                            article={article.data[0]}
                        />

                        <Container>

                            <NewsPanel
                                set='related'
                                article={article.data[0]}
                            />

                        </Container>

                    </>

                }

                <Footer />

            </UiView>

        </UiView>

    ), [article.loading, article.data]);

};

interface NewsArticleScreenProps {
    [key: string]: any;
}

export default NewsArticleScreen;
