import React from "react";
import { UiText, UiView, UiLink } from "@dex/bubl-ui";
import Container from "../Container/Container";

import styles from "./FeaturedNewsBanner.module.scss";

const FeaturedNewsBanner: React.FC<FeaturedNewsBannerProps> = (props) => {

    return (

        <UiView
            className={styles.wrap}
            background={{ src: props.image, type: 'thumb', resizeMode: 'cover', filters: { width: Math.min(window.innerWidth, 1400), height: 500 } }}
        >

            <UiLink
                className={styles.link}
                path={props.path}
            />

            <Container
                padding={4}
                className={styles.content}
            >

                <UiView className={styles.title}>

                    <UiText className={styles.titleText}>{props.title}</UiText>

                </UiView>

                <UiView className={styles.description}>

                    <UiText className={styles.descriptionText}>{props.description}</UiText>

                </UiView>

            </Container>

        </UiView>

    );
};

interface FeaturedNewsBannerProps {
    title: string;
    description: string;
    [key: string]: any;
}

export default FeaturedNewsBanner;