import React, { useEffect } from "react";
import { UiView, UiText, useLink } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";

import styles from "./OrderReturnScreen.module.scss";
import { BeatLoader } from "react-spinners";
import OrderApi from "../../apis/OrderApi";

const OrderReturnScreen: React.FC<OrderReturnScreenProps> = (props) => {

    const orderId = get(props, "screenProps.routeParams.orderId");

    const linkto = useLink();

    const order = OrderApi.getOne(orderId);

    useEffect(() => {

        const timer = setTimeout(() => {

            order.run({
                onComplete: (response) => {

                    try {

                        if (response.status === "paid") {

                            if (response.bookingId) return linkto("/venues/booking/" + response.bookingId, "push", { bookingId: response.bookingId, orderId: orderId });
                            else if (response.streamId) return linkto("/venues/live-stream/" + response.streamId, "push", { streamId: response.streamId, orderId: orderId });
                            else if (response.campSessionId) return linkto("/camps/session/" + response.campSessionId, "push", { sessionId: response.campSessionId, orderId });
                            else if (response.pickupSessionId) return linkto("/pickups/session/" + response.pickupSessionId, "push", { sessionId: response.pickupSessionId, orderId });
                            else if (response.leagueId && response.teamId) return linkto("/leagues/register/" + response.teamId, "push", { teamId: response.teamId, orderId });
                            else if (response.leagueId) return linkto("/leagues/operator/" + response.leagueId, "push", { operatorId: response.leagueId, orderId });
                            else if (response.videoId) return linkto("/watch/video/" + response.videoId + "/" + response.videoSlug, "push", { videoId: response.videoId, slug: response.videoSlug, orderId });

                        }

                        linkto("/order-details/" + orderId);

                    } catch (e) {

                        console.warn(e);

                    }

                },
                onError: (e) => {

                    linkto("/order-details/" + orderId);

                }
            });

        }, 8000);

        return () => {

            clearTimeout(timer);

        }

    }, []);

    return (

        <>

            <Header />

            <SubMenu />

            <Container width="thin">

                <UiView className={styles.headingContainer}>

                    <UiText className={styles.headingText}>Checking Payment Status</UiText>

                    <UiText className={styles.headingTextAlt}>Please wait a few moments.</UiText>

                    <UiView className={styles.indicator}><BeatLoader size={20} /></UiView>

                </UiView>

            </Container>

            <Space />

            <Footer />

        </>

    );

};

interface OrderReturnScreenProps {
    [key: string]: any;
}

export default OrderReturnScreen;
