import React, { useMemo, useState } from "react";
import { UiLink, UiText, UiView } from "@dex/bubl-ui";
import { IoMdClose } from "react-icons/io";

import styles from "./VenueBookingJoin.module.scss";

import Divider from "../../elements/Divider/Divider";
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";
import VenueBookingApi from "../../apis/VenueBookingApi";
import Modal from "../../elements/Modal/Modal";
import EventCard from "../../elements/EventCard/EventCard";

const VenueBookingJoinPopupCancel: React.FC<VenueBookingJoinPopupCancelProps> = (props) => {

    let { handleRefresh, setDidAction, session, athlete } = props;

    const cancel = VenueBookingApi.cancel(session.id);

    const [showConfirm, setShowConfirm] = useState(false);

    const handleCancel = () => {

        cancel.run({
            onComplete: () => {

                setDidAction(true);

            }
        });

    }

    const handleClose = () => {

        setShowConfirm(false);
        handleRefresh();

    }

    return useMemo(() => (

        <>

            <Button
                label={"Cancel Attendance"}
                block={true}
                theme={"primary"}
                onClick={setShowConfirm.bind(null, true)}
            />

            <Modal
                show={showConfirm}
                onClose={handleClose}
            >

                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleClose}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider
                        title={"Cancel Attendance"}
                    />

                    <UiView className={['p-b-3 p-t-2 p-l-3 p-r-3']}>

                        <UiText className={styles.headerText}>You are about to bail on the following session:</UiText>

                        <Space height={1} />

                        <EventCard
                            session={session}
                            hideStatus={true}
                        />

                        <Space height={3} />

                        {!cancel.complete &&
                            <Button
                                label={"Confirm & Cancel Attendance"}
                                block={true}
                                theme={"primary"}
                                onPress={handleCancel}
                                loading={cancel.loading}
                            />
                        }

                        {cancel.complete &&
                            <Button
                                label={"Cancelled"}
                                block={true}
                                theme={"faded"}
                                onPress={() => { }}
                                loading={cancel.loading}
                            />
                        }

                    </UiView>

                </UiView>

            </Modal>

        </>

    ), [cancel]);

};

interface VenueBookingJoinPopupCancelProps {
    [key: string]: any;
}

export default VenueBookingJoinPopupCancel;
