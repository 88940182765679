import React from "react"


function VenueIcon(props: any) {
    return (
        <svg
            width="26"
            height="35"
            viewBox="0 0 26 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.859375 12.7076C0.859375 5.9265 6.34386 0.442017 13.125 0.442017C19.9061 0.442017 25.3906 5.9265 25.3906 12.7076C25.3906 20.0145 17.6458 30.0898 14.4742 33.9272C13.7733 34.7682 12.4942 34.7682 11.7933 33.9272C8.60424 30.0898 0.859375 20.0145 0.859375 12.7076ZM8.74434 12.7077C8.74434 15.1258 10.7068 17.0883 13.1249 17.0883C15.543 17.0883 17.5055 15.1258 17.5055 12.7077C17.5055 10.2896 15.543 8.32709 13.1249 8.32709C10.7068 8.32709 8.74434 10.2896 8.74434 12.7077Z"
                fill="white"
            />
        </svg>

    )
}

export default VenueIcon
