import React from "react";
import { UiText, UiView, UiRow, UiCol, UiImage } from "@dex/bubl-ui";

import styles from "./SampleElement.module.scss";
import Space from "../Space/Space";

const SampleElement: React.FC<SampleProps> = (props) => {

    return (

        <UiView className={"container"}>

            <UiText className={styles.welcomeText}>
                {props.heading}
            </UiText>

            <Space height={2} />

            <UiImage
                className={[styles.avatar, "responsive"]}
                resizeMode={'contain'}
                src={require("../../assets/images/avatar.png")}
                type={"local"}
            />

            <Space height={2} />

            <UiView
                background={{
                    src: require("../../assets/images/bigsearch.png"),
                    type: "local",
                }}
            >

                <Space height={20} />

            </UiView>

            <Space height={2} />

            <UiRow gutter={2} edge={true}>

                <UiCol
                    col={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 8 }}
                    className={styles.col}
                    box={{ className: styles.colInner }}
                >
                    <UiText>Hello World: Test</UiText>
                </UiCol>

                <UiCol
                    col={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 8 }}
                    className={styles.col}
                    box={{ className: styles.colInner }}
                >
                    <UiText>Hello World: Test</UiText>
                </UiCol>

                <UiCol
                    col={{ xs: 24, sm: 24, md: 24, lg: 8, xl: 8 }}
                    className={styles.col}
                    box={{ className: styles.colInner }}
                >
                    <UiText>Hello World: Test</UiText>
                </UiCol>

            </UiRow>


        </UiView>

    );
};

interface SampleProps {
    heading: string;
    [key: string]: any;
}

export default SampleElement;
