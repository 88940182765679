import { apiGet, apiPost, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'LeagueSeasonPlayer';

const LeagueSeasonPlayerApi = {

    getPlayer: (playerId: string, include?: Array<string>, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + playerId,
            params: { include: include },
            cache: true,
            ...options
        });

    },

    update: (playerId: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/" + playerId + '/updatePlayer',
            ...options
        });

    }

}

export default LeagueSeasonPlayerApi;