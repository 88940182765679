import React from "react";
import { UiText, UiView, UiLink } from "@dex/bubl-ui";

import styles from "./ProfileOption.module.scss";

const ProfileOption: React.FC<ProfileOptionProps> = (props) => {

    const { children, heading, desc, active, path } = props;

    return (

        <UiLink
            path={path}
            className={[styles.item, active ? styles.active : '']}
        >

            <UiView className={styles.icon}>

                {children}

            </UiView>

            <UiView className={styles.text}>

                <UiText className={styles.heading}>{heading}</UiText>

                <UiText className={styles.desc}>{desc}</UiText>

            </UiView>

        </UiLink>

    );
};

interface ProfileOptionProps {
    [key: string]: any;
    heading: string;
    desc: string;
    active?: boolean;
    path?: string;
    children?: React.ReactNode;
}

export default ProfileOption;
