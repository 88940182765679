import React, { useMemo, useCallback } from "react";
import { LoginApi } from "@dex/bubl-web";
import { UiRow, UiCol, useStore, UiForm, useForm, UiText, Events } from "@dex/bubl-ui";

import styles from "./ForgotPasswordForm.module.scss";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {

    const forgotPassword = LoginApi.forgotPassword({});

    const onSubmit = useCallback((values) => {

        forgotPassword.run({
            data: values,
            onComplete: (response: any) => {

                Events.emit("ui", {
                    action: "success",
                    message: response.message
                });

            }
        });

    }, [forgotPassword.run]);

    const form = useForm({});

    return useMemo(() => (

        <UiForm
            form={form}
            loading={forgotPassword.loading}
            onSubmit={onSubmit}
        >

            <UiRow
                gutter={2}
                edge={true}
            >

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"email"}
                        required={true}
                        label={"Email address"}
                        defaultValue={form.defaults.email}
                    />

                </UiCol>

                <Space height={2} />

                <UiCol col={{ xs: 24 }}>

                    <UiText className={styles.subHeading}>We will send you a Verification E-Mail and a New Password</UiText>

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <Button
                        label={"RESET MY PASSWORD"}
                        block={true}
                        type={"submit"}
                    />

                </UiCol>

            </UiRow>

        </UiForm>

    ), [form, forgotPassword.loading]);
};

interface ForgotPasswordFormProps {
    [key: string]: any;
}

export default ForgotPasswordForm;
