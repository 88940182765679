import React, { useMemo, useState } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./VenueLiveStreamJoin.module.scss";

import Divider from "../../elements/Divider/Divider";
import VenueLiveStreamJoinInvite from "./VenueLiveStreamJoinInvite";
import VenueLiveStreamJoinPopupJoin from "./VenueLiveStreamJoinPopupJoin";
import Panel from "../../elements/Panel/Panel";

const VenueLiveStreamJoin: React.FC<VenueLiveStreamJoinProps> = (props) => {

    // Api to fetch list of athletes and valid passes for this session/user
    const { handleRefresh, session } = props;

    const [didAction, setDidAction] = useState<any>(false);

    return useMemo(() => (

        <UiView>

            {!session.isAttending &&
                <>

                    <Divider
                        title={"Watch this live stream"}
                        dark={true}
                    />

                    <Panel
                        hideTop={true}
                    >

                        <VenueLiveStreamJoinPopupJoin
                            handleRefresh={handleRefresh}
                            setDidAction={setDidAction}
                            session={session}
                        />

                    </Panel>

                </>
            }

            {session.isOwner &&
                <>

                    <Divider
                        title={"Share"}
                        dark={true}
                    />

                    <UiView className={[styles.box, 'p-4']}>

                        {session.isOwner &&
                            <VenueLiveStreamJoinInvite
                                session={session}
                            />
                        }


                    </UiView>
                </>
            }


        </UiView>

    ), [session, didAction]);

};

interface VenueLiveStreamJoinProps {
    [key: string]: any;
}

export default VenueLiveStreamJoin;
