import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UiView, UiText, UiLink } from '@dex/bubl-ui';

import Grid from '../Grid/Grid';

import styles from "./DateDaySlider.module.scss";
import moment from 'moment';

var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const DateDaySlider: React.FC<DateDaySliderProps> = (props) => {

    const { date, setDate } = props;

    const month = date.format("M");
    const year = date.format("YYYY");
    const today = moment();

    const [datesInMonth, setDatesInMonth]: any = useState([]);

    useEffect(() => {

        const dates: any = [];
        const days = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();

        let n = 0;

        while (n < days) {

            n++;

            const date = moment(`${year}-${month}-${n}`, "YYYY-MM-D");

            if (!date.isBefore(today, "day")) {

                dates.push(date);

            }

        }

        setDatesInMonth(dates);

    }, [month, year]);

    const getDayName = (date: any) => {

        const day = days[new Date(date).getDay()];

        return day;

    }

    const isSelected = useCallback((checkDate: any) => {

        return checkDate.isSame(date, "day");

    }, [date]);

    const isToday = (checkDate: any) => {

        return today.isSame(checkDate, "day");

    }

    return useMemo(() => (

        <UiView>

            <Grid
                columns={7}
                gap={2}
            >

                {datesInMonth.map((date: any) => {

                    const fullDate = date.format("YYYY/MM/DD");
                    const dayName = getDayName(fullDate);
                    const day = date.format("D");

                    return (

                        <UiView key={fullDate} className={styles.flexColumn}>

                            <UiLink
                                key={date}
                                className={[
                                    styles.dateWrapper,
                                    isSelected(date) ? styles.selected : ''
                                ]}
                                onClick={setDate.bind(null, date, true)}
                            >

                                <UiText className={[
                                    styles.text,
                                    isSelected(date) ? styles.textSelected : ''
                                ]}>
                                    {dayName}
                                </UiText>

                                <UiText
                                    className={[
                                        styles.text,
                                        isSelected(date) ? styles.textSelected : ''
                                    ]}
                                >

                                    {day.toString().padStart(2, '0')}/{month.toString().padStart(2, '0')}

                                </UiText>

                            </UiLink>

                            {isToday(date) && <UiText className={[styles.dateInfo]}>TODAY</UiText>}

                        </UiView>
                    )

                })}

            </Grid>

        </UiView>

    ), [datesInMonth, date]);

}

interface DateDaySliderProps {
    [key: string]: any,
}

export default DateDaySlider;