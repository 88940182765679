import React from "react";
import { UiImage, UiText, UiView } from "@dex/bubl-ui";

import styles from "./CampRemoveAthlete.module.scss";

import Button from "../../elements/Button/Button";

const CampRemoveAthlete: React.FC<CampRemoveAthleteProps> = (props) => {

    let { athlete, onSubmit } = props;

    return (

        <UiView className={['p-b-3 p-t-2 p-l-3 p-r-3']}>

            <UiView className={styles.header}>

                <UiText className={styles.headerText}>Are you sure you want to remove this athlete from your profile?</UiText>

                <UiView className={styles.imageContainer}>

                    <UiImage
                        className={styles.image}
                        src={athlete.image || { fileId: "" }}
                        type="thumb"
                        filters={{ width: 60, height: 60 }}
                    />

                </UiView>

                <UiText className={styles.nameText}>{athlete.name}</UiText>

            </UiView>

            <Button
                label={"Remove Athlete"}
                block={true}
                type={"submit"}
                onPress={onSubmit}
            />

        </UiView>

    );
};

interface CampRemoveAthleteProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default CampRemoveAthlete;
