import { get } from '@dex/bubl-helpers/dist';
import { UiImage, UiLink, UiText, UiView, useStore } from '@dex/bubl-ui';
import React from 'react';
import Container from '../Container/Container';
import QuickLinksEventIcon from '../Icons/QuickLinksEventIcon';
import QuickLinksStaticsIcons from '../Icons/QuickLinksStaticsIcons';
import QuickLinksSubscribeIcon from '../Icons/QuickLinksSubscribeIcon';
import QuickLinksWatchIcon from '../Icons/QuickLinksWatchIcon';

import styles from "./QuickLinksPanel.module.scss";

const QuickLinksPanel: React.FC<QuickLinksPanelProps> = (props) => {

    const { links } = props;

    const [settings] = useStore("appSettings");

    return (

        <UiView className={styles.panel}>

            <Container>

                <UiView className={styles.container}>

                    <UiView className={styles.imageContainer}>

                        {get(settings, 'bannerImage') &&
                            <UiLink
                                href={settings.bannerLink || ""}
                            >
                                <UiImage
                                    className={styles.image}
                                    type={'thumb'}
                                    resizeMode={"cover"}
                                    filters={{ width: 912, height: 600, exact: true }}
                                    src={settings.bannerImage}
                                />
                            </UiLink>

                        }

                        {!get(settings, 'bannerImage') &&

                            <UiImage
                                className={styles.image}
                                resizeMode={'contain'}
                                src={require('../../assets/images/quickLinkBanner.png')}
                                type={'local'}
                            />
                        }

                    </UiView>

                    <UiView className={styles.contentContainer}>

                        <UiText className={styles.titleText}>Quicklinks</UiText>

                        <UiView className={styles.quickLinksContainer}>

                            {
                                links.map((link: any, index: number) =>
                                    <UiLink
                                        key={index}
                                        path={link.path}
                                        className={styles.link}
                                    >
                                        <UiView className={styles.quickLinkWrapper}>

                                            <UiView className={styles.leftLine}></UiView>

                                            <UiView className={styles.quickLink}>


                                                <UiView className={styles.iconWrapper}>
                                                    {
                                                        link.icon === 'watch' && <QuickLinksWatchIcon className={styles.icon} />
                                                    }
                                                    {
                                                        link.icon === 'event' && <QuickLinksEventIcon className={styles.icon} />
                                                    }
                                                    {
                                                        link.icon === 'subscribe' && <QuickLinksSubscribeIcon className={styles.icon} />
                                                    }
                                                    {
                                                        link.icon === 'statics' && <QuickLinksStaticsIcons className={styles.icon} />
                                                    }

                                                </UiView>

                                                <UiView className={styles.LinkContent}>

                                                    <UiText className={[styles.linkTitle, link.mobileText ? styles.mobileViewText : '']}>
                                                        {link.title}
                                                    </UiText>

                                                    <UiText className={[styles.linkTitle, link.mobileText ? styles.showMobileViewText : '']}>
                                                        {link.mobileText}
                                                    </UiText>

                                                    <UiText className={[styles.linkDescription, link.mobileDescription ? styles.showMobileDescription : '']}>
                                                        {link.mobileDescription}
                                                    </ UiText>

                                                    <UiText className={[styles.linkDescription, link.mobileDescription ? styles.hideDescription : '']}>
                                                        {link.description}
                                                    </ UiText>


                                                </UiView>

                                            </UiView>

                                        </UiView>

                                        <UiView className={styles.separatorWrapper}>

                                            <UiView className={styles.separator} />

                                        </UiView>

                                    </UiLink>

                                )
                            }



                        </UiView>

                    </UiView>

                </UiView>


            </Container>

        </UiView>

    )

}

interface QuickLinksPanelProps {
    [key: string]: any,
}

export default QuickLinksPanel;