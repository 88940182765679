import React, { useCallback, useEffect, useState } from 'react';
import { UiView } from '@dex/bubl-ui';
import { config } from '@dex/bubl-web';

import Panel from '../../elements/Panel/Panel';
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";
import WatchArchiveFilters from './WatchArchiveFilters';

import WatchApi from '../../apis/WatchApi';
import Grid from '../../elements/Grid/Grid';
import WatchCard from '../../elements/WatchCard/WatchCard';

const WatchArchive: React.FC<WatchArchiveProps> = (props) => {

    const [page, setPage] = useState(1);

    const [filter, setFilter]: any = useState({});

    const [info, setInfo]: any = useState(null);
    const [videos, setVideos]: any = useState([]);

    const [showMore, setShowMore] = useState(true);

    const fetch = WatchApi.list();

    const limit = 12;

    useEffect(() => {

        if (fetch.loading) fetch.cancel();

        setPage(1);
        setInfo(null);
        setFilter({});
        setVideos([]);

    }, [props]);

    useEffect(() => {

        if (fetch.loading) return;

        fetch.run({
            params: {
                filter: {
                    page: page,
                    limit: limit,
                    ...props,
                    ...filter
                },
                includeInfo: info ? false : true
            },
            onComplete: (response: any) => {

                if (response.info) setInfo(response.info);

                if (page === 1) setVideos(response.videos);
                else setVideos((videos: any) => [...videos, ...response.videos]);

                if (response.videos.length === 0 || response.videos.length < limit) setShowMore(false);
                else setShowMore(true);

                config.setMeta({
                    title: response?.info?.title || info.title
                });

            }
        })

    }, [filter, page, props])

    const handleFilterChange = useCallback((name, value) => {

        const update = {
            ...filter,
        }

        update[name] = value;

        setFilter(update);
        setPage(1);
        setVideos([]);

    }, [filter]);

    const loadMore = useCallback(() => {

        setPage(page + 1);

    }, [page]);

    return (

        <>

            {info &&
                <Panel
                    heading={info.title}
                    topLeft={
                        <WatchArchiveFilters
                            values={filter}
                            filters={info.filters}
                            handleFilterChange={handleFilterChange}
                        />
                    }
                >

                    {videos && videos.length > 0 &&
                        <Grid
                            fixed={true}
                            columns={4}
                            gap={2}
                        >

                            {videos && videos.map((video: any, i: any) => (

                                <UiView
                                    key={i}
                                    className={"item"}
                                >

                                    <WatchCard
                                        video={video}
                                    />

                                </UiView>

                            ))}

                        </Grid>
                    }

                </Panel>
            }

            {showMore &&
                <>
                    <Space height={3} />

                    <UiView style={{ textAlign: "center" }}>

                        <Button
                            loading={fetch.loading}
                            onClick={loadMore}
                            label="LOAD MORE"
                            size={"medium"}
                        />

                    </UiView>

                    <Space height={3} />
                </>
            }


        </>
    )
}

interface WatchArchiveProps {
    [key: string]: any
}

export default WatchArchive;