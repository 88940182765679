import { apiPost, apiPatch, options, response, apiGet } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Users';

const ProfileApi = {

    update: (action: string, options: options = {}): response => {

        return apiPatch({
            url: endpoint + '/me/' + action,
            ...options
        })

    },

    communityList: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + '/public/communityList',
            params: {},
            ...options
        })

    },

    communityProfile: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + `/public/${id}/communityProfile`,
            ...options
        })

    },

    myActivity: (options: options = {}): response => {

        return apiGet({
            url: endpoint + `/public/myActivity`,
            ...options
        })

    },

    mySessions: (options: options = {}): response => {

        return apiGet({
            url: endpoint + `/public/mySessions`,
            ...options
        })

    },

    myStats: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + `/public/${id}/myStats`,
            ...options
        })

    },

}

export default ProfileApi;