import React from 'react';
import { get } from '@dex/bubl-helpers'
import { useStore } from '@dex/bubl-ui';

import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Container from '../../elements/Container/Container';
import Footer from "../../elements/Footer/Footer";
import WatchArchive from '../../groups/WatchArchive/WatchArchive';

const WatchHostScreen: React.FC<WatchHostScreenProps> = (props) => {

    const hostId = get(props, 'screenProps.routeParams.hostId');
    const key = get(props, 'screenProps.routeKey');

    const [user] = useStore('appUser');

    const menu = [
        { label: "All Videos", path: "/watch" },
        { label: "Live Streams", path: "/watch/live" }
    ]

    if (user.loggedIn) menu.push({ label: "My Videos", path: "/watch/my-videos" })

    return (

        <>
            <Header />

            <SubMenu
                menu={menu}
            />

            <Container>

                <WatchArchive
                    key={key}
                    hostId={hostId}
                />

            </Container>

            <Footer />

        </>
    )
}

interface WatchHostScreenProps {
    [key: string]: any
}

export default WatchHostScreen;