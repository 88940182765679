import React from "react";
import { UiText, UiLink } from "@dex/bubl-ui";

import styles from "./ShareButton.module.scss";

const ShareButton: React.FC<ShareButtonProps> = (props) => {

    return (

        <UiLink
            ripple={true}
            className={styles.buttonContainer}
        >

            <UiText className={styles.text}>{props.text}</UiText>

        </UiLink>

    );
};

interface ShareButtonProps {
    text: string;
    [key: string]: any;
}

export default ShareButton;
