import React, { useCallback, useMemo, useState } from "react";
import { UiView, UiLink, useStore } from "@dex/bubl-ui";

import styles from "./MenuItems.module.scss";

const MenuItems: React.FC<MenuItemsProps> = (props) => {

    const [active, setActive] = useState('');

    const [nav] = useStore("nav");

    const isActive = useCallback((to?: string) => {

        return (to === active) || (to === '/' + nav.currentRoute.path) || (to === window.location.pathname);

    }, [nav.currentRoute.path, active])

    return useMemo(() => (

        <UiView className={styles.menu}>

            {props.menu.map((item: any, index: number) => (

                <UiLink
                    key={index}
                    path={item.path}
                    onClick={(item.scrollTo || item.onClick) ? (event: any) => {

                        if (item.scrollTo) {

                            event.preventDefault();

                            setActive(item.scrollTo)

                            const el = document.getElementById(item.scrollTo);

                            if (el) window.scrollTo(0, el.offsetTop - 115);

                        }

                        if (item.onClick) item.onClick(event);

                    } : undefined}
                    className={[styles.item, (isActive(item.path || item.scrollTo)) ? styles.active : '']}
                >
                    {item.label}
                </UiLink>

            ))}

        </UiView>

    ), [props.menu, active]);
};

interface MenuItemsProps {
    menu: Array<any>,
    [key: string]: any;
}

export default MenuItems;
