import React, { useEffect, useMemo, useState } from 'react';
import usePortal from 'react-useportal';

// @ts-ignore
import Rodal from 'rodal';

import './Modal.module.scss';
import 'rodal/lib/rodal.css';
import Space from '../Space/Space';

const Modal: React.FC<props> = (props: props) => {

    // const el: any = document && document.getElementById('modals');

    const [scrollTop, setScrollTop] = useState(0);

    const { Portal } = usePortal({
        // bindTo: el,
    });

    useEffect(() => {

        if (props.show) {

            setScrollTop(window.pageYOffset);

            window.scrollTo(0, 0);

        } else {

            if (scrollTop) window.scrollTo(0, scrollTop);

            setScrollTop(0);

        }

    }, [props.show, scrollTop]);

    return useMemo(() => {

        const customStyles = props.customStyles || {};
        const customMaskStyles = props.customMaskStyles || {};

        if (props.top >= 0) {

            customStyles.top = props.top;
            customMaskStyles.justifyContent = "start";

        }

        return (

            <Portal>

                <>

                    {props.show &&
                        <div styleName={"modal-mask"}></div>
                    }

                    <Rodal
                        width={540}
                        styleName={'modal'}
                        visible={props.show ? true : false}
                        animation={'slideUp'}
                        closeOnEsc={true}
                        showCloseButton={false}
                        {...props}
                        customStyles={customStyles}
                        customMaskStyles={customMaskStyles}
                    >

                        <Space height={2} />

                        {props.children}

                        <Space height={2} />

                    </Rodal>

                </>

            </Portal>

        );

    }, [props]);

};

interface props {
    show?: boolean,
    [key: string]: any
}

export default Modal;