import React from "react";
import nl2br from "react-nl2br";
import { UiImage, UiText, UiView} from "@dex/bubl-ui";

import styles from "./AnnouncementContent.module.scss";
import moment from 'moment';

const AnnouncementContent: React.FC<AnnouncementContentProps> = (props) => {

    const { announcement } = props;

    return (

        <UiView className={styles.container}>

            <UiView className={styles.inner}>

                {announcement.host.logo &&
                    <UiImage
                        className={styles.logo}
                        type={'thumb'}
                        filters={{ width: 200, height: 200 }}
                        resizeMode="contain"
                        src={announcement.host.logo}
                    />
                }

                <UiText className={styles.headingText}>{announcement.subject}</UiText>

                <UiText className={styles.dateText}>{moment(announcement.date).format("Do MMMM YYYY")}</UiText>

                <UiText className={styles.descriptionText}>{nl2br(announcement.message)}</UiText>

            </UiView>

        </UiView>

    );
};

interface AnnouncementContentProps {
    [key: string]: any;
}

export default AnnouncementContent;
