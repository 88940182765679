import React, { useMemo, useState, useEffect } from "react";
import { UiView, useForm, useAsyncEffect } from "@dex/bubl-ui";
import { loop, get } from "@dex/bubl-helpers";

import styles from "./DiscoverScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import Panel from "../../elements/Panel/Panel";
import SearchForm from "../../groups/SearchForm/SearchForm";
import StandardCard from "../../elements/StandardCard/StandardCard";
import Grid from "../../elements/Grid/Grid";

import LeaguesApi from "../../apis/LeaguesApi";
import CampSessionGroupApi from "../../apis/CampSessionGroupApi";
import PickupSessionGroupApi from "../../apis/PickupSessionGroupApi";
import NewsApi from "../../apis/NewsApi";
import WatchApi from "../../apis/WatchApi";
import WatchPanel from "../../groups/WatchPanel/WatchPanel";
import VenueCourtApi from "../../apis/VenueCourtApi";

let timer: any = null;

const DiscoverScreen: React.FC<DiscoverScreenProps> = (props) => {

    const keyword = get(props, "screenProps.routeParams.keyword");
    let ref = get(props, "screenProps.routeParams.ref", "all");

    const [filters, setFilters] = useState<any>(null);

    const form = useForm({ searchType: ref, keyword: keyword });

    const list = NewsApi.list();
    const leagueCards = LeaguesApi.search();
    const campCards = CampSessionGroupApi.search();
    const pickupCards = PickupSessionGroupApi.search();
    const watchCards = WatchApi.search();
    const venueCards = VenueCourtApi.search();

    const active = form.values.searchType;

    const handleChange = useEffect(() => {

        const values: any = form.values.filters || {};

        values.keyword = form.values.keyword;

        let hasSearchValue = false;

        loop(values, (value: any) => {

            if (window && window.history) window.history.replaceState({}, '', '/discover');

            if (value) hasSearchValue = true;

        })

        setFilters(hasSearchValue ? values : null);

    }, [form.values]);

    useAsyncEffect(async (isMounted: any) => {

        if (!filters) return;

        const query = { ...filters };

        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {

            if (!isMounted()) return;

            if (active === "all" || active === "news") {

                const filter: any = {
                    include: [
                        {
                            relation: "category",
                            scope: {
                                fields: ["id", "title", "thumbnail"]
                            }
                        }
                    ],
                    limit: 12,
                    skip: 0,
                    where: {},
                    order: ['date Desc'],
                };

                loop(query, (value: any, key: any) => {

                    if (!value) return;

                    if (key === "keyword") filter.where.title = { like: value, options: "i" };

                    else if (key === "order") filter.order = [value];

                    else filter.where[key] = value;

                });

                if (query) list.run({ params: { filter } });

            }

            if (active === "all" || active === "watch") {

                let params = {
                    query: filters,
                    limit: 8,
                };

                if (query) watchCards.run({ params: params });
            }

            if (active === "all" || active === "leagues") {

                let params = {
                    query: filters,
                    limit: 8,
                };

                if (query) leagueCards.run({ params: params });
            }

            if (active === "all" || active === "camps") {

                let params = {
                    query: filters,
                    limit: 8,
                };

                if (query) campCards.run({ params: params });

            }

            if (active === "all" || active === "pickups") {

                let params = {
                    query: filters,
                    limit: 8,
                };

                if (query) pickupCards.run({ params: params });

            }


            if (active === "all" || active === "venues") {

                let params = {
                    query: filters,
                    limit: 8,
                };

                if (query) venueCards.run({ params: params });

            }

        }, 800);

    }, [active, filters, form.values]);

    return useMemo(() => (

        <>

            <Header />

            <Container>

                <Space height={4} />

                <SearchForm
                    form={form}
                />

                <Space height={4} />

                {filters && !list.loading && (active === "all" || active === "news") &&
                    <>

                        <NewsPanel
                            id={"news"}
                            heading="News"
                            search={true}
                            data={list}
                        />

                        <Space />

                    </>
                }

                {filters && (active === "all" || active === "watch") &&
                    <>

                        <WatchPanel
                            data={watchCards.data}
                            loading={watchCards.loading}
                            type={'discover'}
                        />

                        <Space />
                    </>
                }

                {filters && (active === "all" || active === "leagues") &&
                    <>

                        <Panel
                            heading={"Leagues"}
                            edge={"sides"}
                        >

                            <Grid
                                columns={5}
                                gap={4}
                            >

                                {leagueCards &&
                                    leagueCards.data &&
                                    leagueCards.data.cards.map((card: any, key: number) => (

                                        <UiView className="item" key={key}>

                                            <StandardCard
                                                path={`/leagues/operator/${card.id}/${card.slug}`}
                                                title={card.title}
                                                image={card.thumbnail || card.cover}
                                            />

                                        </UiView>

                                    ))
                                }

                            </Grid>

                        </Panel>

                        <Space />

                    </>
                }


                {filters && (active === "all" || active === "camps") &&

                    <>

                        <Panel
                            heading={"Camps"}
                            edge={"sides"}
                        >

                            <Grid
                                columns={5}
                                gap={4}
                            >

                                {campCards &&
                                    campCards.data &&
                                    campCards.data.cards.map((card: any, key: number) => (

                                        <UiView
                                            className="item"
                                            key={key}
                                        >
                                            <StandardCard
                                                path={`/camps/group/${card.id}/${card.slug}`}
                                                title={card.title}
                                                image={card.thumbnail || card.cover}
                                            />
                                        </UiView>

                                    ))
                                }

                            </Grid>

                        </Panel>

                        <Space />

                    </>
                }

                {filters && (active === "all" || active === "pickups") &&
                    <>


                        <Panel
                            heading={"Pick Up"}
                            edge={"sides"}
                        >

                            <Grid
                                columns={5}
                                gap={4}
                            >

                                {pickupCards &&
                                    pickupCards.data &&
                                    pickupCards.data.cards.map((card: any, key: number) => (

                                        <UiView
                                            className="item"
                                            key={key}
                                        >
                                            <StandardCard
                                                path={`/pickups/group/${card.id}/${card.slug}`}
                                                title={card.title}
                                                image={card.thumbnail || card.cover}
                                            />
                                        </UiView>
                                    ))
                                }

                            </Grid>

                        </Panel>

                        <Space />

                    </>
                }

                {filters && (active === "all" || active === "venues") &&
                    <>

                        <Panel
                            heading={"Venues"}
                            edge={"sides"}
                        >

                            <Grid
                                columns={5}
                                gap={4}
                            >

                                {venueCards &&
                                    venueCards.data &&
                                    venueCards.data.cards.map((card: any, key: number) => (

                                        <UiView
                                            className="item"
                                            key={key}
                                        >
                                            <StandardCard
                                                path={`/venues/court/${card.id}/${card.slug}`}
                                                title={card.title}
                                                image={card.thumbnail || card.cover}
                                            />
                                        </UiView>
                                    ))
                                }

                            </Grid>

                        </Panel>

                        <Space />

                    </>
                }
            </Container>

            <Footer />

        </>

    ), [
        filters,
        form.values,
        leagueCards.loading,
        leagueCards.data,
        campCards.loading,
        campCards.data,
        pickupCards.loading,
        pickupCards.data,
        list.loading,
        list.data,
        watchCards.data,
        watchCards.loading,
        venueCards.loading,
        venueCards.data
    ]);

};

interface DiscoverScreenProps {
    [key: string]: any;
}

export default DiscoverScreen;
