
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { UiLink, UiText, UiView } from "@dex/bubl-ui";
import moment from "moment";

import Panel from '../../elements/Panel/Panel';
import DateDaySlider from "../../elements/DateDaySlider/DateDaySlider";

import TimeSlots from "../../elements/TimeSlots/TimeSlots";
import DropSelect from "../../elements/DropSelect/DropSelect";
import DropDate from "../../elements/DropDate/DropDate";
import CartSelected from "../../elements/CartSelected/CartSelected";

import VenueOperatorApi from "../../apis/VenueOperatorApi";

import styles from './VenueSchedulePanel.module.scss'
import OrderApi from "../../apis/OrderApi";
import PassesPopupBuy from "../PassesPopupBuy/PassesPopupBuy";
import CourtBookingWizard from "../CourtWizardModal/CourtBookingWizard";
import { IoMdInformationCircleOutline } from 'react-icons/io';
import BlockBookingModel from "../BlockBookingModel/BlockBookingModel";

const VenueSchedulePanel: React.FC<VenueSchedulePanelProps> = (props) => {

    const { heading, parentType, parentId, groupId, type } = props;

    const [groups, setGroups] = useState<any>(null);

    const [list, setList] = useState<any>(null);

    const [makeBlockBooking, setMakeBlockBooking] = useState<any>(null);

    const [group, setGroup] = useState<string>(groupId);

    const [date, setDate] = useState(moment());

    const [timeSlots, setTimeSlots] = useState([]);

    const [cart, setCart] = useState<any>(null);

    const sessions = VenueOperatorApi.sessionList(parentId, { timeout: 8000 });

    const order = OrderApi.generate();

    const [popup, setPopup] = useState(false);

    useEffect(() => {

        setList([]);

        sessions.run({
            params: {
                type: type,
                courtId: group,
                includeGroups: !groups,
                date: date.startOf("day").format("YYYY-MM-DD"),
            },
            onComplete: (response: any) => {

                if (!groups) setGroups(response.groups);
                setList(response.slots);

            }
        });

    }, [group, date]);

    const handleSetTimeSlots = useCallback((selected, slot) => {

        setTimeSlots(selected);

        let cart: any = null;

        if (selected && selected.length) {

            let label = slot.title + ", " + date.format("D MMM") + " ";

            let slots: any = [];

            selected.map((key: any, i: number) => {

                let keys = key.split(".");

                slots.push(keys[1]);

                if (i === 0) {

                    label += keys[1] + " - ";

                }

                if (i === (selected.length - 1)) {

                    let endTime = keys[1].replace(":", "").toString().padStart(4, "0");

                    endTime = date.clone().set({ 'hour': endTime[0] + endTime[1], 'minute': endTime[2] + endTime[3] }).add(slot.size, "minutes").format("HHmm");
                    endTime = endTime.toString().padStart(4, "0");

                    label += endTime[0] + endTime[1] + ":" + endTime[2] + endTime[3];

                }

                return null;

            });

            cart = {
                ...slot,
                date: date.format("YYYY-MM-DD"),
                slots: slots,
                label: label,
                total: slot.price * selected.length,
            }

        }

        setCart(cart);

    }, [date]);

    const onHandleGroupChange = useCallback((event) => {

        setGroup(event.value);

    }, []);

    const onHandleDateChange = (date: any, exact: false) => {

        date = moment(date, "YYYY-MM-DD");
        const today = moment();

        if (!exact && date.isSame(today, "month")) {
            date = today;
        }

        setDate(date);
        setTimeSlots([]);
        setCart(null);

    }

    const handleCheckout = useCallback((cart) => {

        if (order.loading) return;

        const data: any = { productId: cart.productId, slot: cart };

        order.run({
            data: data,
            onComplete: (response) => {


            }
        });

    }, [order]);

    const handleRefresh = useCallback(() => {

        order.reset();

    }, [date, order])

    return useMemo(() => (

        <>

            {order.complete && order.data &&
                <PassesPopupBuy
                    order={order.data}
                    handleRefresh={handleRefresh}
                />
            }

            <Panel
                id={props.id}
                heading={heading}
                className={styles.panel}
                edge={"all"}
                topLeft={
                    <>
                        <DropDate
                            defaultValue={date}
                            onChange={onHandleDateChange}
                            placeholder="Select Date"
                        />

                        {groups &&
                            <DropSelect
                                items={(groups).map((group: any) => { return { value: group.id, label: group.title }; })}
                                defaultValue={group}
                                onChange={onHandleGroupChange}
                            />
                        }

                    </>
                }
                topRight={
                    <>
                        <UiLink
                            onClick={() => { setPopup(true) }}
                            className={styles.wizardOpen}
                        >

                            <UiText className={styles.wizardText}>How to book</UiText>

                            <IoMdInformationCircleOutline size={18} className={styles.icon} />

                        </UiLink>
                    </>
                }
            >

                {groups &&
                    <DateDaySlider
                        date={date}
                        setDate={onHandleDateChange}
                    />
                }

                <UiView className={styles.slotContainer}>

                    {list && list.map((court: any, key: any) => {

                        return <TimeSlots
                            key={court.id}
                            courtId={court.id}
                            slots={court.slots || []}
                            message={court.message}
                            title={court.title}
                            selected={timeSlots}
                            date={date}
                            blockEnabled={type !== "liveStream" && court.blockEnabled}
                            setSelected={handleSetTimeSlots}
                            handleBlockBooking={setMakeBlockBooking.bind(null, court.id)}
                        />

                    })}

                </UiView>

                <CourtBookingWizard
                    show={popup}
                    onClose={() => setPopup(false)}
                />

            </Panel>

            <UiView>

                {cart &&
                    <CartSelected
                        cart={cart}
                        loading={order.loading}
                        buttonLabel={"BOOK NOW"}
                        handleCheckout={handleCheckout}
                    />
                }

            </UiView>

            {makeBlockBooking &&
                <BlockBookingModel
                    date={date}
                    courtId={makeBlockBooking}
                    handleClose={setMakeBlockBooking.bind(null, null)}
                />
            }

        </>

    ), [date, groups, timeSlots, list, cart, order]);

}

interface VenueSchedulePanelProps {
    [key: string]: any;
}

export default VenueSchedulePanel;
