import React from 'react';
import { UiView, UiText } from '@dex/bubl-ui';
import { Line, Circle } from 'rc-progress';
import styles from "./ProgressBar.module.scss";

const ProgressBar: React.FC<ProgressBarProps> = (props) => {

    const { percentage } = props

    return (

        <UiView className={styles.container}>

            <Line percent={percentage} strokeWidth={1} strokeColor="#FF4E39" />

        </UiView>

    )

}

interface ProgressBarProps {
    [key: string]: any,
}

export default ProgressBar;