import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./Space.module.scss";

const Space: React.FC<SpaceProps> = (props) => {

    const height = props.height || 1;

    return (

        <UiView className={['p-t-' + height]}>
        </UiView>

    );
};

interface SpaceProps {
    height?: number;
    [key: string]: any;
}

export default Space;
