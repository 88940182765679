import React, { useMemo, useCallback } from "react";
import { LoginApi } from "@dex/bubl-web";
import { UiRow, UiCol, useStore, UiForm, useForm, UiText, UiLink, useLink } from "@dex/bubl-ui";

import styles from "./RegisterForm.module.scss";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import Button from "../../elements/Button/Button";
import Space from "../../elements/Space/Space";

const RegisterForm: React.FC<RegisterFormProps> = (props) => {

    const [user] = useStore("appUser");

    const [key, setKey] = useStore("appKey");

    const [loaded, setLoaded] = useStore("appLoaded");

    const register = LoginApi.register({});

    const linkto = useLink();

    const onSubmit = useCallback((values) => {

        const { password, confirmPassword } = values;

        if (password === confirmPassword) {

            register.run({
                data: values,
                onComplete: (response: any) => {

                    if (response) {

                        if (props.redirectTo) linkto(props.redirectTo, "replace");

                        setLoaded(false);

                        setKey(key + 1);

                    }

                }
            });

        }
        else {
            alert("Passwords doesn't match")
        }

    }, [register.run]);

    const form = useForm({});

    return useMemo(() => (

        <UiForm
            form={form}
            loading={register.loading}
            onSubmit={onSubmit}
        >

            {!props.inline &&
                <>
                    <UiText className={styles.heading}>Create a Huddle account</UiText>
                    <UiText className={styles.subHeading}>Already have an account? <UiLink path="/login">Log in →</UiLink></UiText>

                    <Space height={2} />
                </>
            }

            <UiRow
                gutter={2}
                edge={true}
            >

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"firstName"}
                        required={true}
                        label={"First name"}
                        defaultValue={form.defaults.firstName}
                    />

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"lastName"}
                        required={true}
                        label={"Last name"}
                        defaultValue={form.defaults.lastName}
                    />

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"email"}
                        required={true}
                        label={"Email address"}
                        defaultValue={form.defaults.email}
                    />

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"password"}
                        required={true}
                        type={"password"}
                        label={"Set a password"}
                        defaultValue={form.defaults.password}
                    />

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <FieldText
                        form={form}
                        name={"confirmPassword"}
                        required={true}
                        type={"password"}
                        label={"Confirm password"}
                        defaultValue={form.defaults.confirmPassword}
                    />

                </UiCol>

                <UiCol col={{ xs: 24 }}>

                    <Button
                        label={"SUBMIT"}
                        block={true}
                        loading={register.loading}
                        type={"submit"}
                    />

                </UiCol>

            </UiRow>

        </UiForm>

    ),
        [form, register.loading]);
};

interface RegisterFormProps {
    [key: string]: any;
}

export default RegisterForm;
