import React, { useCallback, useState } from "react";
import { UiLink, UiText, UiView } from "@dex/bubl-ui";
import Divider from "../../elements/Divider/Divider";

import styles from "./InlineLogin.module.scss";
import LoginForm from "../LoginForm/LoginForm";
import RegisterForm from "../RegisterForm/RegisterForm";

const InlineLogin: React.FC<InlineLoginProps> = (props) => {

    let { onLoggedIn } = props;

    const [state, setState] = useState('login');

    const onChangeState = useCallback((state: string) => {

        setState(state);

    }, []);

    return (

        <UiView>

            {state === "login" &&

                <>

                    <Divider
                        title={"Log in to your Huddle account"}
                        dark={true}
                    />

                    <UiView className={[styles.box, 'p-4']}>

                        <LoginForm
                            inline={true}
                        />

                        <UiText className={styles.createText}>Don’t have an account? <UiLink onPress={onChangeState.bind(null, 'register')}>Sign up for one →</UiLink></UiText>

                    </UiView>

                </>

            }

            {state === "register" &&

                <>

                    <Divider
                        title={"Create a Huddle account"}
                        dark={true}

                    />

                    <UiView className={[styles.box, 'p-4']}>

                        <RegisterForm
                            inline={true}
                        />

                        <UiText>Already have an account? <UiLink onPress={onChangeState.bind(null, 'login')}>Log in →</UiLink></UiText>

                    </UiView>

                </>

            }

        </UiView>

    );
};

interface InlineLoginProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default InlineLogin;
