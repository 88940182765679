import React from "react";
import { UiImage, UiView } from "@dex/bubl-ui";

import styles from "./SplashLogo.module.scss";

const SplashLogo: React.FC<SplashLogoProps> = (props) => {

    return (

        <UiView className={styles.splash}>

            <UiImage
                resizeMode={"contain"}
                src={require('../../assets/images/logo.png')}
                type={'local'}
            />

        </UiView>

    );
};

interface SplashLogoProps {
    [key: string]: any;
}

export default SplashLogo;
