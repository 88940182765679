import React from "react";
import { UiImage, UiText, UiLink } from "@dex/bubl-ui";

import styles from "./SportCard.module.scss";

const SportCard: React.FC<SportCardProps> = (props) => {

    return (

        <UiLink
            path={props.path}
            className={[styles.card, props.selected ? styles.selected : '']}
            onClick={props.onClick}
        >

            <UiImage
                className={styles.image}
                src={props.image || {}}
                filters={{ width: 200, height: 200 }}
                type={'thumb'}
            />

            <UiText className={styles.title}>
                {props.title}
            </UiText>

        </UiLink>

    );
};

interface SportCardProps {
    [key: string]: any;
}

export default SportCard;
