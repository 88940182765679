import React, { useMemo } from "react";

import styles from "./CampSessionJoin.module.scss";

import OrderCheckout from "../OrderCheckout/OrderCheckout";

const CampSessionJoinPopupPayment: React.FC<CampSessionJoinPopupPaymentProps> = (props) => {

    let { handleRefresh, order } = props;

    return useMemo(() => (

        <OrderCheckout
            order={order}
            handleRefresh={handleRefresh}
        />

    ), [props]);

};

interface CampSessionJoinPopupPaymentProps {
    [key: string]: any;
}

export default CampSessionJoinPopupPayment;
