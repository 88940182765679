import React, { useEffect, useMemo } from "react";
import { config } from "@dex/bubl-web";
import { get } from "@dex/bubl-helpers";

import styles from "./LeagueOperatorScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import LeagueSeasonsPanel from "../../groups/LeagueSeasonsPanel/LeagueSeasonsPanel";
import Space from "../../elements/Space/Space";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import PassesPanel from "../../groups/PassesPanel/PassesPanel";
import ReviewsPanel from "../../groups/ReviewsPanel/ReviewsPanel";
import AboutPanel from "../../groups/AboutPanel/AboutPanel";
import SubMenu from "../../elements/SubMenu/SubMenu";
import LeaguesApi from "../../apis/LeaguesApi";
import PageIntro from "../../elements/PageIntro/PageIntro";
import OrderDetailsPopup from "../../groups/OrderDetailsPopup/OrderDetailsPopup";
import WatchPanel from "../../groups/WatchPanel/WatchPanel";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";

const LeagueOperatorScreen: React.FC<LeagueOperatorScreenProps> = (props) => {

    const operatorId = get(props, 'screenProps.routeParams.operatorId');

    const orderId = get(props, 'screenProps.routeParams.orderId');

    const operator = LeaguesApi.getOne(operatorId);

    useEffect(() => {

        operator.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.title,
                    desc: response.description,
                    image: response.cover || response.thumbnail
                });

            }
        });

    }, []);

    return useMemo(() => (

        <>

            {orderId &&
                <OrderDetailsPopup orderId={orderId} />
            }

            <Header />

            <SubMenu
                menu={[
                    { label: "Seasons", scrollTo: "seasons" },
                    { label: "News", scrollTo: "news" },
                    { label: "Huddle+", scrollTo: "huddle" },
                    { label: "Join", scrollTo: "join" },
                    { label: "About", scrollTo: "about" },
                    { label: "Reviews", scrollTo: "reviews" },
                ]}
            />

            {operator.data &&

                <Container>

                    <PageIntro
                        image={operator.data.cover || operator.data.thumbnail}
                        logo={operator.data.logo}
                        title={operator.data.title}
                        content={operator.data.description}
                        reviewScore={operator.data.reviewScore}
                        reviewCount={operator.data.reviewCount}
                        websiteLink={operator.data.websiteLink}
                        hostId={operator.data.hostId}
                    />

                    <Space />

                    <GoogleAd
                        source="ad-butler"
                        slot="opp-top"
                    />

                    <Space />

                    <LeagueSeasonsPanel
                        id={"seasons"}
                        heading={"Seasons (" + operator.data.seasonSummary.number + ")"}
                        seasons={operator.data.seasonSummary.seasons}
                        operator={operator.data}
                    />

                    <Space />

                    <AboutPanel
                        content={operator.data.contentBlocks}
                        gallery={operator.data.gallery}
                    />

                    <Space />

                    <WatchPanel
                        id={"huddle"}
                        filter={{
                            leagueId: operatorId
                        }}
                    />

                    <Space />

                    <NewsPanel
                        id={"news"}
                        heading="News"
                        filter={{
                            where: {
                                leagueId: operator.data.id
                            }
                        }}
                    />

                    <Space />

                    <PassesPanel
                        id={"join"}
                        heading="Join"
                        parentType={operator.data.modelName}
                        parentId={operator.data.id}
                    />

                    <Space />

                    <ReviewsPanel
                        id={"reviews"}
                        heading="Reviews"
                        rating={operator.data.reviewScore}
                        reviews={operator.data.reviewCount}
                        operatorTitle={operator.data.title}
                        parentType="League"
                        parentId={operator.data.id}
                    />

                    <Space />

                    <GoogleAd
                        source="ad-butler"
                        slot="opp-bottom"
                    />

                    <Space />

                </Container>

            }

            <Footer />

        </>

    ), [operator.loading, operator.data]);
};

interface LeagueOperatorScreenProps {
    [key: string]: any;
}

export default LeagueOperatorScreen;
