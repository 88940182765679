import React, { useEffect, useMemo, useCallback } from "react";
import { UiImage, UiText, UiView, useStore, useLink } from "@dex/bubl-ui";

import styles from "./SportsTabs.module.scss";
import StandardCard from "../../elements/StandardCard/StandardCard";
import Grid from "../../elements/Grid/Grid";
import Container from "../../elements/Container/Container";
import SportsApi from "../../apis/SportsApi";
import Space from "../../elements/Space/Space";
import SportCard from "../../elements/SportCard/SportCard";

const SportsTabs: React.FC<SportsTabsProps> = (props) => {

    const linkTo = useLink();

    const list = SportsApi.list(props.type);

    const [preferredSportId, setPreferredSportId] = useStore('preferredSportId');

    useEffect(() => {

        list.run();

    }, [props.type]);

    return useMemo(() => (

        <>

            {props.fixed &&

                <Container
                    padding={2}
                    width={"full"}
                    className={"w-700"}
                >

                    <Space height={8} />

                    <UiText className={styles.heading}>Select Your Sport</UiText>

                    <Space height={4} />

                    <UiView
                        className={styles.grid}
                    >

                        {(list.data || []).map((item: any, index: number) => (

                            <UiView
                                className="item"
                                key={index}
                            >

                                <SportCard
                                    title={item.title}
                                    selected={item.id === props.selected}
                                    path={`${props.prefix}/${item.id}/${item.slug}`}
                                    onClick={(event: any) => {

                                        event.preventDefault();

                                        setPreferredSportId(item.id);

                                        linkTo(`${props.prefix}/${item.id}/${item.slug}`);

                                    }}
                                    image={item.icon}
                                />

                            </UiView>

                        ))}

                    </UiView>

                    <Space height={8} />

                </Container>

            }

            {!props.fixed &&

                <Container>

                    <Space />

                    <Grid
                        columns={7}
                        gap={1}
                    >

                        {(list.data || []).map((item: any, index: number) => (

                            <UiView
                                className="item"
                                key={index}
                            >

                                <SportCard
                                    title={item.title}
                                    selected={item.id === props.selected}
                                    path={`${props.prefix}/${item.id}/${item.slug}`}
                                    onClick={(event: any) => {

                                        event.preventDefault();

                                        setPreferredSportId(item.id);

                                        linkTo(`${props.prefix}/${item.id}/${item.slug}`);

                                    }}
                                    image={item.icon}
                                />

                            </UiView>

                        ))}

                    </Grid>

                </Container>

            }


        </>

    ), [list.data, list.loading, props.selected]);

};

interface SportsTabsProps {
    type: string;
    prefix?: string;
    selected?: string;
    fixed?: boolean;
    [key: string]: any;
}

export default SportsTabs;
