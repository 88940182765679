import React, { useState } from 'react';

import styles from './OrderCheckout.module.scss';

import Divider from '../../elements/Divider/Divider';
import OrderSummary from '../../elements/OrderSummary/OrderSummary';
import OrderForm from '../../elements/OrderForm/OrderForm';
import OrderApi from '../../apis/OrderApi';
import OrderPayment from '../../elements/OrderPayment/OrderPayment';
import OrderTeamForm from '../../elements/OrderTeamForm/OrderTeamForm';

const OrderCheckout: React.FC<OrderCheckoutProps> = (props) => {

    const { order, handleRefresh } = props;

    const [orderData, setOrderData] = useState(order);

    const getOrder = OrderApi.getOne(orderData.id);

    const refreshOrder = () => {

        getOrder.reset();

        getOrder.run({
            onComplete: (response: any) => {

                setOrderData(response);

            }
        });

    }

    return (

        <>

            {orderData &&

                <>

                    <Divider
                        title={`Order Summary`}
                    />

                    <OrderSummary order={orderData} />

                    {orderData.formId && orderData.formStatus === "pending" &&

                        <OrderForm
                            order={orderData}
                            afterSubmit={refreshOrder}
                        />

                    }

                    {orderData.teamStatus === "pending" &&

                        <OrderTeamForm
                            order={orderData}
                            afterSubmit={refreshOrder}
                        />

                    }

                    {(!orderData.formId || orderData.formStatus === "complete") && orderData.teamStatus !== "pending" &&
                        <>

                            {(orderData.status === "cart" || orderData.status === "due") &&

                                <OrderPayment order={orderData} />

                            }

                        </>

                    }

                </>

            }

        </>

    )

}

interface OrderCheckoutProps {
    [key: string]: any;
}

export default OrderCheckout;