import React, { useEffect, useMemo } from "react";
import { config } from "@dex/bubl-web";
import { get } from "@dex/bubl-helpers";

import styles from "./SportScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Banner from "../../elements/Banner/Banner";
import Space from "../../elements/Space/Space";
import CollectionBlock from "../../elements/CollectionBlock/CollectionBlock";

import SportsApi from "../../apis/SportsApi";

const SportScreen: React.FC<SportScreenProps> = (props) => {

    const key = get(props, 'screenProps.routeKey');

    const sportId = get(props, 'screenProps.routeParams.sportId');

    const sport = SportsApi.getOne(sportId);

    useEffect(() => {

        sport.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.title,
                    desc: response.description,
                    image: response.cover || response.thumbnail
                });

            }
        });

    }, []);

    return useMemo(() => (

        <>

            <Header />

            {sport.data &&
                <>

                    <Banner
                        heading={sport.data.title}
                        backgroundImage={sport.data.cover || sport.data.thumbnail}
                    />

                    <Container>

                        <Space />

                        {(sport.data.groups || []).map((group: any, index: number) => {

                            return (

                                <React.Fragment key={index}>

                                    <CollectionBlock
                                        modelName={group.modelName}
                                        heading={group.title}
                                        cards={group.cards}
                                    />

                                    <Space />

                                </React.Fragment>

                            )

                        })}

                        <Space />

                    </Container>

                </>
            }

            <Footer />

        </>

    ), [sport.data, sport.loading]);

};

interface SportScreenProps {
    [key: string]: any;
}

export default SportScreen;
