import React from "react"


function AccountCircleIcon(props: any) {
    return (
        <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >

            <circle
                cx="12"
                cy="11.5"
                r="11.5"
                fill="currentColor"
            />

            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 1.5C6.48 1.5 2 5.98 2 11.5C2 17.02 6.48 21.5 12 21.5C17.52 21.5 22 17.02 22 11.5C22 5.98 17.52 1.5 12 1.5ZM12 4.5C13.66 4.5 15 5.84 15 7.5C15 9.16 13.66 10.5 12 10.5C10.34 10.5 9 9.16 9 7.5C9 5.84 10.34 4.5 12 4.5ZM6 15.48C7.29 17.42 9.5 18.7 12 18.7C14.5 18.7 16.71 17.42 18 15.48C17.97 13.49 13.99 12.4 12 12.4C10 12.4 6.03 13.49 6 15.48Z"
                fill="white"
            />
        </svg>

    )
}

export default AccountCircleIcon