import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'WatchVideos';

const WatchApi = {

    home: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/home",
            params: {},
            cache: true,
            cacheTTL: 30000,
            ...options
        });

    },

    list: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/list",
            params: {},
            cache: true,
            cacheTTL: 30000,
            ...options
        });

    },

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            cache: true,
            ...options
        })

    },

    search: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/search",
            params: {},
            ...options
        })

    },

}

export default WatchApi;