import { Events, UiForm, UiLink, UiText, UiView, useForm } from '@dex/bubl-ui';
import React, { useCallback, useState } from 'react'
import { IoMdClose } from 'react-icons/io';
import LeagueSeasonPlayerApi from '../../apis/LeagueSeasonPlayerApi';
import Button from '../../elements/Button/Button';
import Divider from '../../elements/Divider/Divider';
import FieldText from '../../elements/Fields/FieldText/FieldText';
import FieldUpload from '../../elements/Fields/FieldUpload/FieldUpload';
import Modal from '../../elements/Modal/Modal';
import Space from '../../elements/Space/Space';
import PlayerProfileFields from '../PlayerProfileFields/PlayerProfileFields';

import styles from './LeagueTeamRegisterPlayerEdit.module.scss'

const LeagueTeamRegisterPlayerEdit: React.FC<LeagueTeamRegisterPlayerEditProps> = (props) => {

    const { team, handleRefresh, player, handleClose } = props;

    const form = useForm(player);

    const update = LeagueSeasonPlayerApi.update(player.id);

    const handleCancel = useCallback(() => {

        if (handleClose) handleClose();
        if (handleRefresh) handleRefresh();

    }, []);

    const handleSubmit = useCallback((values) => {

        update.run({
            data: values,
            onComplete: () => {

                Events.emit("ui", {
                    action: "success",
                    timeout: 3000,
                    message: "Changes Saved"
                });

                handleCancel();

            }
        });

    }, [form.values])

    return (
        <>
            <Modal
                show={true}
                onClose={handleCancel}
            >


                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleCancel}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider
                        title={player.name}
                    />

                    <UiView className={styles.body}>

                        <Space />

                        <UiForm
                            form={form}
                            onSubmit={handleSubmit}
                            loading={update.loading}
                        >

                            <PlayerProfileFields
                                form={form}
                            />

                            <Button
                                label={"Update Player"}
                                block={true}
                                onClick={form.triggerSubmit}
                                loading={update.loading}
                            />

                        </UiForm>

                    </UiView>

                </UiView>

            </Modal>

        </>

    )

}

interface LeagueTeamRegisterPlayerEditProps {
    [key: string]: any;
}

export default LeagueTeamRegisterPlayerEdit