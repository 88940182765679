import React from 'react';
import { UiText, UiView } from '@dex/bubl-ui';

import styles from './InlineMessage.module.scss'

const InlineMessage: React.FC<InlineMessageProps> = (props) => {

    const { type, message } = props;

    const bgClass: any = [styles.container, styles[`${type}Container`]];
    const textClass: any = [styles.text, styles[`${type}Text`]];

    return (
        <UiView className={bgClass}>

            <UiText className={textClass}>
                {message}
            </UiText>

        </UiView>
    )
}

interface InlineMessageProps {
    [key: string]: any
}

export default InlineMessage;