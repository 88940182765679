import React from "react";
import { UiFieldSwitch } from "@dex/bubl-ui";

import styles from "../Fields.module.scss";
import switchStyles from "./FieldSwitch.module.scss";

const FieldSwitch: React.FC<FieldSwitchProps> = (props) => {

    return (

        <UiFieldSwitch
            {...props}
            classes={{
                wrap: [styles.wrap],
                label: [styles.label],
                input: [styles.input, props.className],
                error: [styles.error],
                item: [switchStyles.item],
                itemChecked: [switchStyles.itemChecked],
                itemSymbol: [switchStyles.itemSymbol],
                itemSymbolChecked: [switchStyles.itemSymbolChecked],
                itemLabel: [switchStyles.itemLabel],
                itemLabelChecked: [switchStyles.itemLabelChecked],
            }}
        />


    );
};

interface FieldSwitchProps {
    [key: string]: any;
}

export default FieldSwitch;
