import { UiView } from '@dex/bubl-ui';
import React from 'react'
import FieldText from '../../elements/Fields/FieldText/FieldText';
import FieldUpload from '../../elements/Fields/FieldUpload/FieldUpload';
import Space from '../../elements/Space/Space';

import styles from './PlayerProfileFields.module.scss'

const PlayerProfileFields: React.FC<PlayerProfileFieldsProps> = (props) => {

    const { form } = props;

    return (
        <>

            <UiView className={styles.image}>
                <FieldUpload
                    form={form}
                    name={"photo"}
                    accept={"image/*"}
                    defaultValue={form.defaults.photo}
                />
            </UiView>

            <Space />

            <FieldText
                form={form}
                name={"name"}
                label={"Full Name"}
                placeholder={"Enter your full name"}
                defaultValue={form.defaults.name}
                required={true}
            />

            <Space />

            <FieldText
                form={form}
                name={"icNumber"}
                label={"IC Number"}
                placeholder={"Enter your ic or passport number"}
                defaultValue={form.defaults.icNumber}
                required={true}
            />

            <Space />

            <FieldText
                form={form}
                name={"jerseyNumber"}
                label={"Jersey Number"}
                placeholder={"Enter your jersey number"}
                defaultValue={form.defaults.jerseyNumber}
                required={true}
            />

            <Space />

            <FieldText
                form={form}
                name={"nickName"}
                label={"Nick Name"}
                placeholder={"Enter your nick name"}
                defaultValue={form.defaults.nickName}
            />

            <Space />

            <FieldText
                form={form}
                name={"position"}
                label={"Position"}
                placeholder={"Enter your position"}
                defaultValue={form.defaults.position}
            />

            <Space />

            <FieldText
                form={form}
                name={"city"}
                label={"City"}
                placeholder={"Enter your city"}
                defaultValue={form.defaults.city}
            />

            <Space />

        </>

    )

}

interface PlayerProfileFieldsProps {
    [key: string]: any;
}

export default PlayerProfileFields