import React from "react";
import { UiImage, UiText, UiLink } from "@dex/bubl-ui";

import styles from "./CommunityCard.module.scss";

const CommunityCard: React.FC<CommunityCardProps> = (props) => {

    return (

        <UiLink path={props.path} className={styles.card}>

            <UiImage
                className={styles.image}
                type={'thumb'}
                src={props.image}
                resizeMode={"contain"}
                filters={{ width: 140, height: 140 }}
            />

            <UiText className={styles.title}>
                {props.title}
            </UiText>

        </UiLink>

    );
};

interface CommunityCardProps {
    [key: string]: any;
}

export default CommunityCard;
