import { apiGet, options, response, apiPost, apiDelete } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'LeagueSeasonGame';

const LeagueSeasonGameApi = {

    getGameLog: (gameId: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + gameId,
            params: {},
            ...options
        });

    }

}

export default LeagueSeasonGameApi;