import React from "react";
import { UiImage, UiText, UiView, UiLink, useUser } from "@dex/bubl-ui";

import styles from "./PassCard.module.scss";

const PassCard: React.FC<PassCardProps> = (props) => {

    const user = useUser("appUser");

    return (

        <>

            {user.loggedIn &&

                <UiLink path={props.path} onClick={props.onClick} className={styles.container}>

                    <UiImage
                        className={styles.image}
                        type={'local'}
                        src={require('../../assets/images/pass-card.png')}
                    />

                    <UiView className={styles.textWrapper}>

                        <UiText className={styles.title}>{props.title}</UiText>

                        <UiText className={styles.content}>{props.content}</UiText>

                    </UiView>

                    {props.price &&
                        <UiText className={styles.price}>RM{props.price.toLocaleString()}</UiText>
                    }

                    {props.status &&
                        <UiText className={styles.price}>{props.status.toUpperCase()}</UiText>
                    }

                </UiLink>
            }

            {!user.loggedIn &&

                <UiLink path="/login" onClick={props.onClick} className={styles.container}>

                    <UiImage
                        className={styles.image}
                        type={'local'}
                        src={require('../../assets/images/pass-card.png')}
                    />

                    <UiView className={styles.textWrapper}>

                        <UiText className={styles.title}>{props.title}</UiText>

                        <UiText className={styles.content}>{props.content}</UiText>

                    </UiView>

                    {props.price &&
                        <UiText className={styles.price}>RM{props.price.toLocaleString()}</UiText>
                    }

                    {props.status &&
                        <UiText className={styles.price}>{props.status.toUpperCase()}</UiText>
                    }

                </UiLink>

            }

        </>

    );
};

interface PassCardProps {
    [key: string]: any;
}

export default PassCard;
