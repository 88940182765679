import React from "react";
import { UiImage, UiText, UiView, UiLink } from "@dex/bubl-ui";
import Slider from "react-slick";
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle, IoIosStar } from 'react-icons/io';
import moment from 'moment';
import Rating from "react-rating";

import styles from "./ReviewsCarousel.module.scss";
import Container from "../Container/Container";

const ReviewsCarousel: React.FC<ReviewsCarouselProps> = (props) => {

    let { data } = props;

    let slider: Slider | null;

    const ArrowLeft = () => (
        <UiLink onClick={() => slider?.slickPrev()} className={[styles.slickButtons, styles.slickPrev]}>
            <IoIosArrowDropleftCircle />
        </UiLink>
    )

    const ArrowRight = () => (
        <UiLink onClick={() => slider?.slickNext()} className={[styles.slickButtons, styles.slickNext]}>
            <IoIosArrowDroprightCircle />
        </UiLink>
    )

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        touchMove: true,
        draggable: true,
        focusOnSelect: true,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    }

    return (

        <UiView className={styles.container}>

            <Container>

                <Slider
                    ref={(c) => { slider = c; }}
                    {...settings}
                >

                    {data.map((data: any, index: number) => (

                        <UiView
                            key={index}
                            className={[styles.itemContainer]}
                        >

                            <UiView className={styles.profileContainer}>

                                <UiImage
                                    className={styles.image}
                                    type={'thumb'}
                                    src={data.owner.profilePhoto}
                                />

                                <UiView>

                                    <UiText className={styles.name}>{data.owner.fullName}</UiText>

                                    <UiView className={styles.starContainer}>

                                        <Rating
                                            initialRating={data.rating}
                                            emptySymbol={<IoIosStar size="20" className={styles.startEmpty} />}
                                            fullSymbol={<IoIosStar size="20" className={styles.StarFill} />}
                                            readonly={true}
                                        />

                                    </UiView>

                                    <UiText className={styles.date}>{moment(data.created.date).fromNow()}</UiText>

                                </UiView>

                            </UiView>

                            <UiView className={styles.contentWrapper}>

                                <UiText className={styles.content}>"{data.message}"</UiText>

                            </UiView>

                        </UiView>

                    ))}

                </Slider>

            </Container>

        </UiView>

    );
};

interface ReviewsCarouselProps {
    heading?: string;
    subHeading?: string;
    [key: string]: any;
}

export default ReviewsCarousel;
