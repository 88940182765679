import React from "react";
import { UiImage, UiView, UiImageSource } from "@dex/bubl-ui";
// @ts-ignore
import { SRLWrapper } from "simple-react-lightbox";

import styles from "./PhotoGrid.module.scss";
import Grid from "../Grid/Grid";

const PhotoGrid: React.FC<PhotoGridProps> = (props) => {

    let { gallery, largeWidth, largeHeight, thumbnailWidth, thumbnailHeight } = props;

    largeWidth = largeWidth || 0;
    largeHeight = largeHeight || 1080;

    thumbnailWidth = thumbnailWidth || 400;
    thumbnailHeight = thumbnailHeight || 300;

    return (

        <UiView className={styles.container}>

            <UiView className={styles.imageContainer}>

                <SRLWrapper>

                    <Grid
                        columns={props.columns || 5}
                        gap={1}
                        fixed={true}
                    >

                        {gallery.map((image, index) => {

                            const large = UiImageSource(image, 'thumb', { width: largeWidth, height: largeHeight, resizeMode: "center" });

                            return (

                                <UiView
                                    className="item"
                                    key={index}
                                >

                                    <a
                                        className={styles.item}
                                        href={large}
                                        data-attribute="SRL"
                                    >

                                        <UiImage
                                            src={image}
                                            type={'thumb'}
                                            className={styles.image}
                                            resizeMode={"cover"}
                                            filters={{
                                                width: thumbnailWidth,
                                                height: thumbnailHeight,
                                            }}
                                        />

                                    </a>

                                </UiView>

                            )
                        })}

                    </Grid>

                </SRLWrapper>

            </UiView>

        </UiView>

    );

};

interface PhotoGridProps {
    gallery: Array<any>,
    largeWidth?: number,
    largeHeight?: number,
    thumbnailWidth?: number,
    thumbnailHeight?: number,
    [key: string]: any;
}

export default PhotoGrid;
