import React, { useEffect, useMemo } from "react";
import { get } from "@dex/bubl-helpers";
import { UiView } from "@dex/bubl-ui";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import StandardCard from "../../elements/StandardCard/StandardCard";
import Panel from "../../elements/Panel/Panel";
import Grid from "../../elements/Grid/Grid";
import SportsTabs from "../../groups/SportsTabs/SportsTabs";

import PickupSessionGroupApi from "../../apis/PickupSessionGroupApi";

const PickupsCategoryScreen: React.FC<PickupsCategoryScreenProps> = (props) => {

    const sportId = get(props, 'screenProps.routeParams.sportId');
    const categoryId = get(props, 'screenProps.routeParams.categoryId');

    const cards = PickupSessionGroupApi.cards();

    let params = {
        sportId: sportId,
        categoryId: categoryId,
        limit: 100,
    };

    useEffect(() => {

        cards.run({ params: params });

    }, [sportId]);

    return useMemo(() => (

        <>

            <Header />

            <SportsTabs
                type="League"
                prefix="/pickups/sport"
                selected={sportId}
            />

            <Space />

            {cards.data && cards.data[0] &&

                <>

                    <Space />

                    <Container>

                        <Panel
                            heading={cards.data[0]?.category?.title}
                        >

                            <Grid
                                columns={5}
                                gap={4}
                                fixed={true}
                            >

                                {cards.data.map((card: any, key: number) => (

                                    <UiView className="item" key={key}>

                                        <StandardCard
                                            path={`/pickups/group/${card.id}/${card.slug}`}
                                            title={card.title}
                                            image={card.thumbnail || card.cover}
                                        />

                                    </UiView>

                                ))}

                            </Grid>

                        </Panel>

                        <Space />

                    </Container>
                </>
            }

            <Footer />

        </>

    ), [cards.loading, cards.data]);

};

interface PickupsCategoryScreenProps {
    [key: string]: any;
}

export default PickupsCategoryScreen;
