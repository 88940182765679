import React from "react";
import { UiCol, UiImage, UiLink, UiRow, UiText, UiView } from "@dex/bubl-ui";

import styles from "./Footer.module.scss";
import Container from "../Container/Container";
import Space from "../Space/Space";

const Footer: React.FC<FooterProps> = (props) => {

    return (

        <>

            <Space height={5} />

            <UiView className={styles.footer}>

                <Container padding={4}>

                    <UiImage
                        className={styles.logo}
                        resizeMode={'contain'}
                        src={require('../../assets/images/logo.png')}
                        type={'local'}
                    />

                    <UiRow
                        gutter={2}
                        edge={true}
                    >

                        <UiCol
                            col={{ xs: 24, md: 14 }}
                        >

                            <UiView className={styles.menu}>

                                <UiLink path="/dashboard">DASHBOARD</UiLink>

                                <UiLink path="/about">ABOUT</UiLink>

                                <UiLink path="/terms-conditions">TERMS &amp; CONDITIONS</UiLink>

                            </UiView>

                        </UiCol>

                        <UiCol
                            col={{ xs: 24, md: 10 }}
                        >

                            <UiText className={styles.copyright}>
                                &#169; {new Date().getFullYear()} AIRUPTHERE TECHNOLOGIES. All rights reserved
                            </UiText>

                        </UiCol>

                    </UiRow>

                </Container>

            </UiView>

        </>

    );
};

interface FooterProps {
    [key: string]: any;
}

export default Footer;
