import React from "react";
import { UiView, UiLink } from "@dex/bubl-ui";

import styles from "./SignInCta.module.scss";
import Container from "../Container/Container";

const SignInCta: React.FC<SignInCtaProps> = (props) => {

    const { text, link } = props;

    return (

        <UiView className={styles.bar}>

            <Container padding={4}>

                <UiLink path={link} className={styles.text}>

                    {text}

                </UiLink>

            </Container>

        </UiView>

    );
};

interface SignInCtaProps {
    [key: string]: any;
}

export default SignInCta;
