import React, { useMemo, useState } from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./VenueBookingJoin.module.scss";

import Divider from "../../elements/Divider/Divider";
import Space from "../../elements/Space/Space";
import VenueBookingJoinPopupJoin from "./VenueBookingJoinPopupJoin";
import VenueBookingJoinPopupCancel from "./VenueBookingJoinPopupCancel";
import VenueBookingJoinAttendees from "./VenueBookingJoinAttendees";
import VenueBookingJoinInvite from "./VenueBookingJoinInvite";
import PickupSessionJoinPopupJoin from "../PickupSessionJoin/PickupSessionJoinPopupJoin";
import Panel from "../../elements/Panel/Panel";
import VenueBookingManageSession from "../VenueBookingManageSession/VenueBookingManageSession";

const VenueBookingJoin: React.FC<VenueBookingJoinProps> = (props) => {

    // Api to fetch list of athletes and valid passes for this session/user
    const { handleRefresh, session } = props;

    const [didAction, setDidAction] = useState<any>(false);

    return useMemo(() => (

        <UiView>

            {session.isAttending &&
                <>

                    <Divider
                        title={"Attendance"}
                        dark={true}
                    />

                    <UiView className={[styles.box, 'p-4']}>

                        <UiText className={styles.text}>These are the list of your attendees. Please ensure those planning to come to your session are registered on app to avoid any complications with facilities management.</UiText>

                        <Space />

                        <VenueBookingJoinAttendees
                            session={session}
                            setDidAction={setDidAction}
                            handleRefresh={handleRefresh}
                        />

                        {session.isOwner &&

                            <>
                                <VenueBookingJoinInvite
                                    session={session}
                                />

                                <Space />

                                <hr />

                                <VenueBookingManageSession
                                    session={session}
                                    handleRefresh={handleRefresh}
                                />
                            </>

                        }

                        {!session.isOwner &&
                            <>

                                <Space height={2} />

                                <VenueBookingJoinPopupCancel
                                    handleRefresh={handleRefresh}
                                    setDidAction={setDidAction}
                                    session={session}
                                />

                            </>

                        }

                    </UiView>
                </>
            }

            {!session.isAttending &&
                <>

                    <Divider
                        title={"Join this session"}
                        dark={true}
                    />

                    <Panel
                        hideTop={true}
                    >

                        <VenueBookingJoinPopupJoin
                            session={session}
                            handleRefresh={handleRefresh}
                            setDidAction={setDidAction}
                        />

                    </Panel>

                </>
            }

        </UiView>

    ), [session, didAction]);

};

interface VenueBookingJoinProps {
    [key: string]: any;
}

export default VenueBookingJoin;
