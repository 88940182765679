import React from "react";
import { UiImage, UiLink, UiText, UiView } from "@dex/bubl-ui";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import Container from "../Container/Container";
import Slider from "react-slick";
import AssesmentScoreSlider from "../AssesmentScoreSlider/AssesmentScoreSlider";
import Space from "../Space/Space";

import styles from "./AssesmentDetails.module.scss";

const AssesmentDetails: React.FC<AssesmentDetailsProps> = (props) => {

    const { data } = props;

    let slider: Slider | null;

    const ArrowLeft = (props: any) => {

        return (
            <UiLink onClick={() => slider?.slickPrev()} className={[styles.slickButtons, styles.slickPrev]}>

                <UiView className={styles.left}>

                    {props.currentSlide !== 0 &&
                        <>
                            <IoIosArrowDropleftCircle className={styles.iconLeft} />
                            <UiText className={styles.buttonTextLeft}>{data.criteria[props.currentSlide - 1].title}</UiText>
                        </>
                    }

                </UiView>

            </UiLink>
        )
    }

    const ArrowRight = (props: any) => {

        return (
            <UiLink onClick={() => slider?.slickNext()} className={[styles.slickButtons, styles.slickNext]}>

                <UiView className={styles.button}>

                    <UiView className={styles.right}>

                        {props.currentSlide === 0 &&
                            <>
                                <UiText className={styles.buttonTextRight}>{data.criteria[props.currentSlide + 1].title}</UiText>
                                <IoIosArrowDroprightCircle className={styles.iconRight} />
                            </>
                        }

                        {(props.currentSlide !== 0 && props.currentSlide < props.slideCount && props.currentSlide !== props.slideCount - 1) &&
                            <>
                                <UiText className={styles.buttonTextRight}>{data.criteria[(props.currentSlide + 1)].title}</UiText>
                                <IoIosArrowDroprightCircle className={styles.iconRight} />
                            </>
                        }

                    </UiView>

                </UiView>

            </UiLink>
        )
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        touchMove: true,
        draggable: true,
        focusOnSelect: true,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />
    }

    return (
        <>
            <UiView className={styles.header}>

                <UiView className={styles.headingContainer}>

                    <UiText className={styles.hostText}>{data.title}</UiText>

                    <UiText className={styles.titleText}>{data.title}</UiText>

                    {data.coach &&
                        <UiText className={styles.coachNameText}>By {data.coach.name}</UiText>
                    }

                </UiView>

            </UiView>

            <UiView className={['p-b-3 p-t-2 p-l-3 p-r-3']}>

                <Container className={styles.sliderContainer}>

                    <Slider
                        ref={(c) => {
                            slider = c;
                        }}
                        {...settings}
                    >

                        {data.criteria.map((item: any, index: number) => (
                            <UiView key={index}>

                                <UiView className={styles.contentContainer}>

                                    <UiText className={styles.headerText}>{item.title}</UiText>

                                    <UiView className={styles.descriptionContainer}>

                                        <UiText className={styles.descriptionText}>{item.description}</UiText>

                                    </UiView>

                                </UiView>

                                <UiView style={{marginLeft:20,marginRight:20}}>
                                    <AssesmentScoreSlider
                                        score={item.score}
                                        showLabels={true}
                                    />
                                </UiView>

                                <Space height={5} />

                            </UiView>

                        ))}

                    </Slider>

                </Container>

            </UiView>

            {data.notes &&
                <UiView className={styles.footerContainer}>

                    {data.coach && data.coach.profilePhoto &&
                        <UiImage
                            className={styles.image}
                            src={data.coach.profilePhoto}
                            type="thumb"
                            filters={{ width: 65, height: 65 }}
                        />
                    }

                    <UiText className={styles.footerTitle}>Coach’s Notes</UiText>

                    <UiText className={styles.footerContent}>{data.notes}</UiText>

                </UiView>
            }

        </>

    );
};

interface AssesmentDetailsProps {
    [key: string]: any;
}

export default AssesmentDetails;
