import React, { useCallback, useEffect } from "react";
import { UiView, UiText, UiForm, useForm } from "@dex/bubl-ui";

import styles from "./OrderForm.module.scss";

import Divider from "../Divider/Divider";
import Space from "../Space/Space";
import FormApi from "../../apis/FormApi";
import Button from "../Button/Button";
import FieldText from "../Fields/FieldText/FieldText";
import FieldSelect from "../Fields/FieldSelect/FieldSelect";
import FieldTextArea from "../Fields/FieldText/FieldTextArea";
import FieldNumber from "../Fields/FieldNumber/FieldNumber";
import FieldDate from "../Fields/FieldDate/FieldDate";
import FieldSwitch from "../Fields/FieldSwitch/FieldSwitch";

const OrderForm: React.FC<OrderFormProps> = (props) => {

    const { order, afterSubmit } = props;

    const form = FormApi.getOne(order.formId);
    const addEntry = FormApi.addEntry(order.formId);

    const handleSubmit = useCallback((values) => {

        addEntry.run({
            data: {
                orderId: order.id,
                values: values
            },
            onComplete: (response: any) => {

                afterSubmit();

            }
        });

    }, []);

    useEffect(() => {

        form.run();

    }, []);

    return (

        <UiView className={styles.container}>

            {form.loading &&
                <>

                    <Divider
                        title={"Loading"}
                    />

                </>
            }

            {!form.loading && form.data &&

                <>

                    <Divider
                        title={form.data.title}
                    />


                    <OrderFormFields
                        fields={form.data.fields}
                        defaultValues={form.data.defaultValues}
                        handleSubmit={handleSubmit}
                        loading={addEntry.loading}
                    />

                </>

            }


        </UiView>


    );

};

const OrderFormFields: React.FC<OrderFormProps> = (props) => {

    const { fields, defaultValues, handleSubmit, loading } = props;

    const form = useForm(defaultValues || {}); //defaultValues

    return (

        <UiForm
            form={form}
            loading={loading}
            onSubmit={handleSubmit}
            className={['p-b-3 p-t-3 p-l-3 p-r-3']}
        >

            {fields.map((field: any, index: any) => (

                <React.Fragment key={index}>

                    {field.type === "text" &&

                        <FieldText
                            form={form}
                            name={field.id}
                            defaultValue={form.defaults[field.id]}
                            required={field.required}
                            label={field.label || field.title}
                            placeholder={field.placeholder}
                        />

                    }

                    {field.type === "textArea" &&

                        <FieldTextArea
                            form={form}
                            name={field.id}
                            defaultValue={form.defaults[field.id]}
                            required={field.required}
                            label={field.label || field.title}
                            placeholder={field.placeholder}
                        />

                    }

                    {field.type === "number" &&

                        <FieldNumber
                            form={form}
                            name={field.id}
                            defaultValue={form.defaults[field.id]}
                            required={field.required}
                            label={field.label || field.title}
                            placeholder={field.placeholder}
                        />

                    }

                    {field.type === "dropdown" &&

                        <FieldSelect
                            form={form}
                            name={field.id}
                            defaultValue={form.defaults[field.id]}
                            required={field.required}
                            label={field.label || field.title}
                            placeholder={field.placeholder}
                            options={field.options.map((v: any) => { return { value: v, label: v }; })}
                        />

                    }

                    {field.type === "bool" &&

                        <FieldSwitch
                            form={form}
                            name={field.id}
                            defaultValue={form.defaults[field.id]}
                            required={field.required}
                            label={field.label || field.title}
                            itemLabel={field.placeholder}
                        />

                    }

                    {field.type === "date" &&

                        <FieldDate
                            form={form}
                            name={field.id}
                            defaultValue={form.defaults[field.id]}
                            required={field.required}
                            label={field.label || field.title}
                            placeholder={field.placeholder}
                        />

                    }

                    {field.type === "section" &&
                        <>

                            <UiText className={styles.sectionHeading}>{field.label || field.title}</UiText>

                            {(field.placeholder || field.description) &&
                                <UiText className={styles.sectionText}>{field.placeholder || field.description}</UiText>
                            }

                        </>
                    }

                    <Space height={1} />

                </React.Fragment>

            ))}

            <Button
                label={"Continue"}
                loading={loading}
                type={"submit"}
                block={true}
            />

        </UiForm>

    );

}

interface OrderFormProps {
    [key: string]: any;
}

export default OrderForm;
