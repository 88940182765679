import React from 'react';
import { UiView, UiText } from '@dex/bubl-ui';
import styles from "./EventCardBadge.module.scss";
import PickupSessionIcon from '../Icons/PickupSessionIcon';
import VenueIcon from '../Icons/VenueIcon';
import VideoIcon from '../Icons/VideoIcon';
import CampIcon from '../Icons/CampIcon';
import AccountCircleIcon from '../Icons/AccountCircleIcon';

const EventCardBadge: React.FC<EventCardBadgeProps> = (props) => {

    const { modelName, isOwner, videoStatus } = props;

    return (

        <>
            {modelName === 'CampSession' &&

                <UiView className={styles.badge}>

                    <CampIcon
                        className={styles.icon}
                    />

                </UiView>

            }

            {modelName === 'PickupSession' &&

                <UiView className={styles.badge}>

                    <PickupSessionIcon
                        className={styles.icon}
                    />

                </UiView>

            }

            {modelName === 'VenueBooking' &&

                <UiView className={styles.badge}>

                    <VenueIcon
                        className={styles.icon}
                    />

                    {isOwner && <AccountCircleIcon className={styles.ownerIcon} />}

                    <VideoIcon className={videoStatus === 'ready' ? styles.videoIconAlt : styles.videoIcon} />

                </UiView>

            }
        </>

    )

}

interface EventCardBadgeProps {
    [key: string]: any,
}

export default EventCardBadge;