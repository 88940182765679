import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UiView, UiText, UiLink } from '@dex/bubl-ui';
import useLongPress from '../../hooks/useLongPress';

import styles from "./TimeSlots.module.scss";

const TimeSlots: React.FC<TimeSlotsProps> = (props) => {

    const { slots, message, setSelected, selected, title, courtId, date, blockEnabled, isBlockBooking, handleBlockBooking } = props;

    const [selectedKey, setSelectedKey]: any = useState(null);

    useEffect(() => {

        let selectedKey: any = null;

        slots.map((slot: any, key: number) => {

            if (selected.includes(courtId + "." + slot.time)) selectedKey = key;

            return null;

        });

        setSelectedKey(selectedKey);

    }, [slots, selected]);

    const onClick = useCallback((event) => {

        const key = parseInt(event.target.id);
        const slot = slots[key];

        if (slot.disabled) return;

        if (selected.includes(courtId + "." + slot.time)) {

            setSelected([], null);
            return;

        }

        setSelected([courtId + "." + slot.time], { title, courtId, ...slot });

    }, [setSelected, selected]);

    const onLongPress = useCallback((event) => {

        const key = parseInt(event.target.id);
        const slot = slots[key];

        if (!canExtendTo(slot, key)) return;

        const newSelected = [...selected, courtId + "." + slot.time];

        setSelected(newSelected, { title, courtId, ...slot });

    }, [selectedKey, setSelected, selected]);

    const canExtendTo = useCallback((slot, key) => {

        if (isBlockBooking) return false;

        if (selectedKey < 0 || selectedKey == null) return false;

        if (!slot) return false
        if (slot.disabled) return false

        const current = slots[selectedKey];

        if (slot.productId !== current.productId) return false;
        if (slot.size !== current.size) return false;

        if (selectedKey + 1 !== key) return false;

        return true;

    }, [selectedKey, slots]);

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return useMemo(() => (

        <UiView className={[styles.panel, props.className]}>

            {title &&
                <UiView className={styles.titleContainer}>

                    <UiText className={styles.title}>{title}</UiText>

                    {blockEnabled &&
                        <UiLink
                            className={styles.blockBookingLink}
                            onClick={handleBlockBooking}
                        >
                            Make a block booking
                        </UiLink>
                    }

                </UiView>
            }

            <UiView className={styles.slots}>

                {slots && slots.map((slot: any, index: number) => (

                    <UiLink
                        key={index}
                        id={index}
                        {...longPressEvent}
                        className={[
                            slot.disabled ? styles.slotsDisabled : styles.slot,
                            selected.includes(courtId + "." + slot.time) ? styles.slotSelected : ''
                        ]}
                    >
                        {slot.time}
                    </UiLink>

                ))}


            </UiView>

            {message &&
                <UiView style={styles.message}>
                    <UiText style={styles.messageText}>{message}</UiText>
                </UiView>
            }

            {selectedKey >= 0 && canExtendTo(slots[selectedKey + 1], selectedKey + 1) && (
                <UiText className={styles.info}>
                    * Long press {slots[selectedKey + 1].time} to extend your booking
                </UiText>
            )}

        </UiView>

    ), [slots, selected, longPressEvent, selectedKey])

}

interface TimeSlotsProps {
    [key: string]: any,
}

export default TimeSlots;