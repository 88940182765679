import React, { useEffect, useState } from "react";
import { UiView, UiText, UiLink, useUser } from "@dex/bubl-ui";
import { IoIosStar, IoMdClose } from 'react-icons/io';
import Rating from "react-rating";

import styles from "./ReviewsPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import ReviewsCarousel from "../../elements/ReviewsCarousel/ReviewsCarousel";
import CommentsApi from "../../apis/CommentsApi";
import Button from "../../elements/Button/Button";
import Modal from "../../elements/Modal/Modal";
import Divider from "../../elements/Divider/Divider";
import ReviewForm from "../ReviewForm/ReviewForm";

const ReviewsPanel: React.FC<ReviewsPanelProps> = (props) => {

    let { heading, rating, reviews, parentId, parentType, handleScreenRefresh, operatorTitle } = props;

    const [showPopup, setShowPopup] = useState(false);
    const [key, setKey] = useState(0);

    const user = useUser("appUser");

    const reviewList = CommentsApi.reviews(parentId, parentType);

    useEffect(() => {

        if (parentId) reviewList.run();

    }, [parentId, key, rating]);


    const handleClose = () => {
        setShowPopup(false);
    }

    const handleRefresh = () => {
        setKey(key + 1);
        handleScreenRefresh();
    }

    return (

        <>
            <Panel
                id={props.id}
                heading={heading}
                topLeft={
                    <UiView className={styles.reviewContainer}>

                        <Rating
                            initialRating={rating}
                            emptySymbol={<IoIosStar size="20" className={styles.startEmpty} />}
                            fullSymbol={<IoIosStar size="20" className={styles.StarFill} />}
                            readonly={true}
                        />

                        <UiText className={styles.reviewText}>{rating || 0}/5</UiText>

                    </UiView>

                }
                topRight={
                    <UiView tag={"span"} className={styles.reviews}>

                        <UiText className={styles.reviewText}>
                            {reviews || 0} Reviews
                        </UiText>

                        {user.loggedIn &&

                            <Button
                                label="Write a Review"
                                className={styles.reviewButton}
                                onClick={() => setShowPopup(true)}
                            />

                        }

                    </UiView>
                }
            >

                <UiView className={styles.container}>

                    {reviewList?.data?.comments?.length > 0 ? (

                        <ReviewsCarousel
                            data={reviewList.data.comments}
                        />

                    ) : (

                        <UiView className={styles.submitReview}>

                            <UiText className={styles.submitReviewMessage}>Be the first to submit a review</UiText>

                            {user.loggedIn ? (

                                <Button
                                    label="Write a Review"
                                    className={styles.reviewButton}
                                    onClick={() => setShowPopup(true)}
                                />

                            ) : (

                                <UiLink
                                    href="/login"
                                >

                                    <Button
                                        label="Please Login"
                                        className={styles.reviewButton}
                                    />

                                </UiLink>

                            )}

                        </UiView>

                    )}

                </UiView>

            </Panel>

            <Modal
                show={showPopup}
                onClose={handleClose}
            >

                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleClose}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider title={"Write A Review"} />

                    <ReviewForm
                        parent={{
                            parentId,
                            parentType,
                            parentTitle: operatorTitle
                        }}
                        handleRefresh={handleRefresh}
                        handlePopupClose={handleClose}
                    />

                </UiView>

            </Modal>
        </>
    )
}

interface ReviewsPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}


export default ReviewsPanel;