import React from 'react';
import { UiLink, UiText } from '@dex/bubl-ui';

import styles from './TagBubble.module.scss'

const TabBubble: React.FC<TabBubbleProps> = (props) => {

    const { tag, type } = props;

    const path = `/watch/${type}/${tag.id}/${tag.slug}`;

    return (
        <UiLink path={path} className={styles.container}>

            <UiText className={styles.text}>
                {tag.title}
            </UiText>

        </UiLink>
    )
}

interface TabBubbleProps {
    [key: string]: any
}

export default TabBubble;