import React, { useEffect } from 'react';
import { get } from '@dex/bubl-helpers';
import { useStore } from '@dex/bubl-ui';

import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Container from '../../elements/Container/Container';
import Footer from "../../elements/Footer/Footer";
import WatchArchive from '../../groups/WatchArchive/WatchArchive';

const WatchTagScreen: React.FC<WatchTagScreenProps> = (props) => {

    const tagId = get(props, 'screenProps.routeParams.tagId');
    const key = get(props, 'screenProps.routeKey');

    const [user] = useStore('appUser');

    const menu = [
        { label: "All Videos", path: "/watch" },
        { label: "Live Streams", path: "/watch/live" }
    ]

    if (user.loggedIn) menu.push({ label: "My Videos", path: "/watch/my-videos" })

    return (

        <>
            <Header />

            <SubMenu
                menu={menu}
            />

            <Container>

                <WatchArchive
                    key={key}
                    tagId={tagId}
                />

            </Container>

            <Footer />
        </>

    )

}

interface WatchTagScreenProps {
    [key: string]: any,
}

export default WatchTagScreen;