import React, { useEffect, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./NewsPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import Grid from "../../elements/Grid/Grid";
import NewsCard from "../../elements/NewsCard/NewsCard";
import NewsApi from "../../apis/NewsApi";
import { merge } from "@dex/bubl-helpers";
import NotFound from "../../elements/NotFound/NotFound";

const NewsPanel: React.FC<NewsPanelProps> = (props) => {

    let { set, heading, filter, seasonId, teamId, playerId, search, data, article, type } = props;

    const defaults = {
        include: [
            {
                relation: "category",
                scope: {
                    fields: ["id", "title", "thumbnail"]
                }
            }
        ],
        limit: 12,
        skip: 0,
        where: {},
        order: ['date Desc'],
    };

    filter = merge(defaults, filter || {});

    const list = NewsApi.list();

    if (type) {

        filter.where.type = type;

    }

    if (set === "latest") {

        heading = "Latest News";

        filter.where.globalFeed = true;

        filter.where.latest = true;

    } else if (set === "my") {

        heading = "Latest News From My Communities";

        // Limit to followed communities

    } else if (set === "related") {

        heading = "Similar Stories";

        if (article) {

            filter.where.type = article.type;

            if (article.hostId) filter.where.hostId = article.hostId;

            if (article.type === "recap") {

                if (article.leagueId) filter.where.leagueId = article.leagueId;
                if (article.seasonId) filter.where.seasonId = article.seasonId;

            } else {

                if (article.categoryId) filter.where.categoryId = article.categoryId;
                else if (article.tagId) filter.where.tagId = article.tagId;

            }

            filter.where.id = { neq: article.id };

        }

    }
    else if (set === "LeagueSeason") {

        heading = "News";

        filter.where.seasonId = seasonId;

    }
    else if (set === "LeagueTeam") {

        heading = "News";

        filter.where.teamId = teamId;

    }
    else if (set === "LeaguePlayer") {

        heading = "News";

        filter.where.playerId = playerId;

    }

    useEffect(() => {

        if (!search) list.run({ params: { filter } });

    }, []);

    return useMemo(() => (

        <Panel
            id={props.id ? props.id : "news"}
            heading={heading}
            hideTop={!heading}
            edge={"sides"}
        >

            {list.data &&
                <Grid
                    columns={5}
                    gap={4}
                >

                    {list.data.map((item: any, index: number) => (

                        <UiView
                            className="item"
                            key={index}
                        >

                            <NewsCard
                                id={item.id}
                                slug={item.slug}
                                title={item.title}
                                content={item.description}
                                category={item.category}
                                time={item.date}
                                image={item.thumbnail || item.gallery[0]}
                                sport={item.sport}
                            />

                        </UiView>

                    ))}


                </Grid>
            }

            {search &&
                data &&
                data.data &&
                <Grid
                    columns={5}
                    gap={4}
                >

                    {data.data.map((item: any, index: number) => (

                        <UiView
                            className="item"
                            key={index}
                        >

                            <NewsCard
                                id={item.id}
                                slug={item.slug}
                                title={item.title}
                                content={item.description}
                                category={item.category}
                                time={item.date}
                                image={item.thumbnail || item.gallery[0]}
                                sport={item.sport}
                            />

                        </UiView>

                    ))}

                </Grid>
            }

            {!search &&
                <NotFound
                    loading={list.loading}
                    data={list.data}
                    message="No News Found"
                />
            }

            {search &&
                data &&
                <NotFound
                    loading={data.loading}
                    data={data.data}
                    message="No News Found"
                />
            }

        </Panel>

    ), [list.loading, list.data]);
};

interface NewsPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default NewsPanel;