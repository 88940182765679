import React from "react"


function VideoIcon(props: any) {
    return (
        <svg
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >

            <circle
                cx="11.625"
                cy="11.5"
                r="11.5"
                fill="currentColor"
            />

            <path
                d="M16.4878 10.3412V7.63719C16.4878 7.21228 16.1402 6.86462 15.7153 6.86462H6.44444C6.01953 6.86462 5.67188 7.21228 5.67188 7.63719V15.3629C5.67188 15.7878 6.01953 16.1355 6.44444 16.1355H15.7153C16.1402 16.1355 16.4878 15.7878 16.4878 15.3629V12.6589L18.257 14.4281C18.7438 14.9148 19.5781 14.5671 19.5781 13.8796V9.1128C19.5781 8.42521 18.7438 8.07756 18.257 8.56428L16.4878 10.3412Z"
                fill="white"
            />
        </svg>

    )
}

export default VideoIcon