import React, { useMemo, useCallback } from "react";
import { useForm } from "@dex/bubl-ui";

import styles from "./CampSessionJoin.module.scss";

import Divider from "../../elements/Divider/Divider";
import CampAthletesApi from "../../apis/CampAthletesApi";
import CampAthleteForm from "../CampAthleteForm/CampAthleteForm";

const CampSessionJoinPopupAthlete: React.FC<CampSessionJoinPopupAthleteProps> = (props) => {

    let { handleRefresh } = props;

    const form = useForm();

    const addAthlete = CampAthletesApi.addAthlete();

    const onSubmit = useCallback((values, option) => {

        addAthlete.run({
            data: values,
            onComplete: (response: any) => {

                if (handleRefresh) handleRefresh();

            }
        });

    }, [addAthlete]);

    return useMemo(() => (

        <>

            <Divider
                title={"Add An Athlete"}
            />

            <CampAthleteForm
                onSubmit={onSubmit}
                loading={addAthlete.loading}
            />

        </>

    ), [form.values, form.errors, addAthlete.loading]);

};

interface CampSessionJoinPopupAthleteProps {
    [key: string]: any;
}

export default CampSessionJoinPopupAthlete;
