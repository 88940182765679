import React from "react";
import { UiImage, UiLink, UiText, UiView } from "@dex/bubl-ui";

import styles from "./AnnouncementCard.module.scss";

import { IoIosArrowForward } from "react-icons/io";

const AnnouncementCard: React.FC<AnnouncementCardProps> = (props) => {

    const { id, title, logo, description } = props

    return (

        <UiLink
            className={styles.container}
            path={`/announcement/${id}`}
        >

            <UiView className={styles.logoContainer}>

                {logo &&
                    <UiImage
                        className={styles.logo}
                        type={'thumb'}
                        filters={{ width: 100, height: 100 }}
                        resizeMode="contain"
                        src={logo}
                    />
                }

            </UiView>


            <UiView className={styles.contentContainer}>

                <UiView className={styles.contentContainerInner}>

                    <UiText className={styles.heading}>{title}</UiText>

                    <UiText className={styles.description}>{description}</UiText>

                </UiView>

            </UiView>

            <UiView className={styles.iconContainer}>

                <IoIosArrowForward className={styles.icon} />

            </UiView>


        </UiLink>

    );
};

interface AnnouncementCardProps {
    [key: string]: any;
}

export default AnnouncementCard;
