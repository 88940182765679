import React, { useMemo } from "react";
import { UiText, UiView, useAsyncEffect } from "@dex/bubl-ui";
import { get, slug } from "@dex/bubl-helpers";
import { config } from "@dex/bubl-web";

import styles from "./LeagueGameScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import GameLogApi from "../../apis/GameLogApi";
import LeagueSeasonApi from "../../apis/LeagueSeasonApi";
import GameLogsPlayers from "../../groups/GameLogsPlayers/GameLogsPlayers";
import ResultCard from "../../elements/ResultCard/ResultCard";
import SubMenu from "../../elements/SubMenu/SubMenu";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";
import LeagueSeasonGameApi from "../../apis/LeagueSeasonGameApi";
import VideoBlock from "../../elements/VideoBlock/VideoBlock";
import NewsArticlePhotos from "../../elements/NewsArticle/NewsArticlePhotos";
import ResultDisclaimer from "../../elements/ResultDisclaimer/ResultDisclaimer";

const LeagueGameScreen: React.FC<LeagueGameScreenProps> = (props) => {

    const gameId = get(props, 'screenProps.routeParams.gameId');
    const seasonId = get(props, 'screenProps.routeParams.seasonId');

    const game = LeagueSeasonGameApi.getGameLog(gameId);
    const season = LeagueSeasonApi.getSeason(seasonId);

    useAsyncEffect((isMounted) => {

        season.run({
            onEnd: (error: any, _season: any) => {

                if (!isMounted()) return;

                game.run({
                    onComplete: (response: any) => {

                        config.setMeta({
                            title: response?.title + ' - ' + _season?.title,
                        });

                    }
                });

            }
        });

    }, []);

    return useMemo(() => (

        <UiView>


            {game.data &&

                <>

                    <Header />

                    <SubMenu
                        menu={[
                            { label: season?.data?.title, path: `/leagues/season/${season.data.id}/${slug(season?.data?.title)}` },
                        ]}
                    />

                    <UiView className={styles.wrap}>

                        <Space height={4} />

                        <Container
                            width={"thin"}
                        >
                            <UiView className={styles.titleContainer}>

                                <UiText
                                    className={styles.title}
                                    tag="h1"
                                >
                                    {game.data?.title}
                                </UiText>

                            </UiView>

                            <Space height={2} />

                            <ResultCard
                                results={game.data}
                                alt={true}
                                path={"team"}
                                seasonId={season.data.id}
                                slug={slug(season.data.title)}
                            />

                            <ResultDisclaimer />

                            <Space height={2} />

                            {game.data.video &&
                                <VideoBlock
                                    id={game.data.video?.id}
                                    source={game.data.video?.source}
                                    autoplay={false}
                                />
                            }

                        </Container>

                        <Space height={4} />

                        {game.data?.gallery &&
                            <>

                                <NewsArticlePhotos
                                    article={{
                                        gallery: game.data?.gallery,
                                    }}

                                />

                                <Space height={4} />

                            </>
                        }


                        <Container>

                            {game.data.hasGameData &&
                                <GameLogsPlayers
                                    id="gameLogs"
                                    heading="Box Scores"
                                    columns={game.data.boxScoreColumns}
                                    teams={game.data.teams}
                                    season={season.data}
                                />
                            }

                            <Space />

                            <GoogleAd
                                source="ad-butler"
                                slot="opp-bottom"
                            />

                            <Space />

                        </Container>

                        <Footer />

                    </UiView>

                </>

            }

        </UiView>

    ), [game]);
};

interface LeagueGameScreenProps {
    [key: string]: any;
}

export default LeagueGameScreen;
