import React from "react";

function SvgComponent(props: any) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={16} cy={16} r={16} fill="#FF4E39" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.442 8.592l-2.898 2.301a.965.965 0 01-1.32-.116c-.34-.378-.284-.944.112-1.268l2.907-2.301a.965.965 0 011.321.117c.34.377.284.944-.122 1.267zm13.222.917l-2.907-2.301a.977.977 0 00-1.33.117.878.878 0 00.122 1.267l2.897 2.301a.978.978 0 001.33-.116c.34-.378.284-.944-.112-1.268zm-5.012 9.205l-3.18-1.798v-4.072c0-.36-.302-.647-.68-.647h-.056c-.378 0-.68.288-.68.647v4.243c0 .314.17.611.463.773l3.444 1.968c.321.18.736.09.925-.215a.63.63 0 00-.236-.9zM16 8.6c-4.69 0-8.494 3.623-8.494 8.09 0 4.468 3.803 8.09 8.494 8.09 4.69 0 8.494-3.622 8.494-8.09 0-4.467-3.803-8.09-8.494-8.09zm-6.606 8.09c0 3.47 2.963 6.293 6.606 6.293s6.606-2.823 6.606-6.293S19.643 10.4 16 10.4s-6.606 2.823-6.606 6.292z"
                fill="#fff"
            />
        </svg>
    )
}

export default SvgComponent
