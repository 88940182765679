import { apiGet, apiPost, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Comments';

const CommentsApi = {

    reviews: (parentId: string, parentType: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/list",
            params: { parentId, parentType },
            ...options
        })

    },

    addNew: (parentId: string, parentType: string, type: string | undefined, replyToId?: string, options: options = {}): response => {
        return apiPost({
            url: endpoint + "/public/addNew",
            params: {
                parentId: parentId,
                parentType: parentType,
                type: type,
                replyToId: replyToId,
            },
            ...options
        })
    }

}

export default CommentsApi;