import React, { useEffect, useMemo } from "react";

import styles from "./Announcements.module.scss";

import AnnouncementCard from '../../elements/AnnouncementCard/AnnouncementCard';
import AnnouncementsApi from "../../apis/AnnouncementsApi";
import Panel from "../../elements/Panel/Panel";

const Announcements: React.FC<AnnouncementsProps> = (props) => {

    const { hostId } = props;

    const announcements = AnnouncementsApi.list(hostId);

    useEffect(() => {

        announcements.run();

    }, [props]);

    return useMemo(() => (

        <Panel
            id={props.id}
            heading={"Announcements"}
        >

            {announcements.data &&

                <>
                    {announcements.data.map((data: any, key: string) => (
                        <AnnouncementCard
                            key={key}
                            id={data.id}
                            logo={data.host ? data.host.logo : null}
                            title={data.subject}
                            description={data.message}
                        />
                    ))}
                </>

            }

        </Panel>


    ), [announcements.data]);

};

interface AnnouncementsProps {
    [key: string]: any;
}

export default Announcements;
