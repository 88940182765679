import React, { useEffect, useMemo } from 'react';
import { UiText, UiView } from '@dex/bubl-ui';

import styles from './ActivePasses.module.scss';

import MyActivePass from '../../elements/MyActivePass/MyActivePass';
import UsersApi from '../../apis/UsersApi';
import Space from '../../elements/Space/Space';

const ActivePasses: React.FC<ActivePassesProps> = (props) => {

    const myPasses = UsersApi.myPasses();

    useEffect(() => {

        myPasses.run();

    }, []);

    return useMemo(() => (
        <UiView>

            {myPasses && myPasses.data && myPasses.data.camp.length > 0 &&
                <>
                    <UiText className={styles.heading}>Active Camp Passes</UiText>

                    {myPasses.data.camp.map((item: any, key: any) => (

                        <MyActivePass
                            {...key}
                            passTitle={item.title}
                            sessionsLeft={item.sessionsLeft}
                            daysLeft={item.daysLeft}
                            sessions={item.sessions}
                            image={item.logo}
                        />

                    ))}
                </>
            }

            <Space height={3} />

            {myPasses && myPasses.data && myPasses.data.pickup.length > 0 &&
                <>
                    <UiText className={styles.heading}>Active Pickup Passes</UiText>

                    {myPasses.data.pickup.map((item: any, key: any) => (

                        <MyActivePass
                            {...key}
                            passTitle={item.title}
                            sessionsLeft={item.sessionsLeft}
                            daysLeft={item.daysLeft}
                            sessions={item.sessions}
                            passType={item.passType}
                            image={item.logo}
                        />

                    ))}
                </>
            }

        </UiView>
    ), [myPasses]);
}

interface ActivePassesProps {
    [key: string]: any;
}

export default ActivePasses;