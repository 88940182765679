import React, { useCallback, useEffect, useMemo } from "react";
import { UiView, UiText, UiForm, useForm, UiLink } from "@dex/bubl-ui";

import styles from "./OrderTeamForm.module.scss";

import Divider from "../Divider/Divider";
import Space from "../Space/Space";
import FormApi from "../../apis/FormApi";
import Button from "../Button/Button";
import FieldText from "../Fields/FieldText/FieldText";
import FieldSelect from "../Fields/FieldSelect/FieldSelect";
import FieldTextArea from "../Fields/FieldText/FieldTextArea";
import FieldNumber from "../Fields/FieldNumber/FieldNumber";
import FieldDate from "../Fields/FieldDate/FieldDate";
import FieldSwitch from "../Fields/FieldSwitch/FieldSwitch";
import UsersApi from "../../apis/UsersApi";
import OrderApi from "../../apis/OrderApi";
import MemberCard from "../MemberCard/MemberCard";
import Grid from "../Grid/Grid";

const OrderTeamForm: React.FC<OrderTeamFormProps> = (props) => {

    const { order, afterSubmit } = props;

    const form = useForm({});

    const myTeams = UsersApi.myTeams({ params: { manager: true } });//, excludeSeasonId: order.seasonId

    const createTeam = OrderApi.createTeam(order.id);

    const selectTeam = OrderApi.selectTeam(order.id);

    const handleSubmitNewTeam = useCallback((values) => {

        //afterSubmit();
        createTeam.run({
            data: values,
            onComplete: (response) => {
                afterSubmit();
            }
        });

    }, []);

    const handleSelectTeam = useCallback((teamId) => {

        selectTeam.run({
            data: { teamId: teamId },
            onComplete: (response) => {
                afterSubmit();
            }
        });

    }, []);

    useEffect(() => {

        myTeams.run();

    }, []);

    return useMemo(() => (

        <UiView className={styles.container}>

            {(myTeams.data && myTeams.data.length > 0) &&
                <>
                    <Divider
                        title={"My Teams"}
                    />

                    <UiView
                        className={styles.teamsGrid}
                    >

                        {myTeams.data.map((team: any, key: any) => (

                            <UiView key={key} className={styles.gridItem}>

                                <MemberCard
                                    size={"mini"}
                                    onPress={handleSelectTeam.bind(null, team.id)}
                                    name={team.name}
                                    image={{
                                        type: "thumb",
                                        src: team.logo || { fileId: null }
                                    }}
                                />

                            </UiView>

                        ))}

                    </UiView>

                </>
            }

            <Divider
                title={"Create A New Team"}
            />

            <UiForm
                form={form}
                onSubmit={handleSubmitNewTeam}
                className={"p-b-3 p-t-3 p-l-3 p-r-3"}
            >

                <FieldText
                    form={form}
                    label={"Team Name"}
                    name={"name"}
                    required={true}
                    defaultValue={form.defaults.name}
                />

                <Space height={1} />

                <Button
                    label={"Conitnue"}
                    type={"submit"}
                    block={true}
                // loading={}
                />

            </UiForm>

        </UiView>

    ), [order, myTeams]);

};


interface OrderTeamFormProps {
    [key: string]: any;
}

export default OrderTeamForm;
