import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./Container.module.scss";

const Container: React.FC<ContainerProps> = (props) => {

    const def = {
        width: 'normal',
        padding: 0,
    }

    const widths:any = {
        "normal": "container",
        "narrow": "container-fluid w-1100",
        "thin": "container-fluid w-900",
        "wide": "container-fluid w-1600",
        "full": "container-fluid",
    }

    const width = props.width || def.width;
    const padding = typeof props.padding === "number" ? props.padding : def.padding;

    const classes: any = [widths[width]];

    if (padding) {
        classes.push('p-lr-' + padding);
    }

    classes.push(props.className);

    const rest = { ...props };

    delete (rest.className);
    delete (rest.width);
    delete (rest.padding);

    return (

        <UiView
            {...rest}
            className={classes}
        >

            {props.children}

        </UiView>

    );
};

interface ContainerProps {
    width?: "normal" | "full" | "wide" | "narrow" | "thin",
    padding?: number,
    [key: string]: any;
}

export default Container;
