import React, { useEffect } from "react";
import { config } from "@dex/bubl-web";
import { get } from "@dex/bubl-helpers";

import styles from "./CheckoutScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Space from "../../elements/Space/Space";
import OrderSummary from "../../elements/OrderSummary/OrderSummary";
import OrderPayment from "../../elements/OrderPayment/OrderPayment";

import OrderApi from "../../apis/OrderApi";

const CheckoutScreen: React.FC<CheckoutScreenProps> = (props) => {

    const orderId = get(props, 'screenProps.routeParams.orderId');

    const order = OrderApi.getOne(orderId);

    useEffect(() => {

        order.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.id,
                });

            }
        });

    }, []);

    return (

        <>

            <Header />

            <SubMenu />

            <Container width={"thin"}>

                {order.data &&
                    <>

                        <Space />

                        <OrderSummary order={order.data} />

                        {(order.data.status === "cart" || order.data.status === "due") &&

                            <>

                                <OrderPayment order={order.data} />

                            </>

                        }

                    </>
                }

            </Container>

            <Footer />

        </>

    );
};

interface CheckoutScreenProps {
    [key: string]: any;
}

export default CheckoutScreen;
