import React from "react";
import { UiFieldDate } from "@dex/bubl-ui";
import { MdDateRange } from 'react-icons/md';

import styles from "./DropDate.module.scss";

const DropDate: React.FC<DropDateProps> = (props) => {

    return (

        <UiFieldDate
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            {...props}
            indicator={<MdDateRange className={styles.indicator} />}
            isClearable={false}
            classes={{
                wrap: [styles.wrap, props.showMonthYearPicker === false ? styles.datePickerWrap : ''],
                label: [styles.label],
                input: [styles.input],
                error: [styles.error],
            }}
        />

    );
};

interface DropDateProps {
    [key: string]: any;
}

export default DropDate;
