import { Events, UiForm, UiLink, UiText, UiView, useForm } from '@dex/bubl-ui';
import React, { useCallback, useState } from 'react'
import { IoMdClose } from 'react-icons/io';
import LeagueSeasonTeamApi from '../../apis/LeagueSeasonTeamApi';
import Button from '../../elements/Button/Button';
import Divider from '../../elements/Divider/Divider';
import FieldText from '../../elements/Fields/FieldText/FieldText';
import Modal from '../../elements/Modal/Modal';
import Space from '../../elements/Space/Space';
import PlayerProfileFields from '../PlayerProfileFields/PlayerProfileFields';

import styles from './LeagueTeamRegisterPlayerJoin.module.scss'

const LeagueTeamRegisterPlayerJoin: React.FC<LeagueTeamRegisterPlayerJoinProps> = (props) => {

    const { team, handleRefresh, shareCode } = props;

    const [show, setShow] = useState(shareCode ? true : false);

    const form = useForm({ ...team.player, code: shareCode });

    const join = LeagueSeasonTeamApi.joinTeam(team.id);

    const handleSubmit = useCallback((values) => {

        join.run({
            data: values,
            onComplete: (res) => {

                Events.emit("ui", {
                    action: "success",
                    timeout: 3000,
                    message: "Changes Saved"
                });

                handleRefresh();

            }
        });

    }, [form.values])

    return (
        <>

            <Button
                label={"Join This Roster"}
                size={"large"}
                block={true}
                onClick={setShow.bind(null, true)}
                theme={"success"}
                className={styles.button}
            />

            <Space height={2} />

            <Modal
                show={show}
                onClose={setShow.bind(null, false)}
            >

                {show &&
                    <UiView className={styles.popup}>

                        <UiLink
                            onPress={setShow.bind(null, false)}
                            className={styles.close}
                        >

                            <IoMdClose />

                        </UiLink>

                        <Divider
                            title={"Complete Your Player Profile"}
                        />

                        <UiView className={styles.body}>

                            <UiText className={styles.text}>Kindly fill in additional details about yourself.</UiText>

                            <Space />

                            <UiForm
                                form={form}
                                onSubmit={handleSubmit}
                                loading={join.loading}
                            >

                                <PlayerProfileFields
                                    form={form}
                                />

                                {!team.isManager &&
                                    <>

                                        <FieldText
                                            form={form}
                                            name={"code"}
                                            label={"Joining Code"}
                                            placeholder={"Enter the joining code"}
                                            defaultValue={form.defaults.code}
                                            required={true}
                                        />

                                        <Space />

                                    </>
                                }

                                <Button
                                    label={"Join Roster"}
                                    block={true}
                                    onClick={form.triggerSubmit}
                                    loading={join.loading}
                                />

                            </UiForm>

                        </UiView>

                    </UiView>

                }

            </Modal>

        </>

    )

}

interface LeagueTeamRegisterPlayerJoinProps {
    [key: string]: any;
}

export default LeagueTeamRegisterPlayerJoin