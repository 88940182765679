import React, { useCallback, useState } from "react";
import { UiView, UiLink, UiText, UiImage } from "@dex/bubl-ui";
import { IoIosArrowForward } from 'react-icons/io';
import { BeatLoader } from "react-spinners";

import styles from "./OrderPayment.module.scss";
import Divider from "../Divider/Divider";
import PaymentApi from "../../apis/PaymentApi";
import Space from "../Space/Space";

const OrderPayment: React.FC<OrderPaymentProps> = (props) => {

    const { order } = props;

    const pay = PaymentApi.pay({ timeout: 5000 });

    const [redirecting, setRedirecting] = useState(false);

    const handlePay = useCallback((gateway) => {

        pay.run({
            data: {
                gateway: gateway,
                orderId: order.id,
                redirectUrl: `${window.location.origin}/order-return/${order.id}`
            },
            onComplete: (response: any) => {

                setRedirecting(true);

                window.location.href = response.url;

            }
        })

    }, [order]);

    return (

        <UiView className={styles.container}>

            <Divider
                title={`Payment Methods`}
            />

            {(pay.loading || redirecting) &&

                <UiView className={styles.methods}>

                    <UiView className={styles.indicator}><BeatLoader size={20} /></UiView>

                </UiView>

            }

            {!pay.loading && !redirecting &&
                <UiView className={styles.methods}>

                    <UiLink
                        className={styles.method}
                        onClick={handlePay.bind(null, 'billplz')}
                    >

                        <UiImage
                            className={styles.icon}
                            resizeMode={'contain'}
                            src={require('../../assets/images/fpx logo.PNG')}
                            type={"local"} />

                        <UiView className={styles.text}>

                            <UiView>

                                <UiText className={styles.label}>Online Banking</UiText>

                                <UiText className={styles.desc}>FPX</UiText>

                            </UiView>

                            <IoIosArrowForward className={styles.arrow} />

                        </UiView>

                    </UiLink>

                    {process.env.REACT_APP_ENV === "local" &&
                        <>
                            <Space />
                            <UiLink
                                className={styles.method}
                                onClick={handlePay.bind(null, 'testing')}
                            >

                                <UiView className={styles.text}>

                                    <UiView>

                                        <UiText className={styles.label}>Test Payment</UiText>

                                        <UiText className={styles.desc}>Click to pay</UiText>

                                    </UiView>

                                    <IoIosArrowForward className={styles.arrow} />

                                </UiView>

                            </UiLink>
                        </>
                    }

                </UiView>
            }

        </UiView>

    );

};

interface OrderPaymentProps {
    [key: string]: any;
}

export default OrderPayment;
