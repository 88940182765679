import React, { useCallback, useEffect, useState } from 'react';
import { UiLink, UiText, UiView } from '@dex/bubl-ui';
import { IoMdClose } from 'react-icons/io';
import moment from 'moment';

import Modal from '../../elements/Modal/Modal';
import Divider from '../../elements/Divider/Divider';

import styles from './BlockBookingModel.module.scss';
import VenueCourtApi from '../../apis/VenueCourtApi';
import BlockBookingModelSelectDate from './BlockBookingModelSelectDate';
import BlockBookingModelCheck from './BlockBookingModelCheck';
import { info } from 'console';
import OrderApi from '../../apis/OrderApi';
import PassesPopupBuy from '../PassesPopupBuy/PassesPopupBuy';

const BlockBookingModel: React.FC<BlockBookingModelProps> = (props) => {

    const { handleClose, courtId } = props;

    const [timeSlots, setTimeSlots] = useState([]);

    const [check, setCheck] = useState(false);

    const [date, setDate] = useState(props.date || moment());

    const [number, setNumber] = useState(0);

    const [order, setOrder]: any = useState(null);

    const block = VenueCourtApi.blockSessionList(courtId);

    useEffect(() => {

        block.reset();

        setTimeSlots([]);

        block.run({
            params: { date: date.startOf("day").format("YYYY-MM-DD") }
        });

    }, [date]);

    const handleRefresh = useCallback(() => {

        setOrder(null);
        
    }, [order, date]);

    return (
        <>

            {order &&
                <PassesPopupBuy
                    order={order}
                    handleRefresh={handleRefresh}
                />
            }


            {!order &&
                <Modal
                    show={true}
                    onClose={handleClose}
                >

                    <UiView className={styles.popup}>

                        <UiLink
                            onPress={handleClose}
                            className={styles.close}
                        >

                            <IoMdClose />

                        </UiLink>

                        <Divider
                            title="Block Booking"
                        />

                        {!check && block.data && block.data.info &&
                            <>

                                <BlockBookingModelSelectDate
                                    date={date}
                                    setDate={setDate}
                                    info={block.data.info}
                                    slots={block.data.slots}
                                    timeSlots={timeSlots}
                                    setTimeSlots={setTimeSlots}
                                    setCheck={setCheck}
                                    number={number}
                                    setNumber={setNumber}
                                />

                            </>
                        }

                        {check && block.data && block.data.info &&
                            <BlockBookingModelCheck
                                date={date}
                                number={number}
                                info={block.data.info}
                                courtId={courtId}
                                slot={timeSlots[0]}
                                setCheck={setCheck}
                                setOrder={setOrder}
                            />
                        }

                    </UiView>

                </Modal>
            }

        </>
    )
}

interface BlockBookingModelProps {
    [key: string]: any
}

export default BlockBookingModel;