import React from 'react'

function QuickLinksEventIcon(props: any) {
    return (
        <svg
            width="37"
            height="38"
            viewBox="0 0 37 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="event_24px">

                <path
                    id="icon/action/event_24px"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24.6667 5.12501V6.66668H12.3333V5.12501C12.3333 4.27709 11.6396 3.58334 10.7917 3.58334C9.94375 3.58334 9.25 4.27709 9.25 5.12501V6.66668H7.70833C5.99708 6.66668 4.64042 8.05418 4.64042 9.75001L4.625 31.3333C4.625 33.0292 5.99708 34.4167 7.70833 34.4167H29.2917C30.9875 34.4167 32.375 33.0292 32.375 31.3333V9.75001C32.375 8.05418 30.9875 6.66668 29.2917 6.66668H27.75V5.12501C27.75 4.27709 27.0562 3.58334 26.2083 3.58334C25.3604 3.58334 24.6667 4.27709 24.6667 5.12501ZM24.6667 20.5417H20.0417C19.1938 20.5417 18.5 21.2354 18.5 22.0833V26.7083C18.5 27.5563 19.1938 28.25 20.0417 28.25H24.6667C25.5146 28.25 26.2083 27.5563 26.2083 26.7083V22.0833C26.2083 21.2354 25.5146 20.5417 24.6667 20.5417ZM9.25 31.3333H27.75C28.5979 31.3333 29.2917 30.6396 29.2917 29.7917V14.375H7.70833V29.7917C7.70833 30.6396 8.40208 31.3333 9.25 31.3333Z"
                    fill="white"
                />

            </g>

        </svg>

    )
}

export default QuickLinksEventIcon
