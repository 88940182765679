import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'VenueCourt';

const VenueCourtApi = {

    cards: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/cards",
            params: {},
            ...options
        })

    },

    search: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/search",
            params: {},
            ...options
        })

    },

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            params: {},
            ...options
        });

    },

    blockSessionList: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/blockSessionList",
            params: {},
            ...options
        })

    },

    checkBlockAvailability: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/checkBlockAvailability",
            params: {},
            ...options
        })

    },

}

export default VenueCourtApi;