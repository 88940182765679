import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./AboutPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import ContentBlock from "../../elements/ContentBlocks/ContentBlocks";
import TextBlock from "../../elements/TextBlock/TextBlock";
import PhotoGrid from "../../elements/PhotoGrid/PhotoGrid";
import Space from "../../elements/Space/Space";

const GroupCardPanel: React.FC<GroupCardPanelProps> = (props) => {

    let { set, content, gallery, title, headSelect } = props;

    return (

        <Panel
            id="about"
            heading={"About"}
            headSelect={headSelect}
        >

            {content &&
                <UiView className={styles.contentContainer}>

                    {Array.isArray(content) &&

                        <ContentBlock
                            about={true}
                            blocks={content}
                        />

                    }

                    {!Array.isArray(content) &&

                        <TextBlock
                            content={content}
                        />

                    }

                </UiView>
            }

            {(content && gallery && gallery.length > 0) &&

                <Space height={2} />

            }

            {(gallery && gallery.length > 0) &&

                <PhotoGrid
                    gallery={gallery}
                    columns={5}
                />

            }

        </Panel>

    );
};

interface GroupCardPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default GroupCardPanel;
