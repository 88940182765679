import React, { useCallback, useState } from "react";
import { UiImage, UiLink, UiText, UiView} from "@dex/bubl-ui";
import { IoMdClose } from "react-icons/io";
import { titleCase } from "@dex/bubl-helpers";

import styles from "./Athletes.module.scss";

import CampAthletesApi from "../../apis/CampAthletesApi";
import Modal from "../Modal/Modal";
import Divider from "../Divider/Divider";
import CampAthleteForm from "../../groups/CampAthleteForm/CampAthleteForm";
import CampRemoveAthlete from "../../groups/CampRemoveAthlete/CampRemoveAthlete";

const Athletes: React.FC<AthletesProps> = (props) => {

    const { athlete, handleRefresh } = props;

    const [popup, setPopup] = useState<any>(null);

    const update = CampAthletesApi.updateAthlete(athlete.id);

    const remove = CampAthletesApi.removeAthlete(athlete.id);

    const handlePopup = useCallback((name) => {

        setPopup(name);

    }, []);

    const handleClosePopup = useCallback(() => {

        setPopup(null);
        handleRefresh();

    }, []);

    const handleUpdate = useCallback((values) => {

        update.run({
            data: values,
        });

    }, []);

    const handleRemove = useCallback((values) => {

        remove.run({
            onComplete: () => {

                setPopup(null);
                handleRefresh();

            }
        });

    }, []);

    return (

        <>

            <UiView className={styles.container}>

                <UiView className={styles.imageContainer}>

                    <UiImage
                        className={styles.image}
                        src={athlete.image || { fileId: "" }}
                        type="thumb"
                        filters={{ width: 60, height: 60 }}
                    />

                </UiView>

                <UiView className={styles.content}>

                    <UiView className={styles.contentContainer}>

                        <UiText className={styles.headingText}>NAME</UiText>
                        <UiText className={styles.subHeadingText}>{athlete.name || "-"}</UiText>

                    </UiView>

                    <UiView className={styles.contentContainer}>

                        <UiText className={styles.headingText}>GENDER</UiText>
                        <UiText className={styles.subHeadingText}>{titleCase(athlete.gender) || "-"}</UiText>

                    </UiView>

                    <UiView className={styles.contentContainer}>

                        <UiText className={styles.headingText}>DATE OF BIRTH</UiText>
                        <UiText className={styles.subHeadingText}>{athlete.dob || "-"}</UiText>

                    </UiView>

                </UiView>

                <UiView className={styles.iconContainer}>

                    <UiView>

                        <UiLink
                            className={styles.editIcon}
                            ripple={true}
                            path={`/assessments/${athlete.id}`}
                        >

                            <UiImage
                                className={styles.icon}
                                src={require('../../assets/images/athelete-view.png')}
                                type="local"
                            />

                        </UiLink>

                        <UiLink
                            className={styles.editIcon}
                            ripple={true}
                            onPress={handlePopup.bind(null, 'edit')}
                        >

                            <UiImage
                                className={styles.icon}
                                src={require('../../assets/images/edit.png')}
                                type="local"
                            />

                        </UiLink>

                        <UiLink
                            className={styles.deleteIcon}
                            ripple={true}
                            onPress={handlePopup.bind(null, 'remove')}
                        >

                            <UiImage
                                className={styles.icon}
                                src={require('../../assets/images/delete.png')}
                                type="local"
                            />

                        </UiLink>

                    </UiView>

                </UiView>

            </UiView>

            <Modal
                show={popup}
                onClose={handleClosePopup}
            >
                {popup &&

                    <UiView className={styles.popup}>

                        <UiLink
                            onPress={handleClosePopup}
                            className={styles.close}
                        >

                            <IoMdClose />

                        </UiLink>

                        {popup === "edit" &&
                            <>

                                <Divider
                                    title={"Edit Athlete"}
                                />

                                <CampAthleteForm
                                    onSubmit={handleUpdate}
                                    loading={update.loading}
                                    athlete={athlete}
                                    handleRefresh={handleRefresh}
                                />

                            </>
                        }

                        {popup === "remove" &&

                            <>

                                <Divider
                                    title={"Remove Athlete"}
                                />

                                <CampRemoveAthlete
                                    onSubmit={handleRemove}
                                    loading={remove.loading}
                                    athlete={athlete}
                                />

                            </>

                        }

                    </UiView>

                }

            </Modal>

        </>

    );
};

interface AthletesProps {
    [key: string]: any;
}

export default Athletes;
