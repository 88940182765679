import React, { useEffect, useMemo } from "react";
import { UiLink, UiView } from "@dex/bubl-ui";

import styles from "./WatchPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import WatchCard from "../../elements/WatchCard/WatchCard";
import Grid from "../../elements/Grid/Grid";
import NotFound from "../../elements/NotFound/NotFound";

import WatchApi from "../../apis/WatchApi";

const WatchPanel: React.FC<WatchPanelProps> = (props) => {

    let { id, heading, filter, more, path = {}, data, loading, type } = props;

    const [cards, setCards] = React.useState([]);

    const list = WatchApi.list();

    useEffect(() => {

        if (data) setCards(data.cards);
        if (type === 'discover') return;

        list.run({
            params: {
                filter: {
                    limit: 6,
                    ...filter,
                },
                includeInfo: false,
                includeCount: false,
            },
            onComplete: (data) => {
                setCards(data.videos);
            }
        });


    }, [props]);

    return useMemo(() => (

        <Panel
            id={id}
            heading={heading || "Huddle+"}
            edge={"sides"}
            moreText={"View More"}
            moreLink={more ? path : null}
        >

            <Grid
                columns={5}
                gap={4}
            >

                {cards && cards.length > 0 &&
                    <>
                        {(cards || []).map((video: any, index: number) => (

                            <UiView
                                className="item"
                                key={index}
                            >

                                <WatchCard
                                    video={video}
                                />

                            </UiView>

                        ))}
                    </>
                }

            </Grid>

            <NotFound
                loading={list.loading}
                data={cards ? cards : []}
                message="No Videos Found"
            />

        </Panel>

    ), [cards, list.loading]);

};

interface WatchPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default WatchPanel;
