import React from "react";
import { UiImage, UiText, UiView } from "@dex/bubl-ui";
import { IoIosArrowForward } from 'react-icons/io';

import styles from "./UserActivityCard.module.scss";

import LikeImage from '../../assets/images/thumbs-up.png';
import CommentImage from "../../assets/images/chat.png";

const UserActivityCard: React.FC<UserActivityCardProps> = (props) => {

    return (

        <UiView className={styles.card}>

            <UiImage
                className={styles.image}
                type={'local'}
                src={props.type === "like" ? LikeImage : props.type === "comment" ? CommentImage : null}
            />

            <UiView className={styles.textWrapper}>

                <UiText className={styles.title}>
                    {props.title}
                </UiText>

                <UiText className={styles.time}>
                    {props.time}
                </UiText>

            </UiView>

            <IoIosArrowForward className={styles.icon} />

        </UiView>

    );
};

interface UserActivityCardProps {
    [key: string]: any;
}

export default UserActivityCard;
