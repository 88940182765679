import React from 'react';
import { UiText, UiView } from '@dex/bubl-ui';

import styles from "./AssesmentScoreSlider.module.scss";

const AssesmentScoreSlider: React.FC<AssesmentScoreSliderProps> = (props) => {

    const { score, showLabels } = props;

    let barWidth = 0;

    if (score >= 4) barWidth += 12.5 + 25 + 25 + 25;
    else if (score >= 3) barWidth += 12.5 + 25 + 25 + ((score - 3) * 25);
    else if (score >= 2) barWidth += 12.5 + 25 + ((score - 2) * 25);
    else if (score >= 1) barWidth += 12.5 + ((score - 1) * 25);
    else if (score > 0) barWidth += score * 12.5;

    return (

        <UiView className={styles.wrap}>

            <UiView className={styles.items}>

                {[1, 2, 3, 4].map((n, key) => (

                    <AssesmentScoreSliderItem
                        key={key}
                        current={n}
                        score={score}
                        showLabels={showLabels}
                    />

                ))}

            </UiView>

            <UiView className={styles.bar}>

                <UiView className={styles.progress} style={{ width: barWidth + "%" }}>

                </UiView>

            </UiView>

            <UiView className={styles.scoreWrap} style={{ width: barWidth + "%" }}>

                <UiText className={styles.score}>
                    {score}
                </UiText>

            </UiView>


        </UiView>

    )

}


const AssesmentScoreSliderItem: React.FC<any> = (props) => {

    const { current, score, showLabels } = props;

    const labels: any = {
        1: "Beginner\nLevel",
        2: "Approaching\nStandard",
        3: "Standard\nAchieved",
        4: "Exceed\nStandard"
    }

    const passed = score >= current;

    return (

        <UiView className={[styles.item, passed ? styles.passed : styles.null]}>

            <UiText className={styles.number} tag="span">
                {current}.0
            </UiText>

            <UiView className={[styles.dot]}/>

            {showLabels &&
                <UiText className={styles.label} tag="span">
                    {labels[current]}
                </UiText>
            }

        </UiView>

    )

}

interface AssesmentScoreSliderProps {
    [key: string]: any,
}

export default AssesmentScoreSlider;