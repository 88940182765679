import React, { useCallback, useEffect, useState } from "react";
import { UiView, UiText, UiImage, UiLink } from "@dex/bubl-ui";
import { IoMdArrowBack } from "react-icons/io";
import { get } from "@dex/bubl-helpers";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import SubMenu from "../../elements/SubMenu/SubMenu";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import OrderDetails from "../../elements/OrderDetails/OrderDetails";

import styles from "./OrderDetailsScreen.module.scss";

import OrderApi from "../../apis/OrderApi";
import Button from "../../elements/Button/Button";
import OrderPayment from "../../elements/OrderPayment/OrderPayment";
import Modal from "../../elements/Modal/Modal";

const OrderDetailsScreen: React.FC<OrderDetailsScreenProps> = (props) => {

    const orderId = get(props, "screenProps.routeParams.orderId");

    const order = OrderApi.getOne(orderId);

    const [showPay, setShowPay] = useState(false);

    useEffect(() => {

        if (!orderId) return;

        order.run();

    }, []);

    const handleRefresh = useCallback(() => {

        order.reset();

        order.run();

    }, []);

    let title: string = "";
    let message: string = "";
    let refresh: boolean = false;
    let repay: boolean = false;

    if (order.data) {

        if (order.data.status === "paid") {

            title = "Thank You!";
            message = "Your transaction was successful.";

        } else if (order.data.status === "due" || order.data.status === "failed") {

            title = "We're Sorry!";
            message = "Your transaction was unsuccessful.";
            repay = true;

        } else if (order.data.status === "draft" || order.data.status === "pending" || order.data.status === "cart") {

            if (order.data.processing) {

                title = "Processing Payment";
                message = "Your payment is being processed, please wait up to 30 seconds and tap the refresh button below.";
                refresh = true;

            } else {

                title = "Payment Pending";
                message = "Your almost there, please make payment to complete your order. If you have already made payment please wait 30 seconds and tap the refresh button below.";
                refresh = true;
                repay = true;

            }

        }

    }

    return (

        <>

            <Header />

            <SubMenu />

            <Container width="thin">

                <Space height={4} />

                {order.data && (
                    <>

                        {order.data.host &&

                            <UiLink path={`/community/${order.data.host.id}/${order.data.host.slug}`} className={styles.hostContainer}>

                                <UiImage
                                    className={styles.hostLogo}
                                    type={'thumb'}
                                    filters={{ width: 200, height: 200 }}
                                    resizeMode="contain"
                                    src={order.data.host.logo}
                                />

                                <UiText className={styles.hostName}><IoMdArrowBack className={styles.hostBackIcon} /> {order.data.host.orgName}</UiText>

                            </UiLink>

                        }

                        <UiView className={[styles.headingContainer, order.data.status]}>

                            <UiText className={styles.headingTitle}>{title}</UiText>

                            <UiText className={styles.headingMessage}>{message}</UiText>

                            {(refresh || repay) &&
                                <UiView className={styles.headingButtons}>
                                    {refresh &&
                                        <Button
                                            label={"Refresh Order"}
                                            onPress={handleRefresh}
                                            theme="secondary"
                                        />
                                    }

                                    {repay &&
                                        <Button
                                            label={"Make Payment"}
                                            onPress={setShowPay.bind(null, true)}
                                            theme="primary"
                                        />
                                    }
                                </UiView>
                            }

                        </UiView>

                        <OrderDetails order={order.data} />

                        {showPay &&
                            <Modal
                                show={true}
                                onClose={setShowPay.bind(null, false)}
                            >
                                <OrderPayment order={order.data} />
                            </Modal>
                        }

                    </>
                )}

            </Container>

            <Space />

            <Footer />

        </>

    );

};

interface OrderDetailsScreenProps {
    [key: string]: any;
}

export default OrderDetailsScreen;
