import React from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./SportsPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import UserStatsCard from "../../elements/UserStatsCard/UserStatsCard";

const StatsPanel: React.FC<StatsPanelProps> = (props) => {

    let { heading, stats } = props;

    return (

        <Panel
            heading={heading}
            edge={"sides"}
            id={props.id}
        >

            {stats &&
                stats.map((data, key) => (

                    <UiView className="item" key={key}>

                        <UserStatsCard
                            stat={data}
                        />

                    </UiView>

                ))
            }

        </Panel>

    );
};

interface StatsPanelProps {
    set?: string;
    heading?: string;
    stats?: any[];
    [key: string]: any;
}

export default StatsPanel;
