import React, { useEffect, useMemo, useState } from "react";
import { UiView, useAnalytics } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";
import { config } from "@dex/bubl-web";

import styles from "./CampOperatorScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import GroupBanner from "../../elements/GroupBanner/GroupBanner";
import SchedulePanel from "../../groups/SchedulePanel/SchedulePanel";
import ReviewsPanel from "../../groups/ReviewsPanel/ReviewsPanel";
import PassesPanel from "../../groups/PassesPanel/PassesPanel";
import AboutPanel from "../../groups/AboutPanel/AboutPanel";
import Space from "../../elements/Space/Space";
import SubMenu from "../../elements/SubMenu/SubMenu";

import CampSessionGroupApi from "../../apis/CampSessionGroupApi";
import PageIntro from "../../elements/PageIntro/PageIntro";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";

const CampOperatorScreen: React.FC<CampOperatorScreenProps> = (props) => {

    const [key, setKey] = useState(0);
    const groupId = get(props, "screenProps.routeParams.groupId");

    const group = CampSessionGroupApi.getOne(groupId);

    useEffect(() => {
        group.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.title,
                    desc: response.description || response.operator.description,
                    image: response.cover || response.thumbnail || response.operator.cover || response.operator.thumbnail
                });

            },
        });
    }, [key]);


    const handleRefresh = () => {
        setKey(key + 1);
    }


    return useMemo(
        () => (
            <>
                <Header />

                <SubMenu
                    menu={[
                        { label: "Schedule", scrollTo: "schedule" },
                        { label: "About", scrollTo: "about" },
                        { label: "Reviews", scrollTo: "reviews" },
                        { label: "Passes", scrollTo: "passes" },
                    ]}
                />

                {group.data && (

                    <Container>

                        <PageIntro
                            image={group.data.cover || group.data.thumbnail || group.data.operator.cover || group.data.operator.thumbnail}
                            logo={group.data.operator.logo}
                            title={group.data.title || group.data.operator.title}
                            content={group.data.description || group.data.operator.description}
                            reviewScore={group.data.operator.reviewScore}
                            reviewCount={group.data.operator.reviewCount}
                            websiteLink={group.data.operator.websiteLink}
                            hostId={group.data.hostId}
                        />

                        <Space />

                        <GoogleAd
                            slot="leaderboard"
                        />

                        <Space />

                        <SchedulePanel
                            id={"schedule"}
                            heading="Schedule"
                            prefix={"/camps/session"}
                            parentType="CampOperator"
                            parentId={group.data.operator.id}
                            groupId={group.data.id}
                        />

                        <Space />

                        <AboutPanel
                            content={group.data.operator.contentBlocks}
                            gallery={group.data.operator.gallery}
                        />

                        <Space />

                        <ReviewsPanel
                            id={"reviews"}
                            heading="Reviews"
                            rating={group.data.operator.reviewScore}
                            reviews={group.data.operator.reviewCount}
                            operatorTitle={group.data.operator.title}
                            parentType="CampOperator"
                            parentId={group.data.operator.id}
                            handleScreenRefresh={handleRefresh}
                        />

                        <Space />

                        <PassesPanel
                            id={"passes"}
                            heading="Passes"
                            parentType={"CampOperator"}
                            parentId={group.data.operator.id}
                            preventBuy={true}
                        />

                        <Space />

                        <GoogleAd
                            slot="leaderboard"
                        />

                        <Space />

                    </Container>

                )}

                <Footer />
            </>
        ),
        [group.data, group.loading]
    );
};

interface CampOperatorScreenProps {
    [key: string]: any;
}

export default CampOperatorScreen;
