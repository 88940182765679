import { UiForm, UiText, useForm } from '@dex/bubl-ui';
import React, { useCallback } from 'react';
import VenueBookingApi from '../../apis/VenueBookingApi';
import Button from '../../elements/Button/Button';
import DropSelect from '../../elements/DropSelect/DropSelect';
import FieldSelect from '../../elements/Fields/FieldSelect/FieldSelect';
import Space from '../../elements/Space/Space';

import styles from './VenueBookingManageSession.module.scss'

const VenueBookingManageSession: React.FC<VenueBookingManageSessionProps> = (props) => {

    const { session, handleRefresh } = props;

    const manageBooking = VenueBookingApi.manageBooking(session.id);

    const form = useForm({
        maxAttendees: session.maxAttendees || "0",
    });

    const options = [];
    for (let i = 0; i <= 40; i++) {
        options.push({
            value: i === 0 ? "0" : i,
            label: i === 0 ? 'Unlimited' : i
        });
    }

    const handleSubmit = useCallback((values) => {

        manageBooking.run({
            data: { maxAttendees: values.maxAttendees || "0" },
            onComplete: () => {

                handleRefresh();

            }
        });


    }, [form])

    return (
        <>
            <UiText className={styles.headerText}>Manage Session</UiText>

            <UiForm
                form={form}
                onSubmit={handleSubmit}
                loading={manageBooking.loading}
            >
                <FieldSelect
                    label="Maximum Number of Attendees"
                    name={'maxAttendees'}
                    form={form}
                    options={options}
                    defaultValue={form.defaults.maxAttendees}
                />

                <Space />

                <Button
                    label={'Save Changes'}
                    theme={'primary'}
                    block={true}
                    onClick={form.triggerSubmit}
                    loading={manageBooking.loading}
                />

            </UiForm>

        </>
    )
}

interface VenueBookingManageSessionProps {
    [key: string]: any
}

export default VenueBookingManageSession;