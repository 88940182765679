import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Users';

const UsersApi = {

    mySessions: (options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/mySessions",
            params: {},
            ...options
        });

    },

    myPasses: (options: options = {}): response => {
        return apiGet({
            url: endpoint + "/public/myPasses",
            params: {},
            ...options
        });
    },

    myBookings: (courtId: any = null, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/myBookings",
            params: { courtId },
            ...options
        });

    },

    myTeams: (options: options = {}): response => {
        return apiGet({
            url: endpoint + "/public/myTeams",
            params: {},
            ...options
        });
    },

}

export default UsersApi;