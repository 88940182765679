import React from "react";
import { UiText, UiView } from "@dex/bubl-ui";
// @ts-ignore
import { Scrollbars } from 'react-custom-scrollbars';

import styles from "./FeaturedStats.module.scss";

const FeaturedStats: React.FC<FeaturedStatsProps> = (props) => {

    const { stats, title } = props

    return (

        <UiView
            className={styles.featuredStats}
            id={props.id}>

            <UiView className={styles.leftContainer}>

                <UiView className={styles.titleContainer}>

                    <UiText className={styles.titleText}>{title}</UiText>

                </UiView>


            </UiView>

            <UiView className={styles.scrollContainer}>

                <Scrollbars
                    autoHide>

                    <UiView className={styles.itemsContainer}>

                        {stats && stats.map((item: any, index: any) => (

                            <UiView className={styles.itemContainer} key={index}>

                                <UiText className={styles.nameText}>{item.label}</UiText>

                                <UiText className={styles.valueText}>{item.value === 0 ? "0" : item.value}</UiText>

                            </UiView>

                        ))
                        }

                    </UiView>

                </Scrollbars>

            </UiView>

        </UiView>

    );
};

interface FeaturedStatsProps {
    [key: string]: any;
    title: string;
}

export default FeaturedStats;
