import { apiGet, options, response, apiPost, apiDelete } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Assessments';

const AssessmentsApi = {

    list: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/list/" + id,
            params: {},
            ...options
        });

    }

}

export default AssessmentsApi;