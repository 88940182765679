import React, { useEffect, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./SportsPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import StandardCard from "../../elements/StandardCard/StandardCard";
import Grid from "../../elements/Grid/Grid";

import SportsApi from "../../apis/SportsApi";

const SportsPanel: React.FC<SportsPanelProps> = (props) => {

    let { set, heading, type, id } = props;

    if (set === "all") {

        heading = "Pick Your Sport";
        type = 'Global';

    }

    const list = SportsApi.list(type);

    useEffect(() => {

        list.run();

    }, []);

    return useMemo(() => (

        <Panel
            id={id}
            heading={heading}
            edge={"sides"}
        >

            <Grid
                columns={5}
                gap={4}
            >

                {(list.data || []).map((item: any, index: number) => (

                    <UiView
                        className="item"
                        key={index}
                    >

                        <StandardCard
                            path={`/sports/${item.id}/${item.slug}`}
                            title={item.title}
                            image={item.thumbnail || item.icon || {}}
                        />

                    </UiView>

                ))}

            </Grid>

        </Panel>

    ), [list.data, list.loading]);

};

interface SportsPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default SportsPanel;
