import React, { useMemo, useCallback } from "react";
import { UiView, UiRow, UiCol, UiForm, useForm, useStore, Events } from "@dex/bubl-ui";
import { LoginApi } from "@dex/bubl-web";

import styles from "./EditProfile.module.scss";

import FieldText from "../../elements/Fields/FieldText/FieldText";
import Button from "../../elements/Button/Button";
import FieldSelect from "../../elements/Fields/FieldSelect/FieldSelect";
import FieldDate from "../../elements/Fields/FieldDate/FieldDate";

const EditProfile: React.FC<EditProfileProps> = (props) => {

    const form = useForm(props.profile || {});

    const [nav] = useStore("nav");

    const update = LoginApi.update("updateMe");

    const onSubmit = useCallback((values) => {

        update.run({
            data: values,
            onComplete: (response: any) => {

                Events.emit("ui", {
                    action: "success",
                    message: "Profile updated."
                });

                nav.replace({ routeName: nav.currentRoute.state.routeName, params: nav.currentRoute.state.params, newKey: Date.now(), key: nav.currentRoute.state.key });

            }
        });

    }, [update.run, nav.currentRoute.state.key]);


    return useMemo(() => (

        <UiView className={styles.container}>

            <UiForm
                form={form}
                loading={update.loading}
                className={styles.contentContainer}
                onSubmit={onSubmit}
            >

                <UiRow
                    gutter={2}
                    edge={true}
                >

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"email"}
                            required={true}
                            label={"Email address"}
                            defaultValue={form.defaults.email}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"firstName"}
                            required={true}
                            label={"First name"}
                            defaultValue={form.defaults.firstName}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"lastName"}
                            required={true}
                            label={"Last name"}
                            defaultValue={form.defaults.lastName}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"phone"}
                            required={false}
                            label={"Phone number"}
                            defaultValue={form.defaults.phone}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"location"}
                            required={false}
                            label={"Location"}
                            defaultValue={form.defaults.location}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldText
                            form={form}
                            name={"aboutMe"}
                            required={false}
                            label={"Bio"}
                            defaultValue={form.defaults.aboutMe}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldDate
                            form={form}
                            name={"dob"}
                            required={false}
                            label={"Date of birth"}
                            defaultValue={form.defaults.dob}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            name={"gender"}
                            required={false}
                            label={"Gender"}
                            defaultValue={form.defaults.gender}
                            options={[
                                { label: 'Male', value: 'male' },
                                { label: 'Female', value: 'female' },
                            ]}
                        />

                    </UiCol>

                    <UiCol col={{ xs: 24 }}>

                        <Button
                            loading={update.loading}
                            label={"SAVE CHANGES"}
                            type="submit"
                            block={true}
                        />

                    </UiCol>

                </UiRow>

            </UiForm>
        </UiView>


    ), [form, update.loading]);

};

interface EditProfileProps {
    [key: string]: any;
}

export default EditProfile;
