import React, { useCallback, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { UiText, UiView } from '@dex/bubl-ui';

import styles from "./Bracket.module.scss";
import BracketItem from './BracketItem';
import BracketMatchup from './BracketMatchup';

const Bracket: React.FC<BracketProps> = (props) => {

    const { bracket, season, division } = props;

    const height = ((bracket[0]?.matchups?.length || 3) * 148) + 10;

    const [showMatchup, setShowMatchup] = useState<any>(null);

    const onPressItem = useCallback((matchup) => {

        setShowMatchup(matchup);

    }, []);

    return (

        <UiView className={styles.bracketWrap}>

            {showMatchup &&
                <BracketMatchup
                    matchup={showMatchup}
                    onClose={setShowMatchup.bind(null, null)}
                    season={season}
                    division={division}
                />
            }

            <Scrollbars className={styles.bracketScroll} autoHeight={true} hideTracksWhenNotNeeded={true} autoHeightMin={height}>

                <UiView className={styles.bracketInner}>

                    {bracket.map((column: any, index: any) => (

                        <UiView
                            key={index}
                            className={[
                                styles.column,
                                column.rtl ? styles.columnRtl : '',
                                'columnSize' + column.matchups.length,
                                (index === 0) ? styles.columnFirst : '',
                                (index + 1 === bracket.length) ? styles.columnLast : ''
                            ]}
                        >

                            <UiText className={styles.columnTitle}>{column.title}</UiText>

                            {(column.matchups || []).map((matchup: any, key: any) => (

                                <BracketItem
                                    key={key}
                                    matchup={matchup}
                                    onPress={onPressItem}
                                />

                            ))}

                        </UiView>

                    ))}

                </UiView>

            </Scrollbars>

        </UiView>

    )

}

interface BracketProps {
    [key: string]: any
}

export default Bracket;