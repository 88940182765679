import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'LeagueSeason';

const LeagueSeasonApi = {

    getSeason: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            cache: true,
            ...options
        });

    },

    getBrackets: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/dataBrackets",
            cache: true,
            ...options
        });

    },

    getMatchup: (divisionId: string, matchupKey: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + divisionId + "/getMatchup/" + matchupKey,
            cache: true,
            ...options
        });

    },

    getStandings: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/dataStandings",
            cache: true,
            ...options
        });

    },

    getResults: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/dataGames",
            params: {
                hasGameScores: true,
                order: "desc"
            },
            cache: true,
            ...options
        });

    },

    getUpcoming: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/dataGames",
            params: {
                hasGameScores: false,
                order: "asc"
            },
            cache: true,
            ...options
        });

    },

    getLeaderboards: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/dataLeaderboards",
            params: {
                limit: 10
            },
            cache: true,
            ...options
        });

    },

    getLeaderboardTeam: (id: string, teamId: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/dataLeaderboards",
            params: {
                teamId: teamId,
                limit: 0
            },
            cache: true,
            ...options
        });

    }

}

export default LeagueSeasonApi;