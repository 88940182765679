import React, { useEffect, useMemo } from "react";
import { get } from "@dex/bubl-helpers";
import { UiView, UiButton, UiForm, UiFieldSelect, UiLink } from "@dex/bubl-ui";
import { IoIosSearch } from "react-icons/io";

import styles from "./SearchForm.module.scss";
import FieldText from "../../elements/Fields/FieldText/FieldText";
import SearchApi from "../../apis/SearchApi";
import Container from "../../elements/Container/Container";

const SearchForm: React.FC<SearchFormProps> = (props) => {

    const { form } = props;

    const newsFilters = SearchApi.search('News');
    const leaguesFilters = SearchApi.search('League');
    const campFilters = SearchApi.search('CampSessionGroup');
    const pickupFilters = SearchApi.search('PickupSessionGroup');
    const watchFilters = SearchApi.search('WatchVideos');
    const venueFilters = SearchApi.search('VenueCourt');

    const searchType = form.values.searchType;

    const onChangeType = (type: string) => {

        form.handleChange({ name: "searchType", value: type });
        form.handleChange({ name: "filters", value: {} });

    }

    const onSearch = () => {

        form.handleChange({ name: "key", value: Date.now() });

    }

    useEffect(() => {

        if (searchType === "news")
            newsFilters.run();

        if (searchType === "leagues")
            leaguesFilters.run();

        if (searchType === "camps")
            campFilters.run();

        if (searchType === "pickups")
            pickupFilters.run();

        if (searchType === "venues")
            venueFilters.run();

        if (searchType === "watch")
            watchFilters.run();

    }, [searchType]);

    let fields: any = [];

    if (searchType === "news" && newsFilters.data) fields = newsFilters.data;
    if (searchType === "leagues" && leaguesFilters.data) fields = leaguesFilters.data;
    if (searchType === "camps" && campFilters.data) fields = campFilters.data;
    if (searchType === "pickups" && pickupFilters.data) fields = pickupFilters.data;
    if (searchType === "watch" && watchFilters.data) fields = watchFilters.data;
    if (searchType === "venues" && venueFilters.data) fields = venueFilters.data;

    return useMemo(() => (

        <Container width="thin">

            <UiForm
                form={form}
                loading={
                    newsFilters.loading || leaguesFilters.loading ||
                    campFilters.loading || pickupFilters.loading ||
                    watchFilters.loading || venueFilters.loading}
                onSubmit={() => {

                }}
            >

                <UiView className={styles.container}>

                    <UiView className={styles.searchBoxContainer}>

                        <FieldText
                            form={form}
                            name={"keyword"}
                            required={false}
                            defaultValue={get(form.defaults, 'keyword')}
                            placeholder={"Keyword Search"}
                            className={styles.searchField}
                        />

                        <UiButton
                            className={styles.searchIconContainer}
                            onClick={onSearch}
                        >

                            <IoIosSearch className={styles.searchIcon} />

                        </UiButton>

                    </UiView>

                    <UiView className={styles.types}>

                        <UiLink
                            className={searchType !== 'all' ? styles.type : [styles.type, styles.typeActive]}
                            onClick={() => onChangeType('all')}
                        >
                            All
                        </UiLink>

                        <UiLink
                            className={searchType !== 'news' ? styles.type : [styles.type, styles.typeActive]}
                            onClick={() => onChangeType('news')}
                        >
                            News
                        </UiLink>

                        <UiLink
                            className={searchType !== 'watch' ? styles.type : [styles.type, styles.typeActive]}
                            onClick={() => onChangeType('watch')}
                        >
                            Huddle+
                        </UiLink>

                        <UiLink
                            className={searchType !== 'leagues' ? styles.type : [styles.type, styles.typeActive]}
                            onClick={() => onChangeType('leagues')}
                        >
                            Leagues
                        </UiLink>

                        <UiLink
                            className={searchType !== 'camps' ? styles.type : [styles.type, styles.typeActive]}
                            onClick={() => onChangeType('camps')}
                        >
                            Camps
                        </UiLink>

                        <UiLink
                            className={searchType !== 'pickups' ? styles.type : [styles.type, styles.typeActive]}
                            onClick={() => onChangeType('pickups')}
                        >
                            Pickups
                        </UiLink>

                        <UiLink
                            className={searchType !== 'venues' ? styles.type : [styles.type, styles.typeActive]}
                            onClick={() => onChangeType('venues')}
                        >
                            Venues
                        </UiLink>

                    </UiView>

                    {fields.map((field: any, index: number) => (

                        <React.Fragment key={index}>

                            {field.type === "dropdown" &&

                                <UiFieldSelect
                                    form={form}
                                    name={"filters." + field.id}
                                    placeholder={field.label}
                                    defaultValue={get(form.defaults, 'filters.' + field.id)}
                                    required={field.required}
                                    options={[(field.placeholder ? { value: "", label: field.placeholder } : false), ...field.options].filter(Boolean)}
                                    classes={{ input: styles.select }}
                                />

                            }

                        </React.Fragment>

                    ))}

                </UiView>

            </UiForm>

        </Container>

    ), [fields]);
};

interface SearchFormProps {
    [key: string]: any;
}

export default SearchForm;
