import React, { useEffect, useMemo, useState } from "react";
import { UiView, useUser } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";
import { config } from "@dex/bubl-web";

import styles from "./VenueOperatorScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import SchedulePanel from "../../groups/SchedulePanel/SchedulePanel";
import Space from "../../elements/Space/Space";
import ReviewsPanel from "../../groups/ReviewsPanel/ReviewsPanel";
import PassesPanel from "../../groups/PassesPanel/PassesPanel";
import AboutPanel from "../../groups/AboutPanel/AboutPanel";
import SubMenu from "../../elements/SubMenu/SubMenu";

import VenueCourtApi from "../../apis/VenueCourtApi";
import PageIntro from "../../elements/PageIntro/PageIntro";
import VenueSchedulePanel from "../../groups/VenueSchedulePanel/VenueSchedulePanel";
import VenueBookingsPanel from "../../groups/VenueBookingsPanel/VenueBookingsPanel";

const VenueOperatorScreen: React.FC<VenueOperatorScreenProps> = (props) => {

    const courtId = get(props, "screenProps.routeParams.courtId");

    const [key, setKey] = useState(0);

    const group = VenueCourtApi.getOne(courtId);

    const user = useUser("appUser");

    useEffect(() => {

        group.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.title,
                    desc: response.description || response.operator.description,
                    image: response.cover || response.thumbnail || response.operator.cover || response.operator.thumbnail
                });

            },
        });

    }, [key]);

    const handleRefresh = () => {
        setKey(key + 1);
    }

    return useMemo(() => (
        <>

            <Header />

            {group.data && (

                <>

                    <SubMenu
                        menu={[
                            group.data.enableCourtBooking ? { label: "Court Booking", scrollTo: "court-booking" } : undefined,
                            group.data.enableLiveStream ? { label: "Live Stream", scrollTo: "live-stream" } : undefined,
                            user.loggedIn && group.data.enableCourtBooking ? { label: "My Bookings", scrollTo: "bookings" } : undefined,
                            user.loggedIn && group.data.enableLiveStream ? { label: "My Streams", scrollTo: "streams" } : undefined,
                            { label: "About", scrollTo: "about" },
                            { label: "Reviews", scrollTo: "reviews" },
                        ].filter(Boolean)}
                    />

                    <Container>

                        <PageIntro
                            image={group.data.cover || group.data.thumbnail || group.data.operator.cover || group.data.operator.thumbnail}
                            logo={group.data.operator.logo}
                            title={group.data.title || group.data.operator.title}
                            content={group.data.description || group.data.operator.description}
                            reviewScore={group.data.operator.reviewScore}
                            reviewCount={group.data.operator.reviewCount}
                            websiteLink={group.data.operator.websiteLink}
                            hostId={group.data.hostId}
                        />

                        <Space />

                        {group.data.enableCourtBooking &&
                            <>
                                <VenueSchedulePanel
                                    id={"court-booking"}
                                    heading="Court Booking"
                                    prefix={"/venues/session"}
                                    parentType="VenueOperator"
                                    parentId={group.data.operator.id}
                                    groupId={group.data.id}

                                />

                                <Space />
                            </>
                        }

                        {group.data.enableLiveStream &&
                            <>
                                <VenueSchedulePanel
                                    id={"live-stream"}
                                    heading="Live Stream"
                                    prefix={"/venues/stream"}
                                    parentType="VenueOperator"
                                    parentId={group.data.operator.id}
                                    groupId={group.data.id}
                                    type={"liveStream"}
                                />

                                <Space />
                            </>
                        }

                        {user.loggedIn && group.data.enableCourtBooking &&
                            <>
                                <VenueBookingsPanel
                                    id={"bookings"}
                                    title={"My Bookings"}
                                    courtId={courtId}
                                />

                                <Space />
                            </>
                        }

                        {user.loggedIn && group.data.enableLiveStream &&
                            <>
                                <VenueBookingsPanel
                                    id={"streams"}
                                    title={"My Streams"}
                                    courtId={courtId}
                                    type={"liveStream"}

                                />

                                <Space />
                            </>
                        }

                        <AboutPanel
                            content={group.data.operator.contentBlocks}
                            gallery={group.data.operator.gallery}
                        />

                        <Space />

                        <ReviewsPanel
                            id={"reviews"}
                            heading="Reviews"
                            rating={group.data.operator.reviewScore}
                            reviews={group.data.operator.reviewCount}
                            parentType="VenueOperator"
                            parentId={group.data.operator.id}
                            handleScreenRefresh={handleRefresh}
                        />

                        <Space />

                    </Container>

                </>

            )}

            <Footer />

        </>

    ), [group.data, group.loading]);

};

interface VenueOperatorScreenProps {
    [key: string]: any;
}

export default VenueOperatorScreen;
