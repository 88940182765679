import React from "react";
import { UiImage, UiText, UiView, UiLink } from "@dex/bubl-ui";
import { slug } from "@dex/bubl-helpers";
import moment from 'moment';

import styles from "./ResultCard.module.scss";

const ResultCard: React.FC<ResultCardProps> = (props) => {

    const { results } = props;
    // const recap: any = props.results ? Object.values(props.results.recap) : null;

    let path = props.path;

    if (path === "game") path = (props.hasGameScores && props.id) ? `/leagues/season/${props.seasonId}/${props.slug}/game/${props.id}` : 'team';

    return (

        <UiView className={props.alt ? styles.containerAlt : styles.container}>

            <UiView className={styles.resultsContainer}>
                {results &&

                    results.teams.map((team: any, key: number) => (

                        <UiLink
                            key={key}
                            className={styles.teamContainer}
                            path={path === "team" ? `/leagues/season/${props.seasonId}/${props.slug}/team/${team.id}/${slug(team.name)}` : path}
                        >
                            <UiView className={styles.imageContainer}>

                                <UiImage
                                    className={styles.teamImage}
                                    src={team.logo || { fileId: "#" }}
                                    type={"thumb"}
                                    resizeMode={"contain"}
                                    filters={{ width: 100, height: 100, fallback: { text: team.name[0] } }}
                                />

                            </UiView>

                            <UiView className={styles.textContainer}>

                                <UiView className={styles.teamNameContainer}>

                                    <UiText className={styles.teamText}>{team.name}</UiText>

                                </UiView>

                                <UiView className={styles.scoreContainer}>

                                    <UiText className={styles.scoreText}>{results.totals.length > 0 && results.totals[key]}</UiText>

                                </UiView>

                            </UiView>

                        </UiLink>
                    ))

                }

            </UiView>

            <UiView className={styles.divider} />

            {results.date &&
                <UiView className={styles.dateContainer}>

                    <UiText className={styles.dateText}>{moment(results.date.str).format('ddd, Do MMM YYYY')}</UiText>

                    <UiText className={styles.dateText}>{moment(results.date.str).format('h:mm a')}</UiText>

                    {props.hasGameScores &&
                        <UiText className={styles.finalText}>FINAL</UiText>
                    }

                </UiView>
            }

        </UiView >

    );
};

interface ResultCardProps {
    [key: string]: any;
}

export default ResultCard;
