import React from "react";
import { UiImage, UiText, UiView, UiLink } from "@dex/bubl-ui";
import moment from 'moment';

import styles from "./NewsArticle.module.scss";

import Container from "../Container/Container";
import Space from "../Space/Space";
const NewsArticleHeader: React.FC<NewsArticleHeaderProps> = (props) => {

    const { article } = props;

    return (

        <Container
            width={"thin"}
            padding={4}
            className={styles.header}
        >

            <UiView className={styles.meta}>

                {article.category &&
                    <UiLink path={`/news/category/${article.category.id}/${article.category.slug}`} className={styles.category}>

                        <UiImage
                            className={styles.categoryImage}
                            src={article.category.thumbnail}
                            filters={{ width: 100, height: 100 }}
                            type={'thumb'}
                        />

                        <UiText className={styles.categoryTitle}>{article.category.title}</UiText>

                    </UiLink>
                }

                {article.date &&
                    <UiText className={styles.date}>{moment(article.date).startOf('minute').fromNow()}</UiText>
                }

            </UiView>

            <Space />

            <UiText
                className={styles.title}
                tag="h1"
            >
                {article.title}
            </UiText>

            <Space />

            <UiText className={styles.description}>
                {article.description}
            </UiText>

        </Container>

    );
};

interface NewsArticleHeaderProps {
    article?: any,
    [key: string]: any;
}

export default NewsArticleHeader;
