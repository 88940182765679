import React, { useState } from "react";
import { UiImage, UiLink, UiText, UiView } from "@dex/bubl-ui";
import { IoMdClose } from 'react-icons/io';

import styles from "./MyActivePass.module.scss";

import TimerIcon from "../Icons/TimerIcon";
import Modal from "../../elements/Modal/Modal";
import Divider from "../../elements/Divider/Divider";
import Space from "../Space/Space";
import Button from "../Button/Button";
import EventCard from "../EventCard/EventCard";

const MyActivePass: React.FC<MyActivePassProps> = (props) => {

    const { sessionsLeft, daysLeft, passType, sessions, passTitle, image } = props;

    const [showPopup, setShowPopup] = useState(false);

    const handleClose = () => {
        setShowPopup(false);
    }


    return (

        <>

            <UiView className={styles.container}>

                {image &&
                    <UiView className={styles.imageContainer}>

                        <UiImage
                            className={styles.logo}
                            type="thumb"
                            filters={{ width: 60, height: 60 }}
                            src={image || { fileId: props.id }}
                        />

                    </UiView>
                }

                <UiView className={styles.content}>

                    <UiView className={styles.contentContainer}>

                        <UiText className={styles.tagTitle}>PASS NAME</UiText>
                        <UiText className={styles.text}>{passTitle}</UiText>

                    </UiView>

                    <UiView className={styles.contentContainer}>

                        <UiText className={styles.tagTitle}>Sessions Left</UiText>
                        <UiText className={styles.text}>{passType === "session" ? `${sessionsLeft} Sessions` : "-"}</UiText>

                    </UiView>

                    <UiView className={styles.contentContainer}>

                        <UiText className={styles.tagTitle}>Days Left</UiText>
                        <UiText className={styles.text}>{passType === "time" ? `${daysLeft} Days` : "-"}</UiText>

                    </UiView>

                </UiView>

                <UiLink className={styles.timerIcon} onClick={() => setShowPopup(true)}><TimerIcon /></UiLink>

            </UiView>

            <Modal
                show={showPopup}
                onClose={handleClose}
            >

                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleClose}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider title={"Pass History"} />

                    <UiView className={styles.modelContainer}>

                        {sessions && sessions.length > 0 &&

                            <UiView className={styles.sessionsContainer}>
                                {
                                    sessions.map((session: any, key: any) => (
                                        <UiView key={key}>

                                            <EventCard
                                                session={session}
                                                hideStatus={true}
                                            />

                                            <Space height={2} />

                                        </UiView>
                                    ))
                                }
                            </UiView>
                        }

                        <Button
                            label={"CLOSE"}
                            block={true}
                            className={styles.closeButton}
                            onClick={handleClose}
                        />

                    </UiView>

                </UiView>

            </Modal>

        </>

    );
};

interface MyActivePassProps {
    [key: string]: any;
    passTitle: string,
    sessionsCount: number;
    date: any;
    image: any;
}

export default MyActivePass;
