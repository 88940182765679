import { useRouter } from "@dex/bubl-ui";
import { config } from "@dex/bubl-web";
import { hash } from "@dex/bubl-helpers";
import protectedHandler from "./Routes.protectedHandler";
import DevElementsScreen from "../screens/DevElementsScreen/DevElementsScreen";
import PageNotFoundScreen from "../screens/PageNotFoundScreen/PageNotFoundScreen";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import NewsScreen from "../screens/NewsScreen/NewsScreen";
import LeaguesScreen from "../screens/LeaguesScreen/LeaguesScreen";
import CampsScreen from "../screens/CampsScreen/CampsScreen";
import PickupsScreen from "../screens/PickupsScreen/PickupsScreen";
import LeaguePlayerScreen from "../screens/LeaguePlayerScreen/LeaguePlayerScreen";
import LeagueSeasonScreen from "../screens/LeagueSeasonScreen/LeagueSeasonScreen";
import CommunityHostScreen from "../screens/CommunityHostScreen/CommunityHostScreen";
import LeagueTeamScreen from "../screens/LeagueTeamScreen/LeagueTeamScreen";
import NewsArticleScreen from "../screens/NewsArticleScreen/NewsArticleScreen";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen/RegisterScreen";
import LeagueOperatorScreen from "../screens/LeagueOperatorScreen/LeagueOperatorScreen";
import MyProfileScreen from "../screens/MyProfileScreen/MyProfileScreen";
import OrderDetailsScreen from "../screens/OrderDetailsScreen/OrderDetailsScreen";
import PageScreen from "../screens/PageScreen/PageScreen";
import CampOperatorScreen from "../screens/CampOperatorScreen/CampOperatorScreen";
import CampSessionScreen from "../screens/CampSessionScreen/CampSessionScreen";
import PickupOperatorScreen from "../screens/PickupOperatorScreen/PickupOperatorScreen";
import PickupSessionScreen from "../screens/PickupSessionScreen/PickupSessionScreen";
import SportScreen from "../screens/SportScreen/SportScreen";
import CheckoutScreen from "../screens/CheckoutScreen/CheckoutScreen";
import DiscoverScreen from "../screens/DiscoverScreen/DiscoverScreen";
import CommunityMemberScreen from "../screens/CommunityMemberScreen/CommunityMemberScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen/ForgotPasswordScreen";
import AnnouncementScreen from "../screens/AnnouncementScreen/AnnouncementScreen";
import AssessmentsScreen from "../screens/AssessmentsScreen/AssessmentsScreen";
import OrderReturnScreen from "../screens/OrderReturnScreen/OrderReturnScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen/ResetPasswordScreen";
import LeagueGameScreen from "../screens/LeagueGameScreen/LeagueGameScreen";
import VenueOperatorScreen from "../screens/VenueOperatorScreen/VenueOperatorScreen";
import VenuesScreen from "../screens/VenuesScreen/VenuesScreen";
import VenueBookingScreen from "../screens/VenueBookingScreen/VenueBookingScreen";
import WatchScreen from "../screens/WatchScreen/WatchScreen";
import WatchSportScreen from "../screens/WatchSportScreen/WatchSportScreen";
import WatchCategoryScreen from "../screens/WatchCategoryScreen/WatchCategoryScreen";
import WatchHostScreen from "../screens/WatchHostScreen/WatchHostScreen";
import WatchVideoScreen from "../screens/WatchVideoScreen/WatchVideoScreen";
import WatchTagScreen from "../screens/WatchTagScreen/WatchTagScreen";
import WatchLiveScreen from "../screens/WatchLiveScreen/WatchLiveScreen";
import WatchMyVideosScreen from "../screens/WatchMyVideosScreen/WatchMyVideosScreen";
import WatchSeasonScreen from "../screens/WatchSeasonScreen/WatchSeasonScreen";
import LeagueRegisterTeamScreen from "../screens/LeagueRegisterTeamScreen/LeagueRegisterTeamScreen";
import LeaguesCategoryScreen from "../screens/LeaguesCategoryScreen/LeaguesCategoryScreen";
import CampsCategoryScreen from "../screens/CampsCategoryScreen/CampsCategoryScreen";
import PickupsCategoryScreen from "../screens/PickupsCategoryScreen/PickupsCategoryScreen";
import VenuesCategoryScreen from "../screens/VenuesCategoryScreen/VenuesCategoryScreen";
import VenueLiveStreamScreen from "../screens/VenueLiveStreamScreen/VenueLiveStreamScreen";

export default () => {

    useRouter.protectedHandler = protectedHandler;

    if (config.env === "local") {
        useRouter.addRoute({
            name: "Dev",
            component: DevElementsScreen,
            link: "/dev",
            options: {},
        });
    }

    useRouter.addRoute({
        name: "404",
        component: PageNotFoundScreen,
        link: "/404",
        options: {},
    });

    useRouter.addRoute({
        name: "Home",
        component: HomeScreen,
        link: "/",
        options: {},
    });

    useRouter.addRoute({
        name: "_Home",
        component: HomeScreen,
        link: "/home",
        options: {},
    });

    useRouter.addRoute({
        name: "Dashboard",
        component: HomeScreen,
        link: "/dashboard",
        options: {},
    });

    useRouter.addRoute({
        name: "CommunityHost",
        component: CommunityHostScreen,
        link: "/community/:hostId/:slug",
        options: {},
    });


    useRouter.addRoute({
        name: "Sport",
        component: SportScreen,
        link: "/sports/:sportId/:sportSlug",
        options: {
            title: "Sport",
        },
    });

    useRouter.addRoute({
        name: "News",
        component: NewsScreen,
        link: "/news",
        options: {
            title: "Newsfeed",
        },
    });

    useRouter.addRoute({
        name: "NewsArchiveType",
        component: NewsScreen,
        link: "/news/type/:type",
        options: {
            title: "Newsfeed",
        },
    });

    useRouter.addRoute({
        name: "NewsArchiveCategory",
        component: NewsScreen,
        link: "/news/category/:categoryId/:slug",
        options: {
            title: "Newsfeed",
        },
    });

    useRouter.addRoute({
        name: "NewsArchiveTag",
        component: NewsScreen,
        link: "/news/tag/:tagId/:slug",
        options: {
            title: "Newsfeed",
        },
    });

    useRouter.addRoute({
        name: "NewsArchiveHost",
        component: NewsScreen,
        link: "/news/host/:hostId/:slug",
        options: {
            title: "News",
        },
    });

    useRouter.addRoute({
        name: "NewsArticle",
        component: NewsArticleScreen,
        link: "/news/article/:id/:slug",
        options: {
            title: "News Article",
        },
    });

    useRouter.addRoute({
        name: "Leagues",
        component: LeaguesScreen,
        link: "/leagues",
        options: {
            title: "Leagues",
        },
    });

    useRouter.addRoute({
        name: "LeaguesSport",
        component: LeaguesScreen,
        link: "/leagues/sport/:sportId/:sportTitle",
        options: {
            title: "Leagues",
        },
    });

    useRouter.addRoute({
        name: "LeaguesCategory",
        component: LeaguesCategoryScreen,
        link: "/leagues/sport/:sportId/category/:categoryId", ///leagues/category/:categoryId/:categoryTitle
        options: {
            title: "Leagues"
        }
    });

    useRouter.addRoute({
        name: "LeagueOperator",
        component: LeagueOperatorScreen,
        link: "/leagues/operator/:operatorId/:slug",
        options: {
            title: "League Operator",
        },
    });

    useRouter.addRoute({
        name: "LeagueSeason",
        component: LeagueSeasonScreen,
        link: "/leagues/season/:seasonId/:slug",
        options: {
            title: "League Season",
        },
    });

    useRouter.addRoute({
        name: "LeagueTeam",
        component: LeagueTeamScreen,
        link: "/leagues/season/:seasonId/:slug/team/:teamId/:teamSlug",
        options: {
            title: "League Team",
        },
    });

    useRouter.addRoute({
        name: "LeagueGame",
        component: LeagueGameScreen,
        link: "/leagues/season/:seasonId/:slug/game/:gameId",
        options: {
            title: "League Game",
        },
    });

    useRouter.addRoute({
        name: "LeaguePlayer",
        component: LeaguePlayerScreen,
        link: "/leagues/season/:seasonId/:slug/player/:playerId/:playerSlug",
        options: {
            title: "League Player",
        },
    });
    useRouter.addRoute({
        name: "LeagueRegisterTeam",
        component: LeagueRegisterTeamScreen,
        link: "/leagues/register/:teamId",
        options: {
            title: "League Register Team",
        },
    });

    useRouter.addRoute({
        name: "Camps",
        component: CampsScreen,
        link: "/camps",
        options: {
            title: "Camps",
        },
    });

    useRouter.addRoute({
        name: "CampsSport",
        component: CampsScreen,
        link: "/camps/sport/:sportId/:sportTitle",
        options: {
            title: "Camps",
        },
    });

    useRouter.addRoute({
        name: "CampsCategory",
        component: CampsCategoryScreen,
        link: "/camps/sport/:sportId/category/:categoryId",
        options: {
            title: "Camps"
        }
    });

    useRouter.addRoute({
        name: "CampOperator",
        component: CampOperatorScreen,
        link: "/camps/group/:groupId/:slug",
        options: {
            title: "Camp Operator",
        },
    });

    useRouter.addRoute({
        name: "CampSession",
        component: CampSessionScreen,
        link: "/camps/session/:sessionId",
        options: {
            title: "Camp Session",
        },
    });

    useRouter.addRoute({
        name: "Pickups",
        component: PickupsScreen,
        link: "/pickups",
        options: {
            title: "Pickups",
        },
    });

    useRouter.addRoute({
        name: "PickupsSport",
        component: PickupsScreen,
        link: "/pickups/sport/:sportId/:sportTitle",
        options: {
            title: "Pickups",
        },
    });

    useRouter.addRoute({
        name: "PickupsCategory",
        component: PickupsCategoryScreen,
        link: "/pickups/sport/:sportId/category/:categoryId",
        options: {
            title: "Pickups"
        }
    });

    useRouter.addRoute({
        name: "PickupOperator",
        component: PickupOperatorScreen,
        link: "/pickups/group/:groupId/:slug",
        options: {
            title: "Pickup Operator",
        },
    });

    useRouter.addRoute({
        name: "PickupSession",
        component: PickupSessionScreen,
        link: "/pickups/session/:sessionId",
        options: {
            title: "Pickup Session",
        },
    });


    useRouter.addRoute({
        name: "MyProfile",
        component: MyProfileScreen,
        protected: true,
        link: "/my-profile",
        options: {
            title: "My Profile",
        },
    });

    useRouter.addRoute({
        name: "MyProfilePage",
        component: MyProfileScreen,
        protected: true,
        link: "/my-profile/:page",
        options: {
            title: "My Profile",
        },
    });

    useRouter.addRoute({
        name: "Login",
        component: LoginScreen,
        link: "/login",
        options: {
            title: "Sign In",
        },
    });

    useRouter.addRoute({
        name: "ForgotPassword",
        component: ForgotPasswordScreen,
        link: "/forgot-password",
        options: {
            title: "Forgot Password",
        },
    });

    useRouter.addRoute({
        name: "ResetPassword",
        component: ResetPasswordScreen,
        link: "/reset-password/:key",
        options: {
            title: "Reset Password",
        },
    });

    useRouter.addRoute({
        name: "Register",
        component: RegisterScreen,
        link: "/register",
        options: {
            title: "Register",
        },
    });

    useRouter.addRoute({
        name: "CommunityMember",
        component: CommunityMemberScreen,
        link: "/community/member/:id/:slug",
        options: {
            title: "Profile",
        },
    });

    useRouter.addRoute({
        name: "OrderDetails",
        component: OrderDetailsScreen,
        link: "/order-details/:orderId",
        // protected: true,
        options: {
            title: "Order Details",
        },
    });

    useRouter.addRoute({
        name: "OrderReturn",
        component: OrderReturnScreen,
        link: "/order-return/:orderId",
        // protected: true,
        options: {
            title: "Order Processing",
        },
    });

    useRouter.addRoute({
        name: "Checkout",
        component: CheckoutScreen,
        link: "/checkout/:orderId",
        options: {
            title: "Checkout",
        },
    });

    useRouter.addRoute({
        name: "Discover",
        component: DiscoverScreen,
        link: "/discover",
        options: {
            title: "Discover",
        },
    });

    useRouter.addRoute({
        name: "Announcement",
        component: AnnouncementScreen,
        link: "/announcement/:announcementId",
        options: {
            title: "Announcement",
        },
    });


    useRouter.addRoute({
        name: "Assessments",
        component: AssessmentsScreen,
        // protected: true,
        link: "/assessments/:athleteId",
        options: {
            title: "Assessments",
        },
    });

    useRouter.addRoute({
        name: "VenueOperator",
        component: VenueOperatorScreen,
        link: "/venues/court/:courtId/:slug",
        options: {
            title: "Venue Operator",
        },
    });

    useRouter.addRoute({
        name: "Venues",
        component: VenuesScreen,
        link: "/venues",
        options: {
            title: "Venues",
        },
    });

    useRouter.addRoute({
        name: "VenuesSport",
        component: VenuesScreen,
        link: "/venues/sport/:sportId/:sportTitle",
        options: {
            title: "Venues",
        },
    });

    useRouter.addRoute({
        name: "VenuesCategory",
        component: VenuesCategoryScreen,
        link: "/venues/sport/:sportId/category/:categoryId",
        options: {
            title: "Venues"
        }
    });

    useRouter.addRoute({
        name: "VenueBooking",
        component: VenueBookingScreen,
        link: "/venues/booking/:bookingId",
        options: {
            title: "Venue Booking"
        },
    });

    useRouter.addRoute({
        name: "VenueLiveStream",
        component: VenueLiveStreamScreen,
        link: "/venues/live-stream/:streamId",
        options: {
            title: "Venue Live Stream"
        },
    });

    useRouter.addRoute({
        name: "Watch",
        component: WatchScreen,
        link: "/watch",
        options: {
            title: "Huddle"
        }
    })

    useRouter.addRoute({
        name: "WatchVideo",
        component: WatchVideoScreen,
        link: "/watch/video/:videoId/:slug",
        options: {
            title: "Watch"
        }
    })

    useRouter.addRoute({
        name: "WatchSport",
        component: WatchSportScreen,
        link: "/watch/sport/:sportId/:slug",
        options: {
            title: "Watch Sport"
        }
    })

    useRouter.addRoute({
        name: "WatchCategory",
        component: WatchCategoryScreen,
        link: "/watch/category/:categoryId/:slug",
        options: {
            title: "Watch Category"
        }
    })

    useRouter.addRoute({
        name: "WatchHost",
        component: WatchHostScreen,
        link: "/watch/host/:hostId/:slug",
        options: {
            title: "Watch Host"
        }
    })

    useRouter.addRoute({
        name: "WatchTag",
        component: WatchTagScreen,
        link: "/watch/tag/:tagId/:slug",
        options: {
            title: "Watch Tag"
        }
    })

    useRouter.addRoute({
        name: "WatchSeason",
        component: WatchSeasonScreen,
        link: "/watch/season/:seasonId/:slug",
        options: {
            title: "Watch Season"
        }
    })

    useRouter.addRoute({
        name: "WatchLive",
        component: WatchLiveScreen,
        link: "/watch/live",
        options: {
            title: "Watch Live"
        }
    })

    useRouter.addRoute({
        name: "WatchMyVideos",
        component: WatchMyVideosScreen,
        link: "/watch/my-videos",
        options: {
            title: "Watch My Videos"
        }
    })

    //end
    useRouter.addRoute({
        name: "Page",
        component: PageScreen,
        link: "/:slug",
        options: {},
    });

    if (process.env.NODE_ENV === "development") {
        useRouter.toJsonFile();
    }

    return process.env.NODE_ENV === "development" ? hash(useRouter.routes) : "";

};
