import React, { useEffect } from "react";
import { UiView, UiLink } from "@dex/bubl-ui";
import { IoMdClose } from 'react-icons/io';

import OrderDetails from "../../elements/OrderDetails/OrderDetails";

import styles from "./OrderDetailsPopup.module.scss";

import OrderApi from "../../apis/OrderApi";
import Modal from "../../elements/Modal/Modal";
import { useState } from "react";
import { useCallback } from "react";
import Divider from "../../elements/Divider/Divider";

const OrderDetailsPopup: React.FC<OrderDetailsPopupProps> = (props) => {

    const { orderId } = props;

    const order = OrderApi.getOne(orderId);

    const [show, setShow] = useState(true);

    useEffect(() => {

        if (!orderId) return;

        order.run();

    }, []);

    const handleClosePopup = useCallback(() => {

        setShow(false);

    }, []);

    return (
        <>

            <Modal
                show={show && order.data}
                onClose={handleClosePopup}
            >

                <UiView className={styles.popup}>

                    <UiLink
                        onPress={handleClosePopup}
                        className={styles.close}
                    >

                        <IoMdClose />

                    </UiLink>

                    <Divider title={"Order Details"} />

                    {order.data &&
                        <OrderDetails
                            order={order.data}
                        />
                    }

                </UiView>


            </Modal>

        </>

    );

};

interface OrderDetailsPopupProps {
    [key: string]: any;
}

export default OrderDetailsPopup;
