import React, { useState, useEffect, useMemo, useCallback } from "react";
import { UiRow, UiCol } from "@dex/bubl-ui";

import Panel from '../../elements/Panel/Panel';
import PickupOperatorApi from "../../apis/PickupOperatorApi";
import CampOperatorApi from "../../apis/CampOperatorApi";
import DropSelect from "../../elements/DropSelect/DropSelect";
import NotFound from "../../elements/NotFound/NotFound";
import DropDate from "../../elements/DropDate/DropDate";
import EventCard from "../../elements/EventCard/EventCard";

const SchedulePanel: React.FC<SchedulePanelProps> = (props) => {

    const { heading, parentType, parentId, groupId, prefix } = props;

    const [groups, setGroups] = useState<any>(null);

    const [list, setList] = useState<any>(null);

    const [group, setGroup] = useState<string>(groupId);

    const [date, setDate] = useState<any>(null);

    const api: any = parentType === "PickupOperator" ? PickupOperatorApi : CampOperatorApi;

    const sessions = api.sessionList(parentId);

    useEffect(() => {

        sessions.run({
            params: {
                sessionGroupId: group,
                includeGroups: !groups,
                date: date,
                limit: 12
            },
            onComplete: (response: any) => {

                if (!groups) setGroups(response.groups);
                setList(response.sessions);

            }
        });

    }, [group, date]);

    const onHandleGroupChange = useCallback((selected: any) => {

        setList(null);
        setGroup(selected.value);

    }, [setGroup]);

    const onHandleDateChange = useCallback((value: any) => {

        setList(null);
        setDate(value);

    }, [setGroup]);

    return useMemo(() => (

        <Panel
            id={props.id}
            heading={heading}
            topLeft={
                <>
                    {groups &&
                        <DropSelect
                            items={(groups).map((group: any) => { return { value: group.id, label: group.title }; })}
                            defaultValue={group}
                            onChange={onHandleGroupChange}
                        />
                    }
                    <DropDate
                        defaultValue={date}
                        onChange={onHandleDateChange}
                        placeholder="Select Date"
                    />
                </>
            }

        >

            {list &&
                <UiRow
                    gutter={3}
                    edge={true}
                >

                    {(list || []).map((session: any, index: number) => (

                        <UiCol
                            col={{ xs: 24, sm: 24, md: 12 }}
                            key={index}
                        >

                            <EventCard
                                session={session}
                                hideImage={true}
                                path={`${prefix}/${session.id}`}
                            />

                        </UiCol>

                    ))}

                </UiRow>
            }

            <NotFound
                loading={sessions.loading}
                data={list}
                message={"No Events Found"}
            />

        </Panel>

    ), [sessions.loading, list, groups]);

}

interface SchedulePanelProps {
    [key: string]: any;
}

export default SchedulePanel;