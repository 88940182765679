import React, { useCallback, useMemo } from "react";
import { UiForm, UiSpace, UiText, useForm, useStore } from "@dex/bubl-ui";

import styles from "./VenueLiveStreamJoin.module.scss";

import Button from "../../elements/Button/Button";
import VenueLiveStreamApi from "../../apis/VenueLiveStreamApi";
import { get } from "@dex/bubl-helpers";
import FieldText from "../../elements/Fields/FieldText/FieldText";

const VenueLiveStreamJoinPopupJoin: React.FC<VenueLiveStreamJoinPopupJoinProps> = (props) => {

    let { handleRefresh, setDidAction, session } = props;

    const [nav] = useStore('nav');

    const join = VenueLiveStreamApi.join(session.id);

    const form = useForm({ code: get(nav, "currentRoute.params.code") });

    const handleJoin = useCallback((values) => {

        join.run({
            data: values,
            onComplete: () => {

                setDidAction(true);
                handleRefresh();

            }
        });

    }, []);

    return useMemo(() => (

        <>

            <UiText>Enter the joining code below to watch the live stream.</UiText>

            <UiForm
                form={form}
                onSubmit={handleJoin}
                loading={join.loading}
                className={styles.form}
            >

                <UiSpace height={16} />

                <FieldText
                    form={form}
                    name={"code"}
                    required={true}
                    defaultValue={form.defaults.code}
                />

                <UiSpace height={16} />

                <Button
                    label={"Watch The Live Stream"}
                    block={true}
                    theme={"secondary"}
                    type={"submit"}
                    loading={join.loading}
                />

            </UiForm>

        </>

    ), [form, join]);

};

interface VenueLiveStreamJoinPopupJoinProps {
    [key: string]: any;
}

export default VenueLiveStreamJoinPopupJoin;
