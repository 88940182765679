import React, { useEffect, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";
import { config } from "@dex/bubl-web";

import styles from "./CommunityHostScreen.module.scss";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import SubMenu from "../../elements/SubMenu/SubMenu";
import NewsPanel from "../../groups/NewsPanel/NewsPanel";
import Announcements from "../../groups/Announcements/Announcements";
import CollectionBlock from "../../elements/CollectionBlock/CollectionBlock";
import PageIntro from "../../elements/PageIntro/PageIntro";
import WatchPanel from "../../groups/WatchPanel/WatchPanel";

import HostsApi from "../../apis/HostsApi";

const CommunityHostScreen: React.FC<CommunityHostScreenProps> = (props) => {

    const key = get(props, 'screenProps.routeKey');

    const hostId = get(props, 'screenProps.routeParams.hostId');

    const hostSlug = get(props, 'screenProps.routeParams.slug');

    const host = HostsApi.getOne(hostId);

    useEffect(() => {

        host.run({
            onComplete: (response: any) => {

                config.setMeta({
                    title: response.title,
                    desc: response.orgDescription,
                    image: response.cover || response.icon
                });

            }
        });

    }, [key]);

    return useMemo(() => (

        <>

            <Header />

            {host.data &&

                <>

                    <SubMenu
                        menu={[
                            { label: "About", scrollTo: "about" },
                            { label: "Huddle+", scrollTo: "huddle" },
                            { label: "News", scrollTo: "news" },
                            { label: "Activities", scrollTo: "activities" },
                            { label: "Announcements", scrollTo: "announcements" }
                        ]}
                    />

                    <Container>

                        <UiView id="about">

                            <PageIntro
                                image={host.data.cover}
                                logo={host.data.logo}
                                title={host.data.title}
                                content={host.data.orgDescription}
                                reviewScore={host.data.reviewScore}
                                reviewCount={host.data.reviewCount}
                                websiteLink={host.data.websiteLink}
                                hostId={hostId}
                            />

                        </UiView>

                        <Space />

                        <WatchPanel
                            id="huddle"
                            heading={"Huddle+"}
                            filter={{
                                hostId: hostId
                            }}
                            more
                            path={`/watch/host/${hostId}/${hostSlug}`}
                        />

                        <Space />

                        <NewsPanel
                            id="news"
                            heading={"News"}
                            filter={{
                                where: {
                                    hostId: hostId
                                }
                            }}
                        />

                        <Space />

                        <CollectionBlock
                            id="activities"
                            heading="Activities (Play Now!)"
                            modelName="any"
                            cards={host.data.groups}
                            empty={"No Groups Found"}
                        />

                        <Space />

                        <Announcements
                            id="announcements"
                            hostId={hostId}
                        />

                        <Space />

                    </Container>
                </>
            }

            <Footer />

        </>

    ), [host.loading, host.data]);

};

interface CommunityHostScreenProps {
    [key: string]: any;
}

export default CommunityHostScreen;
