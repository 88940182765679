import { UiView } from '@dex/bubl-ui';
import React from 'react';
import Button from '../Button/Button';
import EventCard from '../EventCard/EventCard';

import styles from './VenueBlockSessionCard.module.scss';

const VenueBlockSessionCard: React.FC<VenueBlockSessionCardProps> = (props) => {

    const { session } = props;

    return (
        <UiView className={styles.container}>

            <EventCard session={session} />

            {session.status !== "Unavailable" && session.status !== "Disable" &&
                <Button
                    label={session.status}
                    theme={"primary"}
                    size={"medium"}
                />
            }

            {session.status === 'Unavailable' &&
                <Button
                    label={session.status}
                    theme={"alt"}
                    size={"small"}
                />
            }

            {session.status === 'Disable' &&
                <Button
                    label={session.status}
                    theme={"faded"}
                    disabled
                    size={"small"}
                />
            }

        </UiView>
    )
}

interface VenueBlockSessionCardProps {
    [key: string]: any
}

export default VenueBlockSessionCard;