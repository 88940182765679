import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Sport';

const SportsApi = {

    list: (type: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/list/" + type,
            params: {},
            cache: true,
            ...options
        })

    },
    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            cache: true,
            ...options
        })

    },

}

export default SportsApi;