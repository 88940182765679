import React from 'react';
import { UiImage, UiSpace, UiView } from '@dex/bubl-ui';
import Wizard, { WizardHeading, WizardText } from '../../elements/Wizard/Wizard';
import Modal from "../../elements/Modal/Modal";

import styles from "./CourtBookingWizard.module.scss"

const CourtBookingWizard: React.FC<CourtBookingWizardProps> = (props) => {

    const { show, onClose } = props;

    const slides = [
        {
            title: "Welcome!",
            align: "bottom",
            render: () => (
                <>
                    <UiView className={styles.inner}>

                        <UiSpace height={30} />

                        <WizardHeading>Welcome to your first time booking on Huddle</WizardHeading>

                        <WizardText> Book a court at your favourite venue now.</WizardText>

                        <WizardText bold>
                            So, let’s begin!
                        </WizardText>

                        <UiSpace height={30} />

                    </UiView>

                    <UiImage
                        src={require('../../assets/images/bookingWizard/firstscreen1.png')}
                        type={'local'}
                        resizeMode="contain"
                        className={styles.image}
                    />

                </>
            )
        },
        {
            title: "Check for availability",
            align: "top",
            render: () => (
                <>

                    <UiView className={styles.inner}>

                        <UiSpace height={30} />

                        <WizardHeading>Check for availability</WizardHeading>

                        <WizardText>
                            Check for availability by using the venue's calendar After selecting a date, select the timing of your booking, the total of your booking will appear at the bottom of the screen.
                        </WizardText>

                        <UiSpace height={30} />

                        <WizardText>
                            Unselect hours or dates by click on them again.
                        </WizardText>

                    </UiView>

                    <UiSpace height={30} />

                    <UiImage
                        src={require('../../assets/images/bookingWizard/secondScreeen.png')}
                        type={'local'}
                        resizeMode="contain"
                        className={styles.image}
                    />

                </>
            )
        },
        {
            title: "Make payment",
            align: "top",
            render: () => (
                <>
                    <UiSpace height={30} />

                    <UiImage
                        src={require('../../assets/images/bookingWizard/thirdScreeen.png')}
                        type={'local'}
                        resizeMode="contain"
                        className={styles.image}
                    />

                    <UiView className={styles.inner}>

                        <WizardHeading>Cashless transaction</WizardHeading>

                        <WizardText>
                            Easily choose payment options, either via FPX (bank transfer) or your debit/credit card.
                        </WizardText>

                    </UiView>

                </>
            )
        },
        {
            title: "Invite your friends",
            align: "bottom",
            render: () => (
                <>
                    <UiView className={styles.inner}>

                        <UiSpace height={30} />

                        <WizardHeading>Invite your friends to come and play</WizardHeading>

                        <WizardText>
                            Manage your attendance list by sharing the invite link to your friends that comes with a passcode. Forget managing lists through whatsapp.
                        </WizardText>

                        <UiSpace height={30} />

                    </UiView>

                    <UiImage
                        src={require('../../assets/images/bookingWizard/screeenFour.png')}
                        type={'local'}
                        resizeMode={'contain'}
                        className={styles.image}
                    />

                </>
            )
        },
        {
            title: "Relive the moments",
            align: "top",
            buttonLabel: "LET’S BOOK MY FIRST SLOT",
            render: () => (
                <>
                    <UiSpace height={30} />

                    <UiImage
                        src={require('../../assets/images/bookingWizard/screenFive.png')}
                        type={'local'}
                        resizeMode={'contain'}
                        className={styles.image}
                    />

                    <UiView className={styles.inner}>

                        <WizardHeading>Video or it didn’t happen</WizardHeading>

                        <WizardText>
                            WIth Huddle+, you can relive the moments over and over again with a digital recording of your session. It comes together with a dedicated viewing page and you can chat (and brag) with all your friends.
                        </WizardText>

                    </UiView>

                </>
            )
        }
    ]

    return (
        <Modal
            show={show}
            onClose={onClose}
        >

            <Wizard slides={slides} onClose={onClose} />

        </Modal>
    )
}

interface CourtBookingWizardProps {
    [key: string]: any
}

export default CourtBookingWizard;