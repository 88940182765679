import React, { useState } from "react";
import { UiView, UiLink } from "@dex/bubl-ui";
import Slider from "react-slick";

import styles from "./FeaturedSlider.module.scss";

import FeaturedGrouCardElement from '../../elements/FeaturedGroupCard/FeaturedGroupCard';

// react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeaturedSlider: React.FC<FeaturedSliderProps> = (props) => {

    const [activeSlide, setActiveSlide] = useState(0);

    let slider: Slider | null;

    const settings = {
        className: "center",
        centerMode: false,
        infinite: props.itemsData && props.itemsData.length > 2,
        centerPadding: "0",
        slidesToShow: 2,
        speed: 500,
        autoplaySpeed: 2000,
        autoplay: true,
        touchMove: true,
        draggable: true,
        focusOnSelect: true,
        dotsClass: "slick-dots",
        afterChange: (current: number) => setActiveSlide(current),
        initialSlide: 0,
        adaptiveHeight: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (

        <UiView>

            <UiView className={styles.wrap}>

                <UiView className={['container']}>

                    <Slider
                        ref={(c) => { slider = c; }}
                        {...settings}
                    >

                        {props.itemsData.map((item: any, index: number) => (

                            <UiView key={index}>

                                <FeaturedGrouCardElement
                                    path={item.path}
                                    title={item.title}
                                    buttonText={item.buttonText}
                                    image={item.image}
                                />

                            </UiView>

                        ))}

                    </Slider>

                    <UiView className={styles.bulletContainer}>

                        {props.itemsData.map((_: any, index: number) => (

                            <UiLink
                                onClick={() => { slider?.slickGoTo(index) }}
                                key={index}
                                className={index === activeSlide ? styles.bulletActive : styles.bullet}
                            />

                        ))}

                    </UiView>

                </UiView>

            </UiView>

        </UiView>

    );
};

interface FeaturedSliderProps {
    [key: string]: any;
}

export default FeaturedSlider;
