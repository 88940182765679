import React, { useEffect, useMemo } from "react";
import { UiView } from "@dex/bubl-ui";

import styles from "./CommunitiesPanel.module.scss";

import Panel from "../../elements/Panel/Panel";
import CommunityCard from "../../elements/CommunityCard/CommunityCard";
import Grid from "../../elements/Grid/Grid";

import HostsApi from "../../apis/HostsApi";

const CommunitiesPanel: React.FC<CommunitiesPanelProps> = (props) => {

    let { set, heading, filters, id } = props;

    filters = {
        limit: 12,
        ...filters || {}
    };

    if (set === "top") {

        heading = "Top Communities";

        filters.featured = true;

    } else if (set === "my") {

        heading = "My Communities";

    }

    const list = HostsApi.list();

    useEffect(() => {

        list.run({
            params: filters
        });

    }, []);

    return useMemo(() => (

        <Panel
            id={id}
            heading={heading}
            edge="sides"
        >

            <Grid
                columns={9}
                gap={2}
            >

                {(list.data || []).map((item: any, index: number) => (

                    <UiView
                        className="item"
                        key={index}
                    >

                        <CommunityCard
                            title={item.title}
                            image={item.logo}
                            path={`/community/${item.id}/${item.slug}`}
                        />

                    </UiView>

                ))}

            </Grid>

        </Panel>

    ), [list.data, list.loading]);

};

interface CommunitiesPanelProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default CommunitiesPanel;
