import React, { useCallback, useEffect, useMemo } from "react";

import styles from "./MySessions.module.scss";

import Space from "../../elements/Space/Space";
import EventsPanel from "../EventsPanel/EventsPanel";

import { UiCol, UiRow, UiText, UiView } from "@dex/bubl-ui";
import ProfileApi from "../../apis/ProfileApi";
import Grid from "../../elements/Grid/Grid";
import EventCard from "../../elements/EventCard/EventCard";
import DropSelect from "../../elements/DropSelect/DropSelect";
import { useState } from "react";

const MySessions: React.FC<MySessionsProps> = (props) => {

    const sessions = ProfileApi.mySessions();

    const [status, setStatus] = useState("upcoming");

    useEffect(() => {

        if (sessions.data) sessions.reset();

        sessions.run({
            params: {
                status: status,
            }
        });

    }, [status]);

    const handleStatusChange = useCallback((item: any) => {

        setStatus(item.value);

    }, []);

    return useMemo(() => (

        <>

            <UiRow>

                <UiCol>

                    <UiText tag="h3" className={styles.heading}>My Sessions</UiText>

                </UiCol>

                <UiCol grow={1}>

                    <DropSelect
                        items={[
                            { value: "upcoming", label: "Upcoming" },
                            { value: "past", label: "Past" },
                        ]}
                        defaultValue={status}
                        onChange={handleStatusChange}
                    />

                </UiCol>

            </UiRow>

            <Space height={2} />

            {!sessions.data && <Space height={6} />}

            {sessions.data &&

                <Grid
                    columns={1}
                    gap={2}
                    fixed={true}
                >

                    {sessions.data.map((session: any, index: any) => (

                        <UiView className={"item"} key={index}>

                            <EventCard
                                session={session}
                                path={`auto`}
                                badge={true}
                            />

                        </UiView>

                    ))}

                </Grid>
            }

        </>

    ), [sessions]);

};

interface MySessionsProps {
    [key: string]: any;
}

export default MySessions;
