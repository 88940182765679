import React from "react";
import { UiText, UiView } from "@dex/bubl-ui";

import styles from "./OrderDetails.module.scss";
import moment from "moment";

const OrderDetails: React.FC<OrderDetailsProps> = (props) => {

    const { order } = props;

    return (

        <UiView className={styles.container}>

            <UiView className={styles.detailContainer}>

                <UiText className={styles.headingText}>ORDER #</UiText>

                <UiText className={styles.detailText}>{order.id}</UiText>

            </UiView>

            <UiView className={styles.detailContainer}>

                <UiText className={styles.headingText}>DATE</UiText>

                <UiText className={styles.detailText}>{moment(order.created.date).format('DD/MM/YYYY')}</UiText>

            </UiView>

            <UiView className={styles.detailContainer}>

                <UiText className={styles.headingText}>STATUS</UiText>

                <UiText className={styles.detailText}>{order.status.toUpperCase()}</UiText>

            </UiView>

            <UiView className={styles.detailContainer}>

                <UiText className={styles.headingText}>TRANSACTION DATE</UiText>

                <UiText className={styles.detailText}>{moment(order.datePaid).format('LLLL')}</UiText>

            </UiView>

            <UiView className={styles.detailContainer}>

                <UiText className={styles.headingText}>TO</UiText>

                <UiText className={styles.detailText}>{order.user.fullName}</UiText>

                <UiText className={styles.descriptionText}>{order.user.email}</UiText>

            </UiView>

            {order.host &&
                <UiView className={styles.detailContainer}>

                    <UiText className={styles.headingText}>HOST</UiText>

                    <UiText className={styles.detailText}>{order.host.orgName}</UiText>

                </UiView>
            }

            <UiView className={styles.detailContainer}>

                <UiText className={styles.headingText}>ITEM</UiText>

                <UiText className={styles.detailText}>{order.item.title}</UiText>

                <UiText className={styles.descriptionText}>{order.item.description}</UiText>

            </UiView>

            <UiView className={styles.detailContainer}>

                <UiText className={styles.headingText}>AMOUNT</UiText>

                <UiText className={styles.detailText}>{order.currency} {order.total}</UiText>

            </UiView>

        </UiView>

    );
};

interface OrderDetailsProps {
    [key: string]: any;
}

export default OrderDetails;
