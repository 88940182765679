import { Events, UiForm, UiLink, UiText, UiView } from '@dex/bubl-ui';
import React, { useCallback, useState } from 'react'
import { IoMdClose } from 'react-icons/io';
import LeagueSeasonTeamApi from '../../apis/LeagueSeasonTeamApi';
import Button from '../../elements/Button/Button';
import Divider from '../../elements/Divider/Divider';
import Modal from '../../elements/Modal/Modal';
import Space from '../../elements/Space/Space';

import styles from './LeagueTeamRegisterPlayerLeave.module.scss'

const LeagueTeamRegisterPlayerLeave: React.FC<LeagueTeamRegisterPlayerLeaveProps> = (props) => {

    const { team, handleRefresh } = props;

    const [show, setShow] = useState(false);

    const leave = LeagueSeasonTeamApi.leaveTeam(team.id);

    const handleLeave = () => {

        leave.run({
            onComplete: (res) => {

                Events.emit("ui", {
                    action: "success",
                    timeout: 3000,
                    message: "Successfully Left Roster"
                });

                handleRefresh();

            }
        })

    }

    return (
        <>
            <Button
                label={"Leave This Roster"}
                size={"large"}
                block={true}
                onClick={setShow.bind(null, true)}
                theme={"primary"}
                className={styles.button}
            />

            <Space height={2} />

            <Modal
                show={show}
                onClose={setShow.bind(null, false)}
            >

                {show &&
                    <UiView className={styles.popup}>

                        <UiLink
                            onPress={setShow.bind(null, false)}
                            className={styles.close}
                        >

                            <IoMdClose />

                        </UiLink>

                        <Divider
                            title={"Leave Roster"}
                        />

                        <UiView className={styles.content}>

                            <UiText className={styles.text}>Are you sure you want to leave {team?.name}</UiText>

                            <Space height={4} />

                            <Button
                                label={"Confirm & Leave Roster"}
                                size={"large"}
                                block={true}
                                type={"primary"}
                                onClick={handleLeave}
                            />

                        </UiView>

                    </UiView>

                }

            </Modal>

        </>
    )
}

interface LeagueTeamRegisterPlayerLeaveProps {
    [key: string]: any;
}

export default LeagueTeamRegisterPlayerLeave