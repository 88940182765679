import React from "react";
import { UiText, UiView, UiRow, UiCol } from "@dex/bubl-ui";

import styles from "./OrderSummary.module.scss";

const OrderSummary: React.FC<OrderSummaryProps> = (props) => {

    const { order } = props;

    return (

        <UiView className={styles.container}>

            <UiView className={styles.detailContainer}>

                <UiRow gutter={1} edge={true} align={"center"}>

                    <UiCol grow={1}>

                        <UiText className={styles.headingText}>Order #{order.id}</UiText>

                        <UiText className={styles.detailText}>{order.item.title}</UiText>

                        <UiText className={styles.descriptionText}>{order.item.description}</UiText>

                    </UiCol>

                    <UiCol>

                        <UiText className={styles.amountText}>{order.currency} {order.total}</UiText>

                    </UiCol>

                </UiRow>

            </UiView>

        </UiView>

    );
};

interface OrderSummaryProps {
    [key: string]: any;
}

export default OrderSummary;
