import React from "react";
import { UiButton, UiView } from "@dex/bubl-ui";
import BeatLoader from 'react-spinners/BeatLoader';

import styles from "./Button.module.scss";

const Button: React.FC<ButtonProps> = (props) => {

    const defaults = {
        loadingIndicator: <UiView className={styles.indicator} tag="span"><BeatLoader size={10} /></UiView>,
        theme: "primary"
    };

    const rest: any = { ...defaults, ...props };

    let classes = [styles.btn];

    const classesLabel = [styles.label];

    const iconPosition = props.iconAfter ? "after" : "before";

    if (rest.loading) {
        rest.label = rest.loadingIndicator;
        rest.icon = null;
        rest.disabled = true;
    }

    if (rest.block) classes.push(styles.block);
    if (rest.round) classes.push(styles.round);
    if (rest.size) classes.push(styles[rest.size]);
    if (rest.theme) classes.push(styles[rest.theme]);

    if (rest.className && Array.isArray(rest.className)) classes = [...classes, rest.className];

    if (rest.className && !Array.isArray(rest.className)) classes.push(rest.className);

    return (

        <UiButton
            className={classes.join(" ")}
            forwardedRef={rest.forwardedRef}
            path={rest.path}
            action={rest.action}
            onClick={rest.onClick || rest.onPress}
            type={rest.type}
            disabled={rest.disabled ? true : undefined}
            href={rest.href}
            target={rest.target}
        >

            {rest.icon && iconPosition === "before" &&
                <UiView tag="span" className={['icon', styles.iconBefore]}>
                    {rest.icon}
                </UiView>
            }

            <UiView tag="span" className={classesLabel}>{rest.label}</UiView>

            {rest.icon && iconPosition === "after" &&
                <UiView tag="span" className={['icon', styles.iconAfter]}>
                    {rest.icon}
                </UiView>
            }

        </UiButton>

    )

};

interface ButtonProps {
    label: string | React.ReactChild;
    type?: string,
    theme?: "primary" | "secondary" | "alt" | "faded" | "success",
    round?: boolean,
    size?: "small" | "medium" | "large" | "tiny",
    icon?: React.ReactChild,
    iconAfter?: boolean,
    block?: boolean,
    loading?: boolean,
    [key: string]: any;
}

export default Button;
