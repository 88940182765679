import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from '@dex/bubl-helpers';
import Header from '../../elements/Header/Header';
import LeagueSeasonTeamApi from '../../apis/LeagueSeasonTeamApi';
import OrderDetailsPopup from '../../groups/OrderDetailsPopup/OrderDetailsPopup';
import Container from '../../elements/Container/Container';
import Space from '../../elements/Space/Space';
import { UiCol, UiImage, UiLink, UiRow, UiText, UiView, useUser } from '@dex/bubl-ui';

import styles from "./LeagueRegisterTeamScreen.module.scss";
import { IoMdArrowBack } from 'react-icons/io';
import InlineLogin from '../../groups/InlineLogin/InlineLogin';
import Panel from '../../elements/Panel/Panel';
import LeagueTeamRegisterForm from '../../groups/LeagueTeamRegisterForm/LeagueTeamRegisterForm';
import LeagueTeamRegisterRoster from '../../elements/LeagueTeamRegisterRoster/LeagueTeamRegisterRoster';
import Footer from '../../elements/Footer/Footer';
import moment from 'moment';

const LeagueRegisterTeamScreen: React.FC<LeagueRegisterTeamScreenProps> = (props) => {

    const teamId = get(props, 'screenProps.routeParams.teamId');
    const orderId = get(props, 'screenProps.routeParams.orderId');
    const shareCode = get(props, 'screenProps.routeParams.code');

    const team = LeagueSeasonTeamApi.getTeam(teamId);

    const [key, setKey] = useState(0);

    const user = useUser("appUser");

    useEffect(() => {

        if (team.data) team.reset();

        team.run({
            cache: false,
            params: { include: ["season", "league", "invite", "player"] }
        });

    }, [key, teamId]);

    const handleRefresh = useCallback(() => {

        setKey(key + 1);

    }, [key])

    return useMemo(() => (
        <>

            {orderId &&
                <OrderDetailsPopup orderId={orderId} />
            }

            <Header />

            {team.data &&
                <Container>

                    <Space height={3} />

                    <UiLink
                        path={`/leagues/operator/${team.data.league.id}/${team.data.league.slug}`}
                        className={styles.back}
                    >

                        <IoMdArrowBack className={styles.backText} />

                        <UiText className={styles.backText}>Back</UiText>

                    </UiLink>

                    <Space height={3} />

                    <UiRow
                        className={styles.box}
                        edge={true}
                        gutter={2}
                    >

                        <UiCol
                            col={{ xs: 24, lg: 14 }}
                            className={styles.cover}
                        >

                            <Panel
                                heading={team.data.name}
                            >

                                <UiView className={styles.teamPanel}>

                                    <UiImage
                                        src={team.data.league?.logo}
                                        type={"thumb"}
                                        filters={{ width: 120, height: 120 }}
                                        resizeMode={"contain"}
                                    />

                                    <UiView className={styles.content}>
                                        <UiText className={styles.seasonTitle}>{team.data.season?.title}</UiText>
                                        {team.data.season?.startDate && <UiText className={styles.startDate}>Start Date: {moment(team.data.season.startDate).format("d MMMM YYYY")}</UiText>}
                                        <UiText className={styles.description}>{team.data.league?.description}</UiText>
                                    </UiView>

                                </UiView>

                            </Panel>

                            <Space />

                            {!user.loggedIn &&
                                <>
                                    <InlineLogin />
                                </>
                            }

                            {user.loggedIn &&
                                <>

                                    <LeagueTeamRegisterRoster
                                        title={"Roster"}
                                        team={team.data}
                                        shareCode={shareCode}
                                        handleRefresh={handleRefresh}
                                    />

                                </>
                            }


                        </UiCol>

                        <UiCol
                            col={{ xs: 24, lg: 10 }}
                            className={styles.cover}
                        >

                            <LeagueTeamRegisterForm
                                team={team.data}
                                handleRefresh={handleRefresh}
                            />

                            <Space />


                        </UiCol>

                    </UiRow>

                </Container>
            }

            <Footer />

        </>

    ), [team, props]);

}

interface LeagueRegisterTeamScreenProps {
    [key: string]: any
}

export default LeagueRegisterTeamScreen;