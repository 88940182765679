import React, { useMemo, useCallback, useState } from "react";
import { UiCol, UiLink, UiRow, UiText, useAsyncEffect, useUser } from "@dex/bubl-ui";
import { get } from "@dex/bubl-helpers";
import { IoMdArrowBack } from "react-icons/io";
import { config } from "@dex/bubl-web";
import moment from "moment";

import Container from "../../elements/Container/Container";
import Header from "../../elements/Header/Header";
import Footer from "../../elements/Footer/Footer";
import Space from "../../elements/Space/Space";
import Panel from "../../elements/Panel/Panel";
import InlineLogin from "../../groups/InlineLogin/InlineLogin";
import VenueLiveStreamJoin from "../../groups/VenueLiveStreamJoin/VenueLiveStreamJoin";
import SessionInfo from "../../elements/SessionInfo/SessionInfo";
import EventCard from "../../elements/EventCard/EventCard";
import VenueLiveStreamApi from "../../apis/VenueLiveStreamApi";
import OrderDetailsPopup from "../../groups/OrderDetailsPopup/OrderDetailsPopup";
import ChatThread from "../../groups/ChatThread/ChatThread";
import VenueBookingVideo from "../../groups/VenueBookingVideo/VenueBookingVideo";

import styles from "./VenueLiveStreamScreen.module.scss";
import GoogleAd from "../../elements/GoogleAd/GoogleAd";

const VenueLiveStreamScreen: React.FC<VenueLiveStreamScreenProps> = (props) => {

    const user = useUser("appUser");

    const streamId = get(props, 'screenProps.routeParams.streamId');

    const orderId = get(props, 'screenProps.routeParams.orderId');

    const stream = VenueLiveStreamApi.getOne(streamId);

    const [key, setKey] = useState(0);

    useAsyncEffect((isMounted) => {

        if (stream.data) stream.reset();

        stream.run({
            cache: false,
            onComplete: (response: any) => {

                if (!isMounted()) return;

                config.setMeta({
                    title: response.sessionGroup.title,
                    desc: moment(response.dateTime).format('ddd, DD MMMM hh:mm a') + ' - ' + moment(response.endDateTime).format('hh:mm a') + ' at ' + response.sessionGroup.location,
                    image: response.sessionGroup.cover || response.sessionGroup.thumbnail
                });

            }
        });

    }, [key, streamId]);

    console.log(stream.data);

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    return useMemo(() => (

        <>

            {orderId &&
                <OrderDetailsPopup orderId={orderId} />
            }

            <Header />

            {stream.data &&

                <Container>

                    <Space height={3} />

                    <UiLink
                        path={`/venues/court/${stream.data.sessionGroup.id}/${stream.data.sessionGroup.slug}`}
                        className={styles.back}
                    >

                        <IoMdArrowBack className={styles.backText} />

                        <UiText className={styles.backText}>Back</UiText>

                    </UiLink>

                    <Space height={3} />

                    <UiRow
                        className={styles.box}
                        edge={true}
                        gutter={2}
                    >

                        <UiCol
                            col={{ xs: 24, lg: 14 }}
                            className={styles.cover}
                        >

                            {user.loggedIn && stream.data.isAttending &&
                                <VenueBookingVideo data={stream.data} />
                            }

                            <Panel
                                heading="Your Selected Stream"
                            >

                                <EventCard
                                    session={stream.data}
                                    hideImage={true}
                                    hideStatus={true}
                                />

                            </Panel>

                            <Space />

                            {!user.loggedIn &&
                                <>
                                    <InlineLogin />
                                </>
                            }

                            {user.loggedIn &&
                                <>

                                    <VenueLiveStreamJoin
                                        key={key}
                                        session={stream.data}
                                        handleRefresh={upKey}
                                    />

                                </>
                            }


                        </UiCol>

                        <UiCol
                            col={{ xs: 24, lg: 10 }}
                            className={styles.cover}
                        >

                            {stream.data.threadId &&
                                <>

                                    <ChatThread
                                        threadId={stream.data.threadId}
                                    />

                                    <Space />

                                </>
                            }

                            {(stream.data.sessionGroup.address || stream.data.sessionGroup.email || stream.data.sessionGroup.contact) &&
                                <>
                                    <SessionInfo
                                        session={stream.data}
                                    />
                                </>
                            }

                            <Space />

                            <GoogleAd
                                slot="sidebar"
                            />

                        </UiCol>

                    </UiRow>

                </Container>

            }

            <Footer />

        </>

    ), [stream, key]);
};

interface VenueLiveStreamScreenProps {
    [key: string]: any;
}

export default VenueLiveStreamScreen;
