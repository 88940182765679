import React, { useCallback, useState, useEffect } from "react";
import nl2br from "react-nl2br";
import { UiImage, UiText, UiView, UiRow, UiCol, useUser, useLink } from "@dex/bubl-ui";
import Button from "../../elements/Button/Button";
import FollowApi from "../../apis/FollowApi";

import styles from "./OrgIntro.module.scss";
import Space from "../Space/Space";

const OrgIntro: React.FC<OrgIntroProps> = (props) => {

    const user = useUser("appUser");

    const linkTo = useLink();

    const [isFollowed, setIsFollowed] = useState(false);

    const follow = FollowApi.follow();

    const followed = FollowApi.followed();

    useEffect(() => {

        if (user.loggedIn) {

            followed.run({
                params: {
                    hostId: props.hostId,
                },
                onComplete: (response: any) => {

                    if (response) setIsFollowed(true)

                }
            });

        }

    }, [user.loggedIn]);

    const handleFollow = useCallback(() => {

        if (user.loggedIn) {

            follow.run({
                params: {
                    hostId: props.hostId,
                },
                onComplete: (response: any) => {

                    setIsFollowed(response ? true : false)

                }
            });

        } else {

            linkTo("/login", "replace", { returnTo: window.location.pathname });

        }

    }, [user.loggedIn]);

    const buttonClasses = [styles.followButton];
    if (isFollowed) buttonClasses.push(styles.followed);

    return (

        <UiRow gutter={4} className={styles.box} align={"center"}>

            <UiCol col={{ xs: 8, sm: 6, md: 5 }} align={"center"}>

                <UiImage
                    className={styles.image}
                    type={'thumb'}
                    filters={{ width: 200, height: 200 }}
                    resizeMode="contain"
                    src={props.image}
                />

            </UiCol>

            <UiCol col={{ xs: 16, sm: 18, md: 19 }}>

                <UiView className={styles.textWrapper}>

                    <Button
                        label={isFollowed ? "Following" : "Follow"}
                        type="submit"
                        block={false}
                        className={buttonClasses.join(' ')}
                        onClick={handleFollow}
                    />

                    <UiText className={styles.title}>{props.title}</UiText>

                    {props.content &&
                        <>

                            <Space />

                            <UiText className={styles.content}>{nl2br(props.content)}</UiText>

                        </>
                    }

                </UiView>

            </UiCol>

        </UiRow>

    );
};

interface OrgIntroProps {
    [key: string]: any;
}

export default OrgIntro;
