import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UiText, UiView, useInterval } from "@dex/bubl-ui";

import styles from "./ChatThread.module.scss";
import Panel from "../../elements/Panel/Panel";
import Space from "../../elements/Space/Space";
import ChatRoomMessages from "../ChatRoomMessages/ChatRoomMessages";
import ChatRoomMessageForm from "../ChatRoomMessageForm/ChatRoomMessageForm";
import ChatApi from "../../apis/ChatApi";

const ChatThread: React.FC<ChatThreadProps> = (props) => {

    let { threadId } = props;

    const [messages, setMessages] = useState<any>([]);

    const thread = ChatApi.getOne(threadId);

    const getMessages = ChatApi.getMessages(threadId);

    useEffect(() => {

        thread.run({
            onComplete: (response) => {

                setMessages(response.messages);

            }
        });

    }, [threadId]);

    const fetchRecentMessages = useCallback(() => {

        if (getMessages.loading) return;

        const last = messages.length ? messages[0] : thread.data;

        if (!last) return;

        getMessages.run({
            params: {
                after: last.date
            },
            onComplete: (response) => {

                setMessages((messages: any) => [...response, ...messages]);

            }
        });

    }, [messages, thread.data, getMessages.loading]);

    useInterval(() => {

        fetchRecentMessages();

    }, 8000); //8 seconds

    return useMemo(() => (
        <UiView className={styles.mainContainer}>
            <UiView className={styles.outerContainer}>
                {/* <Panel
            // heading={"Top Chat"}
            > */}

                <UiView className={styles.chatContainer}>

                    <ChatRoomMessages
                        messages={messages}
                    />

                    <Space />

                </UiView>

                <ChatRoomMessageForm
                    threadId={threadId}
                    onRefresh={fetchRecentMessages}
                />

                {/* </panel> */}
            </UiView>
        </UiView>
    ), [thread.data, messages, fetchRecentMessages]);

};

interface ChatThreadProps {
    set?: string;
    heading?: string;
    [key: string]: any;
}

export default ChatThread;
