import React, { useCallback } from 'react';
import { UiView, UiText, useUser, useLink } from '@dex/bubl-ui';
import { MdAddShoppingCart } from "react-icons/md";

import Button from '../Button/Button';

import styles from "./CartSelected.module.scss";
import { useMemo } from 'react';

const CartSelected: React.FC<CartSelectedProps> = (props) => {

    const user = useUser("appUser");

    const linkTo = useLink();

    const { cart, buttonLabel, loading, handleCheckout } = props;

    const handleBuyNow = useCallback(() => {

        if (user.loggedIn) {

            handleCheckout(cart);

        } else {

            linkTo("/login", "replace", { returnTo: window.location.pathname });

        }

    }, [user.loggedIn, handleCheckout, cart]);

    return useMemo(() => (

        <UiView className={[styles.container, props.className]}>

            <UiView className={styles.textContainer}>

                <UiView className={styles.row}>

                    <UiText className={styles.labelSmall}>SELECTED SLOT</UiText>

                    <UiText className={styles.labelSmall}>TOTAL COST</UiText>

                </UiView>

                <UiView className={styles.row}>

                    <UiText className={styles.labelLarge}>
                        {cart.label}
                    </UiText>

                    <UiText className={styles.labelLarge}>
                        RM {cart.total}
                    </UiText>

                </UiView>

            </UiView>

            <UiView>

                <Button
                    label={buttonLabel}
                    theme={'alt'}
                    block={true}
                    onClick={handleBuyNow}
                    loading={loading}
                    icon={<MdAddShoppingCart name="add-shopping-cart" size={18} />}
                    iconAfter
                />

            </UiView>

        </UiView>

    ), [props, cart, handleBuyNow]);

}

interface CartSelectedProps {
    [key: string]: any,
}

export default CartSelected;