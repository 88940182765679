import React, { useCallback, useRef, useState } from 'react';
import { UiButton, UiCol, UiForm, UiImage, UiLink, UiRow, UiSpace, UiText, UiView, useForm, useUser } from '@dex/bubl-ui';
import { BiSend } from "react-icons/bi";
import ChatApi from '../../apis/ChatApi';
import { BeatLoader } from 'react-spinners';
import ReactEmojiTextArea from "@nikaera/react-emoji-textarea";

import styles from './ChatRoomMessageForm.module.scss';

const ChatRoomMessageForm: React.FC<ChatRoomMessageFormProps> = (props) => {

    const { threadId, onRefresh } = props;

    const form = useForm({});

    const user = useUser("appUser");

    const inputRef: any = useRef();

    const addMessage = ChatApi.addMessage(threadId);
    // //button emoji
    const [showEmoji, setShowEmoji] = useState(false);

    const [hasContent, setHasContent] = useState(false);

    const onSubmit = useCallback((values) => {

        const data = {
            content: inputRef.current.value
        }

        addMessage.run({
            data: data,
            onComplete: (response: any) => {

                if (onRefresh) onRefresh();

                form.handleChange({ name: "content", value: "" });

                inputRef.current.value = "";

                setHasContent(false);

                setShowEmoji(false);

                form.setKey();

            }
        })

    }, [onRefresh]);

    const onContentChange = useCallback((value) => {

        setHasContent(value ? true : false);

    }, []);

    const onClickEmoji = useCallback((value) => {

        setShowEmoji(false);

    }, []);


    return (

        <UiForm
            form={form}
            onSubmit={onSubmit}
            loading={addMessage.loading}
        >

            <UiRow
                className={styles.formInner}
            >

                <UiCol className={styles.formProfileImage}>

                    <UiImage
                        type="profile"
                        src={{ fileId: user.get('id') }}
                        filters={{ width: 60, height: 60, textSize: 96 }}
                        className={styles.profileImage}
                    />

                </UiCol>
                <UiCol className={styles.formInput}>
                    <UiView className={styles.textArea}>
                        <ReactEmojiTextArea
                            key={form.key}
                            style={{ fontSize: "1em" }}
                            ref={inputRef}
                            rows={3}
                            cols={36}
                            onChange={onContentChange}
                            placeholder={"Send a message..."}
                            showPicker={showEmoji}
                            emojiPickerProps={{
                                showSkinTones: false,
                                showPreview: false,
                                onClick: onClickEmoji,
                                style: {
                                    position: 'absolute',
                                    bottom: '50%'
                                }
                            }}
                        />
                    </UiView>
                    <UiSpace height={10} className={styles.spacer} />

                    <UiView className={styles.formMisc}>

                        <UiView className={styles.emojiWrap}>

                            <UiLink
                                onClick={setShowEmoji.bind(null, !showEmoji)}
                                className={styles.emojiButton}
                            >
                                <UiImage
                                    className={styles.emojiIcon}
                                    src={require('../../assets/images/emoji.png')}
                                    type="local"
                                />
                            </UiLink>

                        </UiView>

                        <UiView className={styles.innerFormMisc}>
                            {addMessage.loading &&
                                <UiView className={styles.loading}>
                                    <BeatLoader size={8} />
                                </UiView>
                            }

                            {!addMessage.loading &&
                                <UiButton
                                    type={"submit"}
                                    className={!hasContent ? styles.buttonDisabled : styles.button}
                                    disabled={!hasContent}
                                >

                                    <BiSend className={styles.icon} />

                                </UiButton>
                            }
                        </UiView>
                    </UiView>
                </UiCol>
            </UiRow>

        </UiForm>

    )

}

interface ChatRoomMessageFormProps {
    [key: string]: any
}

export default ChatRoomMessageForm;
