import { UiLink, UiText, useUser } from "@dex/bubl-ui";
import React, { useMemo, useState } from "react";
import Grid from "../../elements/Grid/Grid";
import MemberCard from "../../elements/MemberCard/MemberCard";
import VenueBookingRemoveAttendee from "./VenueBookingRemoveAttendee";

import styles from "./VenueBookingJoin.module.scss";

const VenueBookingJoinAttendees: React.FC<VenueBookingJoinAttendeesProps> = (props) => {

    let { session, handleRefresh, setDidAction } = props;

    const user = useUser('appUser');

    const [toRemove, setToRemove] = useState<any>(null);

    return useMemo(() => (

        <>

            {toRemove &&
                <VenueBookingRemoveAttendee
                    attendee={toRemove}
                    session={session}
                    handleRefresh={handleRefresh}
                    setDidAction={setDidAction}
                    setToRemove={setToRemove}
                />
            }

            <Grid
                columns={4}
                gap={0}
                fixed={true}
            >

                {session.attendees.map((attendee: any, key: any) => (

                    <MemberCard
                        key={key}
                        size={"mini"}
                        name={attendee.fullName}
                        image={{
                            type: "profile",
                            src: { fileId: attendee.id, fileName: attendee.fullName },
                        }}
                        path={`/community/member/${attendee.id}/${attendee.slug}`}
                        overlay={(!session.isOwner || user.data.id === attendee.id) ? null : () => (

                            <UiLink
                                onPress={setToRemove.bind(null, attendee)}
                                className={styles.attendeeRemove}
                            >
                                <UiText>Remove</UiText>
                            </UiLink>

                        )}
                    />

                ))}

            </Grid>

        </>

    ), [session, toRemove]);

};

interface VenueBookingJoinAttendeesProps {
    [key: string]: any;
}

export default VenueBookingJoinAttendees;
