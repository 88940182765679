import React from "react";
import { UiImage, UiText, UiView, UiLink } from "@dex/bubl-ui";
import moment from 'moment';

import styles from "./NewsCard.module.scss";

const NewsCard: React.FC<NewsCardProps> = (props) => {

    return (

        <UiView className={styles.card}>

            <UiView className={styles.cardHead}>

                {props.category &&
                    <UiLink path={`/news/category/${props.category.id}/${props.category.slug}`} className={styles.headContainer}>

                        <UiImage
                            className={styles.headerLogo}
                            src={props.category.thumbnail}
                            filters={{ width: 100, height: 100 }}
                            type={'thumb'}
                        />

                        <UiText className={styles.headerText}>{props.category.title}</UiText>

                    </UiLink>
                }

                {props.time &&
                    <UiText className={styles.timeText}>{moment(props.time).startOf('minute').fromNow()}</UiText>
                }

            </UiView>

            <UiLink path={`/news/article/${props.id}/${props.slug}`}>
                <UiImage
                    className={styles.image}
                    src={props.image || {}}
                    filters={{ width: 300, height: 300 }}
                    type={'thumb'}
                />
            </UiLink>

            <UiLink path={`/news/article/${props.id}/${props.slug}`}>

                <UiText className={styles.title}>{props.title}</UiText>

                <UiText className={styles.content}>{props.content}</UiText>

            </UiLink>

            {props.sport &&
                <UiLink path={`/sports/${props.sport.id}/${props.sport.slug}`} className={styles.sportTag}>

                    <UiText>{props.sport.title}</UiText>

                </UiLink>
            }

        </UiView>

    );
};

interface NewsCardProps {
    title: string,
    content: string,
    id?: string,
    slug?: string,
    image?: any,
    time?: string,
    category?: any,
    sport?: any,
    [key: string]: any;
}

export default NewsCard;
