import { apiGet, options, response, apiPost } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'Form';

const FormApi = {

    getOne: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id,
            ...options
        });

    },

    addEntry: (id: string, options: options = {}): response => {

        return apiPost({
            url: endpoint + "/public/" + id + "/addEntry",
            ...options
        });

    }

}

export default FormApi;