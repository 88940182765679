import React from "react";
import { UiFieldTextArea } from "@dex/bubl-ui";

import styles from "../Fields.module.scss";

const FieldTextArea: React.FC<FieldTextAreaProps> = (props) => {

    return (

        <UiFieldTextArea
            {...props}
            classes={{
                wrap: [styles.wrap],
                label: [styles.label],
                input: [styles.input],
                error: [styles.error],
            }}
        />


    );
};

interface FieldTextAreaProps {
    [key: string]: any;
}

export default FieldTextArea;
