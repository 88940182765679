import React, { useState } from 'react';
import { UiCol, UiImage, UiLink, UiRow, UiText, UiView } from '@dex/bubl-ui';

import styles from "./Bracket.module.scss";

const BracketItem: React.FC<BracketItemProps> = (props) => {

    const { matchup, onPress, key } = props;

    return (

        <UiLink
            onClick={onPress.bind(null, matchup)}
            className={styles.item}
        >

            <UiView className={styles.itemMatchupKey}>
                {matchup.key}
            </UiView>

            {matchup.teams.map((team: any, index: any) => (

                <React.Fragment key={index}>


                    <UiView
                        key={index}
                        className={
                            [
                                styles.itemContainer,
                            ]
                        }
                    >


                        <UiView className={[styles.itemSeed]}>

                            <UiText className={[
                                styles.seedText,
                                matchup.winner && matchup.winner === team.id ? styles.winnerText : ''
                            ]}>
                                {team.seed || "-"}
                            </UiText>

                        </UiView>

                        <UiView className={styles.itemContent}>

                            <UiImage
                                className={styles.logo}
                                type={'thumb'}
                                resizeMode={"center"}
                                filters={{ width: 100, height: 100 }}
                                src={team.logo || { fileId: "#" }}
                            />

                            <UiView className={styles.name}>

                                <UiText className={[
                                    styles.nameText,
                                    matchup.winner && matchup.winner === team.id ? styles.winnerText : ''
                                ]}>
                                    {team.shortName || team.name}
                                </UiText>

                            </UiView>

                            {matchup.teams[0].id && matchup.teams[1].id &&
                                <UiView className={styles.score}>

                                    <UiText className={[
                                        styles.scoreText,
                                        matchup.winner && matchup.winner === team.id ? styles.winnerText : ''
                                    ]}>
                                        {team.score + ""}
                                    </UiText>

                                </UiView>
                            }

                        </UiView>

                    </UiView>

                    {!index &&
                        <UiView className={styles.separator} />
                    }

                </React.Fragment>

            ))}

        </UiLink>

    )
}

interface BracketItemProps {
    [key: string]: any
}

export default BracketItem;