import { UiView } from "@dex/bubl-ui";
import React from "react";
import Iframe from "react-iframe";
import { BeatLoader } from "react-spinners";

import styles from "./VideoBlock.module.scss";

const VideoBlock: React.FC<VideoBlockProps> = (props) => {

    let { source, id, autoplay } = props;

    let uri: any = "";

    let player = process.env.REACT_APP_ENV === "production" ? `https://player.huddlehuddle.com` : `https://player.stg.huddlehuddle.com`;

    if (process.env.REACT_APP_ENV === "local") player = 'http://localhost:3005'

    if (source === 'pixellot') id = encodeURIComponent(id);

    uri = `${player}/?source=${source}&id=${id}`;

    if (autoplay === false) uri += "&autoplay=false";

    return (

        <UiView
            className={styles.wrap}
        >

            <UiView className={styles.loading}>
                <BeatLoader size={10} />
            </UiView>

            <Iframe
                className={styles.frame}
                url={uri}
                width="100%"
                height="100%"
                scrolling="no"
                allow="autoplay; fullscreen;"
            />

        </UiView>

    );

};

interface VideoBlockProps {
    id: string;
    source: string;
    [key: string]: any;
}

export default VideoBlock;
