import React from "react";
import { UiImage, useStore } from "@dex/bubl-ui";

import styles from "./MyProfileSidebar.module.scss";

import ProfileOption from "../../elements/ProfileOption/ProfileOption";

import { FaUserAlt } from 'react-icons/fa';
import { BsLockFill } from 'react-icons/bs';
import { BsGearWideConnected } from 'react-icons/bs';
import { MdMail } from 'react-icons/md';
import { MdLocalGroceryStore } from 'react-icons/md';
import { BiMessageError } from 'react-icons/bi'

const MyProfileSidebar: React.FC<MyProfileSidebarProps> = (props) => {

    const [user] = useStore("appUser");

    const { active } = props;

    return (

        <>

            <ProfileOption
                heading="My sessions"
                desc="View my sessions"
                active={active === "my-sessions"}
                path={"/my-profile/my-sessions"}
            >

                <MdLocalGroceryStore />

            </ProfileOption>

            <ProfileOption
                heading="My active passes"
                desc="View my active passes"
                active={active === "my-active-passes"}
                path={"/my-profile/my-active-passes"}
            >

                <UiImage
                    type={'local'}
                    src={require('../../assets/images/pass-card.png')}
                />

            </ProfileOption>

            <ProfileOption
                heading="My athlete profiles"
                desc="Manage my athlete profiles"
                active={active === "athlete-profile"}
                path={"/my-profile/athlete-profiles"}
            >

                <FaUserAlt />

            </ProfileOption>

            <ProfileOption
                heading="My orders"
                desc="View Payments receipts"
                active={active === "my-orders"}
                path={"/my-profile/my-orders"}
            >

                <MdLocalGroceryStore />

            </ProfileOption>

            <ProfileOption
                heading="My announcements"
                desc="Recent announcements"
                active={active === "announcements"}
                path={"/my-profile/announcements"}
            >

                <BiMessageError />
                
            </ProfileOption>

            <ProfileOption
                heading="Edit my account"
                desc={user.get('email')}
                active={active === "edit-account"}
                path={"/my-profile/edit-account"}

            >

                <BsGearWideConnected />

            </ProfileOption>

            <ProfileOption
                heading="Change my password"
                desc="Change your secure password"
                active={active === "change-password"}
                path={"/my-profile/change-password"}
            >

                <BsLockFill />

            </ProfileOption>           

            <ProfileOption
                heading="Invite your friends"
                desc="Tell em about huddle!"
                active={active === "invite"}
                path={"/my-profile/invite"}
            >

                <MdMail />

            </ProfileOption>

        </>

    );
};

interface MyProfileSidebarProps {
    [key: string]: any;
}

export default MyProfileSidebar;
