import React from 'react';
import { UiImage, UiLink, UiText, UiView } from '@dex/bubl-ui';
import moment from 'moment';

import styles from './WatchCard.module.scss'

const WatchCard: React.FC<WatchCardProps> = (props) => {

    const { video } = props;

    return (
        <>

            <UiView className={styles.container}>

                <UiLink
                    path={`/watch/video/${video.id}/${video.slug}`}
                    className={styles.imageWrap}
                >

                    <UiView className={styles.imageContainer}>

                        <UiImage
                            src={video.thumbnail}
                            type={"thumb"}
                            resizeMode={"cover"}
                            filters={{ width: 400, height: 260 }}
                            className={styles.image}
                        />

                        {
                            video.isLive && <UiText className={styles.live}>LIVE</UiText>
                        }

                        <UiView className={styles.overlay} />

                    </UiView>

                    <UiText
                        tag={"span"}
                        className={styles.title}
                    >
                        {video.title}
                    </UiText>

                </UiLink>

                <UiView className={styles.contentInner}>

                    <UiLink path={`/community/${video.host.id}/${video.host.slug}`}>

                        <UiImage
                            src={video.host.logo}
                            className={styles.avatar}
                            filters={{ width: 100, height: 100 }}
                            type={'thumb'}
                        />

                    </UiLink>

                    <UiText
                        tag={"span"}
                        className={styles.meta}
                    >

                        <UiLink path={`/watch/host/${video.host.id}/${video.host.slug}`}>

                            <UiText
                                tag={"span"}
                                className={styles.hostName}
                            >
                                {video.host.title}
                            </UiText>

                        </UiLink>

                        <UiText>
                            {moment(video.date).fromNow()}
                        </UiText>

                    </UiText>

                </UiView>

            </UiView>


        </>
    )
}

interface WatchCardProps {
    [key: string]: any
}

export default WatchCard;