import React from "react";
import { UiImage, UiText, UiView } from "@dex/bubl-ui";
// @ts-ignore
import { Scrollbars } from 'react-custom-scrollbars';

import styles from "./StatsCard.module.scss";

const StatsCard: React.FC<StatsCardProps> = (props) => {

    const {stat} = props;

    return (

        <UiView className={styles.startsCardContainer}>

            <UiView className={styles.profileContainer}>

                <UiImage
                    className={styles.image}
                    type={'external'}
                    src={stat.thumbnailUrl}
                />

                <UiView className={styles.nameContainer}>

                    <UiText className={styles.nameHead}>{stat.position}</UiText>

                    <UiText className={styles.nameText}>{stat.name}</UiText>

                </UiView>

            </UiView>

            <UiView className={styles.scrollContainer}>

                <Scrollbars
                    autoHide
                >

                    <UiView className={styles.itemsContainer}>


                        {stat.stats.map((item:any, index:number) => (

                            <UiView UiView className={styles.itemContainer} key={index}>

                                <UiText className={styles.nameHead}>{item.label}</UiText>

                                <UiText className={styles.nameText}>{item.value}</UiText>

                            </UiView>

                        ))}

                    </UiView>

                </Scrollbars>

            </UiView>

        </UiView >


    );
};

interface StatsCardProps {
    title?: string,
    id?: string,
    name?: string,
    [key: string]: any;
}

export default StatsCard;
